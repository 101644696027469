import React, { useEffect, useState, useCallback } from "react";
import {
  Tooltip,
  IconButton,
  IconNames,
  TextField,
  StyledMenuItem,
} from "ui-elements";
import { withRouter } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import PropTypes from "prop-types";
import TimeChart from "./Timechart";
import { setNotification } from "Manager/Store/actions";
import { connect } from "react-redux";
import FlowsList from "./FlowsList";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import botManagerAPI from "bot-manager-api";

const TIME_PERIODS = {
  TODAY: "Today",
  YESTERDAY: "Yesterday",
  WEEKLY: "7D",
  MONTHLY: "30D",
};

const API_ENDPOINTS = {
  [TIME_PERIODS.TODAY]: `?time_period=today`,
  [TIME_PERIODS.YESTERDAY]: `?time_period=yesterday`,
  [TIME_PERIODS.WEEKLY]: `?time_period=7d`,
  [TIME_PERIODS.MONTHLY]: `?time_period=30d`,
};

const ENVS = [
  { label: "Live", value: "live" },
  { label: "Sandbox", value: "sandbox" },
];

const Dashboard = ({ flags, customerId, botId, setNotification }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(TIME_PERIODS.TODAY);
  const [env, setEnv] = useState(ENVS[0].value);

  useEffect(() => {
    reFetchData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [env, customerId, botId, selectedTab]);

  const getTotalData = (currentStat) => {
    const lowerCaseStat = currentStat?.toLowerCase();
    return data?.["totals"]?.[lowerCaseStat]?.["total"];
  };

  const getEscalationRate = () => {
    const totalEscalations = data?.totals?.escalations?.total;
    const totalSessions = data?.totals?.sessions?.total;

    if (totalSessions) {
      return ((totalEscalations / totalSessions) * 100).toFixed(2);
    }

    return "0.00";
  };

  const getDeltaFromTimePeriod = (statName) => {
    const lowerCaseStat = statName.toLowerCase();
    const entryValue = data["totals"]?.[lowerCaseStat];
    const value = parseFloat(entryValue?.total)
      ? (parseFloat(entryValue.delta) / parseFloat(entryValue.total)) * 100
      : 0;

    if (value > 0) {
      return {
        label: `+${value.toFixed(2)}%`,
        backgroundColor: "bg-green-200",
      };
    }

    if (value === 0) {
      return {
        label: "0%",
        backgroundColor: "bg-gray-200",
      };
    }

    return {
      label: `-${Math.abs(value).toFixed(2)}%`,
      backgroundColor: "bg-red-200",
    };
  };

  const getPreviousTimePeriodName = (previous = true) => {
    let result = "";

    if (selectedTab === TIME_PERIODS.WEEKLY) {
      result = !previous ? "current selected week" : "previous week";
    } else if (selectedTab === TIME_PERIODS.MONTHLY) {
      result = !previous ? "current selected month" : "previous month";
    } else {
      result = !previous ? "current selected day" : "previous day";
    }

    return result;
  };

  const reFetchData = useCallback(
    (isNotification) => {
      let type = API_ENDPOINTS[selectedTab];
      setLoading(true);

      botManagerAPI
        .getDashboardAnalytics(env, customerId, botId, type)
        .then((res) => {
          const cleanData = res?.data?.hourly_breakdown?.reduce((acc, el) => {
            const label = new Date(el.range[0]).toLocaleDateString();
            acc.push({
              sessions: el.sessions,
              escalations: el.escalations,
              day: el.range[0],
              range: el.range,
              label,
            });
            return acc;
          }, []);

          setData({ info: cleanData, ...res.data });
          if (isNotification) {
            setNotification({
              openNotification: true,
              notificationDuration: 3000,
              notificationTitle: `You have successfully fetched ${getPreviousTimePeriodName(
                false
              )}!`,
              notificationType: "success",
            });
          }
        })
        .catch((e) => console.error(e))
        .finally(() => {
          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [env, customerId, botId, selectedTab, setNotification, flags]
  );

  const handleChangeEnv = (targetEnv) => {
    setEnv(targetEnv);
  };

  return (
    <div className="h-full">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row">
          {Object.values(TIME_PERIODS).map((tab) =>
            !data ? (
              <Skeleton
                key={tab}
                variant="rect"
                animation="wave"
                height="30px"
                width="40px"
                className="last:rounded-tr last:rounded-br first:rounded-bl first:rounded-tl"
              />
            ) : (
              <Tooltip
                key={tab}
                label={
                  selectedTab === tab
                    ? `${new Date(
                        data?.totals?.range[0]
                      ).toLocaleDateString()} -> ${new Date(
                        data?.totals?.range[1]
                      )?.toLocaleDateString()}`
                    : "Select this time period to see date range"
                }
              >
                <div
                  onClick={() => setSelectedTab(tab)}
                  key={tab}
                  className={`border border-gray-800 bg-gray-50 px-3 text-center cursor-pointer py-2 last:rounded-tr last:rounded-br first:rounded-bl first:rounded-tl ${
                    selectedTab === tab &&
                    "bg-gray-100 ring-1 ring-gray-300 z-10"
                  }`}
                >
                  {tab}
                </div>
              </Tooltip>
            )
          )}
        </div>
        <div
          className={
            flags.pe19760
              ? "grid gap-4 items-center justify-center"
              : "flex flex-row gap-4 items-center justify-center"
          }
          style={
            flags.pe19760
              ? {
                  "grid-template-columns": "1fr auto",
                }
              : {}
          }
        >
          <TextField
            select
            label="Environment"
            name="env"
            value={env}
            variant="outlined"
            style={{ width: "10rem" }}
            onChange={({ target }) => handleChangeEnv(target.value)}
          >
            {ENVS.map((el) => (
              <StyledMenuItem key={el.value} value={el.value}>
                {el.label}
              </StyledMenuItem>
            ))}
          </TextField>
          <IconButton
            onClick={() => reFetchData(true)}
            iconName={IconNames.Redo}
          />
        </div>
      </div>
      <div className="flex flex-row gap-2 w-full mt-2.5">
        {!data || loading ? (
          <div className="flex flex-row w-full h-24 gap-4">
            <Skeleton
              variant="rect"
              animation="wave"
              width="33%"
              height="100%"
              className="rounded"
            />
            <Skeleton
              variant="rect"
              animation="wave"
              width="33%"
              height="100%"
              className="rounded"
            />
            <Skeleton
              variant="rect"
              animation="wave"
              width="33%"
              height="100%"
              className="rounded"
            />
          </div>
        ) : (
          [
            { name: "Sessions", value: "sessions" },
            { name: "Escalations", value: "escalations" },
            { name: "Escalation Rate", value: "escalation_rate" },
          ].map((stat) => (
            <div
              key={stat.name}
              className="flex flex-col w-1/3 p-4 pl-0 text-white rounded"
            >
              <p className="m-0 text-gray">{stat.name}</p>
              <h1 className="mt-2 mb-0 text-black">
                {stat.value === "escalation_rate"
                  ? `${
                      getEscalationRate() >= 0 ? getEscalationRate() : "0.00"
                    }%`
                  : getTotalData(stat.value)}
              </h1>
              {
                <div className="flex flex-row mt-1">
                  <div
                    className={`items-center justify-center ${
                      getDeltaFromTimePeriod(stat.value)?.backgroundColor
                    } w-min px-2 py-1 rounded-3xl`}
                  >
                    <p className="m-0 text-xs">
                      {getDeltaFromTimePeriod(stat.value)?.label}
                    </p>
                  </div>
                  <small className="ml-1 self-center text-xs text-gray">
                    From {getPreviousTimePeriodName()}
                  </small>
                </div>
              }
            </div>
          ))
        )}
      </div>
      <div className="flex mt-2.5 h-96 mb-4">
        <div className="flex bg-gray-50 rounded-lg w-full ring-1 ring-gray-300">
          {!data || loading ? (
            <Skeleton
              variant="rect"
              animation="wave"
              height="100%"
              width="100%"
              className="rounded"
            >
              <TimeChart data={[]} />
            </Skeleton>
          ) : (
            <TimeChart data={data.info || []} total={data.totals || {}} />
          )}
        </div>
      </div>
      {flags.pe18313 && data && <FlowsList flows={data.flows || []} />}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setNotification: (data) => dispatch(setNotification(data)),
});

const mapStateToProps = (state, ownProps) => {
  const { customerId, botId } = ownProps.match.params;
  const customer = state.manager.customers[customerId];
  const botData = customer ? customer.botsData[botId] : null;

  return {
    customerId: state.botUserSession?.user?.user_id,
    botId: botData?.bot?.id,
  };
};

Dashboard.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  setNotification: PropTypes.func,
  show: PropTypes.bool,
  flags: PropTypes.object,
  customerId: PropTypes.string,
  botId: PropTypes.string,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withLDConsumer()(Dashboard))
);
