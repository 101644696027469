/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from "react";
import Papa from "papaparse";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import {
  Button,
  TextInputField,
  Icon,
  IconNames,
  DataTable,
  DotMenu,
} from "ui-elements";
import PropTypes from "prop-types";
import { IntentModal } from "../modals/IntentModal";
import { StopModal } from "../modals/StopModal";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";
import {
  updateIntentData,
  setEditedStopWords,
  setAllIntents,
  setIfTrainingNeeded,
  setNotification,
  validateSolution,
} from "../../Store/actions";
import botManagerAPI from "../../../bot-manager-api";
import "../../Styles/Bot.scss";
import activityTracker from "../../../activityTracker";
import {
  NLU_INTENT_HEADERS_H,
  NLU_INTENT_HEADERS_SIMPLIFIED_H,
  BOT_VERSION_FLAGS,
} from "./defs";
import { dateCompare, getSoftTimestamp, debounce } from "../../utils/utils";
// csv file validator functions
import {
  validateFileHeaders,
  validateFileContent,
} from "../../utils/validator";
// TODO - Replace Datagrid with ui-elements Datagrid
// import { Datagrid } from "ui-elements/build/common/datagrid/datagrid";
// import { IconButton } from "ui-elements/build/common/buttons/icon-button";
// const UNESCAPE_CHAR_PATTERN = /("\s*|\n|,\s*)'(?=\s*[@\+\-=\|%])/gm;

// Invisible intent used for 'Add New Intent' view because utterances can't
// be added without defining intent first according to the data structure

const TEMP_INTENT = "ps_temp_intent_hidden";
const isValidIntentName = (name) => /^[a-z]+[a-z0-9 ]*$/gi.test(name);
const deepCloneObject = (obj) => JSON.parse(JSON.stringify(obj));

class Intents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showStopModal: false,
      modalData: {
        title: "",
        messageBody: "",
      },
      // TODO avoid deeply nested objects in state. causes unnecessary re-renders.
      // React.PureComponent? or take the nested objects to top level
      csvObject: {
        columns: NLU_INTENT_HEADERS_SIMPLIFIED_H,
        intents: {
          [TEMP_INTENT]: [],
        },
      },
      editingIntent: null,
      showNewUtteranceInput: false,
      tempIntentLabel: "",
      tempIntentNameError: "",
      intentToDelete: null,
      showEditOrAddNewIntent: false,
      isSavingStopwords: false,
      isLoadingStopwords: false,
      stopWordString: "",
      stopWordOriginalString: "",
      compilerVersion: BOT_VERSION_FLAGS.UNKNOWN,
      intentCreated: false,
      isLoadingIntents: false,
    };

    this.handleData = this.handleData.bind(this);
    this.typeFormatter = this.typeFormatter.bind(this);
    this.typeFormatterUtterance = this.typeFormatterUtterance.bind(this);
    this.typeLastUpdatedFormatter = this.typeLastUpdatedFormatter.bind(this);
    this.typeUtterenceFormatter = this.typeUtterenceFormatter.bind(this);
    this.deleteIntentList = this.deleteIntentList.bind(this);
    this.deleteUtterenceList = this.deleteUtterenceList.bind(this);
    this.handleUtterenceDelete = this.handleUtterenceDelete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onIntentSelection = this.onIntentSelection.bind(this);
    this.handleBackOption = this.handleBackOption.bind(this);
    this.handleIntentLabelChange = this.handleIntentLabelChange.bind(this);
    this.handleSaveIntent = this.handleSaveIntent.bind(this);
    this.addUtterence = this.addUtterence.bind(this);
    this.handleAddIntent = this.handleAddIntent.bind(this);
    this.openIntentLibrary = this.openIntentLibrary.bind(this);
    this.handleSaveUtterence = this.handleSaveUtterence.bind(this);
    this.handleStopModal = this.handleStopModal.bind(this);
    this.onStopCancel = this.onStopCancel.bind(this);
    this.onStopSave = this.onStopSave.bind(this);
    this.handleStopWordChange = this.handleStopWordChange.bind(this);
    this.openCSVFileDialog = this.openCSVFileDialog.bind(this);
    this.selectIntentFile = this.selectIntentFile.bind(this);
    this.uploadIntentFile = this.uploadIntentFile.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
  }

  async componentDidMount() {
    const {
      bot: { versions },
      latestVersion,
    } = this.props;
    if (versions.length) {
      this.getAllIntents();
      this.loadStopwords();
    }
    if (latestVersion && latestVersion.compilerVersion) {
      this.setState({ compilerVersion: latestVersion.compilerVersion });
    }
  }

  componentDidUpdate(prevProps) {
    // update compilerVersion value
    const prevCompilerVersion = prevProps.latestVersion
      ? prevProps.latestVersion.compilerVersion
      : -1;
    const currentLenCompilerVersion = this.props.latestVersion
      ? this.props.latestVersion.compilerVersion
      : -1;
    if (prevCompilerVersion !== currentLenCompilerVersion) {
      this.setState({ compilerVersion: currentLenCompilerVersion });
    }
  }

  async getAllIntents() {
    // const versions = this.props.bot.versions

    // if(!versions.length) { // new bot, no draft yet
    //   return
    // }
    this.setState({ isLoadingIntents: true });
    const promises = [];
    promises.push(
      new Promise((resolve, reject) => {
        botManagerAPI.getAllIntents(this.props.bot.id).then((response) => {
          resolve({ data: response.data });
        });
      })
    );
    const intentData = await Promise.all(promises);
    this.setState({ isLoadingIntents: false });
    this.handleData(intentData);
  }

  async createOneIntent(intentName, utterances) {
    // eslint-disable-next-line no-unused-vars
    const result = await new Promise((resolve, reject) => {
      botManagerAPI
        .createOneIntent(this.props.bot.id, {
          intent: intentName,
          utterances,
        })
        .then((response) => {
          resolve({ data: response.data });
        });
    });
    this.setState({ intentCreated: true });
  }

  async updateIntentName(prevName, intentName) {
    // eslint-disable-next-line no-unused-vars
    const result = new Promise((resolve, reject) => {
      botManagerAPI
        .updateOneIntent(this.props.bot.id, {
          old_intent: prevName,
          new_intent: intentName,
        })
        .then((response) => resolve({ data: response.data }));
    });
  }

  async deleteOneIntent(intentName) {
    // eslint-disable-next-line no-unused-vars
    const result = new Promise((resolve, reject) => {
      botManagerAPI
        .deleteOneIntent(this.props.bot.id, intentName)
        .then((response) => resolve({ data: response.data }));
    });
  }

  async loadStopwords() {
    if (this.state.isLoadingStopwords) return;
    const versions = this.props.bot.versions;
    const index = versions.length === 1 ? 0 : versions.length - 1;
    const versionId = `${this.props.bot.id}.${this.props.bot.versions[index]}`;
    this.setState({ isLoadingStopwords: true });
    const stopWordString = await botManagerAPI.getWords(versionId);
    this.setState({ isLoadingStopwords: false });
    if (
      stopWordString.statusCode === 200 &&
      stopWordString.data.data.length > 0
    ) {
      const stopData = stopWordString.data.data.join(",");
      this.setState({
        stopWordString: stopData,
        stopWordOriginalString: stopData,
        versionId: versionId,
      });
    }
  }
  handleData(intentData) {
    const allIntents = intentData[0].data;
    const csvObject = {
      columns: ["Utterance", "Intent"],
      intents: {},
    };

    if (allIntents) {
      allIntents.forEach((item, index) => {
        csvObject.intents[item.intent] = (item.utterances || []).map(
          (utt, index) => ({
            ...utt,
            id: `${item.intent}_saved_${index + 1}`,
          })
        );
      });
    }

    csvObject.intents[TEMP_INTENT] = [];

    this.setState({ csvObject });
    this.props.setAllIntents(csvObject);
  }

  typeFormatter(value, row) {
    const finalStirng = value.replace(/_/g, " ");
    return (
      <div className="table-header-data">
        <span>{finalStirng}</span>
        <br />
        <span className="table-header-subdata">{row.intent}</span>
      </div>
    );
  }

  typeFormatterUtterance(value, { utterances }) {
    if (!utterances.length) return null;

    const firstThreeUtterances = utterances.slice(0, 3);
    const restOfUtterances = utterances.slice(3);

    return (
      <div className="uttranceRowData">
        {firstThreeUtterances.join(" • ")}
        {restOfUtterances.length ? ` • ${restOfUtterances.length} more` : ``}
      </div>
    );
  }

  typeLastUpdatedFormatter(value) {
    if (value && value.length) {
      return getSoftTimestamp(value[0].time);
    }

    return null;
  }

  typeUtterenceFormatter(value, row, extra, index) {
    const originalValue = row.utterance;
    return (
      <div
        className={
          row.error === undefined || ""
            ? "utterance_data"
            : "utterance_data utterance_error"
        }
        onBlur={(e) => this.handleSaveUtterence(row, e, originalValue, index)}
      >
        <TextInputField
          type="text"
          value={row.utterance}
          placeholder="Training utterance"
          hasError={row.error}
          errorMessage={row.error}
          fill={true}
        />
      </div>
    );
  }

  deleteIntentList(value, row) {
    return (
      <div>
        <Icon
          name={IconNames.Close}
          width="24px"
          viewBox="0 0 24 24"
          fill="#0a3ab4"
          onClick={(e) => this.handleModal(row.intent, e)}
        />
      </div>
    );
  }

  deleteUtterenceList(value, row, enumObject, index) {
    return (
      <div>
        <Icon
          name={IconNames.Close}
          width="24px"
          viewBox="0 0 24 24"
          fill="#0a3ab4"
          onClick={(e) => this.handleUtterenceDelete(row, e, index)}
        />
      </div>
    );
  }

  getIntents() {
    const {
      csvObject: { intents },
    } = this.state;
    return intents;
  }

  getUtterancesForEditingIntent() {
    const { editingIntent } = this.state;
    return this.getIntents()[editingIntent];
  }

  setUtterancesForEditingIntent(intent, utterances, optionalStates = {}) {
    return new Promise((resolve, reject) => {
      this.setState(
        (prevState) => {
          return {
            csvObject: {
              ...prevState.csvObject,
              intents: {
                ...prevState.csvObject.intents,
                [intent]: utterances,
              },
            },
            ...optionalStates,
          };
        },
        () => {
          this.props.updateIntentData(this.state.csvObject);
          resolve();
        }
      );
    });
  }

  async handleUtterenceDelete(row, e, index) {
    e.stopPropagation();
    const newUtterances = this.getUtterancesForEditingIntent().filter(
      ({ id }) => id !== row.id
    );

    // delete utterance from intent in Postgre
    const { editingIntent } = this.state;
    await botManagerAPI.deleteOneUtteranceFromIntent(
      this.props.bot.id,
      editingIntent,
      row.utterance
    );

    this.setUtterancesForEditingIntent(editingIntent, newUtterances, {
      showNewUtteranceInput: false,
    }).then(() => {
      this.validateUtterances();
      this.props.setIfTrainingNeeded(true);
    });
  }

  handleDelete(intent) {
    const intentsClone = deepCloneObject(this.getIntents());

    delete intentsClone[intent];

    this.setState(
      (prevState) => {
        return {
          csvObject: {
            ...prevState.csvObject,
            intents: intentsClone,
          },
        };
      },
      () => {
        this.props.updateIntentData(this.state.csvObject);
        this.deleteOneIntent(intent);
        const { id, version } = this.props.botData.latestVersion;
        this.props.validateSolution(id, version, true);
      }
    );
  }

  handleModal(intentToDelete, e) {
    e.stopPropagation();

    this.setState({
      showModal: true,
      modalData: {
        title: "Delete Intent",
        messageBody:
          "Are you sure you want to delete this Intent? Any utterances associated with it will also be removed.",
      },
    });
    this.setState({ intentToDelete });
  }

  onCancel() {
    this.setState({ showModal: false });
  }

  onConfirm() {
    this.setState({
      showModal: false,
      intentToDelete: null,
      editingIntent: null,
      showEditOrAddNewIntent: false,
    });

    this.handleDelete(this.state.intentToDelete);
  }

  onIntentSelection(data) {
    this.setState({
      showEditOrAddNewIntent: true,
      showNewUtteranceInput: false,
      editingIntent: data.intent,
      tempIntentNameError: "",
      tempIntentLabel: this.getIntentLabel(data.intent),
      intentCreated: true,
    });
  }

  validateUtterances() {
    const editingUtterances = deepCloneObject(
      this.getUtterancesForEditingIntent()
    );
    const uniqueUtterances = {};

    editingUtterances.forEach((utteranceObj) => {
      utteranceObj.error = undefined;
      if (uniqueUtterances[utteranceObj.utterance]) {
        utteranceObj.error =
          "TRAINING UTTERANCE is identical to another utterance.";
      } else {
        uniqueUtterances[utteranceObj.utterance] = true;
      }
      if (!utteranceObj.utterance.length) {
        utteranceObj.error = "TRAINING UTTERANCE cannot be blank.";
      }
    });

    const { editingIntent } = this.state;

    this.setUtterancesForEditingIntent(editingIntent, editingUtterances);
  }

  hasErrors() {
    const { editingIntent, tempIntentNameError } = this.state;
    const utterancesForEditingIntent = this.getUtterancesForEditingIntent();

    // Clicking back without entering anything for utterance or intent
    if (editingIntent === TEMP_INTENT) {
      return utterancesForEditingIntent.length;
    }

    if (tempIntentNameError.length) {
      return true;
    }

    if (!utterancesForEditingIntent.length) {
      return true;
    }
    if (
      utterancesForEditingIntent.some(
        (utterance) => utterance.error && utterance.error.length
      )
    ) {
      return true;
    }

    return false;
  }

  handleBackOption() {
    if (this.hasErrors()) {
      return;
    }
    this.setState({
      editingIntent: null,
      showEditOrAddNewIntent: false,
    });
    const { id, version } = this.props.botData.latestVersion;
    this.props.validateSolution(id, version, true);
  }

  handleIntentLabelChange(tempIntentLabel) {
    this.setState({ tempIntentLabel, tempIntentNameError: "" });
  }

  getIntentLabel(intentName) {
    if (!intentName) return "";

    return intentName.replace(/_/g, " ");
  }

  getIntentName(intentLabel) {
    if (!isValidIntentName(intentLabel)) {
      return "";
    }
    return intentLabel.toLowerCase().replace(/ /g, "_");
  }

  async handleSaveIntent() {
    const {
      editingIntent,
      csvObject: { intents },
      tempIntentLabel,
      intentCreated,
    } = this.state;
    const intentName = this.getIntentName(tempIntentLabel);

    if (intentName === editingIntent) {
      return;
    }

    if (!isValidIntentName(tempIntentLabel)) {
      this.setState({
        tempIntentNameError:
          "Intent name can have a-z, 0-9 or 'space' characters only",
      });
      return;
    }

    if (intents[intentName]) {
      this.setState({
        tempIntentNameError:
          "Intent already exists. Please use a different intent",
      });
      return;
    }

    const intentsClone = deepCloneObject(intents);

    // don't delete temp_intent, instead create new intent with data entered
    if (editingIntent === TEMP_INTENT) {
      Object.assign(intentsClone, {
        [intentName]: intentsClone[editingIntent],
      });
      intentsClone[editingIntent] = [];

      if (intentsClone[intentName] && intentsClone[intentName].length > 0) {
        // save newly created intent to postgre
        await this.createOneIntent(
          intentName,
          intentsClone[intentName].map(({ utterance }) => utterance)
        );

        const { id, version } = this.props.botData.latestVersion;
        this.props.validateSolution(id, version, true);
      }
    } else {
      delete Object.assign(intentsClone, {
        [intentName]: intentsClone[editingIntent],
      })[editingIntent];
      if (intentCreated) {
        // update intent name
        await this.updateIntentName(editingIntent, intentName);

        const { id, version } = this.props.botData.latestVersion;
        this.props.validateSolution(id, version, true);
      }
    }

    this.setState(
      (prevState) => {
        return {
          csvObject: {
            ...prevState.csvObject,
            intents: intentsClone,
          },
          editingIntent: intentName,
          tempIntentNameError: "",
        };
      },
      () => {
        this.props.updateIntentData(this.state.csvObject);
      }
    );
  }

  addUtterence(e) {
    e.preventDefault();
    const { editingIntent, showNewUtteranceInput } = this.state;

    if (!editingIntent) {
      console.error("Intent not selected");
      return;
    }

    if (this.getUtterancesForEditingIntent().length && this.hasErrors()) {
      console.error("Utterances have validation errors");
      return;
    }

    if (showNewUtteranceInput) return;

    this.setState({ showNewUtteranceInput: true });
  }

  handleAddIntent() {
    this.setState({
      showEditOrAddNewIntent: true,
      showNewUtteranceInput: true,
      tempIntentLabel: "",
      tempIntentNameError: "",
      editingIntent: TEMP_INTENT,
      intentCreated: false,
    });
  }

  openIntentLibrary() {
    const url =
      "https://docs.google.com/spreadsheets/d/1_PEarJaHxNQ61EsgzGKR-EZ8WEeggkeTkLqr0ZlO8Gw/edit#gid=868882789";
    window.open(url);
  }

  handleSaveUtterence({ id }, e, originalValue, index) {
    const inputUtterance = e.target.value;
    const { editingIntent, intentCreated } = this.state;
    const editingUtterances = deepCloneObject(
      this.getUtterancesForEditingIntent()
    );
    const utteranceToUpdate = editingUtterances.findIndex(
      (utterance) => utterance.id === id
    );

    if (utteranceToUpdate >= 0) {
      if (originalValue !== inputUtterance) {
        editingUtterances[utteranceToUpdate] = {
          ...editingUtterances[utteranceToUpdate],
          utterance: inputUtterance,
          time: new Date().toISOString(),
        };

        if (intentCreated) {
          botManagerAPI.updateOneUtteranceToIntent(this.props.bot.id, {
            intent: editingIntent,
            old_utterance: originalValue,
            new_utterance: inputUtterance,
          });

          const { id, version } = this.props.botData.latestVersion;
          this.props.validateSolution(id, version, true);
        }
      }
    } else {
      // new utterance
      // save new utterance to postgres
      const newUtterance = {
        utterance: inputUtterance,
        type: "",
        error: undefined,
        id: `${editingIntent}_new_${editingUtterances.length + 1}`,
        time: new Date().toISOString(),
      };
      editingUtterances.push(newUtterance);

      if (inputUtterance) {
        if (intentCreated) {
          botManagerAPI.addOneUtteranceToIntent(this.props.bot.id, {
            intent: editingIntent,
            utterance: inputUtterance,
          });
        } else if (editingIntent !== TEMP_INTENT) {
          this.createOneIntent(
            editingIntent,
            editingUtterances.map(({ utterance }) => utterance)
          );
        }
        const { id, version } = this.props.botData.latestVersion;
        this.props.validateSolution(id, version, true);
      }
    }

    this.setUtterancesForEditingIntent(editingIntent, editingUtterances, {
      showNewUtteranceInput: false,
    }).then(() => {
      this.validateUtterances();
    });
  }

  getIntentTable() {
    const rows = [];
    const intents = this.getIntents();

    Object.keys(intents).forEach((intent, index) => {
      if (intent !== TEMP_INTENT) {
        rows.push({
          intent,
          utterances: intents[intent].map(({ utterance }) => utterance),
          lastUpdated: intents[intent]
            .sort((d1, d2) => dateCompare(d1.time, d2.time, -1))
            .slice(0, 1),
          lastUpdatedId: `lastUpdated-${index}`,
        });
      }
    });
    return rows;
  }

  getUtterancesTableForIntent(intent) {
    let rows = [];
    const {
      csvObject: { intents },
      showNewUtteranceInput,
    } = this.state;

    // when Adding New Intent
    if (!intent) {
      if (showNewUtteranceInput) {
        rows.push({ utterance: "" });
      }
      return rows;
    }

    const editingIntent = intents[intent];
    if (!editingIntent) {
      console.error("Unable to get editing intent");
      return [];
    }
    rows = editingIntent.map((utterance, index) => ({
      ...utterance,
      timeId: `${utterance.time}-${index}`,
    }));

    if (showNewUtteranceInput) {
      rows.push({ utterance: "" });
    }
    return rows;
  }

  // TODO - Replace Datagrid with ui-elements Datagrid
  // utteranceInputRenderer = (row, val, keyVal, index, onCellChange) => (
  //   <TextInputField
  //     key={row.id}
  //     type="text"
  //     value={row.utterance}
  //     hasError={row.error}
  //     placeholder="Training Utterance"
  //     errorMessage={row.error}
  //     onBlur={(updatedVal) => this.handleSaveUtterence(row, updatedVal, val, index, keyVal)}
  //   />
  // )

  // deleteUtteranceRenderer = (row, val, keyVal, index, onCellChange) => (
  //   <IconButton name={IconNames.Close} onClick={(e) => this.handleUtterenceDelete(row, e, index)}/>
  // );

  // intentTableColumnRenderer = () => ([
  //   {
  //     key: 'utterance',
  //     label: 'Utterance',
  //     width: '4fr',
  //     component: this.utteranceInputRenderer,
  //     isSortable: true,
  //   },
  //   {
  //       key: 'delete_utterance',
  //       label: 'Intent',
  //       width: '2fr',
  //       component: this.deleteUtteranceRenderer,
  //       hideHeaderColumn: true
  //   },
  // ]);

  handleStopModal(e) {
    if (this.props.bot.versions.length) {
      this.loadStopwords();
      this.setState({ showStopModal: true });
    }
  }

  onStopCancel() {
    this.setState({
      showStopModal: false,
      stopWordString: this.state.stopWordOriginalString,
    });
  }

  async onStopSave() {
    this.setState({ isSavingStopwords: true });

    const formatedString = this.state.stopWordString
      .trim()
      .replace(/(,{2,}|, +,?)/g, ",")
      .replace(/(^,|,$)/g, "");
    const updatedStopWord = formatedString.split(",");
    const draftVersion = this.props.botData.latestVersion.id;
    // eslint-disable-next-line no-unused-vars
    const data = await botManagerAPI.postStopWords(
      draftVersion,
      updatedStopWord
    );
    this.props.setEditedStopWords(
      formatedString !== this.state.stopWordOriginalString
    );

    this.setState({
      showStopModal: false,
      stopWorddata: updatedStopWord,
      isSavingStopwords: false,
    });
  }

  handleStopWordChange(e) {
    const value = e.target.value || "";
    const stopWordString = value.replace(/[^a-z,' ]+/gi, "");

    this.setState({ stopWordString });
  }

  getBotName() {
    const parts = this.props.bot.id.split(".");
    return parts.length >= 2 ? parts[1] : parts[0];
  }

  openCSVFileDialog() {
    this.intentUpload.click();
  }

  async selectIntentFile() {
    if (this.intentUpload.files && this.intentUpload.files[0]) {
      try {
        const isValidNormalHeaders = await validateFileHeaders(
          this.intentUpload.files[0],
          NLU_INTENT_HEADERS_H
        );
        const isValid = await validateFileHeaders(
          this.intentUpload.files[0],
          NLU_INTENT_HEADERS_SIMPLIFIED_H
        );
        const isValidSimplifiedHeaders = !isValidNormalHeaders && isValid;

        if (!isValidNormalHeaders && !isValidSimplifiedHeaders) {
          this.props.setNotification({
            openNotification: true,
            notificationDuration: 6000,
            notificationTitle: "CSV file header does not match template",
            notificationType: "error",
          });
        } else {
          const isFileContentValid = await validateFileContent(
            this.intentUpload.files[0],
            NLU_INTENT_HEADERS_SIMPLIFIED_H
          );

          if (isFileContentValid) {
            // Amplitude event on Intent csv upload
            const fileObj = this.intentUpload.files[0];
            activityTracker.logEvent(
              activityTracker.eventTypeNames.UPLOAD_TEMPLATE,
              {
                fileSize: fileObj.size,
                fileType: fileObj.type,
                fileName: fileObj.name,
                solutionName: this.getBotName(),
              }
            );

            // upload draft
            await this.uploadIntentFile(isValidSimplifiedHeaders);
          } else {
            this.props.setNotification({
              openNotification: true,
              notificationDuration: 6000,
              notificationTitle: "CSV file does not match template",
              notificationType: "error",
            });
          }
        }
      } catch (err) {
        console.log(err);

        this.props.setNotification({
          openNotification: true,
          notificationDuration: 6000,
          notificationTitle: "Something went wrong. Please try again!",
          notificationType: "error",
        });
      }
    }
  }

  async uploadIntentFile(isValidSimplifiedHeaders) {
    this.props.setNotification({
      openNotification: true,
      notificationDuration: null,
      notificationTitle: "Uploading...",
      notificationType: "loading",
    });
    const formData = new FormData();
    formData.append("trainingIntentFile", this.intentUpload.files[0]);
    formData.append(
      "simplifiedTrainingData",
      isValidSimplifiedHeaders ? "simplified" : "non-simplified"
    );
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await botManagerAPI.populateTrainingIntentDB(
        this.props.bot.id,
        formData
      );
      if (response.status === 200) {
        await this.getAllIntents();
        this.props.setNotification({
          openNotification: true,
          notificationDuration: 6000,
          notificationTitle:
            "You have successfully uploaded your replacement intent document.",
          notificationType: "success",
        });
        const {
          latestVersion: { id, version },
        } = this.props.botData;
        this.props.validateSolution(id, version, true);
      } else {
        this.props.setNotification({
          openNotification: true,
          notificationDuration: 6000,
          notificationTitle:
            "There was a problem uploading your replacement intent document.",
          notificationType: "error",
        });
      }
    } catch (err) {
      console.log(err);
      this.props.setNotification({
        openNotification: true,
        notificationDuration: 6000,
        notificationTitle: err,
        notificationType: "error",
      });
    }
  }

  onMenuClick(menu) {
    switch (menu.value) {
      case "upload":
        this.openCSVFileDialog();
        break;
      default:
    }
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    const {
      showModal,
      showStopModal,
      stopWordString,
      modalData,
      editingIntent,
      tempIntentLabel,
      tempIntentNameError,
      showEditOrAddNewIntent,
      isSavingStopwords,
      isLoadingStopwords,
      isLoadingIntents,
    } = this.state;
    const { notificationType, flags } = this.props;
    const intentTable = this.getIntentTable();
    const intentTableData = this.getUtterancesTableForIntent(editingIntent);
    const utterancesLength = intentTableData.filter(
      (utterance) => utterance.utterance !== ""
    ).length;
    const canDeleteIntent = editingIntent !== TEMP_INTENT;
    const isStopwordsNotAvailable =
      this.props.bot &&
      this.props.bot.versions &&
      this.props.bot.versions.length === 0;

    const Columns = [
      {
        title: "Name",
        dataIndex: "intent",
        key: "intent",
        sorter: (a, b) => a.intent.localeCompare(b.intent),
        render: (data) => this.typeFormatter(data.intent, data),
      },
      {
        title: "Utterances",
        dataIndex: "utterance",
        key: "utterance",
        render: (data) => this.typeFormatterUtterance(data.utterance, data),
      },
      {
        title: "Last Updated",
        dataIndex: "lastUpdated",
        key: "lastUpdatedId",
        width: "180px",
        render: (data) => this.typeLastUpdatedFormatter(data.lastUpdated),
      },
      {
        title: "",
        dataindex: "delete",
        key: "delete",
        showOnHover: true,
        align: "right",
        width: "60px",
        render: (data) => this.deleteIntentList({}, data),
      },
    ];

    const UtterenceColumns = [
      {
        title: "Utterance",
        dataIndex: "utterance",
        key: "utterance",
        sorter: (a, b) =>
          a.utterance ? a.utterance.localeCompare(b.utterance) : -1,
        render: (data, index) =>
          this.typeUtterenceFormatter(data.utterance, data, {}, index),
      },
      {
        title: "Last Updated",
        dataIndex: "time",
        key: "timeId",
        width: "180px",
        render: (data) => (data.time ? getSoftTimestamp(data.time) : null),
      },
      {
        title: "",
        dataIndex: "delete",
        key: "delete",
        align: "right",
        width: "60px",
        render: (data, index) => this.deleteUtterenceList("", data, {}, index),
      },
    ];

    const menuData = [
      {
        icon: IconNames.Upload2,
        menu: "Upload Replacement CSV",
        value: "upload",
      },
    ];

    return (
      <Fragment>
        <IntentModal
          show={showModal}
          title={modalData.title}
          messageBody={modalData.messageBody}
          onCancel={this.onCancel}
          onConfirm={this.onConfirm}
        />
        <StopModal
          show={showStopModal}
          title={modalData.title}
          stopWordString={stopWordString}
          messageBody={modalData.messageBody}
          onCancel={this.onStopCancel}
          onConfirm={this.onStopSave}
          isSaving={isSavingStopwords}
          isLoading={isLoadingStopwords}
          handleStopWordChange={this.handleStopWordChange}
          style={{ marginLeft: "25%" }}
        />
        {!showEditOrAddNewIntent ? (
          <div>
            <div className="Intent_content">
              <div className="description-line">
                <div className="section-title">
                  <h3>NLU Intents</h3>
                </div>
                <p>
                  An intent is the broadest type of classification in NLU. It
                  typically embodies a purpose or goal expressed in a user’s
                  utterance. The intents you identify for your microapp will
                  determine the conversation flows you need to create; they also
                  might determine which back-end systems you will need to
                  integrate with in order to complete customer requests.
                </p>
                <p>
                  You may also modify
                  <span
                    className={`text_anchor stopword_link ${
                      isStopwordsNotAvailable ? "disabled" : ""
                    }`}
                    onClick={(e) => this.handleStopModal(e)}
                  >
                    {" "}
                    Stopwords
                  </span>
                  , words that the NLU intentionally ignores to improve intent
                  classification.
                </p>
              </div>
              <div className="description-line">
                <p className="body-header">Not sure where to begin?</p>
                <p>
                  Start building your microapp from one of our templates to give
                  yourself a head start.
                </p>
                <Button onClick={this.openIntentLibrary}>
                  {" "}
                  Intent Library{" "}
                </Button>
              </div>
            </div>
            <div className="Intent_length">
              <div className="Add_intent_button">
                <h4>{intentTable.length} Intents</h4>
                {intentTable.length > 0 && (
                  <div className="Intent_button_with_table">
                    {notificationType === "loading" ? (
                      <Icon
                        fill="#0a3ab4"
                        name={IconNames.Loading}
                        rotateIcon
                      />
                    ) : (
                      <DotMenu menus={menuData} onClick={this.onMenuClick} />
                    )}
                    <Button onClick={this.handleAddIntent}>Add Intent</Button>
                    <br />
                  </div>
                )}
                {!isLoadingIntents && intentTable.length === 0 ? (
                  <div>Your microapp currently does not have any Intents.</div>
                ) : (
                  <DataTable
                    className="script-file-list"
                    columns={Columns}
                    dataSource={intentTable}
                    isLoading={isLoadingIntents}
                    rowKey="intent"
                    rowHeight={76}
                    bordered={false}
                    onRowClick={this.onIntentSelection}
                  />
                )}
              </div>
              {!isLoadingIntents && intentTable.length === 0 && (
                <div className="Intent_button_without_table">
                  <div className="back-div">
                    <Button onClick={this.handleAddIntent}> Add Intent </Button>
                    <Button hollow onClick={this.openCSVFileDialog}>
                      Upload File
                    </Button>
                  </div>
                </div>
              )}
              <input
                id="myInput"
                type="file"
                ref={(ref) => (this.intentUpload = ref)}
                style={{ display: "none" }}
                accept=".csv"
                onChange={this.selectIntentFile}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="back-div">
              <Icon
                name={IconNames.ChevronLeft}
                width="24px"
                viewBox="0 0 24 24"
                fill="#0a3ab4"
              />
              <span
                className="text_anchor"
                onClick={debounce(this.handleBackOption, 500)}
              >
                Back to Intents
              </span>
            </div>
            <div className="uttrence_title">
              <div className="intent_title" onBlur={this.handleSaveIntent}>
                <TextInputField
                  type="text"
                  value={tempIntentLabel}
                  onChange={(e) => this.handleIntentLabelChange(e)}
                />
                <span>{this.getIntentName(tempIntentLabel)}</span>
              </div>
              {canDeleteIntent ? (
                <div className="back-div">
                  <div
                    className="text_anchor"
                    onClick={(e) => this.handleModal(editingIntent, e)}
                  >
                    <Icon
                      name={IconNames.Close}
                      width="24px"
                      viewBox="0 5 24 24"
                      fill="#0a3ab4"
                    />
                    Delete Intent
                  </div>
                </div>
              ) : null}
            </div>
            <div className="Intent_subtitle">INTENT</div>
            {tempIntentNameError.length ? (
              <div className="errorSection">
                <p className="table-subdata">{tempIntentNameError}</p>
              </div>
            ) : null}
            <div className="uttrence_title">
              <h4>{`${utterancesLength} Training Utterances`}</h4>
              <div className="Intent_button_with_table">
                <Button onClick={debounce(this.addUtterence, 500)}>
                  Add Utterance
                </Button>
              </div>
            </div>
            <DataTable
              className="training-utterance-table"
              columns={UtterenceColumns}
              dataSource={intentTableData}
              rowKey="utterance"
              rowHeight={76}
              bordered={false}
            />
          </div>
        )}
      </Fragment>
    );
  }
}

Intents.propTypes = {
  bot: PropTypes.object,
  latestVersion: PropTypes.object,
  show: PropTypes.bool,
  flags: PropTypes.object,
  notificationType: PropTypes.string,
  setAllIntents: PropTypes.func,
  updateIntentData: PropTypes.func,
  setIfTrainingNeeded: PropTypes.func,
  setEditedStopWords: PropTypes.func,
  setNotification: PropTypes.func,
  botData: PropTypes.object,
  updateSolutionInfo: PropTypes.func,
  validateSolution: PropTypes.func,
};

const mapStateToProps = (state) => ({
  notificationType: state.manager.notificationType,
});

const mapDispatchToProps = (dispatch) => ({
  updateIntentData({ columns, intents }) {
    const rows = [];

    for (const intent in intents) {
      intents[intent].forEach(({ utterance, type }) => {
        const row = [utterance, intent];

        if (columns[2] === NLU_INTENT_HEADERS_H[2]) {
          row.push(type);
        }
        rows.push(row);
      });
    }

    return dispatch(
      updateIntentData({
        data: Papa.unparse({
          fields: columns,
          data: rows,
        }),
        isEdited: true,
      })
    );
  },

  setEditedStopWords(isEdited = false) {
    return dispatch(setEditedStopWords(isEdited));
  },

  setAllIntents(csvObject) {
    return dispatch(setAllIntents(csvObject));
  },

  setIfTrainingNeeded(isNeeded = false) {
    return dispatch(setIfTrainingNeeded(isNeeded));
  },
  setNotification(data) {
    return dispatch(setNotification(data));
  },
  validateSolution: (botId, version, autoTrain) =>
    dispatch(validateSolution(botId, version, autoTrain)),
});

export default withLDConsumer()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Intents))
);
