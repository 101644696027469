import React, { Component } from "react";
import JSZip from "jszip";
import { Button, Glyphicon, Modal, ProgressBar } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  isPermitted,
  PermissionAction,
  PermissionResource,
} from "bot-user-session";
import { DataTable } from "ui-elements";
import { BLACK_LIST_FILE_NAMES, BOT_TEMPLATES } from "./defs";
import IconDownloadArrow from "../../Assets/download_arrow.svg";
import botManagerAPI from "../../../bot-manager-api";
import { saveFile } from "../../utils/utils";

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDownloadModal: false,
      prepProgress: 0,
      downloadProgress: 0,
      zipProgress: 0,
    };
    this.restoreFormatter = this.restoreFormatter.bind(this);
    this.downloadAllFiles = this.downloadAllFiles.bind(this);
    this.versionFormatter = this.versionFormatter.bind(this);
    this.revertSortFunc = this.revertSortFunc.bind(this);
  }

  restoreFormatter(cell, row) {
    if (
      this.props.latestVersion.version !== row.version ||
      !this.props.latestVersion.canBeUpdated
    ) {
      return (
        <span
          className="label restore-box"
          onClick={() => this.props.restore(row.version)}
        >
          Republish
        </span>
      );
    }
  }

  downloadAllFiles(verssion) {
    return async () => {
      this.setState({
        showDownloadModal: true,
        prepProgress: 0,
        downloadProgress: 0,
        zipProgress: 0,
      });
      const version = await botManagerAPI.getBotVersion(
        `${this.props.bot.id}.${verssion}`
      );
      this.setState({ prepProgress: 100 });
      if (version) {
        const promises = [];
        version.files.forEach((file) => {
          if (
            !file.endsWith("/") &&
            !BLACK_LIST_FILE_NAMES.some((f) => f === file)
          ) {
            promises.push(
              new Promise((resolve) => {
                botManagerAPI
                  .downloadTemplateFile(
                    `${this.props.bot.id}.${verssion}`,
                    file
                  )
                  .then((response) => {
                    resolve({ file, data: response.file_data });
                  });
              })
            );
          }
        });
        const [...filesData] = await Promise.all(promises);
        this.setState({ downloadProgress: 100 });
        const zip = new JSZip();
        filesData.forEach(({ file, data }) => {
          zip.file(file, data);
        });

        const zipContent = await new Promise((resolve) => {
          zip.generateAsync({ type: "blob" }).then((content) => {
            // see FileSaver.js
            resolve(content);
          });
        });
        this.setState({ zipProgress: 100 });
        saveFile(zipContent, `${this.props.bot.id}.${verssion}.zip`);
      }
      this.setState({ showDownloadModal: false });
    };
  }

  versionFormatter(cell) {
    return (
      <Button onClick={this.downloadAllFiles(cell)} style={{ border: "none" }}>
        <img alt="" src={IconDownloadArrow} className="download-arrow" />
        {cell}
      </Button>
    );
  }

  revertSortFunc(a, b) {
    return a.version.localeCompare(b.version, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  }

  render() {
    const { currentVersions, presVersions, show, bot, customerGesId } =
      this.props;
    const { prepProgress, downloadProgress, zipProgress } = this.state;
    const prepProgressActive = prepProgress > 0 && prepProgress < 100;
    const prepProgressDone = prepProgress === 100;
    const downloadProgressActive =
      downloadProgress > 0 && downloadProgress < 100;
    const downloadProgressDone = downloadProgress === 100;
    const zipProgressActive = zipProgress > 0 && zipProgress < 100;
    const zipProgressDone = zipProgress === 100;
    // const options = {
    //   onRowClick: null,
    //   defaultSortName: 'version',
    //   defaultSortOrder: 'desc'
    // };
    let botName = "";
    if (bot.id) {
      const parts = bot.id.split(".");
      botName = parts.length >= 2 ? parts[1] : parts[0];
    }

    function envsFormatter(cell) {
      return (
        <div className="env-version">
          {cell.map((c) => (
            <span key={c} className={`label ${c}`}>
              {c}
            </span>
          ))}
        </div>
      );
    }

    if (!show) {
      return null;
    }
    const isWritePermitted = isPermitted(
      PermissionResource.BOT,
      PermissionAction.WRITE,
      customerGesId
    );

    const Columns = [
      {
        title: "Version",
        dataIndex: "version",
        key: "version",
        width: "11%",
        sorter: this.revertSortFunc,
        render: (data) => this.versionFormatter(data.version),
      },
      {
        title: "",
        dataIndex: "envs",
        key: "envs",
        width: "23%",
        render: (data) => envsFormatter(data.envs),
      },
      {
        title: "Last Published",
        dataIndex: "lastPublished",
        key: "lastPublished",
        width: "45%",
        sorter: this.revertSortFunc,
      },
      {
        title: "",
        dataIndex: "publish",
        key: "publish",
        width: "21%",
        render: (data) => this.restoreFormatter({}, data),
      },
    ];

    return (
      <div className="overview">
        <div className="section-title">
          <h3>Overview</h3>
        </div>
        <p>
          Below are the current and previous versions of {botName} microapp. You
          can download different versions of your microapp versions, or restore
          a previous version.
        </p>

        <div className="container-table-header">
          <h4>Current Versions</h4>
        </div>
        {currentVersions && currentVersions.length > 0 ? (
          <DataTable
            className="current-version-list"
            columns={Columns}
            dataSource={(currentVersions || []).map((item, index) => ({
              ...item,
              id: index,
            }))}
            rowKey="id"
            bordered={false}
          />
        ) : (
          <>
            <br />
            <i>
              {" "}
              No microapps have been deployed yet.
              {isWritePermitted && (
                <span>
                  {" "}
                  Would you like to{" "}
                  <span
                    className="as-a-link"
                    onClick={() =>
                      this.props.onDrawerSelectionChanged(BOT_TEMPLATES)
                    }
                  >
                    {" "}
                    upload microapp
                  </span>
                  ?{" "}
                </span>
              )}
            </i>
          </>
        )}
        {presVersions && presVersions.length > 0 && (
          <>
            <div className="container-table-header">
              <h4>Previous Versions</h4>
            </div>
            <DataTable
              className="current-version-list"
              columns={Columns}
              dataSource={(presVersions || []).map((item, index) => ({
                ...item,
                id: index,
              }))}
              rowKey="id"
              bordered={false}
            />
          </>
        )}
        <br />
        <br />

        <Modal show={this.state.showDownloadModal} bsClass="manager modal">
          <Modal.Header>
            <Modal.Title>Download Microapp Version</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProgressBar
              now={prepProgress / 3 + downloadProgress / 3 + zipProgress / 3}
              active
              label={`${Math.round(
                prepProgress / 3 + downloadProgress / 3 + zipProgress / 3
              )}%`}
            />
            <div
              className={`bot-upload-modal-line ${
                prepProgressActive ? "active" : ""
              }`}
            >
              <div>Preparing files...</div>
              <div>
                {prepProgress < 100 ? `${prepProgress}%` : "Complete!"}{" "}
                {prepProgressDone && (
                  <Glyphicon glyph="glyphicon glyphicon-ok-sign" />
                )}
              </div>
            </div>
            <div
              className={`bot-upload-modal-line ${
                downloadProgressActive ? "active" : ""
              }`}
            >
              <div>Downloading files...</div>
              <div>
                {downloadProgress < 100 ? `${downloadProgress}%` : "Complete!"}{" "}
                {downloadProgressDone && (
                  <Glyphicon glyph="glyphicon glyphicon-ok-sign" />
                )}
              </div>
            </div>
            <div
              className={`bot-upload-modal-line ${
                zipProgressActive > 0 ? "active" : ""
              }`}
            >
              <div>Compressing files...</div>
              <div>
                {zipProgress < 100 ? `${zipProgress}%` : "Complete!"}{" "}
                {zipProgressDone && (
                  <Glyphicon glyph="glyphicon glyphicon-ok-sign" />
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

Overview.propTypes = {
  latestVersion: PropTypes.object,
  currentVersions: PropTypes.array,
  presVersions: PropTypes.array,
  customerGesId: PropTypes.string,
  show: PropTypes.bool,
  bot: PropTypes.object,
  restore: PropTypes.func,
  onDrawerSelectionChanged: PropTypes.func,
};

export default Overview;
