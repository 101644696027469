import React from "react";
import PropTypes from "prop-types";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { Nav } from "ui-elements";
import isUndefined from "lodash/isUndefined";
import NewHeader from "./NewHeader";
import OldHeader from "./OldHeader";

const Header = ({ flags, ...props }) => {
  if (isUndefined(flags.pe19774)) {
    return (
      <Nav>
        <Nav.Logo />
        <Nav.SolutionDropdown />
      </Nav>
    );
  }
  return flags.pe19774 ? (
    <NewHeader flags={flags} {...props} />
  ) : (
    <OldHeader {...props} />
  );
};

Header.propTypes = {
  flags: PropTypes.object.isRequired,
};

export default withLDConsumer()(Header);
