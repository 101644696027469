/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from "react";
import { Modal } from "react-bootstrap";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import {
  isPermitted,
  PermissionAction,
  PermissionResource,
} from "bot-user-session";
import NavigationPrompt from "react-router-navigation-prompt";
import Loader from "../Manager/Components/Loader";
import botManagerAPI from "../bot-manager-api";
import PreviewModal from "../Manager/Components/modals/PreviewPanel/PreviewModal";
import "../Manager/Styles/Bot.scss";
import {
  getCustomer,
  getCustomersIfNeeded,
  initBot,
  initBotIfNeeded,
  getPypes,
  updateStream,
  updatePypes,
  setPypeLoading,
  updateBot,
  getInterfaces,
  removeInterfaces,
  trainBot,
  setNotification,
  validateSolution,
} from "Manager/Store/actions";
import {
  BOT_DIALOG,
  BOT_ACTION,
  BOT_SETTINGS,
  BOT_FILES,
  BOT_OVERVIEW,
  BOT_TEMPLATES,
  BOT_TESTING,
  BOT_UPLOAD_STATE,
  BOT_VERSION_FLAGS,
  BOT_INTENTS,
  NLU_INTENT_HEADERS_H,
  NLU_INTENT_HEADERS_SIMPLIFIED_H,
  BOT_ENTITY,
  BOT_NLU_OVERVIEW,
  BOT_INTERFACES,
  BOT_CHANNELS,
  BOT_MONITOR,
} from "../Manager/Components/Bot/defs";
import { WarningModal } from "../Manager/Components/modals/WarningModal";
import PublishSolutionModal from "../Manager/Components/modals/PublishSolutionModal";
import Overview from "../Manager/Components/Bot/Overview";
import Files from "../Manager/Components/Bot/Files";
import Intents from "../Manager/Components/Bot/Intents";
import Entity from "../Manager/Components/Bot/Nlu/Entity";
import Dialog from "../Manager/Components/Bot/Dialog";
import Actions from "../Manager/Components/Bot/Actions";
import Test from "../Manager/Components/Bot/NLU";
import Settings from "../Manager/Components/Bot/Settings";
import Interfaces from "../Manager/Components/Bot/ChannelsOrInterfaces/Interfaces";
import Channels from "../Manager/Components/Bot/ChannelsOrInterfaces/Channels";
import NLUOverview from "../Manager/Components/Bot/Nlu/Overview";
import { Helmet } from "react-helmet";
import moment from "moment";
import activityTracker from "../activityTracker";
import { getIntentFormat } from "../Manager/Components/Bot/utils";
import {
  Button,
  Breadcrumb,
  List,
  ListItem,
  OutlinedButton,
  Tooltip,
  TextField,
  Modal as ModalUI,
  Stack,
  Dropdown,
  IconButton,
  IconNames,
  Nav,
} from "ui-elements";
import RedExclamationIcon from "../Manager/Assets/ico-exclamation-red.svg";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { backgroundGrey600 } from "utils/styleVariables.js";
import Monitor from "../Manager/Components/Bot/Monitor";
import PassthroughModal from "../Manager/Components/modals/PreviewPanel/PassthroughModal";

const ENVS = ["sandbox", "live"];

const DialogLazy = React.lazy(() => import("../Manager/Components/Bot/Dialog"));
const OverviewLazy = React.lazy(() =>
  import("../Manager/Components/Bot/Overview")
);
const IntentsLazy = React.lazy(() =>
  import("../Manager/Components/Bot/Intents")
);
const EntityLazy = React.lazy(() =>
  import("../Manager/Components/Bot/Nlu/Entity")
);
const FilesLazy = React.lazy(() => import("../Manager/Components/Bot/Files"));
const ActionsLazy = React.lazy(() =>
  import("../Manager/Components/Bot/Actions")
);
const TestLazy = React.lazy(() => import("../Manager/Components/Bot/NLU"));
const NLUOverviewLazy = React.lazy(() =>
  import("../Manager/Components/Bot/Nlu/Overview")
);
const SettingsLazy = React.lazy(() =>
  import("../Manager/Components/Bot/Settings")
);
const InterfacesLazy = React.lazy(() =>
  import("../Manager/Components/Bot/ChannelsOrInterfaces/Interfaces")
);
const ChannelsLazy = React.lazy(() =>
  import("../Manager/Components/Bot/ChannelsOrInterfaces/Channels")
);
const PublishSolutionModalLazy = React.lazy(() =>
  import("../Manager/Components/modals/PublishSolutionModal")
);

const MonitorLazy = React.lazy(() =>
  import("../Manager/Components/Bot/Monitor")
);
const SimplePublishSlnModalLazy = React.lazy(() =>
  import("../Manager/Components/modals/SimplePublishSlnModal")
);

const LazyRoutes = ({ children }) => (
  <React.Suspense fallback={<Loader />}>{children}</React.Suspense>
);

LazyRoutes.propTypes = {
  children: PropTypes.node,
};

class PreviewPublishNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDrawer: BOT_DIALOG,
      showPublishModal: false,
      showPublishContent: false,
      isValidatingBeforePublish: false,
      showSolutionModal: false,
      showSurveyModal: false,
      selectedVersion2Publish: null,
      selectedEnvironment2Publish: " ",
      botHasNewTemplates: BOT_UPLOAD_STATE.NO_CHANGE,
      showChangeSectionConfirmationModal: false,
      nextSelectedDrawer: 0,
      pypes: [],
      totalStreams: [],
      solutions: [],
      allPypes: [],
      livePypes: [],
      sandboxPypes: [],
      streams: [],
      selectedPypes: [],
      selectedSolutions: [],
      showEditSolutionModal: false,
      showEditSurveyModal: false,
      isPypeLoading: false,
      isSelectedPypesLoading: false,
      versionLoading: false,
      publishSolution: "",
      previewModal: false,
      refreshInterfaces: false,
      showChannelTab: false,
      cancelDisable: false,
      isOnPublish: false,
      noCreateDraft: false,
      keysPressed: [],
      passthroughModal: false,
      parametersError: false,
      sandboxPreviewMetadata: `{"preview_metadata": [{"onlyedit": "thesevalues"}]}`,
      metadataKeys: [],
      metadataValues: [],
      pype_id: "",
      stream_id: "",
      settingUpPreviewData: true,
      selectedSolutionsForPublish: [],
    };

    this.previewRefreshRef = React.createRef();

    this.onDrawerSelectionChanged = this.onDrawerSelectionChanged.bind(this);
    this.getOrCreateDraftVersionId = this.getOrCreateDraftVersionId.bind(this);
    this.publishLatest = this.publishLatest.bind(this);
    this.closePublishModal = this.closePublishModal.bind(this);
    this.publishVersion = this.publishVersion.bind(this);
    this.onSelectedVersion2PublishChanged =
      this.onSelectedVersion2PublishChanged.bind(this);
    this.onSelectedEnvironment2PublishChanged =
      this.onSelectedEnvironment2PublishChanged.bind(this);
    this.handleVersionDeploy = this.handleVersionDeploy.bind(this);
    this.handleRadioSelection = this.handleRadioSelection.bind(this);
    this.handlePypeSelection = this.handlePypeSelection.bind(this);
    this.handleBotSelection = this.handleBotSelection.bind(this);
    this.handleShowClick = this.handleShowClick.bind(this);
    this.handleSwitchClick = this.handleSwitchClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleEmptyClick = this.handleEmptyClick.bind(this);
    this.onSolutionSelect = this.onSolutionSelect.bind(this);
    this.onSurveySelect = this.onSurveySelect.bind(this);
    this.onUpdateDraft = this.onUpdateDraft.bind(this);
    this.setRefreshInterface = this.setRefreshInterface.bind(this);
    this.getVersionEnvironment = this.getVersionEnvironment.bind(this);
    this.getVersionEnvironmentLatest =
      this.getVersionEnvironmentLatest.bind(this);
    this.getContent = this.getContent.bind(this);
    this.publishVersion = this.publishVersion.bind(this);
    this.ContinueToChangeSection = this.ContinueToChangeSection.bind(this);
    this.hideChangeSectionConfirmationModal =
      this.hideChangeSectionConfirmationModal.bind(this);
    this.botHasNewTemplates = this.botHasNewTemplates.bind(this);
    this.getFileFromServer = this.getFileFromServer.bind(this);
    this.initBot = this.initBot.bind(this);
    this.clearAndFetchInterfaces = this.clearAndFetchInterfaces.bind(this);
    this.handleBreadcrumbClick = this.handleBreadcrumbClick.bind(this);
    this.closePreviewModal = this.closePreviewModal.bind(this);
    this.refreshPreviewModal = this.refreshPreviewModal.bind(this);
    this.uploadDefaultNLUData = this.uploadDefaultNLUData.bind(this);
    this.onTestNLU = this.onTestNLU.bind(this);
    this.isPublishDisabled = this.isPublishDisabled.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleSubmitPassthrough = this.handleSubmitPassthrough.bind(this);
    this.onChangeParameters = this.onChangeParameters.bind(this);
    this.onSimplePublish = this.onSimplePublish.bind(this);
    this.getPypeStreamInSolutions = this.getPypeStreamInSolutions.bind(this);
    this.setPreviewData = this.setPreviewData.bind(this);
    this.updateMetadata = this.updateMetadata.bind(this);
  }

  async componentDidMount() {
    const { flags } = this.props;
    if (!this.props.customer) {
      await this.props.getCustomer(this.props.customerId);
    }
    const previewIsEnabled = flags.designProPreview;
    this.setState({
      previewIsEnabled,
      showChannelTab: true,
    });
    const {
      location: { search },
    } = this.props;
    const currentTab = new URLSearchParams(search).get("tab");
    const allTabs = [
      { name: "BOT_DIALOG", value: BOT_DIALOG },
      { name: "BOT_ACTION", value: BOT_ACTION },
      { name: "BOT_SETTINGS", value: BOT_SETTINGS },
      { name: "BOT_FILES", value: BOT_FILES },
      { name: "BOT_OVERVIEW", value: BOT_OVERVIEW },
      { name: "BOT_TEMPLATES", value: BOT_TEMPLATES },
      { name: "BOT_TESTING", value: BOT_TESTING },
      { name: "BOT_UPLOAD_STATE", value: BOT_UPLOAD_STATE },
      { name: "BOT_VERSION_FLAGS", value: BOT_VERSION_FLAGS },
      { name: "BOT_INTENTS", value: BOT_INTENTS },
      { name: "NLU_INTENT_HEADERS_H", value: NLU_INTENT_HEADERS_H },
      {
        name: "NLU_INTENT_HEADERS_SIMPLIFIED_H",
        value: NLU_INTENT_HEADERS_SIMPLIFIED_H,
      },
      { name: "BOT_ENTITY", value: BOT_ENTITY },
      { name: "BOT_INTERFACES", value: BOT_INTERFACES },
      { name: "BOT_CHANNELS", value: BOT_CHANNELS },
      { name: "BOT_MONITOR", value: BOT_MONITOR },
    ];
    // eslint-disable-next-line eqeqeq
    if (currentTab) {
      const selectedTab = allTabs.find(
        (tab) => tab.value === Number(currentTab)
      );
      if (selectedTab) {
        //#region perf-imporve ff implemented
        if (flags.designProPerformanceImprovements) {
          this.setState({ selectedDrawer: selectedTab.value });
        } else {
          if (
            selectedTab.value !== BOT_INTERFACES &&
            selectedTab.value !== BOT_CHANNELS
          ) {
            this.setState({ selectedDrawer: selectedTab.value });
          } else {
            this.setState({ selectedDrawer: selectedTab.value });
          }
        }
        //#endregion perf-imporve ff implemented
      }
    }

    const data = await this.props.initBot();
    if (
      this.props.botData &&
      this.props.botData.latestVersion &&
      this.props.botData.latestVersion.compilerVersion
    ) {
      this.setState({
        compilerVersionDetail: this.props.botData.latestVersion.compilerVersion,
      });
      const { id, version } = this.props.botData.latestVersion;
      this.props.validateSolution(id, version, true);
    }
    if (data && data.botData && data.botData.bot) {
      this.setState({ publishSolution: data.botData.bot.botType });
    }
    activityTracker.logEvent(
      activityTracker.eventTypeNames.ACCESSED_SOLUTION_MANAGEMENT,
      { solutionName: `${this.props.customerId}.${this.props.botId}` }
    );
  }

  componentDidUpdate(prevProps) {
    const { pypes, botData, flags } = this.props;
    const { selectedPypes, selectedSolutions, isSelectedPypesLoading } =
      this.state;
    if (!flags.designProPerformanceImprovements && !flags.pe18589) {
      if (
        (prevProps.pypes && prevProps.pypes.length) !== (pypes && pypes.length)
      ) {
        this.getPypeStreamCombination(false, selectedPypes, selectedSolutions);
        this.getBotStreamcombination();
      }
      if (this.props.isEmptyPypes) {
        if (
          (prevProps.pypes && prevProps.pypes.length) !==
          (pypes && pypes.length)
        ) {
          this.getPypeStreamCombination(
            false,
            selectedPypes,
            selectedSolutions
          );
          this.getBotStreamcombination();
        }
        if (this.props.isEmptyPypes) {
          if (
            (!selectedPypes.length || !selectedSolutions.length) &&
            isSelectedPypesLoading
          ) {
            this.handleEmptyClick();
          }
        }
      }
    }

    if (
      prevProps.botData &&
      botData &&
      prevProps.botData.latestVersion &&
      botData.latestVersion &&
      botData.latestVersion.compilerVersion &&
      prevProps.botData.latestVersion.compilerVersion !==
        botData.latestVersion.compilerVersion
    ) {
      this.setState({
        compilerVersionDetail: this.props.botData.latestVersion.compilerVersion,
      });
    }
  }

  onTestNLU() {
    this.onDrawerSelectionChanged(BOT_TESTING);
  }

  getPypeStreamCombination(isShowClick, selectedPypes, selectedSolutions) {
    const { pypes, streams, customer, bots, botId } = this.props;
    let updatedPypes = [];
    let updatedSolutions = [];
    const filteredPypes =
      pypes && pypes.filter((p) => p.customer_id === customer.gesId);
    if (this.state.showSolutionModal) {
      filteredPypes &&
        filteredPypes.forEach((p) => {
          streams[p.id] &&
            streams[p.id].forEach((st, streamIndex) => {
              const matchedPypes = selectedPypes.find(
                (sp) => sp.pypeStreamId === `${p.id}_${st.id}_${streamIndex}`
              );
              const mappedPypes =
                matchedPypes !== undefined
                  ? matchedPypes
                  : {
                      ...p,
                      pypeStreamId: `${p.id}_${st.id}_${streamIndex}`,
                      stream_id: st.id,
                      stream_name: st.stream_name,
                      isPublished: false,
                      channel: "",
                      start_chat_bot_id: st.start_chat_bot_id,
                      end_chat_bot_id: st.end_chat_bot_id,
                    };
              updatedPypes.push(mappedPypes);
            });
        });
      if (filteredPypes.length > 2 && !isShowClick) {
        updatedPypes = updatedPypes.slice(0, 2);
      }
      this.setState({ pypes: updatedPypes, totalStreams: filteredPypes });
    } else if (this.state.showSurveyModal) {
      bots
        .filter((b) => b.name === botId)
        .forEach((b) => {
          filteredPypes &&
            filteredPypes.forEach((p) => {
              streams[p.id] &&
                streams[p.id].forEach((st, streamIndex) => {
                  const matchedSolution = selectedSolutions.find(
                    (sp) =>
                      sp.pypeStreamId ===
                      `${b.name}_${p.id}_${st.id}_${streamIndex}`
                  );
                  const mappedSolution =
                    matchedSolution !== undefined
                      ? matchedSolution
                      : {
                          botName: b.name,
                          ...p,
                          pypeStreamId: `${b.name}_${p.id}_${st.id}_${streamIndex}`,
                          stream_id: st.id,
                          stream_name: st.stream_name,
                          isPublished: false,
                          autoStart: st.auto_start_with_bot,
                          start_chat_bot_id: st.start_chat_bot_id,
                          end_chat_bot_id: st.end_chat_bot_id,
                        };
                  updatedSolutions.push(mappedSolution);
                });
            });
        });
      if (filteredPypes.length > 2 && !isShowClick) {
        updatedSolutions = updatedSolutions.slice(0, 2);
      }
      this.setState({
        solutions: updatedSolutions,
        totalStreams: filteredPypes,
      });
    }
  }

  getBotStreamcombination() {
    const { pypes, streams, botStreams, customerId, customer, botId } =
      this.props;
    let updatedPypes = [];
    let updatedSolutions = [];
    const mergedId = `${customerId}.${botId}`;
    const filteredPypes =
      pypes && pypes.filter((p) => p.customer_id === customer.gesId);
    if (this.state.showEditSolutionModal || this.state.showSolutionModal) {
      filteredPypes &&
        filteredPypes.forEach((p) => {
          streams[p.id] &&
            streams[p.id].forEach((st, streamIndex) => {
              botStreams[st.id] &&
                botStreams[st.id].forEach((bs) => {
                  if (bs.id === st.id && mergedId === bs.start_chat_bot_id) {
                    const mappedPypes = {
                      botName: botId,
                      ...p,
                      pypeStreamId: `${p.id}_${st.id}_${streamIndex}`,
                      stream_id: st.id,
                      stream_name: st.stream_name,
                      isPublished: true,
                      channel: bs.auto_start_with_bot ? "web" : "facebook",
                      start_chat_bot_id: st.start_chat_bot_id,
                      end_chat_bot_id: st.end_chat_bot_id,
                    };
                    const matchedPypes = updatedPypes.filter(
                      (up) => up.pypeStreamId === mappedPypes.pypeStreamId
                    );
                    if (!matchedPypes.length) {
                      updatedPypes.push(mappedPypes);
                    }
                  }
                });
            });
        });
      if (updatedPypes.length) {
        updatedPypes.filter(
          (pype) => !pype.stream_name.includes("preview_stream")
        );
        this.setState(
          { selectedPypes: updatedPypes, isSelectedPypesLoading: false },
          () => {
            this.getPypeStreamCombination(
              false,
              updatedPypes,
              updatedSolutions
            );
          }
        );
      } else {
        this.setState(
          {
            showSolutionModal: true,
            showEditSolutionModal: false,
            isSelectedPypesLoading: false,
          },
          () => {
            this.getPypeStreamCombination(
              false,
              updatedPypes,
              updatedSolutions
            );
          }
        );
      }
    } else if (this.state.showEditSurveyModal) {
      filteredPypes &&
        filteredPypes.forEach((p) => {
          streams[p.id] &&
            streams[p.id].forEach((st, streamIndex) => {
              botStreams[st.id] &&
                botStreams[st.id].forEach((bs) => {
                  if (bs.id === st.id && mergedId === bs.end_chat_bot_id) {
                    const mappedSolution = {
                      botName: botId,
                      ...p,
                      pypeStreamId: `${botId}_${p.id}_${st.id}_${streamIndex}`,
                      stream_id: st.id,
                      stream_name: st.stream_name,
                      isPublished: true,
                      autoStart: bs.auto_start_with_bot,
                      start_chat_bot_id: st.start_chat_bot_id,
                      end_chat_bot_id: st.end_chat_bot_id,
                    };
                    const matchedSolution = updatedSolutions.filter(
                      (up) => up.pypeStreamId === mappedSolution.pypeStreamId
                    );
                    if (!matchedSolution.length) {
                      updatedSolutions.push(mappedSolution);
                    }
                  }
                });
            });
        });
      if (updatedSolutions.length) {
        this.setState(
          {
            selectedSolutions: updatedSolutions,
            isSelectedPypesLoading: false,
          },
          () => {
            this.getPypeStreamCombination(
              false,
              updatedPypes,
              updatedSolutions
            );
          }
        );
      } else {
        this.setState(
          {
            showSurveyModal: true,
            showEditSurveyModal: false,
            isSelectedPypesLoading: false,
          },
          () => {
            this.getPypeStreamCombination(
              false,
              updatedPypes,
              updatedSolutions
            );
          }
        );
      }
    }
  }

  onDrawerSelectionChanged(newVal) {
    if (
      this.state.selectedDrawer === BOT_DIALOG &&
      this.state.botHasNewTemplates !== BOT_UPLOAD_STATE.NO_CHANGE
    ) {
      if (newVal !== BOT_DIALOG) {
        this.setState({
          showChangeSectionConfirmationModal: true,
          nextSelectedDrawer: newVal,
        });
      }
    } else {
      this.setState({ selectedDrawer: newVal });
      this.props.history.push(`?tab=${newVal}`);
    }
  }

  async onSelectedEnvironment2PublishChanged(eventKey) {
    await this.props.updatePypes([]);
    this.setState({
      selectedEnvironment2Publish: ENVS[eventKey],
      selectedSolutions: [],
      selectedPypes: [],
    });
    await this.props.getPypes(this.props.customer.gesId, ENVS[eventKey]);
    this.getPypeStreamCombination(
      false,
      this.state.selectedPypes,
      this.state.selectedSolutions
    );
  }

  onSelectedVersion2PublishChanged(event) {
    this.publishVersion(event.target.value);
  }

  getBotName() {
    const parts = this.props.botId.split(".");
    return parts.length >= 2 ? parts[1] : parts[0];
  }

  handleRadioSelection(value, pype) {
    const { pypes } = this.state;
    let currentPype = pype;
    let newPypes = pypes;
    let pypeFound = newPypes.find(
      (pype) => pype.pypeStreamId === currentPype.pypeStreamId
    );

    if (pypeFound) {
      pypeFound.channel = value;
      this.setState({ pypes: newPypes });
    }
  }

  handlePypeSelection(value, selectedPype) {
    const { pypes } = this.state;
    const selectedIndex = pypes.findIndex(
      (item) => item.pypeStreamId === selectedPype.pypeStreamId
    );
    if (selectedIndex >= 0) {
      pypes[selectedIndex].isPublished = value;
      const filteredPypes =
        pypes &&
        pypes.filter(
          (pype) =>
            pype.isPublished && !pype.stream_name.includes("preview_stream")
        );
      this.setState({ pypes, selectedPypes: filteredPypes });
    }
  }

  handleBotSelection(value, selectedSolution) {
    const { solutions } = this.state;
    const selectedIndex = solutions.findIndex(
      (item) => item.pypeStreamId === selectedSolution.pypeStreamId
    );
    if (selectedIndex >= 0) {
      solutions[selectedIndex].isPublished = value;
      const filteredSolutions =
        solutions && solutions.filter((p) => p.isPublished);
      this.setState({ solutions, selectedSolutions: filteredSolutions });
    }
  }

  handleShowClick() {
    this.getPypeStreamCombination(
      true,
      this.state.selectedPypes,
      this.state.selectedSolutions
    );
    this.setState({ totalStreams: [] });
  }

  async handleSwitchClick(showSolutionModal) {
    await this.props.updatePypes([]);
    const selectedEnv =
      ENVS[0] === "sandbox" || ENVS[0] === "claybox" ? ENVS[0] : ENVS[1];
    await this.props.getPypes(this.props.customer.gesId, selectedEnv);
    if (showSolutionModal) {
      this.setState({
        showSolutionModal: false,
        showEditSolutionModal: false,
        showSurveyModal: true,
        totalStreams: [],
        showPublishContent: false,
        isSelectedPypesLoading: false,
        selectedEnvironment2Publish: selectedEnv,
        selectedSolutions: [],
        selectedPypes: [],
        publishSolution: "survey",
      });
    } else {
      this.setState({
        showSolutionModal: true,
        showEditSurveyModal: false,
        showSurveyModal: false,
        totalStreams: [],
        showPublishContent: false,
        isSelectedPypesLoading: false,
        selectedEnvironment2Publish: selectedEnv,
        selectedSolutions: [],
        selectedPypes: [],
        publishSolution: "main",
      });
    }
  }

  async handleEditClick() {
    await this.props.updatePypes([]);
    await this.props.getPypes(
      this.props.customer.gesId,
      this.state.selectedEnvironment2Publish
    );
    if (this.state.showEditSolutionModal || this.state.showSolutionModal) {
      this.setState({ showSolutionModal: true, showEditSolutionModal: false });
    } else {
      this.setState({ showSurveyModal: true, showEditSurveyModal: false });
    }
    this.setState({ isSelectedPypesLoading: false });
  }

  async handleEmptyClick() {
    if (this.state.showEditSolutionModal || this.state.showSolutionModal) {
      this.setState({
        showSolutionModal: true,
        showEditSolutionModal: false,
        isSelectedPypesLoading: false,
      });
    } else {
      this.setState({
        showSurveyModal: true,
        showEditSurveyModal: false,
        isSelectedPypesLoading: false,
      });
    }
  }

  async onSolutionSelect(MainSolution) {
    this.setState({ publishSolution: MainSolution });
    const { botData, customer } = this.props;
    const selectedEnv =
      ENVS[0] === "sandbox" || ENVS[0] === "claybox" ? ENVS[0] : ENVS[1];
    await this.props.updatePypes([]);
    await this.props.getPypes(customer.gesId, selectedEnv);
    if (botData.latestVersion.version !== "v1")
      this.setState({
        showEditSolutionModal: true,
        showPublishContent: false,
        isSelectedPypesLoading: true,
        selectedEnvironment2Publish: selectedEnv,
      });
    else
      this.setState({
        showSolutionModal: true,
        showPublishContent: false,
        selectedEnvironment2Publish: selectedEnv,
      });
  }

  async onSurveySelect(EndSolution) {
    this.setState({ publishSolution: EndSolution });
    const { botData, customer } = this.props;
    const selectedEnv =
      ENVS[0] === "sandbox" || ENVS[0] === "claybox" ? ENVS[0] : ENVS[1];
    await this.props.updatePypes([]);
    await this.props.getPypes(customer.gesId, selectedEnv);
    if (botData.latestVersion.version !== "v1")
      this.setState({
        showEditSurveyModal: true,
        showPublishContent: false,
        isSelectedPypesLoading: true,
        selectedEnvironment2Publish: selectedEnv,
      });
    else
      this.setState({
        showSurveyModal: true,
        showPublishContent: false,
        selectedEnvironment2Publish: selectedEnv,
      });
  }

  async onUpdateDraft(simplifiedTrainingData) {
    await this.props.updateBot(this.props.botData.bot.id, {
      botLanguage: this.props.botData.bot.botLanguage,
      simplifiedTrainingData,
    });
  }

  setRefreshInterface(trueOrFalse) {
    this.setState({
      refreshInterfaces: trueOrFalse,
    });
  }

  getVersionEnvironment(lv) {
    let env = "";

    if (lv && lv.envs) {
      if (lv.envs.includes("live") || lv.envs.includes("prod")) {
        env = "Live";
      } else if (lv.envs.includes("draft")) {
        env = "Draft";
      } else {
        env = "Sandbox";
      }
    }
  }

  getVersionEnvironmentLatest(currentVersions, latestVersion) {
    let envs = currentVersions
      .filter((ver) => ver.version === latestVersion.version)
      .reduce((res, ver) => [...res, ...ver.envs], []);
    let env = "";

    if (envs) {
      if (envs.includes("live") || envs.includes("prod")) {
        env = "Live";
      } else if (envs.includes("draft")) {
        env = "Draft";
      } else {
        env = "Sandbox";
      }
    }

    return env;
  }

  isPublishDisabled() {
    const {
      botData: { canPublish },
      isTrainingNeeded,
      solutionInfo,
    } = this.props;

    return !canPublish || isTrainingNeeded || solutionInfo.error;
  }

  getContent() {
    const { selectedDrawer, hasNewData, isValidatingBeforePublish } =
      this.state;
    const {
      customerId,
      botId,
      customer: { gesId: customerGesId },
      botData,
      intents,
      flags,
      ...props
    } = this.props;

    let { currentVersions, presVersions, bot, latestVersion, hasDraft } =
      botData;
    const groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    const botHistory = botData.botHistory.data.data;
    const groupedVersions = groupBy(botHistory, "version");
    const groupedKeys = Object.keys(groupedVersions);
    const finalVersions = groupedKeys.reduce((acc, el) => {
      const highestTimestamp = groupedVersions[el].reduce((acc2, el2) => {
        if (Object.keys(acc2).length < 1) {
          acc2 = el2;
        } else if (new Date(el2.time) > new Date(acc2.time)) {
          acc2 = el2;
        }
        return acc2;
      }, {});
      acc.push(highestTimestamp);
      return acc;
    }, []);
    finalVersions.forEach((newEl, index) => {
      currentVersions.forEach((oldEl) => {
        if (newEl.version === oldEl.version) {
          const curVersionTime = new Date(newEl.time);
          const diffInCurTime =
            (new Date().getTime() - curVersionTime.getTime()) /
            (1000 * 60 * 60 * 24);
          const curUserVersionUsername = botHistory[index].username;
          oldEl["lastPublished"] = `${
            diffInCurTime >= 2
              ? `${moment(curVersionTime).local().format("ll")} at ${moment(
                  curVersionTime
                )
                  .local()
                  .format("LT")}`
              : moment(curVersionTime).local().calendar()
          } by ${curUserVersionUsername}`;
          if (typeof newEl.env === "string") {
            if (oldEl["envs"].length === 0) {
              oldEl["envs"].push(newEl.env);
            }
          } else {
            oldEl.envs = [...new Set(newEl.env)];
          }
        }
      });
    });
    const finalVersionsprevious = groupedKeys.reduce((acc, el) => {
      const highestTimestamp = groupedVersions[el].reduce((acc2, el2) => {
        if (Object.keys(acc2).length < 1) {
          acc2 = el2;
        } else if (new Date(el2.time) > new Date(acc2.time)) {
          acc2 = el2;
        }
        return acc2;
      }, {});
      acc.push(highestTimestamp);
      return acc;
    }, []);
    finalVersionsprevious.forEach((newEl) => {
      if (newEl.version !== botHistory[0].version) {
        presVersions.forEach((oldEl) => {
          if (newEl.version === oldEl.version) {
            const newDateTime = new Date(newEl.time);
            const diffInNewTime =
              (new Date().getTime() - newDateTime.getTime()) /
              (1000 * 60 * 60 * 24);
            const newUserVersionUsername = newEl.username;
            oldEl["lastPublished"] = `${
              diffInNewTime >= 2
                ? `${moment(newDateTime).local().format("ll")} at ${moment(
                    newDateTime
                  )
                    .local()
                    .format("LT")}`
                : moment(newDateTime).local().calendar()
            } by ${newUserVersionUsername}`;
            if (typeof newEl.env === "string") {
              if (oldEl["envs"].length === 0) {
                oldEl["envs"].push(newEl.env);
              }
            } else {
              oldEl.envs = [...new Set(newEl.env)];
            }
          }
        });
      }
    });
    //#region perf-imporve ff implemented
    if (flags.designProPerformanceImprovements) {
      return (
        <Fragment>
          {selectedDrawer === BOT_DIALOG && (
            <LazyRoutes>
              <DialogLazy
                initBot={this.initBot}
                show={selectedDrawer === BOT_DIALOG}
                customerGesId={customerGesId}
                currentVersions={currentVersions}
                presVersions={presVersions}
                latestVersion={latestVersion}
                hasDraft={hasDraft}
                customerId={customerId}
                botId={botId}
                bot={bot}
                flags={flags}
                botData={botData}
                customer={this.props.customer}
                envs={ENVS}
                botHasNewTemplates={this.botHasNewTemplates}
                getOrCreateDraftVersionId={this.getOrCreateDraftVersionId}
                onUpdateDraft={this.onUpdateDraft}
                onDrawerSelectionChanged={this.onDrawerSelectionChanged}
                restore={this.publishVersion}
              />
            </LazyRoutes>
          )}
          {selectedDrawer === BOT_OVERVIEW && (
            <LazyRoutes>
              <OverviewLazy
                show={selectedDrawer === BOT_OVERVIEW}
                onDrawerSelectionChanged={this.onDrawerSelectionChanged}
                currentVersions={currentVersions}
                presVersions={presVersions}
                latestVersion={latestVersion}
                bot={bot}
                restore={this.publishVersion}
                customerGesId={customerGesId}
                botData={botData}
              />
            </LazyRoutes>
          )}
          {!flags["pe17357"] && selectedDrawer === BOT_INTENTS && (
            <LazyRoutes>
              <IntentsLazy
                show={selectedDrawer === BOT_INTENTS}
                bot={bot}
                latestVersion={latestVersion}
                getOrCreateDraftVersionId={this.getOrCreateDraftVersionId}
                initBot={this.initBot}
                botData={botData}
              />
            </LazyRoutes>
          )}
          {!flags["pe17357"] && selectedDrawer === BOT_ENTITY && (
            <LazyRoutes>
              <EntityLazy
                show={selectedDrawer === BOT_ENTITY}
                bot={bot}
                latestVersion={latestVersion}
                getOrCreateDraftVersionId={this.getOrCreateDraftVersionId}
                hasDraft={hasDraft}
                initBot={this.initBot}
                botData={botData}
              />
            </LazyRoutes>
          )}
          {selectedDrawer === BOT_FILES && (
            <LazyRoutes>
              <FilesLazy
                show={selectedDrawer === BOT_FILES}
                assets={botData.bot.assets}
                getOrCreateDraftVersionId={this.getOrCreateDraftVersionId}
                customerId={customerId}
                botId={botId}
                initBot={this.initBot}
                customerGesId={customerGesId}
                botData={botData}
              />
            </LazyRoutes>
          )}
          {selectedDrawer === BOT_ACTION && (
            <LazyRoutes>
              <ActionsLazy
                show={selectedDrawer === BOT_ACTION}
                latestVersion={latestVersion}
                getOrCreateDraftVersionId={this.getOrCreateDraftVersionId}
                customerGesId={customerGesId}
                initBot={this.initBot}
                customerId={customerId}
                botId={botId}
                botData={botData}
              />
            </LazyRoutes>
          )}
          {selectedDrawer === BOT_TESTING && (
            <LazyRoutes>
              <TestLazy
                show={selectedDrawer === BOT_TESTING}
                latestVersion={latestVersion}
                botData={botData}
                intents={intents}
                {...props}
              />
            </LazyRoutes>
          )}
          {!flags["pe17357"] && selectedDrawer === BOT_NLU_OVERVIEW && (
            <LazyRoutes>
              <NLUOverviewLazy
                show={selectedDrawer === BOT_NLU_OVERVIEW}
                bot={bot}
                botData={botData}
                onTestNLU={this.onTestNLU}
              />
            </LazyRoutes>
          )}
          {selectedDrawer === BOT_SETTINGS && (
            <LazyRoutes>
              <SettingsLazy show={selectedDrawer === BOT_SETTINGS} bot={bot} />
            </LazyRoutes>
          )}
          {selectedDrawer === BOT_INTERFACES && (
            <LazyRoutes>
              <InterfacesLazy
                show={selectedDrawer === BOT_INTERFACES}
                bot={bot}
                botData={botData}
                currentVersions={currentVersions}
                presVersions={presVersions}
                latestVersion={latestVersion}
                customer={this.props.customer}
                refreshInterfaces={this.state.refreshInterfaces}
                setRefreshInterface={this.setRefreshInterface}
              />
            </LazyRoutes>
          )}
          {selectedDrawer === BOT_CHANNELS && (
            <LazyRoutes>
              <ChannelsLazy
                show={selectedDrawer === BOT_CHANNELS}
                bot={bot}
                botData={botData}
                currentVersions={currentVersions}
                presVersions={presVersions}
                latestVersion={latestVersion}
                customer={this.props.customer}
                refreshInterfaces={this.state.refreshInterfaces}
                setRefreshInterface={this.setRefreshInterface}
              />
            </LazyRoutes>
          )}
          {selectedDrawer === BOT_MONITOR && (
            <LazyRoutes>
              <MonitorLazy show={selectedDrawer === BOT_MONITOR} />
            </LazyRoutes>
          )}
        </Fragment>
      );
    }
    //#endregion perf-imporve ff implemented

    return (
      <Fragment>
        <Dialog
          initBot={this.initBot}
          show={selectedDrawer === BOT_DIALOG}
          customerGesId={customerGesId}
          currentVersions={currentVersions}
          presVersions={presVersions}
          latestVersion={latestVersion}
          hasDraft={hasDraft}
          customerId={customerId}
          botId={botId}
          bot={bot}
          flags={flags}
          botData={botData}
          customer={this.props.customer}
          envs={ENVS}
          botHasNewTemplates={this.botHasNewTemplates}
          getOrCreateDraftVersionId={this.getOrCreateDraftVersionId}
          onUpdateDraft={this.onUpdateDraft}
          onDrawerSelectionChanged={this.onDrawerSelectionChanged}
          restore={this.publishVersion}
        />
        <Monitor show={selectedDrawer === BOT_MONITOR} />
        <Overview
          show={selectedDrawer === BOT_OVERVIEW}
          onDrawerSelectionChanged={this.onDrawerSelectionChanged}
          currentVersions={currentVersions}
          presVersions={presVersions}
          latestVersion={latestVersion}
          bot={bot}
          restore={this.publishVersion}
          customerGesId={customerGesId}
          botData={botData}
        />
        <Intents
          show={selectedDrawer === BOT_INTENTS}
          bot={bot}
          latestVersion={latestVersion}
          getOrCreateDraftVersionId={this.getOrCreateDraftVersionId}
          initBot={this.initBot}
          botData={botData}
        />

        <Entity
          show={selectedDrawer === BOT_ENTITY}
          bot={bot}
          latestVersion={latestVersion}
          getOrCreateDraftVersionId={this.getOrCreateDraftVersionId}
          hasDraft={hasDraft}
          initBot={this.initBot}
          botData={botData}
        />

        <Files
          show={selectedDrawer === BOT_FILES}
          assets={botData.bot.assets}
          getOrCreateDraftVersionId={this.getOrCreateDraftVersionId}
          customerId={customerId}
          botId={botId}
          initBot={this.initBot}
          customerGesId={customerGesId}
          botData={botData}
        />
        <Actions
          show={selectedDrawer === BOT_ACTION}
          latestVersion={latestVersion}
          getOrCreateDraftVersionId={this.getOrCreateDraftVersionId}
          customerGesId={customerGesId}
          initBot={this.initBot}
          customerId={customerId}
          botId={botId}
          botData={botData}
        />
        <Test
          show={selectedDrawer === BOT_TESTING}
          latestVersion={latestVersion}
          botData={botData}
          intents={intents}
          {...props}
        />
        <NLUOverview
          show={selectedDrawer === BOT_NLU_OVERVIEW}
          bot={bot}
          botData={botData}
          onTestNLU={this.onTestNLU}
        />
        <Settings show={selectedDrawer === BOT_SETTINGS} bot={bot} />
        <Interfaces
          show={selectedDrawer === BOT_INTERFACES}
          bot={bot}
          botData={botData}
          currentVersions={currentVersions}
          presVersions={presVersions}
          latestVersion={latestVersion}
          customer={this.props.customer}
          refreshInterfaces={this.state.refreshInterfaces}
          setRefreshInterface={this.setRefreshInterface}
        />
        <Channels
          show={selectedDrawer === BOT_CHANNELS}
          bot={bot}
          botData={botData}
          currentVersions={currentVersions}
          presVersions={presVersions}
          latestVersion={latestVersion}
          customer={this.props.customer}
          refreshInterfaces={this.state.refreshInterfaces}
          setRefreshInterface={this.setRefreshInterface}
        />
      </Fragment>
    );
  }

  async getOrCreateDraftVersionId(compilerVersion) {
    const { botData } = this.props;
    const { hasDraft, latestVersion, bot } = botData;

    if (hasDraft && latestVersion.environments.length === 0) {
      return latestVersion ? latestVersion.id : "";
    }
    await botManagerAPI.createNewBotVersion(
      bot.id,
      latestVersion ? latestVersion.version : null
    );
    const res = await this.props.initBot(compilerVersion);
    return res.botData.latestVersion.id;
  }

  async publishVersion(version, noCreateDraft = false) {
    await this.clearAndFetchInterfaces();

    let csvFileFromServerIntent;
    let csvFileFromServerEntity;
    let simplifiedTrainingData;
    this.setState({ isValidatingBeforePublish: true });
    const csvFileFromServerBot = await this.getFileFromServer(
      "templates/bot.csv"
    );

    const formData = new FormData();
    const blobCsv = new Blob([csvFileFromServerBot], { type: "text/xml" });
    formData.append("templateFile", blobCsv, "bot.csv");
    if (
      this.props.botData.latestVersion.compilerVersion ===
        BOT_VERSION_FLAGS.NLU_ENABLED ||
      this.props.botData.latestVersion.compilerVersion ===
        BOT_VERSION_FLAGS.NLU_ROUTING
    ) {
      csvFileFromServerIntent = await this.getFileFromServer(
        "templates/intent.csv"
      );

      csvFileFromServerEntity = await this.getFileFromServer(
        "templates/entity.csv"
      );
      simplifiedTrainingData = getIntentFormat(
        csvFileFromServerIntent,
        NLU_INTENT_HEADERS_H,
        NLU_INTENT_HEADERS_SIMPLIFIED_H
      );
      const blobIntent = new Blob([csvFileFromServerIntent], {
        type: "text/xml",
      });
      const blobEntity = new Blob([csvFileFromServerEntity], {
        type: "text/xml",
      });
      formData.append("trainingIntentFile", blobIntent, "intent.csv");
      formData.append("trainingEntityFile", blobEntity, "entity.csv");
      formData.append("simplifiedTrainingData", simplifiedTrainingData);
    }
    const { status, data } = await botManagerAPI.compileTemplate(
      formData,
      this.props.botData.latestVersion.compilerVersion
    ); // 5 = NLU_ENABLED
    let msg = "error";
    if (status === 200) {
      this.setState({
        showPublishModal: true,
        selectedVersion2Publish: version,
        noCreateDraft,
      });
      const { flags } = this.props;
      //#region perf-imporve ff implemented
      if (!flags.pe18589 && !flags.designProPerformanceImprovements) {
        if (this.state.publishSolution !== null) {
          if (this.state.publishSolution === "main") {
            this.onSolutionSelect("main");
          } else {
            this.onSurveySelect("survey");
          }
        } else {
          this.setState({ showPublishContent: true });
        }
      }
      //#endregion perf-imporve ff implemented
    } else {
      if (typeof data === "object") {
        const errors = JSON.stringify(data.errors);
        msg += " " + errors;
      } else if (typeof data === "string") {
        msg = data;
      }
      this.props.setNotification({
        openNotification: true,
        notificationDuration: 3000,
        notificationTitle: msg,
        notificationType: "error",
      });
    }
    this.setState({ isValidatingBeforePublish: false });
  }

  ContinueToChangeSection() {
    const { nextSelectedDrawer } = this.state;
    this.setState(() => ({
      showChangeSectionConfirmationModal: false,
      selectedDrawer: nextSelectedDrawer,
    }));
    this.props.history.push(`?tab=${nextSelectedDrawer}`);
  }

  hideChangeSectionConfirmationModal() {
    this.setState({ showChangeSectionConfirmationModal: false });
  }

  botHasNewTemplates(hasNewTemplate) {
    this.setState({ botHasNewTemplates: hasNewTemplate });
  }

  publishLatest() {
    this.publishVersion(this.props.botData.latestVersion.version);
  }

  async getFileFromServer(filename) {
    return new Promise((resolve, reject) => {
      botManagerAPI
        .downloadTemplateFile(this.props.botData.latestVersion.id, filename)
        .then((response) => {
          resolve(response.file_data);
        });
    });
  }

  async closePublishModal() {
    await this.props.updatePypes([]);
    this.setState({
      showPublishModal: false,
      showPublishContent: false,
      showSolutionModal: false,
      showSurveyModal: false,
      showEditSolutionModal: false,
      showEditSurveyModal: false,
      totalStreams: [],
      pypes: [],
      solutions: [],
      selectedSolutions: [],
      selectedPypes: [],
      isSelectedPypesLoading: false,
    });
  }

  initBot() {
    this.props.initBot();
  }

  async clearAndFetchInterfaces() {
    await this.props.removeInterfaces();
    botManagerAPI.getPypes(this.props.customer.gesId, "live").then((pypes) => {
      this.setState({
        livePypes: pypes,
        allPypes: [...this.state.allPypes, ...pypes],
      });
      pypes.forEach(async (pype) => {
        await this.props.getInterfaces(pype.id, "live", true);
        botManagerAPI
          .getPypeStream(pype.customer_id, "live", pype.id)
          .then((newStream) =>
            this.setState({
              streams: [
                ...this.state.streams.filter(
                  (stream) => stream.id !== newStream.id
                ),
                ...newStream,
              ],
            })
          );
      });
    });

    botManagerAPI
      .getPypes(this.props.customer.gesId, "sandbox")
      .then((pypes) => {
        this.setState({
          sandboxPypes: pypes,
          allPypes: [...this.state.allPypes, ...pypes],
        });
        pypes.forEach(async (pype) => {
          await this.props.getInterfaces(pype.id, "sandbox", true);
          botManagerAPI
            .getPypeStream(pype.customer_id, "sandbox", pype.id)
            .then((newStream) => {
              this.setState({
                streams: [
                  ...this.state.streams.filter(
                    (stream) => stream.id !== newStream.id
                  ),
                  ...newStream,
                ],
              });
            });
        });
      });
  }

  async getPypeStreamInSolutions(env, selectedSolutions) {
    const { customer, customerId } = this.props;
    let res = [];

    try {
      const pypes = await botManagerAPI.getPypes(customer.gesId, env);
      const filteredPypes =
        pypes && pypes.filter((p) => p.customer_id === customer.gesId);

      let requestsForStreams = filteredPypes.map((pype) =>
        botManagerAPI.getPypeStream(customer.gesId, env, pype.id)
      );

      const resStreams = await Promise.all(requestsForStreams);

      resStreams.forEach((streams, index) => {
        res = [
          ...res,
          ...(streams || [])
            .filter((st) => !st.stream_name.includes("preview_stream"))
            .filter((stream) =>
              selectedSolutions.some(
                (sln) => stream.start_chat_bot_id === `${customerId}.${sln}`
              )
            )
            .map((st) => ({
              ...filteredPypes[index],
              stream_id: st.id,
              stream_name: st.stream_name,
              auto_start_with_bot: st.auto_start_with_bot,
              bot_enabled: st.bot_enabled,
              end_chat_bot_enabled: st.end_chat_bot_enabled,
              end_chat_bot_id: st.end_chat_bot_id,
              start_chat_bot_enabled: st.start_chat_bot_enabled,
              start_chat_bot_id: st.start_chat_bot_id,
              channel: st.auto_start_with_bot ? "web" : "facebook",
            })),
        ];
      });
    } catch (err) {
      console.log(err);
    }

    return res;
  }

  async onSimplePublish(botType, env, selectedSolutions) {
    this.setState({ versionLoading: true });

    const { customerId, botId, flags, customer } = this.props;
    const { selectedVersion2Publish, compilerVersionDetail, noCreateDraft } =
      this.state;
    const version = `${customerId}.${botId}.${selectedVersion2Publish}`;
    const environment = JSON.stringify({
      environment: env,
    });
    this.props.setPypeLoading(true);
    const user_id =
      this.props.botUserSession &&
      this.props.botUserSession.user &&
      this.props.botUserSession.user.user_id;
    if (typeof user_id !== "string" || !user_id) {
      throw Error(
        `User ID is not the correct type or is not valid: { Type: ${typeof user_id}, value: ${user_id} }`
      );
    }

    let csvFileFromServerIntent;
    let csvFileFromServerEntity;

    try {
      // 1. Update bot config.
      if (compilerVersionDetail === BOT_VERSION_FLAGS.NLU_ENABLED) {
        csvFileFromServerIntent = await this.getFileFromServer(
          "templates/intent.csv"
        );
        await this.props.updateBot(this.props.botData.bot.id, {
          botLanguage: this.props.botData.bot.botLanguage,
          botType,
          simplifiedTrainingData: getIntentFormat(
            csvFileFromServerIntent,
            NLU_INTENT_HEADERS_H,
            NLU_INTENT_HEADERS_SIMPLIFIED_H
          ),
        });

        csvFileFromServerEntity = await this.getFileFromServer(
          "templates/entity.csv"
        );
      } else {
        await this.props.updateBot(this.props.botData.bot.id, {
          botLanguage: this.props.botData.bot.botLanguage,
          botType,
        });
      }
      // 2. Update bot streams in case of survey
      // get pype/streams of selected solutions
      if (botType === "survey") {
        const selectedPypeStreams = await this.getPypeStreamInSolutions(
          env,
          selectedSolutions
        );
        if (!isEmpty(selectedPypeStreams)) {
          let requests = selectedPypeStreams.map((sp) => {
            return this.props.updateStream(
              customer.gesId,
              env,
              sp.id,
              sp.stream_id,
              `${customerId}.${botId}`,
              !!sp.auto_start_with_bot,
              sp.start_chat_bot_id,
              sp.end_chat_bot_id,
              "end_chat_bot_id"
            );
          });
          await Promise.all(requests);
        }
      }
      // 3. Deploy Version
      const csvFileFromServerBot = await this.getFileFromServer(
        "templates/bot.csv"
      );
      const blob = new Blob([csvFileFromServerBot], { type: "text/xml" });
      const formData = new FormData();
      formData.append("templateFile", blob, "bot.csv");

      if (
        csvFileFromServerIntent !== undefined &&
        csvFileFromServerEntity !== undefined
      ) {
        const intentBlob = new Blob([csvFileFromServerIntent], {
          type: "text/xml",
        });
        const entityBlob = new Blob([csvFileFromServerEntity], {
          type: "text/xml",
        });

        formData.append("trainingIntentFile", intentBlob, "intent.csv");
        formData.append("trainingEntityFile", entityBlob, "entity.csv");
        formData.append(
          "simplifiedTrainingData",
          getIntentFormat(
            csvFileFromServerIntent,
            NLU_INTENT_HEADERS_H,
            NLU_INTENT_HEADERS_SIMPLIFIED_H
          )
        );
      }
      const deployStatus = await botManagerAPI.deployVersion(
        version,
        environment
      );

      if (deployStatus === 200) {
        // 4. Automatically create new draft
        if (!noCreateDraft) {
          await this.uploadDefaultNLUData(customerId, botId);
        }
        // 5. Create interfaces
        // no need to create interfaces anymore

        if (flags.pe17497) {
          await this.props.initBot();
        }

        this.props.setNotification({
          openNotification: true,
          notificationDuration: 5000,
          notificationTitle: `You have published ${selectedVersion2Publish} of ${this.props.botId} microapp!`,
          notificationType: "success",
        });

        activityTracker.logEvent(activityTracker.eventTypeNames.PUBLISH, {
          targetEnv: env,
          solutionName: `${customerId}.${botId}`,
          success: true,
          solutionVersion: selectedVersion2Publish,
        });
      } else {
        this.props.setNotification({
          openNotification: true,
          notificationDuration: 3000,
          notificationTitle: `${deployStatus.errors}`,
          notificationType: "error",
        });
        activityTracker.logEvent(activityTracker.eventTypeNames.PUBLISH, {
          targetEnv: env,
          solutionName: `${customerId}.${botId}`,
          success: false,
        });
      }
    } catch (err) {
      this.props.setNotification({
        openNotification: true,
        notificationDuration: 3000,
        notificationTitle: `Something went wrong ${err || ""}`,
        notificationType: "error",
      });
    } finally {
      this.setState({ versionLoading: false });
      this.props.setPypeLoading(false);
      this.closePublishModal();
    }
  }

  async handleVersionDeploy(
    selectedPypesData,
    selectedSolutionsData,
    solutionsData,
    pypesData,
    selectedEnvironment2PublishData,
    modalType
  ) {
    const { isOnPublish } = this.state;
    const { flags } = this.props;
    const perfImprove = flags.designProPerformanceImprovements;

    if (isOnPublish) {
      return;
    }
    this.setState({ cancelDisable: true, isOnPublish: true });
    const user_id =
      this.props.botUserSession &&
      this.props.botUserSession.user &&
      this.props.botUserSession.user.user_id;
    if (typeof user_id !== "string" || !user_id) {
      throw Error(
        `User ID is not the correct type or is not valid: { Type: ${typeof user_id}, value: ${user_id} }`
      );
    }
    //#region perf-imporve ff implemented
    if (perfImprove) {
      this.setState({ versionLoading: true, publishSolution: modalType });
    } else {
      this.setState({ versionLoading: true });
    }

    let selectedPypes;
    let solutions;
    let pypes;
    let selectedSolutions;
    let selectedEnvironment2Publish;
    const { customer, customerId, botId } = this.props;

    if (perfImprove) {
      selectedPypes = selectedPypesData;
      solutions = solutionsData;
      pypes = pypesData;
      selectedSolutions = selectedSolutionsData;
      selectedEnvironment2Publish = selectedEnvironment2PublishData;
    } else {
      selectedPypes = this.state.selectedPypes;
      solutions = this.state.solutions;
      pypes = this.state.pypes;
      selectedSolutions = this.state.selectedSolutions;
      selectedEnvironment2Publish = this.state.selectedEnvironment2Publish;
    }

    const { selectedVersion2Publish, compilerVersionDetail, noCreateDraft } =
      this.state;
    const version = `${customerId}.${botId}.${selectedVersion2Publish}`;
    const environment = JSON.stringify({
      environment: selectedEnvironment2Publish,
    });
    this.props.setPypeLoading(true);

    let csvFileFromServerIntent;
    let csvFileFromServerEntity;

    try {
      if (compilerVersionDetail === BOT_VERSION_FLAGS.NLU_ENABLED) {
        csvFileFromServerIntent = await this.getFileFromServer(
          "templates/intent.csv"
        );
        await this.props.updateBot(this.props.botData.bot.id, {
          botLanguage: this.props.botData.bot.botLanguage,
          botType: perfImprove ? modalType : this.state.publishSolution,
          simplifiedTrainingData: getIntentFormat(
            csvFileFromServerIntent,
            NLU_INTENT_HEADERS_H,
            NLU_INTENT_HEADERS_SIMPLIFIED_H
          ),
        });

        csvFileFromServerEntity = await this.getFileFromServer(
          "templates/entity.csv"
        );
      } else {
        await this.props.updateBot(this.props.botData.bot.id, {
          botLanguage: this.props.botData.bot.botLanguage,
          botType: this.state.publishSolution,
        });
      }

      const v = selectedVersion2Publish;
      //#region Solution Modal bool old and new after ff
      const solutionModalBoolOld =
        this.state.showSolutionModal || this.state.showEditSolutionModal;
      const solutionModalBoolNew = modalType === "main";
      const surveyModalBoolOld =
        this.state.showSurveyModal || this.state.showEditSurveyModal;
      const surveyModalBoolNew = modalType === "survey";

      if (perfImprove ? solutionModalBoolNew : solutionModalBoolOld) {
        let requests = selectedPypes.map((sp) => {
          return this.props.updateStream(
            customer.gesId,
            selectedEnvironment2Publish,
            sp.id,
            sp.stream_id,
            `${customerId}.${botId}`,
            sp.channel === "web",
            sp.start_chat_bot_id,
            sp.end_chat_bot_id,
            "start_chat_bot_id"
          );
        });
        await Promise.all(requests);
      }

      if (perfImprove ? surveyModalBoolNew : surveyModalBoolOld) {
        let requests = selectedSolutions.map((sp) => {
          return this.props.updateStream(
            customer.gesId,
            selectedEnvironment2Publish,
            sp.id,
            sp.stream_id,
            `${customerId}.${sp.botName}`,
            !!sp.autoStart,
            sp.start_chat_bot_id,
            sp.end_chat_bot_id,
            "end_chat_bot_id"
          );
        });
        await Promise.all(requests);
      }
      //#endregion Solution Modal bool old and new after ff
      const csvFileFromServerBot = await this.getFileFromServer(
        "templates/bot.csv"
      );
      const blob = new Blob([csvFileFromServerBot], { type: "text/xml" });
      const formData = new FormData();
      formData.append("templateFile", blob, "bot.csv");

      if (
        csvFileFromServerIntent !== undefined &&
        csvFileFromServerEntity !== undefined
      ) {
        const intentblob = new Blob([csvFileFromServerIntent], {
          type: "text/xml",
        });
        const entityblob = new Blob([csvFileFromServerEntity], {
          type: "text/xml",
        });

        formData.append("trainingIntentFile", intentblob, "intent.csv");
        formData.append("trainingEntityFile", entityblob, "entity.csv");
        formData.append(
          "simplifiedTrainingData",
          getIntentFormat(
            csvFileFromServerIntent,
            NLU_INTENT_HEADERS_H,
            NLU_INTENT_HEADERS_SIMPLIFIED_H
          )
        );
      }
      const deploystatus = await botManagerAPI.deployVersion(
        version,
        environment
      );

      if (deploystatus === 200) {
        // Automatically create new Draft
        if (!noCreateDraft) {
          await this.uploadDefaultNLUData(
            this.props.customerId,
            this.props.botId
          );
        }
        // Create interfaces
        const { interfaces } = this.props;
        const interfacesToCreate = selectedPypes.reduce((acc, pype) => {
          const interfaceExists = interfaces.find(
            (oldInterface) =>
              oldInterface.widget.pype_id === pype.id &&
              oldInterface.widget.stream_id === pype.stream_id
          );
          if (!interfaceExists && pype.channel === "web") {
            acc.push(pype);
          }
          return acc;
        }, []);

        let requests = [];
        interfacesToCreate.map(async (selectedInterface) => {
          const newStyle = {
            name: "Web Conversational Interface",
            styleSelection: 1,
            widgetPosition: "bottom-right",
            shouldLockRatio: true,
            widgetHeight: 800,
            widgetWidth: 500,
            widgetStyleColor: "#007BFF",
            widgetStyleTextColor: "#ffffff",
            agentBubbleColor: "#DEE8F5",
            agentBubbleTextColor: "#000000",
            userBubbleColor: "#113260",
            userBubbleTextColor: "#ffffff",
            bannerUrl: "",
          };
          const beta = {
            customStyling: {
              buttonColor: "#454545",
              textColor: "white",
              brandMomentUrl:
                "https://www.pypestream.com/wp-content/uploads/Customer_engagement_center_gray4.jpg",
            },
          };
          const createNewInterface = {
            user_id,
            correlation_id: null,
            reply_to: selectedInterface.id,
            version: 1,
            type: "request",
            auth_token: sessionStorage.getItem("global_accessToken"),
            request_type: "x_widget",
            request_action: "new",
            data: {
              pype_id: selectedInterface.id,
              stream_id: selectedInterface.stream_id,
              style: JSON.stringify(newStyle),
              widget_name: "Interface",
              beta: JSON.stringify(beta),
            },
          };
          requests.push(
            botManagerAPI.createInterface(
              createNewInterface,
              selectedEnvironment2Publish,
              true
            )
          );
        });
        await Promise.all(requests);
        await this.props.initBot();

        this.setState({ refreshInterfaces: true });
        this.setState({ versionLoading: false });
        this.props.setNotification({
          openNotification: true,
          notificationDuration: 5000,
          notificationTitle: `You have published ${v} of ${this.props.botId} microapp!`,
          notificationType: "success",
        });

        activityTracker.logEvent(activityTracker.eventTypeNames.PUBLISH, {
          targetEnv: selectedEnvironment2Publish,
          solutionName: `${customerId}.${botId}`,
          success: true,
          solutionVersion: v,
        });
      } else {
        this.props.setNotification({
          openNotification: true,
          notificationDuration: 3000,
          notificationTitle: `${deploystatus.errors}`,
          notificationType: "error",
        });
        activityTracker.logEvent(activityTracker.eventTypeNames.PUBLISH, {
          targetEnv: selectedEnvironment2Publish,
          solutionName: `${customerId}.${botId}`,
          success: false,
        });
      }

      this.props.setPypeLoading(false);
    } catch (e) {
      this.props.setNotification({
        openNotification: true,
        notificationDuration: 3000,
        notificationTitle: `Something went wrong ${e || ""}`,
        notificationType: "error",
      });
      await this.props.setPypeLoading(false);
    } finally {
      this.closePublishModal();
      this.setState({ cancelDisable: false, isOnPublish: false });
    }
    const filteredPypes = pypes
      .filter((p) => p.isPublished)
      .filter((pype) => !pype.stream_name.includes("preview_stream"));
    const filteredSolutions = solutions.filter((p) => p.isPublished);
    await this.props.updatePypes([]);
    this.setState({
      selectedPypes: filteredPypes,
      selectedSolutions: filteredSolutions,
      totalStreams: [],
    });
  }

  handleBreadcrumbClick(link) {
    if (link !== undefined) this.props.navigate(link);
  }

  closePreviewModal(Pypestream) {
    if (typeof Pypestream === "function") {
      Pypestream("shutdown");
    }
    this.setState({ previewModal: false });
  }

  refreshPreviewModal() {
    console.log("refresh clicked");
  }

  async uploadDefaultNLUData(customerId, botId) {
    // create draft version
    await botManagerAPI.createNewBotVersion(`${customerId}.${botId}`, null);
    const res = await this.props.initBot(BOT_VERSION_FLAGS.NLU_ENABLED);
    const draftVersion = res.botData.latestVersion.id;
    console.log("default templates are successfully uploaded.");
    // train data
    await this.props.trainBot(draftVersion);
  }

  handleKeyDown(e) {
    const key = e.key;
    this.setState(
      (prevState) => {
        return {
          keysPressed: [...prevState.keysPressed, key],
        };
      },
      () => {
        const { keysPressed } = this.state;
        const { flags } = this.props;
        const keyComboPressed =
          keysPressed.includes("Shift") &&
          (keysPressed.includes("Control") || keysPressed.includes("Meta"));

        if (keyComboPressed) {
          if (
            flags["pe17357"] &&
            (keysPressed.includes("u") || keysPressed.includes("U"))
          ) {
            this.props.trainBot(this.props.botData.latestVersion.id);
          } else if (keysPressed.includes("e") || keysPressed.includes("E")) {
            const jwt = window.sessionStorage.getItem("global_accessToken");
            navigator.clipboard
              .writeText(jwt)
              .then(() => {
                activityTracker.logEvent(
                  activityTracker.eventTypeNames.COPY_JWT
                );
                this.props.setNotification({
                  openNotification: true,
                  notificationDuration: 5000,
                  notificationTitle:
                    "JWT token has been copied to your clipboard",
                  notificationType: "success",
                });
              })
              .catch((e) => console.error(e));
          }
        }
      }
    );
  }
  setPreviewData() {
    this.setState({ previewModal: true, settingUpPreviewData: false });
  }

  updateMetadata(metadata) {
    if (metadata != "undefined" && metadata != undefined) {
      this.setState({
        sandboxPreviewMetadata: JSON.stringify({
          preview_metadata: [...metadata],
        }),
      });
    } else
      this.setState({
        sandboxPreviewMetadata: JSON.stringify({
          preview_metadata: [{ title: "value" }],
        }),
      });
  }

  handleKeyUp() {
    this.setState({
      keysPressed: [],
    });
  }

  handleSubmitPassthrough() {
    this.previewRefreshRef?.current?.handleSubmitPassthrough();
    try {
      JSON.parse(this.state.sandboxPreviewMetadata);
      this.setState({
        passthroughModal: false,
        parametersError: false,
      });
    } catch (e) {
      this.setState({ parametersError: true });
      return;
    }
  }

  onChangeParameters(value) {
    this.setState(() => ({
      sandboxPreviewMetadata: value,
    }));
  }

  render() {
    const { customer, isTrainingNeeded, solutionInfo, botData, flags } =
      this.props;
    const {
      selectedDrawer,
      selectedSolutions,
      selectedPypes,
      selectedVersion2Publish,
      botHasNewTemplates,
      showChangeSectionConfirmationModal,
      selectedEnvironment2Publish,
      cancelDisable,
      isValidatingBeforePublish,
      previewIsEnabled,
      showChannelTab,
      versionLoading,
      selectedSolutionsForPublish,
    } = this.state;
    const isChannelSelected = selectedPypes.filter((sp) => sp.channel !== "");
    const isPublishActive =
      (selectedPypes.length && isChannelSelected.length) ||
      selectedSolutions.length;

    if (!customer || !botData || !botData.bot) return <Loader />;

    const {
      currentVersions,
      canPublish,
      publishMsg,
      latestVersion,
      bot,
      isTrained,
    } = botData;
    const lv =
      currentVersions && currentVersions.length > 0
        ? currentVersions[currentVersions.length - 1]
        : null;

    const customerGesId = customer.gesId;
    if (
      !isPermitted(PermissionResource.BOT, PermissionAction.READ, customerGesId)
    ) {
      return <Redirect to="/unauthorized" />;
    }

    let confirmStr = "";
    if (botHasNewTemplates === BOT_UPLOAD_STATE.READY) {
      confirmStr =
        "You have not finished uploading your solution. Your draft cannot be created until you upload. Are you sure you want to leave?";
    } else if (botHasNewTemplates === BOT_UPLOAD_STATE.NOT_READY) {
      confirmStr =
        "You have not finished uploading your solution. Select the appropriate Intents and Entities files for your solution. " +
        "Your solution cannot be published without all files. Are you sure you want to leave?";
    }

    let switchContent = "";
    if (this.state.showSolutionModal) {
      switchContent = "Switch to end survey";
    } else if (this.state.showSurveyModal) {
      switchContent = "Switch to main solution";
    }

    const breadcrumbLinks = [
      { name: "All Customers", link: "/customers" },
      { name: customer.id, link: `/customer/${customer.id}` },
    ];

    const popoverErrors = () => {
      return isTrainingNeeded
        ? "Solution version has not been trained"
        : solutionInfo.error
        ? solutionInfo.description
        : publishMsg;
    };

    const pypes = this.state.pypes.filter(
      (pype) => !pype.stream_name.includes("preview")
    );
    const streams = this.state.totalStreams.filter(
      (stream) => !stream.name.includes("preview")
    );

    const publishModalPreSelected =
      botData.latestVersion && botData.latestVersion.version !== "v1";

    return (
      <div
        style={{ display: "contains" }}
        onKeyDown={this.handleKeyDown}
        onKeyUp={this.handleKeyUp}
      >
        <Stack
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          size="xsmall"
        >
          <Stack.Item>
            <OutlinedButton
              disabled={!isTrained || !customer || this.isPublishDisabled()}
              onClick={this.setPreviewData}
            >
              Preview
            </OutlinedButton>
          </Stack.Item>
          <Stack.Item>
            <div className="flex items-center">
              <Button
                {...(isValidatingBeforePublish ? { isLoading: true } : {})}
                disabled={this.isPublishDisabled()}
                onClick={this.publishLatest}
              >
                Publish
              </Button>
              {this.isPublishDisabled() && (
                <span className="inline-block mr-1.5">
                  <Tooltip label={popoverErrors()} placement="bottom">
                    <div>
                      <img
                        className="h-4"
                        src={RedExclamationIcon}
                        alt="Exclamation Icon"
                      />
                      <span style={{ color: "#e67b88" }}></span>
                    </div>
                  </Tooltip>
                </span>
              )}
            </div>
          </Stack.Item>
        </Stack>
        <PreviewModal
          variant="temporary"
          show={this.state.previewModal}
          title={"Preview Conversation"}
          messageBody={""}
          handleClose={this.closePreviewModal}
          handleRefresh={this.refreshPreviewModal}
          customer={customer}
          botData={botData}
          envs={ENVS}
          settingUpPreviewData={this.state.settingUpPreviewData}
          handleEdit={() => this.setState({ passthroughModal: true })}
          ref={this.previewRefreshRef}
          editFlag={flags.pe18176}
          botUserId={this.props.botUserSession.user.user_id}
          updateMetadata={this.updateMetadata}
          sandboxPreviewMetadata={this.state.sandboxPreviewMetadata}
        />
        {this.state.passthroughModal && (
          <PassthroughModal
            passthroughModal={this.state.passthroughModal}
            sandboxPreivewMetadata={this.state.sandboxPreviewMetadata}
            parametersError={this.state.parametersError}
            onClose={() => this.setState({ passthroughModal: false })}
            submitPassthrough={this.handleSubmitPassthrough}
            onChange={(e) => this.onChangeParameters(e.target.value)}
          ></PassthroughModal>
        )}
        <Helmet>
          <title>{`${this.getBotName()} - ${customer.id}`}</title>
        </Helmet>
        {flags.pe18589 ? (
          <React.Suspense fallback={<Loader />}>
            <SimplePublishSlnModalLazy
              show={this.state.showPublishModal}
              version={this.state.selectedVersion2Publish}
              botType={this.props.botData.bot.botType}
              envs={ENVS}
              bots={this.props.bots}
              latestVersion={lv ? lv.version : ""}
              versionLoading={versionLoading}
              selectedSolutionsForPublish={selectedSolutionsForPublish}
              onCancel={this.closePublishModal}
              onPublish={this.onSimplePublish}
              onSelectedSolutions={(slns) => {
                this.setState({ selectedSolutionsForPublish: slns });
              }}
            />
          </React.Suspense>
        ) : (
          <>
            {flags.designProPerformanceImprovements ? (
              <React.Suspense fallback={<Loader />}>
                {this.state.showPublishModal && (
                  <PublishSolutionModalLazy
                    flags={flags}
                    customerId={this.props.customerId}
                    customer={this.props.customer}
                    envs={ENVS}
                    bots={this.props.bots}
                    botId={this.props.botId}
                    show={this.state.showPublishModal}
                    publishedSolutionType={this.state.publishSolution}
                    openInEditMode={
                      publishModalPreSelected && this.state.publishSolution
                    }
                    selectedVersion2Publish={selectedVersion2Publish}
                    latestVersion={lv ? lv.version : ""}
                    onCancel={this.closePublishModal}
                    onPublish={this.handleVersionDeploy}
                    versionLoading={versionLoading}
                  />
                )}
              </React.Suspense>
            ) : (
              <PublishSolutionModal
                envs={ENVS}
                flags={flags}
                customerId={this.props.customerId}
                customer={this.props.customer}
                switchContent={switchContent}
                isPypeLoading={this.props.isPypeLoading}
                isSelectedPypesLoading={this.state.isSelectedPypesLoading}
                pypes={pypes}
                solutions={this.state.solutions}
                totalStreams={streams}
                selectedPypes={this.state.selectedPypes}
                selectedSolutions={this.state.selectedSolutions}
                show={this.state.showPublishModal}
                showPublishContent={this.state.showPublishContent}
                showSurveyModal={this.state.showSurveyModal}
                showSolutionModal={this.state.showSolutionModal}
                showEditSolutionModal={this.state.showEditSolutionModal}
                showEditSurveyModal={this.state.showEditSurveyModal}
                selectedVersion2Publish={selectedVersion2Publish}
                selectedEnvironment2Publish={selectedEnvironment2Publish}
                disabled={!isPublishActive}
                cancelDisabled={cancelDisable}
                latestVersion={lv ? lv.version : ""}
                onSolutionSelect={this.onSolutionSelect}
                onSurveySelect={this.onSurveySelect}
                onCancel={this.closePublishModal}
                onPublish={this.handleVersionDeploy}
                onSelectEnv={this.onSelectedEnvironment2PublishChanged}
                handlePypeSelection={this.handlePypeSelection}
                handleRadioSelection={this.handleRadioSelection}
                handleShowClick={this.handleShowClick}
                handleSwitchClick={this.handleSwitchClick}
                handleBotSelection={this.handleBotSelection}
                handleEditClick={this.handleEditClick}
                versionLoading={this.state.versionLoading}
              />
            )}
          </>
        )}
        <WarningModal
          show={showChangeSectionConfirmationModal}
          messageBody={confirmStr}
          onCancel={this.hideChangeSectionConfirmationModal}
          onConfirm={this.ContinueToChangeSection}
        />
        <div
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          tabIndex={0}
          style={{ outline: "none", display: "none" }}
        >
          <div className="flex flex-row justify-between my-3.5">
            <div className="flex items-center">
              <span className="font-semibold text-base">
                {this.getVersionEnvironmentLatest(
                  currentVersions,
                  latestVersion
                )}
              </span>
              <span
                style={{
                  color: backgroundGrey600,
                }}
                className="ml-2 text-base"
              >
                {latestVersion ? latestVersion.version : null}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let bots = [];
  const { customerId, botId } = ownProps;
  const customer = state.manager.customers[customerId];
  if (customer && customer.bots) {
    bots = customer.bots
      .sort()
      .map((bot) => ({ name: bot.substr(customer.id.length + 1) }));
  }
  return {
    customer,
    customerId,
    botId,
    bots,
    pypes: state.manager.pypes,
    isPypeLoading: state.manager.isPypeLoading,
    streams: state.manager.streams,
    botStreams: state.manager.botStreams,
    isEmptyPypes: state.manager.isEmptyPypes,
    botData: customer ? customer.botsData[botId] : null,
    customersList: state.manager.customersList,
    botUserSession: state.botUserSession,
    interfaces: state.manager.interfaces,
    intents: state.manager.intents,
    isTrainingNeeded: state.manager.isTrainingNeeded,
    solutionInfo: state.manager.solutionInfo,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCustomer(customerId) {
    dispatch(getCustomersIfNeeded());
    return dispatch(getCustomer(customerId));
  },
  getPypes(customerId, versionId) {
    dispatch(getPypes(customerId, versionId));
  },
  updateStream(
    customerId,
    versionId,
    pypeId,
    streamId,
    botId,
    value,
    startChatId,
    endChatId,
    solution
  ) {
    dispatch(
      updateStream(
        customerId,
        versionId,
        pypeId,
        streamId,
        botId,
        value,
        startChatId,
        endChatId,
        solution
      )
    );
  },
  initBot(compilerVersion) {
    const { customerId, botId } = ownProps;
    return dispatch(initBot(customerId, botId, compilerVersion));
  },
  initBotIfNeeded() {
    const { customerId, botId } = ownProps.match.params;
    dispatch(initBotIfNeeded(customerId, botId));
  },
  updatePypes(pypes) {
    dispatch(updatePypes(pypes));
  },
  setPypeLoading(value) {
    dispatch(setPypeLoading(value));
  },
  updateBot(botId, data) {
    return dispatch(updateBot(botId, data));
  },
  navigate(to) {
    ownProps.history.push(to);
  },
  removeInterfaces: () => dispatch(removeInterfaces()),
  getInterfaces: (pypeId, env, interfacesAreEnabled) =>
    dispatch(getInterfaces(pypeId, env, interfacesAreEnabled)),
  trainBot(versionId) {
    dispatch(trainBot(versionId));
  },
  setNotification: (data) => dispatch(setNotification(data)),
  validateSolution: (botId, version, autoTrain) =>
    dispatch(validateSolution(botId, version, autoTrain)),
});

const PreviewPublishNavContainer = withLDConsumer()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PreviewPublishNav))
);

PreviewPublishNav.propTypes = {
  flags: PropTypes.object,
  location: PropTypes.object,
  botData: PropTypes.object,
  botUserSession: PropTypes.object,
  pypes: PropTypes.array,
  streams: PropTypes.object,
  bots: PropTypes.array,
  botStreams: PropTypes.object,
  intents: PropTypes.object,
  interfaces: PropTypes.array,
  customersList: PropTypes.array,
  isEmptyPypes: PropTypes.bool,
  isPypeLoading: PropTypes.bool,
  isTrainingNeeded: PropTypes.bool,
  customer: PropTypes.objectOf(PropTypes.any),
  customerId: PropTypes.string.isRequired,
  getCustomer: PropTypes.func.isRequired,
  botId: PropTypes.string.isRequired,
  initBot: PropTypes.func,
  updatePypes: PropTypes.func,
  getPypes: PropTypes.func,
  updateBot: PropTypes.func,
  removeInterfaces: PropTypes.func,
  getInterfaces: PropTypes.func,
  setPypeLoading: PropTypes.func,
  updateStream: PropTypes.func,
  navigate: PropTypes.func,
  history: PropTypes.object,
  solutionInfo: PropTypes.object,
  trainBot: PropTypes.func,
  onTestNLU: PropTypes.func,
  setNotification: PropTypes.func,
  validateSolution: PropTypes.func,
};

PreviewPublishNavContainer.propTypes = {
  customerId: PropTypes.string.isRequired,
  botId: PropTypes.string.isRequired,
};

PreviewPublishNavContainer.defaultProps = {};

export default PreviewPublishNavContainer;
