import { Modal, ModalFooter } from "react-bootstrap";
import { Button } from "ui-elements";
import React from "react";
import PropTypes from "prop-types";

const IntentModal = ({ show, title, messageBody, onCancel, onConfirm }) => (
  <Modal show={show} className="intent_modal">
    <Modal.Header className="intent_modal_header">
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body className="intent_modal_body">{messageBody}</Modal.Body>
    <ModalFooter className="intent_modal_footer">
      <Button hollow onClick={onCancel}>
        Cancel
      </Button>
      <Button onClick={onConfirm}>Delete</Button>
    </ModalFooter>
  </Modal>
);

IntentModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  messageBody: PropTypes.any,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export { IntentModal };
