import { Button, ControlLabel, Glyphicon, Row, Col } from "react-bootstrap";
import React from "react";
import PropTypes from "prop-types";
import { SkeletonLoader } from "ui-elements";

const EditMainSolution = ({
  selectedPypes,
  isSelectedPypesLoading,
  selectedEnvironment2Publish,
  handleEditClick,
  versionLoading,
}) => (
  <div className="modal-middle-content">
    <div>
      <Row>
        <Col sm={6}>
          <ControlLabel>Environment</ControlLabel>
          <h3 className="mt-0">{selectedEnvironment2Publish}</h3>
        </Col>
        <Col className="small-text text-right" sm={6}>
          <Button className="edit-btn" onClick={handleEditClick}>
            <Glyphicon glyph="glyphicon glyphicon-pencil" />
            &nbsp;&nbsp;Edit
          </Button>
        </Col>
      </Row>
    </div>
    <br />
    <br />
    <div className="middle-content">
      <ControlLabel>Pypes</ControlLabel>
      {isSelectedPypesLoading && <SkeletonLoader loadingRows={3} />}
      {!versionLoading ? (
        !isSelectedPypesLoading &&
        selectedPypes &&
        selectedPypes.length > 0 &&
        selectedPypes.map((pype) => (
          <div key={pype.pypeStreamId} className="pypes-list">
            <Row>
              <Col md={6} xs={12} className="left">
                <Glyphicon glyph="glyphicon glyphicon-ok-circle" />
                <div className="title_des">
                  <h5 className="pype-name">
                    {pype.name}&nbsp;&nbsp;({pype.stream_name})
                  </h5>
                  <p className="pype-description">
                    {pype.channel === "web"
                      ? "Web Conversational Interface"
                      : pype.channel === "facebook"
                      ? "Messaging Apps (e.g Facebook, WhatsApp..)"
                      : "Uncustomized"}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        ))
      ) : (
        <SkeletonLoader loadingRows={5} />
      )}
    </div>
  </div>
);

EditMainSolution.propTypes = {
  selectedPypes: PropTypes.array,
  isSelectedPypesLoading: PropTypes.bool,
  selectedEnvironment2Publish: PropTypes.string,
  handleEditClick: PropTypes.func,
  versionLoading: PropTypes.bool,
};

export { EditMainSolution };
