/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";

const TimeChart = ({ data }) => {
  let processedData = data;
  processedData.forEach((el) => {
    el.date = moment(el?.range[0])?.valueOf();
  });

  const dateFormatter = (date) => {
    return moment(date).format("MM/DD/YY HH:mm");
  };

  return (
    <div className="w-full p-10 pl-0">
      {processedData && (
        <ResponsiveContainer>
          <LineChart data={processedData}>
            <Line
              strokeWidth={3}
              type="monotone"
              dataKey="sessions"
              stroke="#1E40AF"
              dot={false}
              activeDot
              isAnimationActive
              animationDuration={1000}
              animationEasing="ease-in-out"
            />
            <Line
              strokeWidth={3}
              type="monotone"
              dataKey="escalations"
              stroke="#888"
              dot={false}
              activeDot
              isAnimationActive
              animationDuration={1000}
              animationEasing="ease-in-out"
            />
            <XAxis
              dataKey="date"
              tickFormatter={dateFormatter}
              scale="time"
              type="number"
              domain={[
                processedData[0]?.date,
                processedData[processedData?.length - 1]?.date,
              ]}
              angle={0}
              height={50}
              tickMargin={15}
              minTickGap={25}
            />
            <YAxis domain={["dataMin - 10", "dataMax + 10"]} />
            <Legend verticalAlign="top" height="25px" />
            <Tooltip
              wrapperStyle={{ backgroundColor: "#000" }}
              labelFormatter={dateFormatter}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default TimeChart;
