import React, { Component } from "react";
import { FormGroup, FormControl } from "react-bootstrap";
import { Button, IconNames } from "ui-elements";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import config from "../../../config";
import { updateBot, setNotification } from "Manager/Store/actions";
import {
  doesUserHasRole,
  SUPER_ADMIN_ROLE,
  CONSOLE_ROLES,
  BOT_MANAGER_ROLES,
} from "bot-user-session";

import DeleteSolutionModal from "./DeleteSolutionModal";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatedBotLang: props.bot.botLanguage,
      isSaving: false,
      openDeleteSolutionModal: false,
      botType: props.bot.botType,
    };
    this.updateBotLang = this.updateBotLang.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.updateBotLang = this.updateBotLang.bind(this);
    this.updateBotType = this.updateBotType.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.bot.botLanguage !== this.props.bot.botLanguage &&
      this.props.bot.botLanguage !== this.state.updatedBotLang
    ) {
      this.setState({ updatedBotLang: this.props.bot.botLanguage });
    }

    if (
      prevProps.bot.botType !== this.props.bot.botType &&
      this.props.bot.botType !== this.state.botType
    ) {
      this.setState({ botType: this.props.bot.botType });
    }
  }

  updateBotLang(event) {
    this.setState({ updatedBotLang: event.target.value });
  }

  updateBotType(type) {
    this.setState({ botType: type });
  }

  handleCancel() {
    const { bot } = this.props;

    this.setState({
      updatedBotLang: bot.botLanguage,
      botType: bot.botType,
    });
  }

  async handleSave() {
    try {
      const { botType, updatedBotLang } = this.state;
      const { flags } = this.props;

      if (flags.pe19583) {
        let notificationTitle = "";

        if (flags.pe18589) {
          if (updatedBotLang !== this.props.bot.botLanguage) {
            if (botType !== this.props.bot.botType) {
              notificationTitle =
                "Microapp's type and language were updated successfully";
            } else {
              notificationTitle =
                "Microapp's language was updated successfully";
            }
          } else {
            notificationTitle = "Microapp's type was updated successfully";
          }
          await this.props.updateBot(this.props.bot.id, {
            botLanguage: updatedBotLang,
            botType,
          });
        } else {
          notificationTitle = "Microapp's language was updated successfully";
          await this.props.updateBot(this.props.bot.id, {
            botLanguage: updatedBotLang,
          });
        }
        this.props.setNotification({
          openNotification: true,
          notificationDuration: 3000,
          notificationTitle,
          notificationType: "success",
        });
      } else {
        if (flags.pe18589) {
          await this.props.updateBot(this.props.bot.id, {
            botLanguage: updatedBotLang,
            botType,
          });
        } else {
          await this.props.updateBot(this.props.bot.id, {
            botLanguage: updatedBotLang,
          });
        }
        this.props.setNotification({
          openNotification: true,
          notificationDuration: 3000,
          notificationTitle: "Microapp's language was updated successfully",
          notificationType: "success",
        });
      }
    } catch (e) {
      this.props.setNotification({
        openNotification: true,
        notificationDuration: 3000,
        notificationTitle: `The following error occurred while updating microapp's language: ${e}`,
        notificationType: "error",
      });
    }
  }

  onOpenDeleteSolutionModal(open) {
    this.setState({ openDeleteSolutionModal: open });
  }

  render() {
    const {
      show,
      bot,
      bot: { botLanguage: origLanguage, botType: originBotType },
      flags,
    } = this.props;
    const { updatedBotLang, botType, openDeleteSolutionModal } = this.state;

    if (!show) {
      return null;
    }

    const CUSTOMER_ADMIN = [CONSOLE_ROLES[1]];
    const BOT_MANAGER = [BOT_MANAGER_ROLES[1]];

    const userHasCorrectPermissions =
      doesUserHasRole(SUPER_ADMIN_ROLE) ||
      (doesUserHasRole(CUSTOMER_ADMIN) && doesUserHasRole(BOT_MANAGER));

    const enableToDeleteSoln = userHasCorrectPermissions;

    return (
      <div className="settings-page">
        <div className="section-title">
          <h3>Settings</h3>
        </div>
        <FormGroup>
          <h4>Language</h4>
          <FormControl
            disabled={this.state.creatingBot}
            componentClass="select"
            onChange={this.updateBotLang}
            value={updatedBotLang}
            style={{ width: "47%" }}
          >
            {config.supportedTrainingLanguages.map((v) => (
              <option key={v} value={v}>
                {v}
              </option>
            ))}
          </FormControl>
          {flags.pe18589 && (
            <>
              <h4>Type</h4>
              <FormControl
                disabled={this.state.creatingBot}
                componentClass="select"
                onChange={(e) => this.updateBotType(e.target.value)}
                value={botType}
                style={{ width: "47%" }}
              >
                {config.supportedBotTypes.map((v) => (
                  <option key={v} value={v}>
                    {v}
                  </option>
                ))}
              </FormControl>
            </>
          )}
        </FormGroup>
        <Button
          onClick={this.handleCancel}
          disabled={
            updatedBotLang === origLanguage && botType === originBotType
          }
          hollow
          style={{ marginRight: "8px" }}
        >
          Cancel
        </Button>
        <Button
          onClick={this.handleSave}
          disabled={
            updatedBotLang === origLanguage && botType === originBotType
          }
        >
          Save
        </Button>
        {enableToDeleteSoln && (
          <div className="settings-page-delete">
            <Button
              iconName={IconNames.Delete}
              onClick={() => this.onOpenDeleteSolutionModal(true)}
            >
              Delete Microapp
            </Button>
          </div>
        )}
        <DeleteSolutionModal
          open={openDeleteSolutionModal}
          bot={bot}
          onClose={() => this.onOpenDeleteSolutionModal(false)}
        />
      </div>
    );
  }
}

Settings.propTypes = {
  show: PropTypes.bool,
  bot: PropTypes.object,
  flags: PropTypes.object,
  updateBot: PropTypes.func,
  setNotification: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  updateBot(botId, data) {
    return dispatch(updateBot(botId, data));
  },
  setNotification: (data) => dispatch(setNotification(data)),
});

export default withLDConsumer()(
  withRouter(connect(null, mapDispatchToProps)(Settings))
);
