/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Col } from "react-bootstrap";
import PropTypes from "prop-types";
import "../../../Styles/Nlu.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import botManagerAPI from "../../../../bot-manager-api";
import { Button, DataTable } from "ui-elements";
import { Modal } from "react-bootstrap";
import Papa from "papaparse";
import { v4 as uuidv4 } from "uuid";
import {
  NLU_INTENT_HEADERS_H,
  NLU_INTENT_HEADERS_SIMPLIFIED_H,
  NLU_MODEL_STATUS,
} from "../defs";
import {
  updateIntentData,
  trainBot,
  setNotification,
  updateSolutionInfo,
} from "../../../Store/actions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import activityTracker from "../../../../activityTracker";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import NLUTrainingState from "./NLUTrainingState";
import { capitalizeEachWord } from "../../../utils/utils";
import { TextField } from "ui-elements";

// eslint-disable-next-line no-useless-escape
const UNESCAPE_CHAR_PATTERN = /("\s*|\n|,\s*)'(?=\s*[@\+\-=\|%])/gm;

// Invisible intent used for 'Add New Intent' view because utterances can't
// be added without defining intent first according to the data structure
const TEMP_INTENT = "ps_temp_intent_hidden";
const sortAlphaNum = (a, b) => a.localeCompare(b, "en", { numeric: true });

class NLUOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      utterances: [],
      fetching: false,
      currentNum: 0,
      showModal: false,
      csvObject: {
        columns: NLU_INTENT_HEADERS_SIMPLIFIED_H,
        intents: {
          [TEMP_INTENT]: [],
        },
      },
      option: "",
      selectedUtterance: {},
      selectedIntent: "",
      editedUtterance: "",
      allIntents: [],
      intentsWithPercentages: [],
      newUtterance: "",
      utteranceExistsInData: false,
      utteranceError: "",
      trainingSolution: false,
      trainingError: undefined,
    };

    this.handleReload = this.handleReload.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleData = this.handleData.bind(this);
    this.setOption = this.setOption.bind(this);
    this.handleUtteranceChange = this.handleUtteranceChange.bind(this);
    this.handleChangeUtteranceOrIntent =
      this.handleChangeUtteranceOrIntent.bind(this);
    this.handleAddUtterance = this.handleAddUtterance.bind(this);
    this.getNLUModelState = this.getNLUModelState.bind(this);
    this.onTrainSolution = this.onTrainSolution.bind(this);
    this.formatTimestamp = this.formatTimestamp.bind(this);
    this.intentFormatter = this.intentFormatter.bind(this);
    this.isDisableAddUtterance = this.isDisableAddUtterance.bind(this);
  }

  componentDidMount() {
    const { customerId, botId } = this.props.match.params;
    const { currentNum } = this.state;

    botManagerAPI
      .getUtterances(`${customerId}.${botId}`, currentNum, 25)
      .then((res) => res.data)
      .then((utterances) => {
        const data = utterances.data.map((item) => ({
          ...item,
          id: uuidv4(),
        }));
        this.setState({ utterances: data });
      })
      .catch((e) => console.error(e));

    const versions = this.props.bot.versions;

    if (versions.length) {
      this.downloadAllFiles();
    }
  }

  handleReload() {
    // const { currentNum } = this.state;
    const { customerId, botId } = this.props.match.params;

    this.setState({ fetching: true });
    botManagerAPI
      .getUtterances(`${customerId}.${botId}`, 0, 25)
      .then((res) => res.data)
      .then((utterances) => {
        const data = utterances.data.map((item) => ({
          ...item,
          id: uuidv4(),
        }));
        this.setState({ utterances: data, fetching: false });
      })
      .catch((e) => console.error(e));
  }

  loadMore() {
    const { currentNum } = this.state;
    const newNum = currentNum + 25;
    const { customerId, botId } = this.props.match.params;

    botManagerAPI
      .getUtterances(`${customerId}.${botId}`, newNum, 25)
      .then((res) => res.data)
      .then((utterances) => {
        const data = utterances.data.map((item) => ({
          ...item,
          id: uuidv4(),
        }));
        if (data) {
          this.setState({
            utterances: [...this.state.utterances, ...data],
            fetching: false,
            currentNum: newNum,
          });
        }
      })
      .catch((e) => console.error(e));
  }

  toggleModal(newState, utterance = {}, intent = "") {
    if (!newState) {
      this.setState({
        showModal: newState,
        selectedUtterance: "",
        option: "",
        selectedIntent: "",
        intentsWithPercentages: [],
      });
    }

    const { allIntents } = this.state;
    let intentsWithPercentages = [];
    const utteranceIntents = utterance.intent;

    Object.keys(allIntents).forEach((intentKey) => {
      const matchedIntent = utteranceIntents === intentKey;
      if (matchedIntent) {
        let newLabel = intent.replace(/_/g, " ");
        newLabel = capitalizeEachWord(newLabel);

        intentsWithPercentages.push({
          label: `${newLabel} (${(utterance.intent_confidence * 100).toFixed(
            1
          )}%)`,
          value: intent,
        });
      } else if (!matchedIntent) {
        let newLabel = intentKey;
        newLabel = capitalizeEachWord(newLabel);

        intentsWithPercentages.push({
          label: `${newLabel}`,
          value: intentKey,
        });
      }
    });

    const intentAlreadyExists = Object.keys(allIntents).find(
      (trainedIntent) => trainedIntent === intent
    );
    if (!intentAlreadyExists) {
      let newLabel = intent.replace(/_/g, " ");
      newLabel = capitalizeEachWord(newLabel);

      intentsWithPercentages.push({
        label: `${newLabel} (${(utterance.intent_confidence * 100).toFixed(
          1
        )}%)`,
        value: intent,
      });
    }

    intentsWithPercentages = intentsWithPercentages.filter(
      (intent) => intent.value !== "ps_temp_intent_hidden"
    );

    this.setState({
      showModal: newState,
      selectedUtterance: utterance,
      option: intent,
      editedUtterance: utterance.user_utterance.replace(/"/g, ""),
      selectedIntent: intent,
      intentsWithPercentages,
      newUtterance: utterance.user_utterance.replace(/"/g, ""),
    });
  }

  async downloadAllFiles() {
    const versions = this.props.bot.versions;

    if (!versions.length) {
      // new bot, no draft yet
      return;
    }

    const { latestVersion } = this.props.botData;
    const file = "templates/intent.csv";

    if ((latestVersion.files || []).includes(file)) {
      const promises = [];
      promises.push(
        new Promise((resolve, reject) => {
          botManagerAPI
            .downloadTemplateFile(
              `${this.props.bot.id}.${latestVersion.version}`,
              file
            )
            .then((response) => {
              resolve({ data: response.file_data });
            });
        })
      );
      const [...filesData] = await Promise.all(promises);
      this.handleData(filesData);
    }
  }

  handleData(filesData) {
    const csv = filesData[0].data;
    const unescapedStreams = csv.replace(UNESCAPE_CHAR_PATTERN, ($0) =>
      $0.replace("'", "")
    );
    if (unescapedStreams) {
      const csvToArray = Papa.parse(unescapedStreams, { skipEmptyLines: true });

      if (csvToArray.errors.length) {
        console.error("Error while parsing Intent CSV");
      }

      const [columns, ...rows] = csvToArray.data;

      const csvObject = {
        columns,
        intents: {},
      };

      rows.forEach(([utterance, intent, type], index) => {
        csvObject.intents[intent] = csvObject.intents[intent] || [];
        csvObject.intents[intent].push({
          utterance,
          type,
          error: undefined,
          id: `${intent}_${index}`,
        });
      });
      csvObject.intents[TEMP_INTENT] = [];

      const { intents } = csvObject;
      this.setState({ csvObject, allIntents: intents });
    }
  }

  getIntents() {
    const {
      csvObject: { intents },
    } = this.state;
    return intents;
  }

  setOption(newInputLabel) {
    this.setState({ option: newInputLabel });
  }

  handleUtteranceChange(value) {
    this.setState({
      newUtterance: value,
    });
  }

  handleChangeUtteranceOrIntent() {
    this.setState({ utterances: [] });
  }

  handleAddUtterance() {
    const { bot } = this.props;
    const {
      newUtterance,
      option,
      intentsWithPercentages,
      editedUtterance,
      selectedUtterance,
      selectedIntent,
    } = this.state;
    const { customerName, botName } = bot;
    let intentValue = intentsWithPercentages.find(
      (intent) => intent.label === option
    );
    const body = {
      intent: intentValue.value,
      utterance: newUtterance || editedUtterance,
    };
    botManagerAPI
      .addIntentToUtterance(customerName, botName, body)
      .then((res) => {
        this.setState({
          showModal: false,
        });
        activityTracker.logEvent(
          activityTracker.eventTypeNames.NEW_UTTERANCE_LABELED,
          {
            from: selectedIntent,
            fromConfidenceLevel: selectedUtterance.intent_confidence,
            to: intentValue.value,
            solution: botName,
            solutionVersion: this.props.botData.currentVersions[0].version,
            customer: customerName,
            initialUtterance: editedUtterance,
            addedUtterance: newUtterance,
          }
        );
      })
      .catch((e) => console.error(e));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.option !== this.state.option ||
      prevState.newUtterance !== this.state.newUtterance
    ) {
      const { newUtterance, allIntents, option, intentsWithPercentages } =
        this.state;
      let intentValue = intentsWithPercentages.find(
        (intent) => intent.label === option
      );
      let currentIntent = "";
      if (intentValue) {
        intentValue = intentValue.value;
        currentIntent = allIntents[intentValue];
      } else {
        currentIntent = allIntents[option];
      }
      let checkIfExists = false;
      if (currentIntent) {
        currentIntent.forEach((intent) => {
          if (intent.utterance === newUtterance) {
            checkIfExists = true;
            this.setState({
              utteranceError: "Utterance already exists in training data",
            });
          }
        });
      }
      if (!checkIfExists) {
        this.setState({
          utteranceError: newUtterance.trim()
            ? ""
            : "Utterance cannot be blank.",
        });
      }
    }
  }

  isDisableAddUtterance() {
    const { utteranceError, intentsWithPercentages, option } = this.state;

    const intentValue = intentsWithPercentages.find(
      (intent) => intent.label === option
    );

    const isValidIntent = intentValue && intentValue.value !== "out_of_scope";

    return !isValidIntent || utteranceError;
  }

  formatTimestamp(timestamp) {
    let newDate = new Date(timestamp);

    let formattedDate = "";
    let minutes = newDate.getMinutes();
    const hours = newDate.getHours();
    const month = newDate.getMonth();
    const day = newDate.getDay();
    const year = String(newDate.getFullYear()).slice(2);

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    if (hours > 12) {
      formattedDate = `${hours - 12}:${minutes} PM`;
    } else if (hours === 12) {
      formattedDate = `${hours}:${minutes} PM`;
    } else {
      formattedDate = `${hours}:${minutes} AM`;
    }

    const formattedTime = formattedDate;
    const formattedYear = `${month}.${day}.${year}`;

    return (
      <div>
        <p
          className="body-notation"
          style={{ fontStyle: "normal", fontSize: "1rem" }}
        >
          {formattedTime}
        </p>
        <p
          className="body-notation"
          style={{ fontStyle: "normal", fontSize: "1rem" }}
        >
          {formattedYear}
        </p>
      </div>
    );
  }

  intentFormatter(utterance) {
    return (
      <div>
        <p style={{}}>
          {capitalizeEachWord(utterance.intent.replace(/_/g, " "))}
        </p>
        <p style={{ lineHeight: 1.5, color: "#7a6769" }}>
          {(utterance.intent_confidence * 100).toFixed(1)}% Conf
        </p>
      </div>
    );
  }

  getNLUModelState(hasError = false) {
    const { isTrainingNeeded } = this.props;

    let state = isTrainingNeeded
      ? NLU_MODEL_STATUS.NOT_TRAINED
      : NLU_MODEL_STATUS.TRAINED;
    if (hasError) state = NLU_MODEL_STATUS.NOT_TRAINED;

    return state;
  }

  async onTrainSolution() {
    this.setState({ trainingSolution: true });

    const error = await this.props.trainBot();

    this.props.setNotification({
      openNotification: true,
      notificationDuration: 6000,
      notificationTitle: error
        ? "Microapp has not been trained."
        : "Microapp was trained successfully",
      notificationType: error ? "error" : "success",
    });

    if (!error) {
      this.props.updateSolutionInfo({
        loading: false,
        error: false,
        title: `Your latest draft (${this.props.botData.latestVersion.version}) is ready to be published.`,
        description: "",
      });
    }
    this.setState({ trainingSolution: false, trainingError: error });
  }

  render() {
    const { show } = this.props;
    const {
      utterances,
      showModal,
      selectedUtterance,
      intentsWithPercentages,
      selectedIntent,
      newUtterance,
      utteranceError,
      trainingSolution,
      trainingError,
    } = this.state;

    const editUtteranceModal = (
      <Modal
        className="edit-utterance-modal"
        dialogClassName="modal-width"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={showModal}
        onHide={() => this.toggleModal(false, selectedUtterance)}
        backdrop="static"
        enforceFocus={false}
      >
        <Modal.Header className="edit-utterance-modal--header">
          <Modal.Title>Add to Training Data</Modal.Title>
        </Modal.Header>
        <Modal.Body className="edit-utterance-modal--body">
          <p>
            Modify the utterance or intent label below before adding this to
            your intent training data.
          </p>
          <form>
            <div className="display-utterance">
              <Col lg={8} style={{ paddingLeft: 0 }}>
                <TextField
                  error={!!utteranceError}
                  label="utterance"
                  value={newUtterance}
                  onChange={(e) => this.handleUtteranceChange(e.target.value)}
                  variant="outlined"
                  fullWidth
                  helperText={utteranceError}
                  InputLabelProps={{
                    style: {
                      textTransform: "capitalize",
                    },
                  }}
                  FormHelperTextProps={{
                    style: {
                      margin: 0,
                      marginLeft: "16px",
                      fontSize: "12px",
                    },
                  }}
                />
              </Col>
              <Col lg={4} style={{ paddingRight: 0 }}>
                <Autocomplete
                  options={intentsWithPercentages ? intentsWithPercentages : []}
                  getOptionLabel={(option) => option.label || option}
                  defaultValue={
                    intentsWithPercentages.length > 0
                      ? intentsWithPercentages.find(
                          (intent) => intent.value === selectedIntent
                        )
                        ? intentsWithPercentages.find(
                            (intent) => intent.value === selectedIntent
                          ).label
                        : null
                      : null
                  }
                  autoHighlight
                  disableClearable
                  className="autocomplete"
                  popupIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none" fillRule="evenodd">
                        <g>
                          <g
                            style={{ transition: "none" }}
                            transform="translate(-80 -300) translate(80 300)"
                          >
                            <circle cx="12" cy="12" r="12" fill="#FFF" />
                            <path
                              fill="#0A3AB4"
                              d="M12.265 16.46l6.594-6.597c.15-.147.15-.384.003-.531l-.222-.222c-.147-.147-.384-.147-.531 0l-6.106 6.11-6.11-6.11c-.147-.147-.384-.147-.531 0l-.222.222c-.147.147-.147.384 0 .531l6.594 6.597c.147.147.384.147.531 0z"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  }
                  inputValue={
                    this.state.option !== undefined
                      ? this.state.option
                      : selectedIntent
                  }
                  onInputChange={(event, newInputValue) => {
                    this.setOption(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="intent"
                      InputLabelProps={{
                        style: {
                          textTransform: "capitalize",
                          fill: "#bbbbbb",
                        },
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Col>
            </div>
          </form>
          <div className="text-right">
            <Button
              type="button"
              onClick={() => this.toggleModal(false, selectedUtterance)}
              hollow
              style={{ marginRight: ".57rem" }}
            >
              Cancel
            </Button>
            <Button
              style={{ marginRight: 0 }}
              disabled={this.isDisableAddUtterance()}
              onClick={this.handleAddUtterance}
            >
              Add
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );

    const columns = [
      {
        title: "Date",
        dataIndex: "timestamp",
        key: "timestamp",
        render: (value) => this.formatTimestamp(value.timestamp),
        sorter: (a, b) => (a.timestamp > b.timestamp ? 1 : -1),
      },
      {
        title: "Utterance",
        dataIndex: "user_utterance",
        key: "user_utterance",
        render: (value) => (
          <div style={{ padding: "14px 0" }}>
            <p style={{ fontWeight: 600 }}>
              {value && value.user_utterance.replace(/"/g, "")}
            </p>
          </div>
        ),
      },
      {
        title: "Intent",
        dataIndex: "intent_confidence",
        key: "intent_confidence",
        sorter: (a, b) => (a.intent_confidence > b.intent_confidence ? 1 : -1),
        render: (utterance) => this.intentFormatter(utterance),
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
        align: "right",
        render: (utterance) => (
          <Button
            className="whitespace-nowrap"
            onClick={() => this.toggleModal(true, utterance, utterance.intent)}
          >
            NLU Training
          </Button>
        ),
      },
    ];

    return (
      <div>
        {show && (
          <div>
            <div className="main-title">NLU Overview</div>

            <div className="description-text first">
              Microapps that use Intents & Entities or a Search Engine must be
              trained before they can be tested or deployed.
            </div>
            <div className="description-text">
              Training includes providing training data and then pressing the
              "Train Microapp" button below. Training may take several minutes
              (especially when training Intents), so please be patient. You must
              retrain your microapp every time you update your training data,
              otherwise, your microapp may not respond as expected.
            </div>

            <NLUTrainingState
              state={this.getNLUModelState(trainingError ? true : false)}
              className="nlu-overview-status"
              training={trainingSolution}
              onTrainSolution={this.onTrainSolution}
              onTestNLU={this.props.onTestNLU}
            />

            <div>
              <div className="main-title">Utterances</div>

              <div className="description-text">
                Whenever a user submits an utterance to your Microapp, it is
                collected below. Text that is not processed by NLU and secure
                text (for passwords or other secure interactions) are not
                included. Utterances are only saved for thirty days before they
                are removed for security purposes.
              </div>

              <DataTable
                columns={columns}
                dataSource={utterances}
                rowKey="id"
                bordered={false}
                hover={true}
                rowHeight={76}
              />
              {utterances && utterances.length > 25 ? (
                <Button hollow onClick={() => this.loadMore()}>
                  Show 25 More
                </Button>
              ) : null}
              {utterances.length < 1 && (
                <p className="body-notation" style={{ marginTop: "1rem" }}>
                  No utterances have been recorded yet.
                </p>
              )}
            </div>
          </div>
        )}
        {editUtteranceModal}
      </div>
    );
  }
}

NLUOverview.propTypes = {
  show: PropTypes.bool,
  flags: PropTypes.object,
  match: PropTypes.object,
  customerId: PropTypes.string,
  botId: PropTypes.string,
  bot: PropTypes.object,
  botData: PropTypes.object,
  trainBot: PropTypes.func,
  setNotification: PropTypes.func,
  onTestNLU: PropTypes.func,
  updateSolutionInfo: PropTypes.func,
  isTrainingNeeded: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    isTrainingNeeded: state.manager.isTrainingNeeded,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateIntentData({ columns, intents }) {
    const rows = [];

    for (const intent in intents) {
      intents[intent].forEach(({ utterance, type }) => {
        const row = [utterance, intent];

        if (columns[2] === NLU_INTENT_HEADERS_H[2]) {
          row.push(type);
        }
        rows.push(row);
      });
    }

    return dispatch(
      updateIntentData({
        data: Papa.unparse({
          fields: columns,
          data: rows,
        }),
      })
    );
  },
  trainBot: async () =>
    await dispatch(trainBot(ownProps.botData.latestVersion.id)),
  setNotification(data) {
    return dispatch(setNotification(data));
  },
  updateSolutionInfo({ loading, title, error, description }) {
    return dispatch(updateSolutionInfo({ loading, title, error, description }));
  },
});

export default withLDConsumer()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(NLUOverview))
);
