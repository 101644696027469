import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { DotMenu, IconNames } from "ui-elements";

const VersionCard = ({
  title,
  version,
  type,
  description,
  hideMenu,
  children,
  onMenuClick,
}) => {
  const menuData = [
    {
      icon: IconNames.DownloadArrow,
      menu: "Download CSV",
      value: "download",
    },
  ];

  return (
    <div className={clsx("dialog-version-card", type)}>
      <div className="dialog-version-card-header">
        <span className="dialog-version-card-title">{title}</span>
        <span className="dialog-version-card-version">{version}</span>
      </div>
      <p className="dialog-version-card-desc">{description}</p>
      {children}
      {!hideMenu && (
        <div className="dialog-version-card-dotmenu">
          <DotMenu menus={menuData} onClick={onMenuClick} />
        </div>
      )}
    </div>
  );
};

VersionCard.propTypes = {
  title: PropTypes.string,
  version: PropTypes.string,
  lastVersion: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
  hideMenu: PropTypes.bool,
  children: PropTypes.any,
  onMenuClick: PropTypes.func,
};

VersionCard.defaultProps = {
  title: "",
  version: "",
  lastVersion: "",
  type: "primary",
  description: "",
  hideMenu: false,
  children: null,
  onMenuClick: () => {},
};

export default VersionCard;
