import React from "react";

import IconPype from "../../../Assets/icon-pypestream.png";
import IconApple from "../../../Assets/icon-apple.png";
import IconMessenger from "../../../Assets/icon-messenger.png";
import IconGoogle from "../../../Assets/icon-google.png";
import IconInstagram from "../../../Assets/icon-instagram.png";
import IconSMS from "../../../Assets/icon-sms.png";
import IconWhatsApp from "../../../Assets/icon-whatsapp.png";

export const CHANNEL_TYPE = {
  PYPE: "pype",
  APPLE_BUSINESS_CHAT: "apple",
  FACEBOOK: "facebook",
  GOOGLE_BUSINESS_MESSAGE: "google",
  INSTAGRAM: "instagram",
  SMS: "sms",
  WHATSAPP: "whatsapp",
};

export const CHANNEL_FIELDS = {
  [CHANNEL_TYPE.APPLE_BUSINESS_CHAT]: {
    accountID: "account_id",
  },
  [CHANNEL_TYPE.FACEBOOK]: {
    appID: "app_id",
    pageID: "page_id",
    token: "access_token",
  },
  [CHANNEL_TYPE.GOOGLE_BUSINESS_MESSAGE]: {
    agentName: "agent_name",
  },
  [CHANNEL_TYPE.INSTAGRAM]: {
    appID: "app_id",
    pageID: "page_id",
    token: "access_token",
  },
  [CHANNEL_TYPE.SMS]: {
    accountSID: "account_sid",
    accountAuthToken: "account_auth_token",
    phoneNumber: "phone_number",
    messagingServiceSID: "phone_number_sid",
  },
  [CHANNEL_TYPE.WHATSAPP]: {
    accountSID: "account_sid",
    accountAuthToken: "account_auth_token",
    phoneNumber: "phone_number",
    messagingServiceSID: "phone_number_sid",
  },
};

export const CHANNEL_INFO = [
  {
    title: "Pype",
    description: "Pypestream's interface, can be embedded on websites.",
    value: CHANNEL_TYPE.PYPE,
    icon: <img src={IconPype} />,
    hide: false,
  },
  {
    title: "Apple Messages for Business",
    description: "",
    value: CHANNEL_TYPE.APPLE_BUSINESS_CHAT,
    icon: <img src={IconApple} alt="" width="48" height="48" />,
    hide: false,
  },
  {
    title: "Messenger",
    description: "",
    value: CHANNEL_TYPE.FACEBOOK,
    icon: <img src={IconMessenger} alt="" width="48" height="48" />,
    hide: false,
  },
  {
    title: "Google Business Messages",
    description: "",
    value: CHANNEL_TYPE.GOOGLE_BUSINESS_MESSAGE,
    icon: <img src={IconGoogle} alt="" width="48" height="48" />,
    hide: false,
  },
  {
    title: "Instagram Messenger",
    description: "",
    value: CHANNEL_TYPE.INSTAGRAM,
    icon: <img src={IconInstagram} alt="" width="48" height="48" />,
    hide: false,
  },
  {
    title: "SMS",
    description: "Text messages are enabled through Twillio.",
    value: CHANNEL_TYPE.SMS,
    icon: <img src={IconSMS} alt="" width="48" height="48" />,
    hide: false,
  },
  {
    title: "WhatsApp",
    description: "WhatsApp chat is enabled through Twillio.",
    value: CHANNEL_TYPE.WHATSAPP,
    icon: <img src={IconWhatsApp} alt="" width="48" height="48" />,
    hide: false,
  },
];
