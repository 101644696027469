/* eslint-disable no-unused-vars */
/* eslint-disable react/no-deprecated */
import React, { Component, Fragment } from "react";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import Papa from "papaparse";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  TextInputField,
  Icon,
  IconNames,
  DataTable,
  DotMenu,
  TextField,
} from "ui-elements";
import SwitchC from "react-switch";
import { IntentModal } from "../../modals/IntentModal";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";
import {
  updateEntityData,
  updateBot,
  setIfTrainingNeeded,
  setNotification,
  validateSolution,
} from "../../../Store/actions";
import botManagerAPI from "../../../../bot-manager-api";
import { NLU_ENTITY_HEADERS_H, BOT_VERSION_FLAGS } from "../defs";
import "../../../Styles/Bot.scss";
import activityTracker from "../../../../activityTracker";
import {
  validateFileHeaders,
  validateFileContent,
} from "../../../utils/validator";
import { dateCompare, getSoftTimestamp, debounce } from "../../../utils/utils";

/* eslint-disable array-callback-return */
class Entity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entityDataView: false,
      entityData: {},
      showModal: false,
      modalData: {
        title: "",
        messageBody: "",
      },
      entityTable: [],
      entityTableData: [],
      entityList: [],
      editEntity: false,
      selectedEntityType: "",
      entityError: "",
      initialEntityTableData: [{ id: uuidv4(), entity: "", synonym: "" }],
      enableFuzzyMatching: props.bot.enableFuzzyEntityMatching,
      compilerVersion: BOT_VERSION_FLAGS.UNKNOWN,
      entityCreated: false,
      isLoadingEntities: false,
    };

    this.typeFormatter = this.typeFormatter.bind(this);
    this.typeFormatterEntity = this.typeFormatterEntity.bind(this);
    this.typeLastUpdatedFormatter = this.typeLastUpdatedFormatter.bind(this);
    this.typeSynonymsFormatter = this.typeSynonymsFormatter.bind(this);
    this.typeEntityFormatter = this.typeEntityFormatter.bind(this);
    this.deleteEntityList = this.deleteEntityList.bind(this);
    this.deleteEntityTableData = this.deleteEntityTableData.bind(this);
    this.handleSaveEntityData = this.handleSaveEntityData.bind(this);
    this.handleSaveEntitySynonyms = this.handleSaveEntitySynonyms.bind(this);
    this.handleEntityDataDelete = this.handleEntityDataDelete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onEntitySelection = this.onEntitySelection.bind(this);
    this.handleBackOption = this.handleBackOption.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSaveEntityType = this.handleSaveEntityType.bind(this);
    this.handleAddEntityData = this.handleAddEntityData.bind(this);
    this.handleAddEntity = this.handleAddEntity.bind(this);
    this.handleFuzzyMatchingChange = this.handleFuzzyMatchingChange.bind(this);
    this.openCSVFileDialog = this.openCSVFileDialog.bind(this);
    this.selectEntityFile = this.selectEntityFile.bind(this);
    this.uploadEntityFile = this.uploadEntityFile.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      enableFuzzyMatching: nextProps.bot.enableFuzzyEntityMatching,
    });
  }

  async componentDidMount() {
    const {
      bot: { versions },
      latestVersion,
    } = this.props;
    if (versions.length) {
      this.getAllEntities();
    }
    if (latestVersion && latestVersion.compilerVersion) {
      this.setState({ compilerVersion: latestVersion.compilerVersion });
    }
  }

  componentDidUpdate(prevProps) {
    // update compilerVersion value
    const prevCompilerVersion = prevProps.latestVersion
      ? prevProps.latestVersion.compilerVersion
      : -1;
    const currentLenCompilerVersion = this.props.latestVersion
      ? this.props.latestVersion.compilerVersion
      : -1;
    if (prevCompilerVersion !== currentLenCompilerVersion) {
      this.setState({ compilerVersion: currentLenCompilerVersion });
    }
  }

  async getAllEntities() {
    const { versions } = this.props.bot;

    if (!versions.length) {
      // new bot, no draft yet
      return;
    }

    this.setState({ isLoadingEntities: true });
    const promises = [];
    promises.push(
      new Promise((resolve, reject) => {
        botManagerAPI.getAllEntities(this.props.bot.id).then((response) => {
          resolve({ data: response.data });
        });
      })
    );
    const entityResponse = await Promise.all(promises);
    const data = this.handleData(entityResponse);
    if (data) {
      this.setState({
        entityTable: data.entity,
        entityList: data.entityList,
        entityColumn: data.columns,
      });
    }
    this.setState({ isLoadingEntities: false });
  }

  handleData(entityResponse) {
    const data = entityResponse[0].data;

    const csvObject = {
      columns: ["Type", "Entity", "Synonym"],
      entity: [],
      entityList: [],
      time: {},
    };

    if (data) {
      data.forEach((item) => {
        if (csvObject.entityList.includes(item.type)) {
          const index = csvObject.entity.findIndex(
            ({ type }) => type === item.type
          );
          if (index >= 0) {
            const temp = csvObject.entity[index];
            csvObject.entity[index].entity.push(item.entity);
            csvObject.entity[index].synonym.push(
              (item.synonyms || []).join(",")
            );
            csvObject.entity[index].time[item.entity] = item.time;
            csvObject.entity[index].lastUpdated =
              dateCompare(temp.lastUpdated, item.time) > 0
                ? temp.lastUpdated
                : item.time;
          }
        } else {
          csvObject.entityList.push(item.type);
          csvObject.entity.push({
            id: uuidv4(),
            type: item.type,
            entity: [item.entity],
            synonym: [(item.synonyms || []).join(",")],
            time: { [item.entity]: item.time },
            lastUpdated: item.time,
          });
        }
      });
    }

    return csvObject;
  }

  typeFormatter(value, row) {
    let editedType = "";
    if (row.type) {
      editedType = row.type.replace(/_/g, " ");
    }
    return (
      <div className="table-header-data">
        <span>{editedType}</span>
        <br />
        <span className="table-header-subdata">{row.type}</span>
      </div>
    );
  }

  typeFormatterEntity(value, row) {
    const firstFiveEntity = value.slice(0, 5);
    const restEntity = value.slice(5);

    return (
      <div className="uttranceRowData">
        {firstFiveEntity.join(" • ")}
        {restEntity.length ? ` • ${restEntity.length} More ` : ``}
      </div>
    );
  }

  typeLastUpdatedFormatter(value) {
    if (value) {
      return getSoftTimestamp(value);
    }

    return null;
  }

  typeSynonymsFormatter(value, row) {
    let final = "";
    if (row && row.synonym && row.synonym.length > 0) {
      final = value.replace(/"/g, "");
    }

    return (
      <div onBlur={(e) => this.handleSaveEntitySynonyms(row, e.target.value)}>
        <TextInputField
          fill
          type="text"
          value={final}
          placeholder="Seperate synonyms with a comma"
          hasError={row.synonymserror}
          errorMessage={row.synonymserror}
        />
      </div>
    );
  }

  typeEntityFormatter(value, row) {
    return (
      <div onBlur={(e) => this.handleSaveEntityData(row, e.target.value)}>
        <TextInputField
          fill
          type="text"
          value={value}
          placeholder="Name"
          hasError={row.error}
          errorMessage={row.error}
        />
      </div>
    );
  }

  deleteEntityList(value, row) {
    return (
      <div>
        <Icon
          name={IconNames.Close}
          width="24px"
          viewBox="0 0 24 24"
          fill="#0a3ab4"
          onClick={(e) => this.handleModal(row, e, "List")}
        />
      </div>
    );
  }

  deleteEntityTableData(value, row) {
    return (
      <div>
        <Icon
          name={IconNames.Close}
          width="24px"
          viewBox="0 0 24 24"
          fill="#0a3ab4"
          onClick={(e) => this.handleEntityDataDelete(row, e)}
        />
      </div>
    );
  }

  async handleSaveEntityData(row, newEntityValue) {
    const { entityTableData, entitySubValue, entityCreated } = this.state;
    if (newEntityValue !== row.entity) {
      const entities = entityTableData.map((item) => {
        return item.entity;
      });
      const isValidEntity = !entities.includes(newEntityValue);
      if (isValidEntity) {
        try {
          let result = { status: 200 };
          if (!entityCreated || !row.entity) {
            if (entitySubValue) {
              // create
              result = await botManagerAPI.createOneEntityType(
                this.props.bot.id,
                {
                  entity: newEntityValue,
                  type: entitySubValue,
                  synonyms: [],
                }
              );
              this.setState({ entityCreated: true });

              const {
                latestVersion: { id, version },
              } = this.props.botData;
              this.props.validateSolution(id, version, true);
            }
          } else if (newEntityValue) {
            // update
            result = await botManagerAPI.updateOneEntityType(
              this.props.bot.id,
              {
                old_entity: row.entity,
                old_type: entitySubValue,
                new_entity: newEntityValue,
                new_type: entitySubValue,
              }
            );

            const {
              latestVersion: { id, version },
            } = this.props.botData;
            this.props.validateSolution(id, version, true);
          }

          if (result.status === 200) {
            const userRefobj = [...entityTableData];
            userRefobj.forEach((item) => {
              if (item.entity === row.entity) {
                item.type = entitySubValue;
                item.entity = newEntityValue;
                item.error = "";
                item.lastUpdated = new Date().toISOString();
              }
            });
            const newData = this.state.entityTable.map((item) => {
              if (item.type === entitySubValue) {
                item.entity = [
                  ...item.entity.filter((iEn) => iEn !== row.entity),
                  newEntityValue,
                ];
                item.lastUpdated = new Date().toISOString();
                item.time[newEntityValue] = new Date().toISOString();
              }
              return item;
            });
            this.setState({
              entityTableData: userRefobj,
              entityTable: newData,
            });
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        const userRefobj = [...entityTableData];
        userRefobj.forEach((item) => {
          if (item.entity === row.entity) {
            item["type"] = entitySubValue;
            item["error"] = "Entity names must be unique.";
          }
        });
        this.setState({ entityTableData: userRefobj });
      }
    }
  }

  async handleSaveEntitySynonyms(row, newSynonymValue) {
    const { entityTableData, entitySubValue, entityCreated } = this.state;
    const regex = /^[a-zA-Z0-9_, -]*$/;
    if (newSynonymValue !== "" && row.entity !== "") {
      if (regex.test(newSynonymValue)) {
        const oldSynonyms = row.synonym ? row.synonym.split(",") : [];
        const currentSynonyms = newSynonymValue.split(",");
        const newSynonyms = currentSynonyms.filter(
          (item) => !oldSynonyms.includes(item)
        );
        const removedSynonyms = oldSynonyms.filter(
          (item) => !currentSynonyms.includes(item)
        );

        try {
          let i = 0;

          if (entityCreated) {
            for (; i < newSynonyms.length; i++) {
              await botManagerAPI.addSynonymToEntityTypePair(
                this.props.bot.id,
                {
                  type: entitySubValue,
                  entity: row.entity,
                  synonym: newSynonyms[i],
                }
              );
            }
            for (i = 0; i < removedSynonyms.length; i++) {
              await botManagerAPI.deleteSynonymFromEntityTypePair(
                this.props.bot.id,
                entitySubValue,
                row.entity,
                removedSynonyms[i]
              );
            }

            const {
              latestVersion: { id, version },
            } = this.props.botData;
            this.props.validateSolution(id, version, true);
          }

          const userRefobj = [...entityTableData];
          userRefobj.forEach((item) => {
            if (item.entity === row.entity) {
              item.type = entitySubValue;
              item.synonym = newSynonymValue;
              item.synonymserror = "";
              item.lastUpdated = new Date().toISOString();
            }
          });
          const newData = this.state.entityTable.map((item) => {
            if (item.type === entitySubValue) {
              item.lastUpdated = new Date().toISOString();
              item.time[row.entity] = new Date().toISOString();
              const index = item.entity.findIndex(
                (iEntity) => iEntity === row.entity
              );
              if (index >= 0) {
                if (index < item.synonym.length) {
                  item.synonym[index] = newSynonymValue;
                } else {
                  item.synonym.push(newSynonymValue);
                }
              }
            }
            return item;
          });
          this.setState({ entityTableData: userRefobj, entityTable: newData });
        } catch (err) {
          console.log(err);
        }
      } else {
        const userRefobj = [...entityTableData];
        userRefobj.forEach((item) => {
          if (item.entity === row.entity) {
            item.type = entitySubValue;
            item.synonym = "";
            item.synonymserror =
              " Synonyms may only contain commas, letters, numbers, spaces, dashes, and underscores. ";
          }
        });
        this.setState({ entityTableData: userRefobj });
      }
    }
  }

  async handleEntityDataDelete(row, e) {
    e.stopPropagation();

    try {
      let removed = true;

      if (row.entity) {
        const { entitySubValue } = this.state;
        const result = await botManagerAPI.deleteOneEntityType(
          this.props.bot.id,
          entitySubValue,
          row.entity
        );
        removed = result.status === 200;
      }

      if (removed) {
        const indexOfEntity = this.state.entityTableData.findIndex(
          (entity) => entity.entity === row.entity
        );

        const newData = this.state.entityTableData.filter(
          (item) => item.entity !== row.entity
        );
        this.setState({ entityTableData: newData });

        const {
          latestVersion: { id, version },
        } = this.props.botData;
        this.props.validateSolution(id, version, true);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async handleDelete(row, event, method) {
    event.stopPropagation();

    try {
      const deletedEntityTypeData = this.state.entityTable.find(
        (item) => item.type === row.type
      );

      if (deletedEntityTypeData && deletedEntityTypeData.entity) {
        let requests = deletedEntityTypeData.entity.map((item) => {
          return botManagerAPI.deleteOneEntityType(
            this.props.bot.id,
            row.type,
            item
          );
        });
        const results = await Promise.all(requests);

        const removedEntities = results.filter((item) => item.status === 200);

        let newData = [];
        let newEntityList = [];
        if (removedEntities.length === results.length) {
          // success
          newData = this.state.entityTable.filter(
            (item) => item.entity !== row.entity
          );
          newEntityList = this.state.entityList.filter(
            (item) => item !== row.type
          );
        } else {
          const unremovedEntities = results.reduce((res, item, index) => {
            if (item.status !== 200) {
              return [...res, deletedEntityTypeData.entity[index]];
            }
            return res;
          }, []);

          newData = this.state.entityTable.map((item) => {
            if (item.type === row.type) {
              item.entity = unremovedEntities;
            }
            return item;
          });
        }
        this.setState({
          entityTable: newData,
          entityList: newEntityList,
          entityTableData: [],
        });
        this.props.updateEntityData({
          data: newData,
          entityColumn: this.state.entityColumn,
        });

        this.props.setIfTrainingNeeded(true);
      }
    } catch (e) {
      console.log(e);
    }
  }

  handleModal(row, event, method) {
    event.stopPropagation();
    this.setState({
      showModal: true,
      modalData: {
        title: "Delete Entity Type",
        messageBody:
          "Are you sure you want to remove this Entity Type? Any Entities associated with it will also be removed.",
      },
    });
    this.setState({ deleteEntityData: { row: row, event: event, method } });
  }

  onCancel() {
    this.setState({ showModal: false });
  }

  onConfirm() {
    this.setState({ showModal: false });
    const { row, event, method } = this.state.deleteEntityData;
    this.handleDelete(row, event, method);
    this.setState({ entityDataView: false });
  }

  onEntitySelection(data) {
    let arr = [];
    data.entity.forEach((item, index) => {
      arr.push({
        id: uuidv4(),
        type: data.type,
        entity: item,
        synonym: data.synonym[index],
        lastUpdated: data.time[item],
      });
    });
    const entityName = data.type.replace(/_/g, " ");
    const entityList = this.state.entityList.filter(
      (item) => item !== data.type
    );
    this.setState({
      entityTableData: arr,
      entityDataView: true,
      entityData: data,
      entityType: entityName,
      entitySubValue: data.type,
      selectedEntityType: data.type,
      editEntity: true,
      entityList: entityList,
      entityCreated: true,
    });
  }

  handleBackOption() {
    const {
      entityTableData,
      entityTable,
      editEntity,
      entityList,
      entityType,
      selectedEntityType,
    } = this.state;
    let error = [];
    let entityError = [];
    entityTableData.forEach((item) => {
      if (item.synonymserror && item.synonymserror !== "") {
        error.push(item.synonymserror);
      }
    });
    entityTableData.forEach((item) => {
      if (item.error && item.error !== "") {
        entityError.push(item.error);
      }
    });
    const index =
      entityTableData.length > 0
        ? entityTableData.length - 1
        : entityTableData.length;
    if (
      this.state.entityError === "" &&
      error.length === 0 &&
      entityError.length === 0 &&
      entityTableData.length > 0 &&
      entityTableData[index].type !== "" &&
      entityTableData[index].entity !== ""
    ) {
      if (
        entityTableData.length === 1 &&
        entityTableData[0].entity === "" &&
        entityTableData[0].synonym === ""
      ) {
        this.setState({ entityDataView: false });
      } else if (entityTableData.length === 0) {
        const newData = this.state.entityTable.filter(
          (item) => item.type !== selectedEntityType
        );
        this.setState({ entityTable: newData });
        this.props.updateEntityData({
          data: newData,
          entityColumn: this.state.entityColumn,
        });
        console.error("Type cannot be without entity");
      } else if (!editEntity) {
        if (entityType !== "") {
          entityList.push(entityType.toLowerCase());
          if (entityTableData[0].type === undefined) {
            entityTableData.shift();
          }
          entityTable.push({
            type: entityTableData[0].type,
            synonym: [],
            entity: [],
            lastUpdated: new Date().toISOString(),
            time: {},
          });
          const entity = entityTableData.map((item) => {
            return item.entity;
          });
          const synonyms = entityTableData.map((item) => {
            return item.synonym;
          });
          const time = entityTableData.reduce(
            (sum, item) => ({
              ...sum,
              [item.entity]: item.lastUpdated,
            }),
            {}
          );
          let userRefobj = [...entityTable];
          userRefobj.forEach((item) => {
            if (item.type === entityTableData[0].type) {
              item["synonym"] = synonyms;
              item["entity"] = entity;
              item["time"] = time;
            }
          });
          this.props.updateEntityData({
            data: userRefobj,
            entityColumn: this.state.entityColumn,
          });
          this.setState({ entityTable: userRefobj });
        }
      } else {
        let entityToReplaceIndex = entityList.indexOf(selectedEntityType);
        if (entityToReplaceIndex !== -1) {
          entityList[entityToReplaceIndex] = entityType;
        }
        let editedentityTableData = [...entityTableData];
        if (
          editedentityTableData[0].entity === "" &&
          editedentityTableData[0].synonym === ""
        ) {
          editedentityTableData.shift();
        }
        const entity = editedentityTableData.map((item) => {
          return item.entity;
        });
        const synonyms = editedentityTableData.map((item) => {
          return item.synonym;
        });
        entityList.push(entityType.replace(/ /g, "_"));
        let userRefobj = [...entityTable];
        userRefobj.forEach((item) => {
          if (item.type === selectedEntityType) {
            item["type"] = entityType.replace(/ /g, "_");
            item["synonym"] = synonyms;
            item["entity"] = entity;
          }
        });
        this.props.updateEntityData({
          data: userRefobj,
          entityColumn: this.state.entityColumn,
        });
        this.setState({ entityTable: userRefobj });
      }
      this.setState({
        entityTableData: [],
        entityError: "",
        entityDataView: false,
        selectedEntityType: "",
      });
    } else {
      if (entityTableData.length > 0) {
        if (
          entityTableData.length === 1 &&
          entityTableData[0].entity === "" &&
          entityTableData[0].synonym === "" &&
          (entityTableData[0].type === undefined ||
            entityTableData[0].type === "")
        ) {
          this.setState({
            entityDataView: false,
            entityError: "",
            synonymserror: "",
          });
        } else {
          if (
            entityTableData.length > 1 &&
            entityTableData[0].entity === "" &&
            entityTableData[0].synonym === ""
          ) {
            entityTableData.shift();
          }
          if (entityTableData[0].type === "" && this.state.entityError === "")
            this.setState({ entityError: "Entity Type name cannot be empty" });
        }
      } else {
        if (this.state.entityError === "" && this.state.entityType === "")
          this.setState({ entityDataView: false });
      }
    }
  }

  handleOnChange(value) {
    this.setState({ entityType: value, entityError: "" });
  }

  async handleSaveEntityType() {
    const {
      entityType,
      entityList,
      entityTableData,
      entityCreated,
      selectedEntityType,
    } = this.state;
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9_ -]*$/;
    const value = regex.test(entityType);
    if (value === true) {
      let lowerCaseString = entityType.toLowerCase();
      lowerCaseString = lowerCaseString.slice(0, 29);
      const finalStirng = lowerCaseString.replace(/ /g, "_");
      if (finalStirng.startsWith("sys-")) {
        this.setState({
          entitySubValue: "",
          entityError: `User created entity types cannot start with "sys-".`,
        });
      } else if (entityList.includes(finalStirng)) {
        this.setState({
          entitySubValue: "",
          entityError: "Entity Type names must be unique.",
        });
      } else {
        // create or save entity type.
        try {
          if (entityCreated) {
            if (selectedEntityType !== finalStirng) {
              const requests = (entityTableData || [])
                .filter((entity) => !!entity.entity)
                .map((entity) => {
                  return botManagerAPI.updateOneEntityType(this.props.bot.id, {
                    old_type: selectedEntityType,
                    new_type: finalStirng,
                    old_entity: entity.entity,
                    new_entity: entity.entity,
                  });
                });
              await Promise.all(requests);

              const {
                latestVersion: { id, version },
              } = this.props.botData;
              this.props.validateSolution(id, version, true);
            }
          } else {
            const {
              latestVersion: { id, version },
            } = this.props.botData;
            const requests = (entityTableData || [])
              .filter((entity) => !!entity.entity)
              .map((entity) => {
                return botManagerAPI.createOneEntityType(this.props.bot.id, {
                  entity: entity.entity,
                  type: finalStirng,
                  synonyms: entity.synonym ? entity.synonym.split(",") : [],
                });
              });
            await Promise.all(requests);
            this.setState({ entityCreated: true });
            this.props.validateSolution(id, version, true);
          }
          const userRefobj = [...entityTableData];
          userRefobj.forEach((item) => {
            item["type"] = finalStirng;
            item["error"] = "";
          });
          this.setState({
            entityTableData: userRefobj,
            entitySubValue: finalStirng,
          });
        } catch (e) {
          console.log(e);
        }
      }
    } else {
      this.setState({
        entitySubValue: "",
        entityError: entityType
          ? "Entity name can have a-z, 0-9,'space','-', and '_' characters only"
          : "Entity Type name cannot be empty",
      });
    }
  }

  handleAddEntityData(e) {
    e.preventDefault();
    const { entityTableData, initialEntityTableData } = this.state;
    if (entityTableData.length === 0 || entityTableData[0].entity !== "") {
      const initialData = [...initialEntityTableData];
      let oldList = [...entityTableData];
      oldList.unshift(initialData[0]);
      const emptyData = [{ id: uuidv4(), entity: "", synonym: "" }];
      this.setState({
        entityTableData: oldList,
        initialEntityTableData: emptyData,
      });
    }
  }

  handleAddEntity() {
    if (this.state.entityTableData.length === 0) {
      this.state.entityTableData.push({
        id: uuidv4(),
        entity: "",
        synonym: "",
      });
    }
    this.setState({
      entityDataView: true,
      entityType: "",
      entitySubValue: "",
      editEntity: false,
      entityCreated: false,
    });
  }

  handleFuzzyMatchingChange() {
    this.props.updateBot(this.props.bot.id, {
      enableFuzzyEntityMatching: !this.state.enableFuzzyMatching,
    });
    this.setState({ enableFuzzyMatching: !this.state.enableFuzzyMatching });
  }

  openCSVFileDialog() {
    this.entityUpload.click();
  }

  getBotName() {
    const parts = this.props.bot.id.split(".");
    return parts.length >= 2 ? parts[1] : parts[0];
  }

  async selectEntityFile() {
    if (this.entityUpload.files && this.entityUpload.files[0]) {
      try {
        const isValid = await validateFileHeaders(
          this.entityUpload.files[0],
          NLU_ENTITY_HEADERS_H
        );
        if (!isValid) {
          this.props.setNotification({
            openNotification: true,
            notificationDuration: 6000,
            notificationTitle: "CSV file header does not match template",
            notificationType: "error",
          });
        } else {
          const isFileContentValid = await validateFileContent(
            this.entityUpload.files[0],
            NLU_ENTITY_HEADERS_H.slice(0, 2)
          );

          if (isFileContentValid) {
            // Amplitude event on Intent csv upload
            const fileObj = this.entityUpload.files[0];
            activityTracker.logEvent(
              activityTracker.eventTypeNames.UPLOAD_TEMPLATE,
              {
                fileSize: fileObj.size,
                fileType: fileObj.type,
                fileName: fileObj.name,
                solutionName: this.getBotName(),
              }
            );

            // upload draft
            await this.uploadEntityFile();
          } else {
            this.props.setNotification({
              openNotification: true,
              notificationDuration: 6000,
              notificationTitle: "CSV file does not match template",
              notificationType: "error",
            });
          }
        }
      } catch (err) {
        console.log(err);

        this.props.setNotification({
          openNotification: true,
          notificationDuration: 6000,
          notificationTitle: "Something went wrong. Please try again!",
          notificationType: "error",
        });
      }
    }
  }

  async uploadEntityFile() {
    this.props.setNotification({
      openNotification: true,
      notificationDuration: null,
      notificationTitle: "Uploading...",
      notificationType: "loading",
    });
    const formData = new FormData();
    formData.append("trainingEntityFile", this.entityUpload.files[0]);
    try {
      const response = await botManagerAPI.populateTrainingEntityDB(
        this.props.bot.id,
        formData
      );
      if (response.status === 200) {
        await this.getAllEntities();
        this.props.setNotification({
          openNotification: true,
          notificationDuration: 6000,
          notificationTitle:
            "You have successfully uploaded your replacement entity document.",
          notificationType: "success",
        });
        const {
          latestVersion: { id, version },
        } = this.props.botData;
        this.props.validateSolution(id, version, true);
      } else {
        this.props.setNotification({
          openNotification: true,
          notificationDuration: 6000,
          notificationTitle:
            "There was a problem uploading your replacement entity document.",
          notificationType: "error",
        });
      }
    } catch (err) {
      console.log(err);
      this.props.setNotification({
        openNotification: true,
        notificationDuration: 6000,
        notificationTitle: err,
        notificationType: "error",
      });
    }
  }

  onMenuClick(menu) {
    switch (menu.value) {
      case "upload":
        this.openCSVFileDialog();
        break;
      default:
    }
  }

  render() {
    if (!this.props.show) {
      return null;
    }
    const {
      showModal,
      entityTable,
      entityType,
      entitySubValue,
      entityTableData,
      entityDataView,
      entityData,
      modalData,
      entityError,
      isLoadingEntities,
    } = this.state;
    const { notificationType, flags } = this.props;
    if (entityTable.length > 0 && entityTable[0].type === "") {
      entityTable.shift();
    }

    const AllEntitiesColumns = [
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        width: "250px",
        sorter: (a, b) => (a.type ? a.type.localeCompare(b.type) : -1),
        render: (data) => this.typeFormatter(data.type, data),
      },
      {
        title: "Entities",
        dataIndex: "entity",
        key: "entity",
        render: (data) => this.typeFormatterEntity(data.entity, data),
      },
      {
        title: "Last Updated",
        dataIndex: "lastUpdated",
        key: "lastUpdated",
        width: "180px",
        render: (data) => this.typeLastUpdatedFormatter(data.lastUpdated),
      },
      {
        title: "",
        dataIndex: "delete",
        key: "delete",
        align: "right",
        width: "60px",
        render: (data) => this.deleteEntityList("", data),
      },
    ];

    const SingleEntityColumns = [
      {
        title: "Entity",
        dataIndex: "entity",
        key: "entity",
        width: "250px",
        sorter: (a, b) => (a.entity ? a.entity.localeCompare(b.entity) : -1),
        render: (data) => this.typeEntityFormatter(data.entity, data),
      },
      {
        title: "Synonyms (Optional)",
        dataIndex: "synonym",
        key: "synonym",
        render: (data) => this.typeSynonymsFormatter(data.synonym, data),
      },
      {
        title: "Last Updated",
        dataIndex: "lastUpdated",
        key: "lastUpdated",
        width: "180px",
        render: (data) =>
          data.lastUpdated ? getSoftTimestamp(data.lastUpdated) : null,
      },
      {
        title: "",
        dataIndex: "delete",
        key: "delete",
        width: "60px",
        render: (data) => this.deleteEntityTableData({}, data),
      },
    ];

    const menuData = [
      {
        icon: IconNames.Upload2,
        menu: "Upload Replacement CSV",
        value: "upload",
      },
    ];

    return (
      <Fragment>
        <IntentModal
          show={showModal}
          title={modalData.title}
          messageBody={modalData.messageBody}
          onCancel={this.onCancel}
          onConfirm={this.onConfirm}
        />
        {entityDataView === false ? (
          <div>
            <div className="Intent_content">
              <div className="description-line">
                <div className="section-title">
                  <h3>NLU Entities</h3>
                </div>
                <p>
                  An entity type is a term or object that you want your microapp
                  to understand. For example, Country might be an entity type,
                  with a list such as Afghanistan, Albania, Algeria, Andorra,
                  Argentina, Armenia… as its entities. Entities can also be
                  supplied with synonyms so that a term like USA or the US
                  matches with the entity “United States of America.
                </p>
              </div>
              <div className="description-line">
                <p className="body-header">Fuzzy Matching</p>
                <p>
                  Accept user inputs that are almost an exact match with an
                  entity or its synonyms.
                </p>
                <div className="fuzzy-matching-switch-container">
                  <SwitchC
                    checked={this.state.enableFuzzyMatching || false}
                    onChange={this.handleFuzzyMatchingChange}
                    onColor="#cbdffb"
                    onHandleColor="#0a3ab4"
                    handleDiameter={20}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={14}
                    width={34}
                    id="new-switch"
                    className="fuzzy-matching-switch"
                  />
                  <span>
                    {`Fuzzy Matching ${
                      this.state.enableFuzzyMatching ? "Enabled" : "Disabled"
                    }`}
                  </span>
                </div>
              </div>
            </div>
            <div className="Intent_length">
              <div className="Add_intent_button">
                <h4>{entityTable.length} Entity Types</h4>
                {entityTable.length > 0 && (
                  <div className="Intent_button_with_table">
                    {notificationType === "loading" ? (
                      <Icon
                        fill="#0a3ab4"
                        name={IconNames.Loading}
                        rotateIcon
                      />
                    ) : (
                      <DotMenu menus={menuData} onClick={this.onMenuClick} />
                    )}
                    <Button onClick={this.handleAddEntity}>
                      {" "}
                      Add Entity Type{" "}
                    </Button>
                  </div>
                )}
                {!isLoadingEntities && entityTable.length === 0 ? (
                  <div>Your microapp currently does not have any Entities.</div>
                ) : (
                  <DataTable
                    className="Intent_table_data"
                    columns={AllEntitiesColumns}
                    dataSource={entityTable}
                    isLoading={isLoadingEntities}
                    rowHeight={76}
                    bordered={false}
                    onRowClick={this.onEntitySelection}
                  />
                )}
              </div>
              {!isLoadingEntities && entityTable.length === 0 && (
                <div className="Intent_button_without_table">
                  <Button onClick={this.handleAddEntity}>
                    {" "}
                    Add Entity Type{" "}
                  </Button>
                  <Button hollow onClick={this.openCSVFileDialog}>
                    Upload File
                  </Button>
                </div>
              )}
              <input
                id="myInput"
                type="file"
                ref={(ref) => (this.entityUpload = ref)}
                style={{ display: "none" }}
                accept=".csv"
                onChange={this.selectEntityFile}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="back-div">
              <div
                className="text_anchor"
                onClick={debounce(this.handleBackOption, 500)}
              >
                <Icon
                  name={IconNames.ChevronLeft}
                  width="24px"
                  viewBox="0 -6 24 24"
                  fill="#0a3ab4"
                  margin-top="-5px"
                />
                Back to Entities
              </div>
            </div>
            <div className="uttrence_title">
              <div className="intent_title" onBlur={this.handleSaveEntityType}>
                <TextField
                  label="Entity Type Name"
                  variant="outlined"
                  value={entityType}
                  error={!!entityError}
                  helperText={entityError || "ENTITY TYPE"}
                  onChange={(e) => this.handleOnChange(e.target.value)}
                />
                <span>{entitySubValue}</span>
              </div>

              <div className="back-div">
                <div
                  className="text_anchor"
                  onClick={(e) => this.handleModal(entityData, e, "Single")}
                >
                  <Icon
                    name={IconNames.Close}
                    width="24px"
                    viewBox="0 5 24 24"
                    fill="#0a3ab4"
                  />
                  Delete Entity Type
                </div>
              </div>
            </div>
            <div className="uttrence_title">
              <h4>
                {entityTableData.length && entityTableData[0].entity !== ""
                  ? entityTableData.length
                  : entityTableData.length === 0
                  ? entityTableData.length
                  : entityTableData.length - 1}{" "}
                Entities
              </h4>
              <div className="Intent_button_with_table">
                <Button onClick={debounce(this.handleAddEntityData, 500)}>
                  Add Entity
                </Button>
              </div>
            </div>
            <DataTable
              className="Utterence_table entity_table_data"
              columns={SingleEntityColumns}
              dataSource={entityTableData}
              rowHeight={76}
              bordered={false}
            />
          </div>
        )}
      </Fragment>
    );
  }
}

Entity.propTypes = {
  show: PropTypes.bool,
  hasDraft: PropTypes.bool,
  bot: PropTypes.object,
  flags: PropTypes.object,
  latestVersion: PropTypes.object,
  notificationType: PropTypes.string,
  setIfTrainingNeeded: PropTypes.func,
  updateEntityData: PropTypes.func,
  updateBot: PropTypes.func,
  setNotification: PropTypes.func,
  botData: PropTypes.object,
  updateSolutionInfo: PropTypes.func,
  validateSolution: PropTypes.func,
};

const mapStateToProps = (state) => ({
  notificationType: state.manager.notificationType,
});

const mapDispatchToProps = (dispatch) => ({
  updateEntityData(data) {
    const rows = [];
    data.data.forEach((item) => {
      item.entity.forEach((items, index) => {
        const row = [item.type, items, item.synonym[index]];
        rows.push(row);
      });
    });
    return dispatch(
      updateEntityData({
        data: Papa.unparse({
          fields: data.entityColumn ? data.entityColumn : NLU_ENTITY_HEADERS_H,
          data: rows,
        }),
        isEdited: true,
      })
    );
  },
  updateBot(botId, data) {
    return dispatch(updateBot(botId, data));
  },
  setIfTrainingNeeded(isNeeded = false) {
    return dispatch(setIfTrainingNeeded(isNeeded));
  },
  setNotification(data) {
    return dispatch(setNotification(data));
  },
  validateSolution: (botId, version, autoTrain) =>
    dispatch(validateSolution(botId, version, autoTrain)),
});

export default withLDConsumer()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Entity))
);
