import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { connect } from "react-redux";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import isEmpty from "lodash/isEmpty";
import installScript from "../installscripts";
import config from "../../../../config";

import {
  Button,
  IconNames,
  DataTable,
  DotMenu,
  IconButton,
  Icon,
  Tooltip,
  DataModal,
} from "ui-elements";

import ChannelPlaceholder from "../Placeholders/ChannelPlaceholder";

import botManagerAPI from "../../../../bot-manager-api";
import {
  getInterfaces,
  removeInterfaces,
  selectedInterface,
  setNotification,
} from "../../../Store/actions";

import IconPypestream from "../../../Assets/ico-pypestream.svg";
import { backgroundGrey600 } from "../../../../utils/styleVariables";
import IconError from "../../../Assets/icons-status-error.svg";
import activityTracker from "../../../../activityTracker";

import { CHANNEL_TYPE } from "../SelectChannel/ChannelUtils";

const ChannelType = CHANNEL_TYPE;

class Channels extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      livePypes: [],
      allPypes: [],
      streams: [],
      channels: [],
      liveChannels: [],
      sandboxChannels: [],
      sandboxPypes: [],
      shouldShowDeleteModal: false,
      interfaceToDelete: null,
      filteredInterfaces: [],
      loading: false,
    };

    this.revertSortFunc = this.revertSortFunc.bind(this);
    this.channelNameFormat = this.channelNameFormat.bind(this);
    this.channelEnvironmentFormat = this.channelEnvironmentFormat.bind(this);
    this.channelLastEditedFormat = this.channelLastEditedFormat.bind(this);
    this.findChannels = this.findChannels.bind(this);
    this.clearAndFetchInterfaces = this.clearAndFetchInterfaces.bind(this);
    this.channelMenuFormat = this.channelMenuFormat.bind(this);
    this.addChannel = this.addChannel.bind(this);
    this.onDeleteChannel = this.onDeleteChannel.bind(this);
    this.onEditChannel = this.onEditChannel.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.getChannelEnvironment = this.getChannelEnvironment.bind(this);
    this.copyEmbedCode = this.copyEmbedCode.bind(this);
    this.handleDeleteInterface = this.handleDeleteInterface.bind(this);
    this.onHideDeleteModal = this.onHideDeleteModal.bind(this);
    this.onPreviewClick = this.onPreviewClick.bind(this);
    this.copyAppID = this.copyAppID.bind(this);
  }

  componentDidMount() {
    this.clearAndFetchInterfaces();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps && this.props.refreshInterfaces === true) {
      console.log("Refreshing........");
      this.clearAndFetchInterfaces();
      this.props.setRefreshInterface(false);
    }

    // check the changes of interfaces, streams
    const { interfaces } = this.props;
    const { streams, channels } = this.state;
    if (
      JSON.stringify(prevProps.interfaces) !== JSON.stringify(interfaces) ||
      JSON.stringify(prevState.streams) !== JSON.stringify(streams)
    ) {
      const { customerId, botId } = this.props.match.params;
      const mergedId = `${customerId}.${botId}`;
      const filteredStreams = streams.filter(
        (stream) => stream.start_chat_bot_id === mergedId
      );

      let filteredInterfaces = filteredStreams.reduce((acc, el) => {
        interfaces.forEach((item) => {
          if (item.widget.stream_id === el.id) {
            acc.push(item);
          }
        });
        return acc;
      }, []);
      // Deduplicate
      filteredInterfaces = [...new Set(filteredInterfaces)]
        .filter((item, index) => filteredInterfaces.indexOf(item) === index)
        .reduce(
          (unique, item) =>
            unique.includes(item) ? unique : [...unique, item],
          []
        )
        .filter((widget) => !widget.widget.created_by.includes("preview"))
        .filter(
          (widget, index, self) =>
            index === self.findIndex((t) => t.widget.id === widget.widget.id)
        )
        .sort((a, b) => (a.widget.widget_name > b.widget.widget_name ? 1 : -1));
      channels &&
        filteredInterfaces.push(
          ...channels.filter((chnl) => {
            const channelFlags = {
              [ChannelType.PYPE]: true,
              [ChannelType.APPLE_BUSINESS_CHAT]: true,
              [ChannelType.FACEBOOK]: true,
              [ChannelType.GOOGLE_BUSINESS_MESSAGE]: true,
              [ChannelType.INSTAGRAM]: true,
              [ChannelType.SMS]: true,
              [ChannelType.WHATSAPP]: true,
            };

            return channelFlags[chnl?.widget?.widget_type ?? ""];
          })
        );
      this.setState({
        filteredInterfaces: filteredInterfaces.map((interf) => ({
          ...interf,
          id: uuidv4(),
        })),
      });
    }
  }

  findChannels() {
    const { botId, customerId } = this.props.match.params;
    this.setState({ channels: [] });
    botManagerAPI
      .getAllChannelsInSolution(
        this.props.customer.gesId,
        `${customerId}.${botId}`,
        "live"
      )
      .then((res) => {
        const channelList = res.data.map((item) => {
          if (
            item.id == null ||
            Object.values(item.config).some((value) => !value) ||
            item.pype_id === undefined ||
            item.pype_id === null ||
            isEmpty(item.config)
          ) {
            return {
              widget: {
                isAChannel: false,
                channel_id: item.id,
                widget_name: item.name,
                widget_icon: "Warning Icon",
                widget_type: item.type,
                widget_config: item.config,
                last_updated: item.last_updated,
                last_update_by: item.last_update_by,
                pype_id: item.pype_id,
                env: "live",
                start_bot: item.start_bot,
              },
            };
          }
          return {
            widget: {
              channel_id: item.id,
              isAChannel: true,
              widget_config: item.config,
              widget_name: item.name,
              widget_icon: item.logo,
              widget_type: item.type,
              last_updated: item.last_updated,
              last_update_by: item.last_update_by,
              pype_id: item.pype_id,
              env: "live",
              start_bot: item.start_bot,
            },
          };
        });
        this.setState({ liveChannels: [...channelList] });
        this.setState((prevState) => {
          return {
            channels: [...prevState.channels, ...this.state.liveChannels],
          };
        });
      })
      .catch((err) => {
        console.error(err);
      });
    botManagerAPI
      .getAllChannelsInSolution(
        this.props.customer.gesId,
        `${customerId}.${botId}`,
        "sandbox"
      )
      .then((res) => {
        const channelList = res.data.map((item) => {
          if (
            item.id == null ||
            Object.values(item.config).some((value) => !value) ||
            item.pype_id === undefined ||
            item.pype_id === null ||
            item.config.length < 1
          ) {
            return {
              widget: {
                isAChannel: false,
                channel_id: item.id,
                widget_name: item.name,
                widget_icon: "Warning Icon",
                widget_type: item.type,
                widget_config: item.config,
                last_updated: item.last_updated,
                last_update_by: item.last_update_by,
                pype_id: item.pype_id,
                env: "sandbox",
                start_bot: item.start_bot,
              },
            };
          }
          return {
            widget: {
              isAChannel: true,
              channel_id: item.id,
              widget_config: item.config,
              widget_name: item.name,
              widget_icon: item.logo,
              widget_type: item.type,
              last_updated: item.last_updated,
              last_update_by: item.last_update_by,
              pype_id: item.pype_id,
              env: "sandbox",
              start_bot: item.start_bot,
            },
          };
        });
        this.setState({ sandboxChannels: [...channelList] });
        this.setState((prevState) => {
          return {
            channels: [...prevState.channels, ...this.state.sandboxChannels],
          };
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async clearAndFetchInterfaces() {
    this.setState({ loading: true });
    let requests = [];

    this.findChannels();

    try {
      await this.props.removeInterfaces();

      const resLivePypes = await botManagerAPI.getPypes(
        this.props.customer.gesId,
        "live"
      );
      this.setState({
        livePypes: resLivePypes,
        allPypes: [...this.state.allPypes, ...resLivePypes],
      });

      requests = resLivePypes.map((pype) =>
        this.props.getInterfaces(pype.id, "live", true)
      );
      await Promise.all(requests);
      requests = resLivePypes.map((pype) =>
        botManagerAPI.getPypeStream(pype.customer_id, "live", pype.id)
      );
      const resNewStreams = await Promise.all(requests);
      resNewStreams.forEach((newStream) => {
        this.setState({
          streams: [
            ...this.state.streams.filter(
              (stream) => stream.id !== newStream.id
            ),
            ...newStream,
          ],
        });
      });

      const resSandboxPypes = await botManagerAPI.getPypes(
        this.props.customer.gesId,
        "sandbox"
      );
      this.setState({
        sandboxPypes: resSandboxPypes,
        allPypes: [...this.state.allPypes, ...resSandboxPypes],
      });

      requests = resSandboxPypes.map((pype) =>
        this.props.getInterfaces(pype.id, "sandbox", true)
      );
      await Promise.all(requests);

      requests = resSandboxPypes.map((pype) =>
        botManagerAPI.getPypeStream(pype.customer_id, "sandbox", pype.id)
      );
      const resSandboxNewStreams = await Promise.all(requests);
      resSandboxNewStreams.forEach((newStream) => {
        this.setState({
          streams: [
            ...this.state.streams.filter(
              (stream) => stream.id !== newStream.id
            ),
            ...newStream,
          ],
        });
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  revertSortFunc(a, b) {
    return b.widget.widget_name.localeCompare(a.widget.widget_name, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  }

  getTruncatedName(str) {
    if (!str || str.length < 30) {
      return str;
    }

    return `${str.slice(0, 30)} ...`;
  }

  channelNameFormat(channel) {
    const { widget_type, pype_id } = channel.widget;
    return (
      <div className="channel-list-item">
        {channel.widget.style && (
          <div className="interfaces-tab-list-preview">
            <div
              className="interfaces-tab-list-preview--rectangle"
              style={
                channel.widget.style && {
                  backgroundColor: JSON.parse(
                    channel.widget.style.replace(/\\/g, "")
                  ).widgetStyleColor,
                }
              }
            />
            <div
              className="interfaces-tab-list-preview-bubbles"
              style={
                channel.widget.style && {
                  border: `1px solid ${
                    JSON.parse(channel.widget.style.replace(/\\/g, ""))
                      .widgetStyleColor
                  }`,
                }
              }
            >
              <svg
                className="interfaces-tab-list-preview-bubbles--solution-bubble"
                xmlns="http://www.w3.org/2000/svg"
                width="83"
                height="31"
                viewBox="0 0 83 31"
              >
                <g fill="none" fillRule="evenodd">
                  <g>
                    <path
                      fill="#FFF"
                      d="M0 0H1440V1200H0z"
                      transform="translate(-424 -387)"
                    />
                    <g>
                      <path
                        fill="#FFF"
                        stroke="#6B6587"
                        d="M96 23.996c.966 0 1.841.392 2.475 1.026.633.633 1.025 1.508 1.025 2.474h0v71.5H.5v-71.5c0-.966.392-1.841 1.025-2.474.634-.634 1.509-1.026 2.475-1.026h0z"
                        transform="translate(-424 -387) translate(420 356) rotate(-180 50 61.496)"
                      />
                      <g>
                        <path
                          fill={
                            channel.widget.style &&
                            JSON.parse(channel.widget.style.replace(/\\/g, ""))
                              .agentBubbleColor
                          }
                          d="M69 0c5.523 0 10 4.477 10 10v6l-.001.037L79 24.14c0 4.21 3.568 5.86 3.499 5.86-5.086 0-8.328-2.002-10.392-4.492-.978.32-2.022.492-3.107.492H10C4.477 26 0 21.523 0 16v-6C0 4.477 4.477 0 10 0h59z"
                          transform="translate(-424 -387) translate(420 356) translate(4 31.5) matrix(-1 0 0 1 82.5 0)"
                        />
                        <text
                          fill={
                            channel.widget.style &&
                            JSON.parse(channel.widget.style.replace(/\\/g, ""))
                              .agentBubbleTextColor
                          }
                          fillRule="nonzero"
                          fontFamily="OpenSans-Regular, Open Sans"
                          fontSize="14"
                          transform="translate(-424 -387) translate(420 356) translate(4 31.5)"
                        >
                          <tspan x="12" y="18">
                            Microapp
                          </tspan>
                        </text>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <svg
                className="interfaces-tab-list-preview-bubbles--user-bubble"
                xmlns="http://www.w3.org/2000/svg"
                width="56"
                height="31"
                viewBox="0 0 56 31"
              >
                <g fill="none" fillRule="evenodd">
                  <g>
                    <path
                      fill="#FFF"
                      d="M0 0H1440V1200H0z"
                      transform="translate(-460 -417)"
                    />
                    <g>
                      <path
                        fill="#FFF"
                        stroke="#6B6587"
                        d="M96 23.996c.966 0 1.841.392 2.475 1.026.633.633 1.025 1.508 1.025 2.474h0v71.5H.5v-71.5c0-.966.392-1.841 1.025-2.474.634-.634 1.509-1.026 2.475-1.026h0z"
                        transform="translate(-460 -417) translate(420 356) rotate(-180 50 61.496)"
                      />
                      <g fill="#D1D1D1">
                        <path
                          d="M69 0c5.523 0 10 4.477 10 10v6l-.001.037L79 24.14c0 4.21 3.568 5.86 3.499 5.86-5.086 0-8.328-2.002-10.392-4.492-.978.32-2.022.492-3.107.492H10C4.477 26 0 21.523 0 16v-6C0 4.477 4.477 0 10 0h59z"
                          transform="translate(-460 -417) translate(420 356) translate(4 31.5) matrix(-1 0 0 1 82.5 0)"
                        />
                      </g>
                      <g>
                        <path
                          fill={
                            channel.widget.style &&
                            JSON.parse(channel.widget.style.replace(/\\/g, ""))
                              .userBubbleColor
                          }
                          d="M42 0c5.523 0 10 4.477 10 10v6l-.001.037L52 24.14c0 4.21 3.568 5.86 3.499 5.86-5.086 0-8.328-2.002-10.392-4.492-.978.32-2.022.492-3.107.492H10C4.477 26 0 21.523 0 16v-6C0 4.477 4.477 0 10 0h32z"
                          transform="translate(-460 -417) translate(420 356) translate(40 61.5)"
                        />
                        <text
                          fill={
                            channel.widget.style &&
                            JSON.parse(channel.widget.style.replace(/\\/g, ""))
                              .userBubbleTextColor
                          }
                          fillRule="nonzero"
                          fontFamily="OpenSans-Regular, Open Sans"
                          fontSize="14"
                          transform="translate(-460 -417) translate(420 356) translate(40 61.5)"
                        >
                          <tspan x="8.5" y="18">
                            User
                          </tspan>
                        </text>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        )}
        <div className="channel-list-item-interface">
          <div className="interface-name">
            {this.getTruncatedName(channel.widget.widget_name)}
          </div>
          {/*  checks widget_type and sets logo accordingly  */}
          <div className={widget_type && "w-60"}>
            {widget_type != null && channel.widget.isAChannel == true ? (
              <Icon
                className="relative top-1.5 mr-1"
                fill="#29302e"
                name={
                  widget_type === "facebook"
                    ? IconNames.Facebook
                    : widget_type === "whatsapp"
                    ? IconNames.WhatsApp
                    : widget_type === "apple"
                    ? IconNames.Apple
                    : widget_type === "google"
                    ? IconNames.Google
                    : widget_type === "sms"
                    ? IconNames.Phone
                    : widget_type === "instagram"
                    ? IconNames.Instagram
                    : null
                }
              />
            ) : channel.widget.isAChannel === false || widget_type === null ? (
              <Tooltip
                className="mr-1"
                label={
                  widget_type == undefined || null
                    ? "This channel has errors and cannot be used (Channel type missing)"
                    : pype_id == undefined || null
                    ? "Channel is missing a Pype ID, contact the developer for help"
                    : "This channel has config errors that must be resolved"
                }
              >
                <img src={IconError} />
              </Tooltip>
            ) : (
              <img className="mr-1" src={IconPypestream} alt="ico-pypestream" />
            )}
            {channel.widget.isAChannel ||
            widget_type !== undefined ||
            widget_type !== null ||
            widget_type !== "" ? (
              <span className="font-normal">
                {widget_type === "facebook"
                  ? "Messenger"
                  : widget_type === "apple"
                  ? "Apple Messages for Business"
                  : widget_type === "instagram"
                  ? "Instagram Messenger"
                  : widget_type === "whatsapp"
                  ? "WhatsApp (Twilio)"
                  : widget_type === "google"
                  ? "Google Business Messages"
                  : widget_type === "sms"
                  ? "SMS (Twilio)"
                  : channel.widget.style
                  ? "Pype"
                  : "Unknown"}
              </span>
            ) : channel.widget.isAChannel !== undefined ? (
              <span className="font-normal">Undefined</span>
            ) : (
              <span className="font-normal">Pype</span>
            )}
          </div>
        </div>
      </div>
    );
  }

  getChannelEnvironment(channel) {
    const { livePypes, sandboxPypes } = this.state;

    let environment = "";
    let pype = livePypes.find((p) => p.id === channel.pype_id);

    if (pype) {
      environment = "Live";
    } else {
      pype = sandboxPypes.find((p) => p.id === channel.pype_id);

      if (pype) {
        environment = "Sandbox";
      }
    }

    return environment;
  }

  channelEnvironmentFormat(channel) {
    let environment = this.getChannelEnvironment(channel.widget);

    const { livePypes, sandboxPypes } = this.state;

    let pype = livePypes.find((p) => p.id === channel.widget.pype_id);

    if (!pype) {
      pype = sandboxPypes.find((p) => p.id === channel.widget.pype_id);
    }

    return (
      <div className="channel-list-environment">
        <p className="environment">{environment}</p>
        <p className="pype">{pype ? pype.name : ""}</p>
      </div>
    );
  }

  channelLastEditedFormat(channel) {
    const currentDate = new Date();
    const lastUpdatedDate = new Date(channel.widget.last_updated);
    let res = "";

    const daysDifference = Math.round(
      (currentDate - lastUpdatedDate) / 1000 / 60 / 60 / 24
    );

    if (daysDifference === 0) {
      res = "Today";
    } else if (daysDifference === 1) {
      res = "Yesterday";
    } else {
      res = moment(channel.widget.last_updated).format("MMMM D, YYYY");
    }

    res += ` at ${moment(channel.widget.last_updated).format("h:mm a")}`;

    const { allPypes, channels } = this.state;
    const { customers } = this.props;
    let customer = {};
    let pype = allPypes.find((p) => p.id === channel.widget.pype_id);
    if (pype) {
      // eslint-disable-next-line no-unused-vars
      customer =
        Object.values(customers).find(
          (cus) => cus.gesId === pype.customer_id
        ) || {};
    }

    const updateByMap = channels.map(
      (channel) => channel.widget.last_update_by
    );

    const updateBy = updateByMap.find(
      (id) => id === channel.widget.last_update_by
    );
    if (
      updateBy === "undefined" ||
      updateBy === "Unknown User" ||
      updateBy === null ||
      updateBy === undefined
    ) {
      // res += ` by ${customer.customerName || ""}`; to be implemented later
      res += ``;
    } else {
      res += ` by ${updateBy || ""}`;
    }

    return (
      <div className="channel-list-lastedited">
        <p>{res}</p>
      </div>
    );
  }

  channelMenuFormat(channel) {
    const { isAChannel } = channel.widget;
    let menus = [
      {
        icon: "",
        menu: "Delete",
      },
    ];

    if (isAChannel === undefined) {
      menus = [
        {
          icon: "",
          menu: "Copy Embed Code",
        },
        {
          icon: "",
          menu: "Copy App ID",
        },
        {
          icon: "",
          menu: "Delete",
        },
      ];
    }

    let formattedLink = "";
    const env = this.getChannelEnvironment(channel.widget).toLowerCase();
    const interfaceConfig = config.webserviceEnvConfig[env];
    const interfaceLink = interfaceConfig.wcwLauncherUrl;
    const {
      widget: { id },
    } = channel;
    formattedLink = `${interfaceLink.replace(
      "webchat",
      "web"
    )}/preview.html?id=${id}`;

    return (
      <div className="channel-list-menus">
        <Tooltip label="More" className={clsx({ "w-full": isAChannel })}>
          <DotMenu
            closeOnMouseLeave={true}
            menus={menus}
            onClick={(menu) => this.handleMenu(menu, channel)}
          />
        </Tooltip>
        {isAChannel === undefined ? (
          <IconButton
            iconName={IconNames.View}
            onClick={(e) => this.onPreviewClick(e, formattedLink)}
          />
        ) : null}
      </div>
    );
  }

  addChannel() {
    this.props.history.push(
      `/bot/${this.props.bot.customerName}/${this.props.bot.botName}/channel`
    );
  }

  onDeleteChannel(channel) {
    this.setState({
      shouldShowDeleteModal: true,
      interfaceToDelete: channel,
    });
  }

  onEditChannel(channel) {
    this.props.selectedInterface(channel);

    const { widget } = channel || {};

    if (widget?.widget_type) {
      this.props.history.push(
        `/bot/${this.props.bot.customerName}/${this.props.bot.botName}/channel/${widget.channel_id}`
      );
    } else {
      this.props.history.push(
        `/bot/${this.props.bot.customerName}/${this.props.bot.botName}/interface/${channel.widget.id}`
      );
    }
  }

  handleMenu(menu, channel) {
    switch (menu.menu) {
      case "Copy Embed Code":
        this.copyEmbedCode(channel);
        break;
      case "Copy App ID":
        this.copyAppID(channel);
        break;
      case "Delete":
        this.onDeleteChannel(channel);
        break;
      default:
    }
  }

  copyEmbedCode(channel) {
    const environment = this.getChannelEnvironment(channel.widget);
    const pypesLaunchUrl = window.config.PS_ENV_CONFIGS[
      environment.toLowerCase()
    ].wcwLauncherUrl.replace("webchat", "web");

    const code = installScript
      .replace("<APP_ID>", channel.widget.id)
      .replace(
        "<wcwEnv>",
        window.config.PS_ENV_CONFIGS[environment.toLowerCase()].wcwEnv
      )
      .replace(
        "<domain>",
        window.config.PS_ENV_CONFIGS[environment.toLowerCase()].wcwDomain
      )
      .replace("<launcherUrl>", pypesLaunchUrl);

    navigator.clipboard
      .writeText(code)
      .then(() => {
        this.props.setNotification({
          openNotification: true,
          notificationDuration: 3000,
          notificationTitle: "Embed code has been copied to your clipboard.",
          notificationType: "success",
        });
      })
      .catch((e) => console.error(e));
  }

  copyAppID(channel) {
    navigator.clipboard
      .writeText(channel.widget.id)
      .then(() => {
        this.props.setNotification({
          openNotification: true,
          notificationDuration: 3000,
          notificationTitle: "App ID has been copied to your clipboard.",
          notificationType: "success",
        });
      })
      .catch((e) => console.error(e));
  }

  async handleDeleteInterface(conversationalInterface) {
    const { widget } = conversationalInterface;

    const { sandboxPypes } = this.state;
    let env = "live";
    sandboxPypes.forEach((pype) => {
      if (pype.id === conversationalInterface.widget.pype_id) {
        env = "sandbox";
      }
    });

    if (widget.widget_type) {
      if (widget.channel_id) {
        await botManagerAPI.deleteChannel(
          this.props.customer.gesId,
          env,
          widget.channel_id
        );
        this.props.setNotification({
          openNotification: true,
          notificationDuration: 6000,
          notificationTitle: "The channel has been deleted.",
          notificationType: "success",
        });
        this.onHideDeleteModal();
        this.clearAndFetchInterfaces();
      }
    } else {
      const body = {
        user_id: this.props.state.botUserSession.user.user_id,
        correlation_id: null,
        reply_to: conversationalInterface.widget.pype_id,
        version: 1,
        type: "request",
        auth_token: sessionStorage.getItem("global_accessToken"),
        request_type: "x_widget",
        request_action: "delete",
        data: {
          pype_id: conversationalInterface.widget.pype_id,
          id: conversationalInterface.widget.id,
          stream_id: conversationalInterface.widget.stream_id,
        },
      };
      botManagerAPI
        .deleteInterface(body, env, true)
        .then(() => {
          this.props.setNotification({
            openNotification: true,
            notificationDuration: 6000,
            notificationTitle: "The channel has been deleted.",
            notificationType: "success",
          });
          const tempFilteredInterfaces = this.state.filteredInterfaces;
          const deletedInterface = tempFilteredInterfaces.find(
            (item) => item.widget.id === conversationalInterface.widget.id
          );
          if (deletedInterface) {
            deletedInterface.deleted = true;
            this.setState({ filteredInterfaces: [...tempFilteredInterfaces] });
            setTimeout(() => {
              this.clearAndFetchInterfaces();
              this.setState({ filteredInterfaces: [] });
            }, 5000);
          } else {
            this.clearAndFetchInterfaces();
            this.setState({ filteredInterfaces: [] });
          }
          this.setState({ shouldShowDeleteModal: false });
        })
        .catch((e) => console.error(e));
    }
  }

  onHideDeleteModal() {
    this.setState({
      shouldShowDeleteModal: false,
      interfaceToDelete: null,
    });
  }

  onPreviewClick(e, link) {
    e.stopPropagation();
    activityTracker.logEvent(
      activityTracker.eventTypeNames.OPEN_PYPE_IN_NEW_TAB
    );

    window.open(link, "_blank");
  }

  render() {
    const { show } = this.props;
    const {
      filteredInterfaces,
      sandboxPypes,
      loading,
      shouldShowDeleteModal,
      interfaceToDelete,
    } = this.state;
    let sandboxPypesIds = [];
    const versionOne = this.props.botData.currentVersions.find(
      (version) => version.version === "v1"
    );
    let versionOneIsDraft = false;
    if (typeof versionOne === "undefined") versionOneIsDraft = true;
    if (versionOne) {
      versionOneIsDraft = versionOne.envs.includes("draft");
    } else {
      versionOneIsDraft = false;
    }
    sandboxPypes.forEach((pype) => {
      sandboxPypesIds.push(pype.id);
    });

    if (!show) {
      return null;
    }

    const Columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: "30%",
        hover: true,
        sorter: this.revertSortFunc,
        render: (data) => this.channelNameFormat(data),
      },
      {
        title: "Environment",
        dataIndex: "environment",
        key: "environment",
        width: "20%",
        render: (data) => this.channelEnvironmentFormat(data),
      },
      {
        title: "Last Edited",
        dataIndex: "lastEdited",
        key: "lastEdited",
        width: "40%",
        render: (data) => this.channelLastEditedFormat(data),
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        width: "10%",
        showOnHover: true,
        render: (data) => this.channelMenuFormat(data),
      },
    ];

    const deleteModal = (
      <DataModal
        title="Are you sure?"
        open={shouldShowDeleteModal}
        okLabel="Delete"
        onCancel={this.onHideDeleteModal}
        onClose={this.onHideDeleteModal}
        onAdd={() => this.handleDeleteInterface(interfaceToDelete)}
      >
        <p>
          Are you sure you want to delete this channel? Users will no longer be
          able to access your microapp using it.
        </p>
      </DataModal>
    );

    return (
      <div className="channels-tab">
        <h3 className="channels-tab-title">Channels</h3>
        <div className="channels-tab-description first">
          <p>
            Channels are how your users will interact with your microapp, for
            example you can place a Pype on your website or publish your
            microapp to a 3rd party messaging channel (e.g. Facebook).
          </p>
          <Button iconName={IconNames.Plus} hollow onClick={this.addChannel}>
            Add Channel
          </Button>
        </div>
        <DataTable
          className="channels-tab-table"
          columns={Columns}
          dataSource={loading ? [] : filteredInterfaces}
          rowKey="id"
          defaultSortName="name"
          defaultSortOrder="desc"
          bordered={true}
          onRowClick={this.onEditChannel}
        />
        {loading && (
          <div className="channels-tab-placeholders">
            {Array.from(Array(4).keys()).map((index) => (
              <ChannelPlaceholder key={index} />
            ))}
          </div>
        )}
        {(this.props.botData.currentVersions.length === 0 ||
          versionOneIsDraft) && (
          <p
            style={{
              fontFamily: "Open Sans",
              fontSize: "16px",
              lineHeight: 1.5,
              color: backgroundGrey600,
              marginTop: "16px",
            }}
          >
            To get started, simply click on Add Channel.
          </p>
        )}
        {deleteModal}
      </div>
    );
  }
}

Channels.propTypes = {
  show: PropTypes.bool,
  customer: PropTypes.object,
  flags: PropTypes.object,
  customers: PropTypes.object,
  history: PropTypes.object,
  bot: PropTypes.object,
  state: PropTypes.object,
  match: PropTypes.object,
  botData: PropTypes.object,
  interfaces: PropTypes.array,
  refreshInterfaces: PropTypes.bool,
  setRefreshInterface: PropTypes.func,
  removeInterfaces: PropTypes.func,
  getInterfaces: PropTypes.func,
  selectedInterface: PropTypes.func,
  setNotification: PropTypes.func,
};

Channels.defaultProps = {
  show: false,
  flags: {},
};

const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps.match.params;
  const customer = state.manager.customers[customerId];
  return {
    customer,
    customers: state.manager.customers,
    state,
    interfaces: state.manager.interfaces,
    streams: state.manager.streams,
    botStreams: state.manager.botStreams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeInterfaces: async () => dispatch(removeInterfaces()),
    getInterfaces: (pypeId, env, interfacesAreEnabled) =>
      dispatch(getInterfaces(pypeId, env, interfacesAreEnabled)),
    selectedInterface: (conversationalInterface) =>
      dispatch(selectedInterface(conversationalInterface)),
    setNotification: (data) => dispatch(setNotification(data)),
  };
};

export default withLDConsumer()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Channels))
);
