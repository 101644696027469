import React from "react";
import PropTypes from "prop-types";

const CheckboxComponent = ({ checked, onChange }) => (
  <div className="checkbox">
    <label>
      <input
        type="checkbox"
        checked={checked}
        onChange={() => {
          onChange(!checked);
        }}
      />
      <span className="checkmark" />
    </label>
  </div>
);

CheckboxComponent.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

CheckboxComponent.defaultProps = {
  checked: false,
  onChange: () => {},
};

export { CheckboxComponent };
