import {
  Button,
  ControlLabel,
  Glyphicon,
  DropdownButton,
  MenuItem,
  Row,
  Col,
} from "react-bootstrap";
import PropTypes from "prop-types";
import React from "react";
import { CheckboxComponent } from "../share/CheckboxComponent";
import { RadioComponent } from "../share/RadioComponent";
import { SkeletonLoader } from "ui-elements";

const MainSolution = ({
  envs,
  pypes,
  onSelectEnv,
  isPypeLoading,
  totalStreams,
  selectedEnvironment2Publish,
  handlePypeSelection,
  handleRadioSelection,
  handleShowClick,
}) => (
  <div className="modal-middle-content">
    <div>
      <ControlLabel>Environment</ControlLabel>
      <Row>
        <Col sm={6}>
          <DropdownButton
            title={selectedEnvironment2Publish}
            id="document-type"
            onSelect={onSelectEnv}
            disabled={isPypeLoading}
          >
            {envs.map((v, i) => (
              <MenuItem key={i} className="capitalize" eventKey={i}>
                {v}
              </MenuItem>
            ))}
          </DropdownButton>
        </Col>
        <Col className="small-text text-right" sm={6}>
          <p className="body-notation">
            Microapps should always be tested on Sandbox before being published
            to Production.
          </p>
        </Col>
      </Row>
    </div>
    <br />
    <br />
    <div className="middle-content">
      <ControlLabel>Pypes</ControlLabel>
      {isPypeLoading && <SkeletonLoader loadingRows={3} />}
      {!isPypeLoading && pypes && pypes.length === 0 && (
        <div className="none-available">
          <Glyphicon glyph="glyphicon glyphicon-remove-circle" />
          <div className="none-left">
            <h5 className="pype-name">None Available</h5>
            <p className="pype-description">
              You need to add a pype and a stream to this environment
            </p>
          </div>
        </div>
      )}
      {!isPypeLoading &&
        pypes &&
        pypes.length > 0 &&
        pypes.map((pype, index) => (
          <div key={pype.pypeStreamId} className="pypes-list">
            <Row>
              <Col md={6} xs={12} className="left">
                <CheckboxComponent
                  checked={pype.isPublished}
                  onChange={(checked) => handlePypeSelection(checked, pype)}
                />
                <div className="title_des">
                  <h5 className="pype-name">
                    {pype.name}&nbsp;&nbsp;({pype.stream_name})
                  </h5>
                  <p className="pype-description">
                    {pype.channel === "web"
                      ? "Web Conversational Interface"
                      : pype.channel === "facebook"
                      ? "Messaging Apps (e.g Facebook, WhatsApp..)"
                      : "Uncustomized"}
                  </p>
                </div>
              </Col>
              <Col md={6} xs={12} className="right">
                <RadioComponent
                  index={index}
                  checked={pype.channel === "web"}
                  name="Web Conversational Interface"
                  channel="web"
                  onChange={() => handleRadioSelection("web", pype)}
                />
                <RadioComponent
                  index={index}
                  checked={pype.channel === "facebook"}
                  name="Messaging Apps (e.g Facebook, WhatsApp..)"
                  channel="facebook"
                  onChange={() => handleRadioSelection("facebook", pype)}
                />
              </Col>
            </Row>
          </div>
        ))}
      {totalStreams && totalStreams.length > 2 && (
        <div className="show-all-btn">
          <Button variant="link" onClick={handleShowClick}>
            Show all pypes <i className="glyphicon glyphicon-chevron-down"></i>
          </Button>
        </div>
      )}
    </div>
  </div>
);

MainSolution.propTypes = {
  envs: PropTypes.array,
  pypes: PropTypes.array,
  onSelectEnv: PropTypes.func,
  isPypeLoading: PropTypes.bool,
  totalStreams: PropTypes.array,
  selectedEnvironment2Publish: PropTypes.string,
  handlePypeSelection: PropTypes.func,
  handleRadioSelection: PropTypes.func,
  handleShowClick: PropTypes.func,
};

export { MainSolution };
