import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updatePypes, getPypes } from "../../Store/actions";
import { MainSolution } from "./MainSolution";
import { EditMainSolution } from "./EditMainSolution";
import { SurveySolution } from "./SurveySolution";
import { EditSurveySolution } from "./EditSurveySolution";

const ModalType = {
  SURVEY: "survey",
  SOLUTION: "main",
};

const ViewMode = {
  VIEW: "VIEW",
  EDIT: "EDIT",
};

const PublishSolutionModal = ({
  // new perf ff props
  show,
  customerId,
  isPypeLoading_FF,
  isEmptyPypes,
  publishedSolutionType,
  openInEditMode,
  pypes_FF,
  streams,
  botStreams,
  bots,
  botId,
  latestVersion,
  envs,
  selectedVersion2Publish,
  onCancel,
  onPublish,
  cancelDisabled,
  versionLoading,
  updatePypesData,
  getPypesData,
  customer,
}) => {
  //#region perf ff start
  const [selectedSolutions_FF, setSelectedSolutions] = useState([]);
  const [selectedPypes_FF, setSelectedPypes] = useState([]);
  const [pypesData, setPypesData] = useState([]);
  const [solutions_FF, setSolutions] = useState([]);
  const [totalStreams_FF, setTotalStreams] = useState([]);
  const [modalType, setModalType] = useState(null);
  const [viewMode, setViewMode] = useState(ViewMode.VIEW);
  const [selectedPypesLoading, setSelectedPypesLoading] = useState(false);
  const [selectedEnvironment2Publish_FF, setSelectedEnvironment2Publish] =
    useState(envs[0] === "sandbox" ? envs[0] : envs[1]);

  const prevViewModeRef = useRef(null);

  useEffect(() => {
    async function initModal() {
      await updatePypesData([]);
      await getPypesData(customer.gesId, selectedEnvironment2Publish_FF);
    }
    initModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pypes_FF.length) {
      getPypeStreamCombination(false, selectedPypes_FF, selectedSolutions_FF);
      getBotStreamcombination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pypes_FF]);

  useEffect(() => {
    if (
      prevViewModeRef.current === ViewMode.EDIT &&
      viewMode === ViewMode.VIEW
    ) {
      getPypeStreamCombination(false, [], []);
    }
    prevViewModeRef.current = viewMode;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode]);

  useEffect(() => {
    if (isEmptyPypes) {
      if (
        (!selectedPypes_FF.length || !selectedSolutions_FF.length) &&
        selectedPypesLoading
      ) {
        if (viewMode === ViewMode.EDIT) {
          setViewMode(ViewMode.VIEW);
          setSelectedPypesLoading(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmptyPypes, selectedPypes_FF, selectedSolutions_FF]);

  useEffect(() => {
    if (openInEditMode && !versionLoading) {
      setViewMode(ViewMode.EDIT);
      setSelectedPypesLoading(true);
    }
    if (publishedSolutionType) {
      setModalType(publishedSolutionType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openInEditMode, publishedSolutionType]);

  const getPypeStreamCombination = (
    showAll,
    selectedPypes,
    selectedSolutions
  ) => {
    let updatedPypes = [];
    let updatedSolutions = [];
    const filteredPypes =
      pypes_FF && pypes_FF.filter((p) => p.customer_id === customer.gesId);
    if (viewMode === ViewMode.VIEW && modalType === ModalType.SOLUTION) {
      filteredPypes &&
        filteredPypes.forEach((p) => {
          streams[p.id] &&
            streams[p.id].forEach((st, streamIndex) => {
              const matchedPypes = selectedPypes.find(
                (sp) => sp.pypeStreamId === `${p.id}_${st.id}_${streamIndex}`
              );
              const mappedPypes =
                matchedPypes !== undefined
                  ? matchedPypes
                  : {
                      ...p,
                      pypeStreamId: `${p.id}_${st.id}_${streamIndex}`,
                      stream_id: st.id,
                      stream_name: st.stream_name,
                      isPublished: false,
                      channel: "",
                      start_chat_bot_id: st.start_chat_bot_id,
                      end_chat_bot_id: st.end_chat_bot_id,
                    };
              updatedPypes.push(mappedPypes);
            });
        });
      if (filteredPypes.length > 2 && !showAll) {
        updatedPypes = updatedPypes.slice(0, 2);
      }
      setPypesData(updatedPypes);
      setTotalStreams(filteredPypes);
    } else if (viewMode === ViewMode.VIEW && modalType === ModalType.SURVEY) {
      bots
        .filter((b) => b.name === botId)
        .forEach((b) => {
          filteredPypes &&
            filteredPypes.forEach((p) => {
              streams[p.id] &&
                streams[p.id].forEach((st, streamIndex) => {
                  const matchedSolution = selectedSolutions.find(
                    (sp) =>
                      sp.pypeStreamId ===
                      `${b.name}_${p.id}_${st.id}_${streamIndex}`
                  );
                  const mappedSolution =
                    matchedSolution !== undefined
                      ? matchedSolution
                      : {
                          botName: b.name,
                          ...p,
                          pypeStreamId: `${b.name}_${p.id}_${st.id}_${streamIndex}`,
                          stream_id: st.id,
                          stream_name: st.stream_name,
                          isPublished: false,
                          autoStart: st.auto_start_with_bot,
                          start_chat_bot_id: st.start_chat_bot_id,
                          end_chat_bot_id: st.end_chat_bot_id,
                        };
                  updatedSolutions.push(mappedSolution);
                });
            });
        });
      if (filteredPypes.length > 2 && !showAll) {
        updatedSolutions = updatedSolutions.slice(0, 2);
      }
      setSolutions(updatedSolutions);
      setTotalStreams(filteredPypes);
    }
  };

  const getBotStreamcombination = async () => {
    let updatedPypes = [];
    let updatedSolutions = [];
    const mergedId = `${customerId}.${botId}`;
    const filteredPypes =
      pypes_FF && pypes_FF.filter((p) => p.customer_id === customer.gesId);
    if (modalType === ModalType.SOLUTION) {
      filteredPypes &&
        filteredPypes.forEach((p) => {
          streams[p.id] &&
            streams[p.id].forEach((st, streamIndex) => {
              botStreams[st.id] &&
                botStreams[st.id].forEach((bs) => {
                  if (bs.id === st.id && mergedId === bs.start_chat_bot_id) {
                    const mappedPypes = {
                      botName: botId,
                      ...p,
                      pypeStreamId: `${p.id}_${st.id}_${streamIndex}`,
                      stream_id: st.id,
                      stream_name: st.stream_name,
                      isPublished: true,
                      channel: bs.auto_start_with_bot ? "web" : "facebook",
                      start_chat_bot_id: st.start_chat_bot_id,
                      end_chat_bot_id: st.end_chat_bot_id,
                    };
                    const matchedPypes = updatedPypes.filter(
                      (up) => up.pypeStreamId === mappedPypes.pypeStreamId
                    );
                    if (!matchedPypes.length) {
                      updatedPypes.push(mappedPypes);
                    }
                  }
                });
            });
        });
      if (updatedPypes.length) {
        updatedPypes.filter(
          (pype) => !pype.stream_name.includes("preview_stream")
        );
        setSelectedPypes(updatedPypes);
        setSelectedPypesLoading(false);
        getPypeStreamCombination(false, updatedPypes, updatedSolutions);
      } else {
        setViewMode(ViewMode.VIEW);
        setSelectedPypesLoading(false);
      }
    } else if (viewMode === ViewMode.EDIT && modalType === ModalType.SURVEY) {
      filteredPypes &&
        filteredPypes.forEach((p) => {
          streams[p.id] &&
            streams[p.id].forEach((st, streamIndex) => {
              botStreams[st.id] &&
                botStreams[st.id].forEach((bs) => {
                  if (bs.id === st.id && mergedId === bs.end_chat_bot_id) {
                    const mappedSolution = {
                      botName: botId,
                      ...p,
                      pypeStreamId: `${botId}_${p.id}_${st.id}_${streamIndex}`,
                      stream_id: st.id,
                      stream_name: st.stream_name,
                      isPublished: true,
                      autoStart: bs.auto_start_with_bot,
                      start_chat_bot_id: st.start_chat_bot_id,
                      end_chat_bot_id: st.end_chat_bot_id,
                    };
                    const matchedSolution = updatedSolutions.filter(
                      (up) => up.pypeStreamId === mappedSolution.pypeStreamId
                    );
                    if (!matchedSolution.length) {
                      updatedSolutions.push(mappedSolution);
                    }
                  }
                });
            });
        });
      if (updatedSolutions.length) {
        setSelectedSolutions(updatedSolutions);
        setSelectedPypesLoading(false);
        getPypeStreamCombination(false, updatedPypes, updatedSolutions);
      } else {
        setViewMode(ViewMode.VIEW);
        setSelectedPypesLoading(false);
      }
    }
  };

  const handlePypeSelection_FF = (value, selectedPype) => {
    const newPypes = [...pypesData];
    const selectedIndex = newPypes.findIndex(
      (item) => item.pypeStreamId === selectedPype.pypeStreamId
    );
    if (selectedIndex >= 0) {
      newPypes[selectedIndex].isPublished = value;
      const filteredPypes =
        newPypes &&
        newPypes
          .filter((p) => p.isPublished)
          .filter((pype) => !pype.stream_name.includes("preview_stream"));
      setPypesData(newPypes);
      setSelectedPypes(filteredPypes);
    }
  };

  const handleRadioSelection_FF = (value, selectedPype) => {
    let newPypes = [...pypesData];
    let pypeFound = newPypes.find(
      (pype) => pype.pypeStreamId === selectedPype.pypeStreamId
    );

    if (pypeFound) {
      pypeFound.channel = value;
      setPypesData(newPypes);
    }
  };

  const handleBotSelection_FF = (value, selectedSolution) => {
    const newSolutions = [...solutions_FF];
    const selectedIndex = newSolutions.findIndex(
      (item) => item.pypeStreamId === selectedSolution.pypeStreamId
    );
    if (selectedIndex >= 0) {
      newSolutions[selectedIndex].isPublished = value;
      const filteredSolutions =
        newSolutions && newSolutions.filter((p) => p.isPublished);
      setSolutions(newSolutions);
      setSelectedSolutions(filteredSolutions);
    }
  };

  const onSelectEnv_FF = async (evtkey) => {
    await updatePypesData([]);
    setSelectedEnvironment2Publish(envs[evtkey]);
    await getPypesData(customer.gesId, envs[evtkey]);
    getPypeStreamCombination(false, selectedPypes_FF, selectedSolutions_FF);
  };

  const handleShowClick_FF = () => {
    getPypeStreamCombination(true, selectedPypes_FF, selectedSolutions_FF);
    setTotalStreams([]);
  };

  const filteredSelectedPypes = selectedPypes_FF.filter(
    (pype) => !pype.stream_name.includes("preview_stream")
  );
  const filteredTotalPypes = pypesData.filter(
    (pype) => !pype.stream_name.includes("preview")
  );
  const filteredTotalStreams = totalStreams_FF.filter(
    (stream) => !stream.name.includes("preview")
  );

  const getUpdatedPypesData = async () => {
    await updatePypesData([]);
    await getPypesData(customer.gesId, selectedEnvironment2Publish_FF);
  };

  const selectModalType = async (isMainSolution) => {
    await getUpdatedPypesData();
    setModalType(isMainSolution ? ModalType.SOLUTION : ModalType.SURVEY);
  };

  const handleSwitchClick_FF = async () => {
    await getUpdatedPypesData();
    setModalType((prevState) =>
      prevState === ModalType.SOLUTION ? ModalType.SURVEY : ModalType.SOLUTION
    );
    setSelectedPypesLoading(false);
  };

  const handleEditClick_FF = async () => {
    await getUpdatedPypesData();
    setViewMode(ViewMode.VIEW);
    setSelectedPypesLoading(false);
  };

  const handlePublish_FF = () => {
    onPublish(
      selectedPypes_FF,
      selectedSolutions_FF,
      solutions_FF,
      pypesData,
      selectedEnvironment2Publish_FF,
      modalType
    );
  };

  const switchContent_FF =
    modalType === ModalType.SOLUTION
      ? "Switch to end survey"
      : "Switch to main microapp";

  const isChannelSelected = selectedPypes_FF.filter((sp) => sp.channel !== "");
  const isPublishActive_FF =
    ((selectedPypes_FF.length && isChannelSelected.length) ||
      selectedSolutions_FF.length) &&
    !versionLoading;

  //#region perf ff start

  return (
    <Modal show={show} className="publish-modal">
      <Modal.Header>
        <Modal.Title>
          <span>
            {latestVersion === selectedVersion2Publish
              ? modalType === ModalType.SURVEY
                ? "Publish End Survey"
                : "Publish Microapp"
              : "Republish Microapp"}
          </span>
          &nbsp;&nbsp;
          <Button bsStyle="default" className="version">
            {selectedVersion2Publish}
          </Button>
          {!!modalType && viewMode === ViewMode.VIEW && (
            <div className="switch-btn pull-right">
              <Button onClick={handleSwitchClick_FF}>{switchContent_FF}</Button>
            </div>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!modalType && (
          <div>
            <p>
              Is this a stand alone microapp (Main Microapp) or supplemental
              questions that are asked after a stand alone microapp (End
              Survey)?
            </p>
            <div className="text-center">
              <Button onClick={() => selectModalType(true)} bsStyle="primary">
                Main Microapp
              </Button>
              <Button onClick={() => selectModalType(false)} bsStyle="primary">
                End Survey
              </Button>
            </div>
          </div>
        )}
        {modalType === ModalType.SOLUTION && viewMode === ViewMode.VIEW && (
          <MainSolution
            envs={envs}
            isPypeLoading={isPypeLoading_FF}
            pypes={filteredTotalPypes}
            totalStreams={filteredTotalStreams}
            selectedPypes={filteredSelectedPypes}
            onSelectEnv={onSelectEnv_FF}
            handleRadioSelection={handleRadioSelection_FF}
            handlePypeSelection={handlePypeSelection_FF}
            selectedEnvironment2Publish={selectedEnvironment2Publish_FF}
            handleShowClick={handleShowClick_FF}
          />
        )}
        {modalType === ModalType.SOLUTION && viewMode === ViewMode.EDIT && (
          <EditMainSolution
            isSelectedPypesLoading={selectedPypesLoading}
            selectedPypes={filteredSelectedPypes}
            selectedEnvironment2Publish={selectedEnvironment2Publish_FF}
            handleEditClick={handleEditClick_FF}
            versionLoading={versionLoading}
          />
        )}
        {modalType === ModalType.SURVEY && viewMode === ViewMode.VIEW && (
          <SurveySolution
            envs={envs}
            isPypeLoading={isPypeLoading_FF}
            solutions={solutions_FF}
            totalStreams={filteredTotalStreams}
            onSelectEnv={onSelectEnv_FF}
            handleBotSelection={handleBotSelection_FF}
            selectedEnvironment2Publish={selectedEnvironment2Publish_FF}
            handleShowClick={handleShowClick_FF}
          />
        )}
        {modalType === ModalType.SURVEY && viewMode === ViewMode.EDIT && (
          <EditSurveySolution
            isSelectedPypesLoading={selectedPypesLoading}
            selectedSolutions={selectedSolutions_FF}
            selectedEnvironment2Publish={selectedEnvironment2Publish_FF}
            handleEditClick={handleEditClick_FF}
            versionLoading={versionLoading}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={cancelDisabled} onClick={onCancel} bsStyle="info">
          Cancel
        </Button>
        <Button
          disabled={!isPublishActive_FF}
          onClick={handlePublish_FF}
          bsStyle="primary"
        >
          Publish
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

PublishSolutionModal.propTypes = {
  show: PropTypes.bool,
  flags: PropTypes.object,
  isPypeLoading: PropTypes.bool,
  isPypeLoading_FF: PropTypes.bool,
  isSelectedPypesLoading: PropTypes.bool,
  showSolutionModal: PropTypes.bool,
  showSurveyModal: PropTypes.bool,
  showPublishContent: PropTypes.bool,
  pypes: PropTypes.array,
  pypes_FF: PropTypes.array,
  solutions: PropTypes.array,
  selectedPypes: PropTypes.array,
  showEditSolutionModal: PropTypes.bool,
  latestVersion: PropTypes.string,
  envs: PropTypes.array,
  switchContent: PropTypes.string,
  totalStreams: PropTypes.array,
  selectedEnvironment2Publish: PropTypes.string,
  selectedVersion2Publish: PropTypes.string,
  cancelDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
  showEditSurveyModal: PropTypes.bool,
  selectedSolutions: PropTypes.array,
  onSolutionSelect: PropTypes.func,
  onSurveySelect: PropTypes.func,
  onCancel: PropTypes.func,
  onPublish: PropTypes.func,
  onSelectEnv: PropTypes.func,
  handlePypeSelection: PropTypes.func,
  handleRadioSelection: PropTypes.func,
  handleShowClick: PropTypes.func,
  handleSwitchClick: PropTypes.func,
  handleBotSelection: PropTypes.func,
  handleEditClick: PropTypes.func,
  versionLoading: PropTypes.bool,
  customerId: PropTypes.string,
  isEmptyPypes: PropTypes.bool,
  streams: PropTypes.object,
  publishedSolutionType: PropTypes.string,
  openInEditMode: PropTypes.bool,
  bots: PropTypes.array,
  botStreams: PropTypes.object,
  botId: PropTypes.string,
  updatePypesData: PropTypes.func,
  getPypesData: PropTypes.func,
  customer: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    pypes_FF: state.manager.pypes,
    streams: state.manager.streams,
    botStreams: state.manager.botStreams,
    isEmptyPypes: state.manager.isEmptyPypes,
    isPypeLoading_FF: state.manager.isPypeLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updatePypesData(pypes = []) {
    dispatch(updatePypes(pypes));
  },
  getPypesData(customerId, versionId) {
    dispatch(getPypes(customerId, versionId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublishSolutionModal);
