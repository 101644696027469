import React from "react";
import PropTypes from "prop-types";
import { Icon, Tooltip } from "ui-elements";

const FlowsList = ({ flows }) => {
  return (
    <div className="w-full">
      <div className="grid grid-flow-row smallScreen:grid-cols-2 grid-cols-3 gap-6">
        {flows.map((flow) => (
          <div
            key={flow.flowName}
            className="flex flex-col rounded transform hover:scale-110 transition-all hover:shadow-lg ring-1 ring-gray-300"
          >
            <div className="flex p-4 rounded-t bg-gray-50 items-center justify-between">
              <h2 className="m-0 text-gray-500">{flow.flowName}</h2>
              <Tooltip label="Completion Rate">
                <p
                  className={`text-md m-0 rounded-full text-gray-600 px-4 ${
                    flow.totals.completion_rate <= 0.5
                      ? "bg-red-200"
                      : flow.totals.completion_rate <= 0.7
                      ? "bg-yellow-200"
                      : "bg-green-200"
                  }`}
                >{`${flow.totals.completion_rate * 100}%`}</p>
              </Tooltip>
            </div>
            <div className="flex flex-col p-4 rounded-b">
              <div className="flex justify-around items-center rounded-md">
                <div>
                  <h5 className="text-gray-500 p-0 text-sm mb-0">Attempted</h5>
                  <p className="text-xl text-gray-500">
                    {flow.totals.attempts}
                  </p>
                </div>
                <div>
                  <Icon name="arrow-right" fill="#DDD" width={48} />
                </div>
                <div className="">
                  <h5 className="text-gray-500 p-0 text-sm mb-0">Completed</h5>
                  <p className="text-xl text-gray-500">
                    {flow.totals.completed}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

FlowsList.propTypes = {
  flows: PropTypes.any,
};

export default FlowsList;
