export const getMaxTableHeight = (table) => {
  const top =
    table && table.table ? table.table.getBoundingClientRect().top + 60 : 323;
  return `calc(100vh - ${top}px`;
};

export const saveFile = (blob, filename) => {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
};

export const getSoftTimestamp = (time) => {
  let res = "Never updated";

  if (time) {
    const currentDate = new Date();
    const lastUpdatedDate = new Date(time);

    const minutesDifference = Math.round(
      (currentDate - lastUpdatedDate) / 1000 / 60
    );

    if (minutesDifference <= 15) {
      res = "Just Now";
    } else {
      currentDate.setHours(0, 0, 0, 0);
      lastUpdatedDate.setHours(0, 0, 0, 0);

      const daysDifference = Math.round(
        (currentDate - lastUpdatedDate) / 1000 / 60 / 60 / 24
      );

      const weeksDifference = Math.round(
        (currentDate - lastUpdatedDate) / 1000 / 60 / 60 / 24 / 7
      );

      if (daysDifference === 0) {
        res = "Today";
      } else if (daysDifference === 1) {
        res = "Yesterday";
      } else if (daysDifference <= 6) {
        res = `${daysDifference} Days Ago`;
      } else if (weeksDifference === 1) {
        res = "Last Week";
      } else {
        res = `${weeksDifference} Weeks Ago`;
      }
    }
  }

  return res;
};

export const dateCompare = (d1, d2, direct = 1) => {
  if (!d1) {
    return -1;
  } else if (!d2) {
    return 1;
  }

  const date1 = new Date(d1);
  const date2 = new Date(d2);

  if (date1 > date2) {
    return 1 * direct;
  } else if (date1 < date2) {
    return -1 * direct;
  }

  return 0;
};

export const capitalizeEachWord = (str) => {
  return str
    .split(" ")
    .map((word) =>
      word && word.length ? word[0].toUpperCase() + word.slice(1) : ""
    )
    .join(" ");
};

export const debounce = (fn, time) => {
  let timeout;

  return function () {
    const functionCall = () => fn.apply(this, arguments);

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
};

export const validateUrl = (value) => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
};
