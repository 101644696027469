export default `
<script>
  window.pypestreamConfig = {
    APP_ID: '<APP_ID>',
    first_name: '', // TODO: Input user's first name
    last_name: 'Visitor', // TODO: Input user's last name
    phone: '', // TODO: Input user's phone number
    email: '', // TODO: Input user's email address
    screen_name: 'Screen 1', // TODO: Input user's landing page
    token: '', // TODO: Input user token
    passthrough: '', // TODO: Pass any additional data
    language: 'en',
    play_chime: false
  };

  function insertScript () {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '<launcherUrl>/launcher.js?ts=' + Date.now();
    script.async = true;
    document.body.appendChild(script);
  }

  if (document.readyState === 'complete') {
    insertScript();
  } else {
    window.addEventListener('load', insertScript);
  }
</script>
`.trim();
