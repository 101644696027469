/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from "react";
import { Button, DeleteInfoCard, Tooltip } from "ui-elements";
import PropTypes from "prop-types";
import { TextField, InputAdornment } from "@material-ui/core";
import SkeletonLoader from "../../share/SkeletonLoader";
import botManagerAPI from "../../../../bot-manager-api";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import installScript from "../installscripts";
import moment from "moment";
import {
  getInterfaces,
  removeInterfaces,
  selectedInterface,
  setNotification,
} from "../../../Store/actions";
import { backgroundGrey600 } from "../../../../utils/styleVariables";
import { Modal } from "react-bootstrap";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

class Interfaces extends Component {
  constructor() {
    super();
    this.state = {
      showCodes: false,
      streams: [],
      allPypes: [],
      livePypes: [],
      sandboxPypes: [],
      shouldShowDeleteModal: false,
      interfaceToDelete: null,
      filteredInterfaces: [],
      filteringInterfaces: false,
      fetchingInterfaces: false,
    };

    this.toggleCode = this.toggleCode.bind(this);
    this.clearAndFetchInterfaces = this.clearAndFetchInterfaces.bind(this);
    this.handleCopyClick = this.handleCopyClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteInterface = this.handleDeleteInterface.bind(this);
  }

  componentDidMount() {
    this.clearAndFetchInterfaces();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps && this.props.refreshInterfaces === true) {
      this.clearAndFetchInterfaces();
      this.props.setRefreshInterface(false);
    }

    // check the changes of interfaces, streams
    const { interfaces, flags } = this.props;
    const { streams } = this.state;
    if (
      JSON.stringify(prevProps.interfaces) !== JSON.stringify(interfaces) ||
      JSON.stringify(prevState.streams) !== JSON.stringify(streams)
    ) {
      this.setState({ filteringInterfaces: true });

      const { customerId, botId } = this.props.match.params;
      const mergedId = `${customerId}.${botId}`;
      const filteredStreams = streams.filter(
        (stream) => stream.start_chat_bot_id === mergedId
      );
      let filteredInterfaces = filteredStreams.reduce((acc, el) => {
        interfaces.forEach((item) => {
          if (item.widget.stream_id === el.id) {
            acc.push(item);
          }
        });
        return acc;
      }, []);
      // Deduplicate
      filteredInterfaces = [...new Set(filteredInterfaces)]
        .filter((item, index) => filteredInterfaces.indexOf(item) === index)
        .reduce(
          (unique, item) =>
            unique.includes(item) ? unique : [...unique, item],
          []
        )
        .filter((widget) => !widget.widget.created_by.includes("preview"))
        .filter(
          (widget, index, self) =>
            index === self.findIndex((t) => t.widget.id === widget.widget.id)
        )
        .sort((a, b) => (a.widget.widget_name > b.widget.widget_name ? 1 : -1));

      this.setState({
        filteredInterfaces: [...filteredInterfaces],
        filteringInterfaces: false,
      });
    }
  }

  toggleCode(id) {
    if (this.state.showCodes === id) {
      this.setState({ showCodes: "" });
    } else {
      this.setState({ showCodes: id });
    }
  }

  getPromise(pype_id, customer_id, env) {
    return new Promise((resolve, reject) => {
      botManagerAPI
        .getPypeStream(customer_id, env, pype_id)
        .then((newStream) => resolve(newStream));
    });
  }

  async clearAndFetchInterfaces() {
    this.setState({ fetchingInterfaces: true });

    await this.props.removeInterfaces();

    const livePypes = await botManagerAPI.getPypes(
      this.props.customer.gesId,
      "live"
    );
    this.setState({
      livePypes: livePypes,
      allPypes: [...this.state.allPypes, ...livePypes],
    });

    const livePypesPromises = [];
    livePypes.forEach(async (pype) => {
      await this.props.getInterfaces(pype.id, "live", true);
      livePypesPromises.push(
        this.getPromise(pype.id, pype.customer_id, "live")
      );
    });

    const sandboxPypes = await botManagerAPI.getPypes(
      this.props.customer.gesId,
      "sandbox"
    );
    this.setState({
      sandboxPypes: sandboxPypes,
      allPypes: [...this.state.allPypes, ...sandboxPypes],
    });

    const sandboxPypesPromises = [];
    sandboxPypes.forEach(async (pype) => {
      await this.props.getInterfaces(pype.id, "sandbox", true);
      sandboxPypesPromises.push(
        this.getPromise(pype.id, pype.customer_id, "sandbox")
      );
    });

    Promise.all([...livePypesPromises, ...sandboxPypesPromises]).then((res) => {
      res.forEach((newStream, index) => {
        const updatedStreams = [
          ...this.state.streams.filter((stream) => stream.id !== newStream.id),
          ...newStream,
        ];
        if (index === res.length - 1) {
          this.setState({
            streams: updatedStreams,
            fetchingInterfaces: false,
          });
        } else {
          this.setState({
            streams: updatedStreams,
          });
        }
      });
    });
  }

  handleCopyClick(env) {
    if (env === "sandbox") {
      const sandboxText = document.getElementById("sandboxText");
      navigator.clipboard
        .writeText(sandboxText.value)
        .then(() => "Copied successfully")
        .catch((e) => console.error(e));
    } else {
      const prodText = document.getElementById("prodText");
      navigator.clipboard
        .writeText(prodText.value)
        .then(() => "Copied successfully")
        .catch((e) => console.error(e));
    }
  }

  handleEditClick(conversationalInterface) {
    this.props.selectedInterface(conversationalInterface);
    this.props.history.push(
      `/bot/${this.props.bot.customerName}/${this.props.bot.botName}/editInterface/${conversationalInterface.widget.id}`
    );
  }

  handleDeleteInterface(conversationalInterface) {
    const { sandboxPypes } = this.state;
    let env = "live";
    sandboxPypes.forEach((pype) => {
      if (pype.id === conversationalInterface.widget.pype_id) {
        env = "sandbox";
      }
    });
    const body = {
      user_id: this.props.state.botUserSession.user.user_id,
      correlation_id: null,
      reply_to: conversationalInterface.widget.pype_id,
      version: 1,
      type: "request",
      auth_token: sessionStorage.getItem("global_accessToken"),
      request_type: "x_widget",
      request_action: "delete",
      data: {
        pype_id: conversationalInterface.widget.pype_id,
        id: conversationalInterface.widget.id,
        stream_id: conversationalInterface.widget.stream_id,
      },
    };
    botManagerAPI
      .deleteInterface(body, env, true)
      .then(() => {
        this.props.setNotification({
          openNotification: true,
          notificationDuration: 6000,
          notificationTitle: "The interface has been deleted.",
          notificationType: "success",
        });
        const tempFilteredInterfaces = this.state.filteredInterfaces;
        const deletedInterface = tempFilteredInterfaces.find(
          (item) => item.widget.id === conversationalInterface.widget.id
        );
        if (deletedInterface) {
          deletedInterface.deleted = true;
          this.setState({ filteredInterfaces: [...tempFilteredInterfaces] });
          setTimeout(() => {
            this.clearAndFetchInterfaces();
            this.setState({ filteredInterfaces: [] });
          }, 5000);
        } else {
          this.clearAndFetchInterfaces();
          this.setState({ filteredInterfaces: [] });
        }
        this.setState({ shouldShowDeleteModal: false });
      })
      .catch((e) => console.error(e));
  }

  render() {
    const {
      streams,
      allPypes,
      shouldShowDeleteModal,
      interfaceToDelete,
      filteredInterfaces,
      filteringInterfaces,
      fetchingInterfaces,
    } = this.state;
    let sandboxPypesIds = [];
    const versionOne = this.props.botData.currentVersions.find(
      (version) => version.version === "v1"
    );
    let versionOneIsDraft = false;
    if (typeof versionOne === "undefined") versionOneIsDraft = true;
    if (versionOne) {
      versionOneIsDraft = versionOne.envs.includes("draft");
    } else {
      versionOneIsDraft = false;
    }
    this.state.sandboxPypes.forEach((pype) => {
      sandboxPypesIds.push(pype.id);
    });

    const deleteModal = (
      <Modal
        show={shouldShowDeleteModal}
        onHide={() =>
          this.setState({
            shouldShowDeleteModal: false,
            interfaceToDelete: null,
          })
        }
        centered
      >
        <Modal.Header>
          <p
            style={{
              fontFamily: "Open Sans",
              fontSize: "16px",
              lineHeight: 1.5,
            }}
          >
            Are you sure you want to delete{" "}
            {interfaceToDelete ? (
              <span style={{ fontWeight: "bolder" }}>
                {interfaceToDelete.widget.widget_name}
              </span>
            ) : (
              ""
            )}{" "}
            ?
          </p>
        </Modal.Header>
        <Modal.Footer>
          <Button
            hollow
            onClick={() =>
              this.setState({
                shouldShowDeleteModal: false,
                interfaceToDelete: null,
              })
            }
          >
            Cancel
          </Button>
          <Button onClick={() => this.handleDeleteInterface(interfaceToDelete)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );

    let showLoader = false;
    showLoader =
      this.props.botData.currentVersions.length !== 0 &&
      (fetchingInterfaces || filteringInterfaces);

    return (
      this.props.show && (
        <div className="interfaces-tab">
          <h3>Interfaces</h3>
          <p style={{ marginBottom: "40px" }}>
            Interfaces are how users will interact with your microapp.
            Eventually, more channels (e.g. Google Business Messages) will be
            managed through these settings.
          </p>
          {showLoader ? (
            <SkeletonLoader />
          ) : !filteredInterfaces.length ? null : (
            filteredInterfaces.map((conversationalInterface, idx) =>
              conversationalInterface.deleted ? (
                <DeleteInfoCard
                  text="This interface has been deleted."
                  style={{ marginBottom: "24px" }}
                />
              ) : (
                <div key={idx}>
                  <div className="interfaces-tab-list">
                    <div className="interfaces-tab-list-preview">
                      <div
                        className="interfaces-tab-list-preview--rectangle"
                        style={{
                          backgroundColor: JSON.parse(
                            conversationalInterface.widget.style.replace(
                              /\\/g,
                              ""
                            )
                          ).widgetStyleColor,
                        }}
                      />
                      <div
                        className="interfaces-tab-list-preview-bubbles"
                        style={{
                          border: `1px solid ${
                            JSON.parse(
                              conversationalInterface.widget.style.replace(
                                /\\/g,
                                ""
                              )
                            ).widgetStyleColor
                          }`,
                        }}
                      >
                        <svg
                          className="interfaces-tab-list-preview-bubbles--solution-bubble"
                          xmlns="http://www.w3.org/2000/svg"
                          width="83"
                          height="31"
                          viewBox="0 0 83 31"
                        >
                          <g fill="none" fillRule="evenodd">
                            <g>
                              <path
                                fill="#FFF"
                                d="M0 0H1440V1200H0z"
                                transform="translate(-424 -387)"
                              />
                              <g>
                                <path
                                  fill="#FFF"
                                  stroke="#6B6587"
                                  d="M96 23.996c.966 0 1.841.392 2.475 1.026.633.633 1.025 1.508 1.025 2.474h0v71.5H.5v-71.5c0-.966.392-1.841 1.025-2.474.634-.634 1.509-1.026 2.475-1.026h0z"
                                  transform="translate(-424 -387) translate(420 356) rotate(-180 50 61.496)"
                                />
                                <g>
                                  <path
                                    fill={
                                      JSON.parse(
                                        conversationalInterface.widget.style.replace(
                                          /\\/g,
                                          ""
                                        )
                                      ).agentBubbleColor
                                    }
                                    d="M69 0c5.523 0 10 4.477 10 10v6l-.001.037L79 24.14c0 4.21 3.568 5.86 3.499 5.86-5.086 0-8.328-2.002-10.392-4.492-.978.32-2.022.492-3.107.492H10C4.477 26 0 21.523 0 16v-6C0 4.477 4.477 0 10 0h59z"
                                    transform="translate(-424 -387) translate(420 356) translate(4 31.5) matrix(-1 0 0 1 82.5 0)"
                                  />
                                  <text
                                    fill={
                                      JSON.parse(
                                        conversationalInterface.widget.style.replace(
                                          /\\/g,
                                          ""
                                        )
                                      ).agentBubbleTextColor
                                    }
                                    fillRule="nonzero"
                                    fontFamily="OpenSans-Regular, Open Sans"
                                    fontSize="16"
                                    transform="translate(-424 -387) translate(420 356) translate(4 31.5)"
                                  >
                                    <tspan x="12" y="18">
                                      Microapp
                                    </tspan>
                                  </text>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                        <svg
                          className="interfaces-tab-list-preview-bubbles--user-bubble"
                          xmlns="http://www.w3.org/2000/svg"
                          width="56"
                          height="31"
                          viewBox="0 0 56 31"
                        >
                          <g fill="none" fillRule="evenodd">
                            <g>
                              <path
                                fill="#FFF"
                                d="M0 0H1440V1200H0z"
                                transform="translate(-460 -417)"
                              />
                              <g>
                                <path
                                  fill="#FFF"
                                  stroke="#6B6587"
                                  d="M96 23.996c.966 0 1.841.392 2.475 1.026.633.633 1.025 1.508 1.025 2.474h0v71.5H.5v-71.5c0-.966.392-1.841 1.025-2.474.634-.634 1.509-1.026 2.475-1.026h0z"
                                  transform="translate(-460 -417) translate(420 356) rotate(-180 50 61.496)"
                                />
                                <g fill="#D1D1D1">
                                  <path
                                    d="M69 0c5.523 0 10 4.477 10 10v6l-.001.037L79 24.14c0 4.21 3.568 5.86 3.499 5.86-5.086 0-8.328-2.002-10.392-4.492-.978.32-2.022.492-3.107.492H10C4.477 26 0 21.523 0 16v-6C0 4.477 4.477 0 10 0h59z"
                                    transform="translate(-460 -417) translate(420 356) translate(4 31.5) matrix(-1 0 0 1 82.5 0)"
                                  />
                                </g>
                                <g>
                                  <path
                                    fill={
                                      JSON.parse(
                                        conversationalInterface.widget.style.replace(
                                          /\\/g,
                                          ""
                                        )
                                      ).userBubbleColor
                                    }
                                    d="M42 0c5.523 0 10 4.477 10 10v6l-.001.037L52 24.14c0 4.21 3.568 5.86 3.499 5.86-5.086 0-8.328-2.002-10.392-4.492-.978.32-2.022.492-3.107.492H10C4.477 26 0 21.523 0 16v-6C0 4.477 4.477 0 10 0h32z"
                                    transform="translate(-460 -417) translate(420 356) translate(40 61.5)"
                                  />
                                  <text
                                    fill={
                                      JSON.parse(
                                        conversationalInterface.widget.style.replace(
                                          /\\/g,
                                          ""
                                        )
                                      ).userBubbleTextColor
                                    }
                                    fillRule="nonzero"
                                    fontFamily="OpenSans-Regular, Open Sans"
                                    fontSize="16"
                                    transform="translate(-460 -417) translate(420 356) translate(40 61.5)"
                                  >
                                    <tspan x="8.5" y="18">
                                      User
                                    </tspan>
                                  </text>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                    <div className="interfaces-tab-list-info">
                      <div>
                        <p>{conversationalInterface.widget.widget_name}</p>
                        <small>
                          {
                            allPypes.filter(
                              (pype) =>
                                pype.id ===
                                conversationalInterface.widget.pype_id
                            )[0].name
                          }
                          {` (${
                            streams.find(
                              (stream) =>
                                stream.id ===
                                conversationalInterface.widget.stream_id
                            ).stream_name
                          })`}
                        </small>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            marginTop: "16px",
                          }}
                        >
                          <Button
                            style={{ display: "block" }}
                            onClick={() =>
                              this.handleEditClick(conversationalInterface)
                            }
                          >
                            Edit
                          </Button>
                          <Tooltip label="Delete Interface">
                            <svg
                              className="trashcan-icon"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              onClick={() => {
                                this.setState({
                                  shouldShowDeleteModal: true,
                                  interfaceToDelete: conversationalInterface,
                                });
                              }}
                            >
                              <g fill="none" fillRule="evenodd">
                                <g>
                                  <g transform="translate(-754 -396) translate(754 396)">
                                    <circle
                                      cx="12"
                                      cy="12"
                                      r="12"
                                      fill="#FFF"
                                    />
                                    <path
                                      fill="#0A3AB4"
                                      d="M15.628 20c.757 0 1.426-.612 1.494-1.366L18.159 7h.591c.138 0 .25-.112.25-.25v-.5c0-.138-.112-.25-.25-.25H15.5l-1.05-1.4c-.248-.331-.786-.6-1.2-.6h-2.5c-.414 0-.952.269-1.2.6L8.5 6H5.25c-.138 0-.25.112-.25.25v.5c0 .138.112.25.25.25h.59l1.038 11.634c.068.754.737 1.366 1.494 1.366h7.256zM14.25 6h-4.5l.6-.8c.083-.11.262-.2.4-.2h2.5c.138 0 .317.09.4.2l.6.8zm1.38 13H8.37c-.252 0-.474-.204-.495-.456L6.844 7h10.312l-1.031 11.544c-.02.252-.243.456-.496.456z"
                                    />
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </Tooltip>
                        </div>
                      </div>
                      <div>
                        <p className="interfaces-tab-list-info--informational-text">
                          Last edited{" "}
                          {moment
                            .utc(conversationalInterface.widget.last_updated)
                            .local()
                            .format("LL") +
                            " at " +
                            moment
                              .utc(conversationalInterface.widget.last_updated)
                              .local()
                              .format("LT")}
                        </p>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Button
                          hollow
                          onClick={() =>
                            this.toggleCode(conversationalInterface.widget.id)
                          }
                        >
                          Embed Code
                        </Button>
                      </div>
                    </div>
                  </div>
                  {this.state.showCodes ===
                    conversationalInterface.widget.id && (
                    <div
                      style={{
                        marginTop: "-16px",
                        marginBottom: "16px",
                        justifyContent: "space-between",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {sandboxPypesIds.includes(
                        conversationalInterface.widget.pype_id
                      ) ? (
                        <TextField
                          multiline
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {
                                  <Tooltip label="Copy Code">
                                    <svg
                                      className="copy-icon"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      onClick={() =>
                                        this.handleCopyClick("sandbox")
                                      }
                                    >
                                      <g fill="none" fillRule="evenodd">
                                        <g>
                                          <g transform="translate(-452 -252) translate(452 252)">
                                            <circle
                                              cx="12"
                                              cy="12"
                                              r="12"
                                              fill="#FFF"
                                            />
                                            <path
                                              fill="#0A3AB4"
                                              d="M15.5 20c.828 0 1.5-.672 1.5-1.5V17h1.5c.828 0 1.5-.672 1.5-1.5v-10c0-.828-.672-1.5-1.5-1.5h-10C7.672 4 7 4.672 7 5.5V7H5.5C4.672 7 4 7.672 4 8.5v10c0 .828.672 1.5 1.5 1.5h10zm3-4h-10c-.276 0-.5-.224-.5-.5v-10c0-.276.224-.5.5-.5h10c.276 0 .5.224.5.5v10c0 .276-.224.5-.5.5zm-3 3h-10c-.276 0-.5-.224-.5-.5v-10c0-.276.224-.5.5-.5H7v7.5c0 .828.672 1.5 1.5 1.5H16v1.5c0 .276-.224.5-.5.5z"
                                            />
                                          </g>
                                        </g>
                                      </g>
                                    </svg>
                                  </Tooltip>
                                }
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{
                            style: {
                              height: "44px",
                              color: "#7a6769",
                              fontSize: "16px",
                              lineHeight: "1.5",
                              fontFamily: "Open Sans",
                              overflowY: "auto",
                            },
                            id: "sandboxText",
                          }}
                          label="Embed Code"
                          variant="outlined"
                          disabled
                          multiline
                          rows={3}
                          value={installScript
                            .replace(
                              "<APP_ID>",
                              conversationalInterface.widget.id
                            )
                            .replace(
                              "<wcwEnv>",
                              window.config.PS_ENV_CONFIGS["sandbox"].wcwEnv
                            )
                            .replace(
                              "<domain>",
                              window.config.PS_ENV_CONFIGS["sandbox"].wcwDomain
                            )
                            .replace(
                              "<launcherUrl>",
                              window.config.PS_ENV_CONFIGS["sandbox"]
                                .wcwLauncherUrl
                            )}
                          style={{ width: "100%" }}
                        />
                      ) : (
                        <TextField
                          multiline
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {
                                  <Tooltip label="Copy Code">
                                    <svg
                                      className="copy-icon"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      onClick={() =>
                                        this.handleCopyClick("prod")
                                      }
                                    >
                                      <g fill="none" fillRule="evenodd">
                                        <g>
                                          <g transform="translate(-452 -252) translate(452 252)">
                                            <circle
                                              cx="12"
                                              cy="12"
                                              r="12"
                                              fill="#FFF"
                                            />
                                            <path
                                              fill="#0A3AB4"
                                              d="M15.5 20c.828 0 1.5-.672 1.5-1.5V17h1.5c.828 0 1.5-.672 1.5-1.5v-10c0-.828-.672-1.5-1.5-1.5h-10C7.672 4 7 4.672 7 5.5V7H5.5C4.672 7 4 7.672 4 8.5v10c0 .828.672 1.5 1.5 1.5h10zm3-4h-10c-.276 0-.5-.224-.5-.5v-10c0-.276.224-.5.5-.5h10c.276 0 .5.224.5.5v10c0 .276-.224.5-.5.5zm-3 3h-10c-.276 0-.5-.224-.5-.5v-10c0-.276.224-.5.5-.5H7v7.5c0 .828.672 1.5 1.5 1.5H16v1.5c0 .276-.224.5-.5.5z"
                                            />
                                          </g>
                                        </g>
                                      </g>
                                    </svg>
                                  </Tooltip>
                                }
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{
                            style: {
                              height: "44px",
                              color: "#7a6769",
                              fontSize: "16px",
                              lineHeight: "1.5",
                              fontFamily: "Open Sans",
                              overflowY: "auto",
                            },
                            id: "prodText",
                          }}
                          label="Embed Code"
                          variant="outlined"
                          multiline
                          rows={3}
                          disabled
                          value={installScript
                            .replace(
                              "<APP_ID>",
                              conversationalInterface.widget.id
                            )
                            .replace(
                              "<wcwEnv>",
                              window.config.PS_ENV_CONFIGS["live"].wcwEnv
                            )
                            .replace(
                              "<domain>",
                              window.config.PS_ENV_CONFIGS["live"].wcwDomain
                            )
                            .replace(
                              "<launcherUrl>",
                              window.config.PS_ENV_CONFIGS["live"]
                                .wcwLauncherUrl
                            )}
                          style={{ width: "100%" }}
                        />
                      )}
                    </div>
                  )}
                </div>
              )
            )
          )}
          {this.props.botData.currentVersions.length > 0 &&
          !versionOneIsDraft ? (
            <div>
              <Button
                hollow
                onClick={() =>
                  this.props.history.push(
                    `/bot/${this.props.bot.customerName}/${this.props.bot.botName}/createNewInterface`
                  )
                }
              >
                + Add Interface
              </Button>
            </div>
          ) : (
            !showLoader && (
              <p
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  lineHeight: 1.5,
                  color: backgroundGrey600,
                }}
              >
                Your microapp must be published before an interface can be
                created.
              </p>
            )
          )}
          {deleteModal}
        </div>
      )
    );
  }
}

Interfaces.propTypes = {
  show: PropTypes.bool,
  flags: PropTypes.object,
  customer: PropTypes.object,
  history: PropTypes.object,
  bot: PropTypes.object,
  state: PropTypes.object,
  match: PropTypes.object,
  botData: PropTypes.object,
  interfaces: PropTypes.array,
  refreshInterfaces: PropTypes.bool,
  setRefreshInterface: PropTypes.func,
  removeInterfaces: PropTypes.func,
  getInterfaces: PropTypes.func,
  selectedInterface: PropTypes.func,
  setNotification: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps.match.params;
  const customer = state.manager.customers[customerId];
  return {
    customer,
    state,
    interfaces: state.manager.interfaces,
    streams: state.manager.streams,
    botStreams: state.manager.botStreams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeInterfaces: async () => dispatch(removeInterfaces()),
    getInterfaces: (pypeId, env, interfacesAreEnabled) =>
      dispatch(getInterfaces(pypeId, env, interfacesAreEnabled)),
    selectedInterface: (conversationalInterface) =>
      dispatch(selectedInterface(conversationalInterface)),
    setNotification: (data) => dispatch(setNotification(data)),
  };
};

export default withLDConsumer()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Interfaces))
);
