import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { NODE_SETTING } from "../../enum";

import {
  SELECT_SAMPLE_NODE,
  UPDATE_SAMPLE_NODE,
  CLEAR_SAMPLE_NODE,
  ADD_CHILD_SAMPLE_NODE,
  FORMAT_SAMPLE_NODE,
  SET_SAMPLE_NODE_FLAGS,
} from "./actionTypes";

const EmbedTypes = NODE_SETTING.EMBED_TYPES;
const NodeType = NODE_SETTING.TYPE;

const getInitialState = () => ({
  nodeInfo: {},
  featureFlags: {},
});

// ------------------------------------
// Normal Functions
// ------------------------------------
const populateEmbedsInfo = (nodeInfo, isEmbed, srcEmbedType) => {
  let res = nodeInfo;

  // 1. get common field.
  let commonValues = [];
  let srcType = "";
  if (isEmbed) {
    const embedInfo = nodeInfo.embeds[srcEmbedType];
    if (embedInfo) {
      switch (srcEmbedType) {
        case EmbedTypes.BUTTONS:
        case EmbedTypes.LIST_PICKER:
        case EmbedTypes.CAROUSEL:
          if (embedInfo.type !== "dynamic") {
            commonValues = (embedInfo[embedInfo.type] || [])
              .map(({ label, link }) => ({ label, link }))
              .filter(({ label, link }) => !!label || !!link.number);
            srcType = embedInfo.type;
          }
          break;
        default:
          break;
      }
    }
  } else {
    const textFieldInfo = nodeInfo.textfield["None"];
    if (
      textFieldInfo &&
      textFieldInfo.data &&
      textFieldInfo.data.link &&
      textFieldInfo.data.link.number
    ) {
      commonValues = [{ link: textFieldInfo.data.link }];
    }
  }

  // 2. update other embeds.
  const fields = {
    [EmbedTypes.BUTTONS]: ["text", "images"],
    [EmbedTypes.LIST_PICKER]: ["text", "images"],
    [EmbedTypes.CAROUSEL]: ["standard"],
  };

  if (isEmbed) {
    if (srcType) {
      Object.keys(fields).forEach((type) => {
        fields[type].forEach((subType) => {
          if (type !== srcEmbedType || subType !== srcType) {
            if (res.embeds[type]) {
              if (res.embeds[type][subType]) {
                res.embeds[type][subType] = commonValues.map(
                  ({ label, link }, index) => ({
                    ...(res.embeds[type][subType][index] || {}),
                    label,
                    link,
                  })
                );
              } else {
                res.embeds[type][subType] = commonValues;
              }
            } else {
              res.embeds[type] = {
                type: fields[type][0],
                [subType]: commonValues,
              };
            }
          }
        });
      });
      // Update TextField info
      const link = commonValues.length > 0 ? commonValues[0].link : {};
      if (res.textfield) {
        if (res.textfield["None"]) {
          res.textfield["None"].data = {
            ...res.textfield["None"].data,
            link,
          };
        } else {
          res.textfield["None"] = {
            data: {
              link,
            },
          };
        }
      } else {
        res.textfield = {
          None: { data: { link } },
          type: srcEmbedType,
        };
      }
    }
  } else if (srcEmbedType === "None") {
    const link = commonValues.length > 0 ? commonValues[0].link : {};
    Object.keys(fields).forEach((type) => {
      fields[type].forEach((subType) => {
        res.embeds = res.embeds || {};
        if (res.embeds[type]) {
          if (
            res.embeds[type][subType] &&
            res.embeds[type][subType].length > 0
          ) {
            res.embeds[type][subType][0].link = link;
          } else {
            res.embeds[type][subType] = commonValues;
          }
        } else {
          res.embeds[type] = {
            type: fields[type][0],
            [subType]: commonValues,
          };
        }
      });
    });
  }
  return res;
};

const orderChanged = (array1, array2) => {
  const filterValues = (ar1, ar2) =>
    (ar1 || []).filter((a) => (ar2 || []).includes(a));
  const newArray1 = filterValues(array1, array2);
  const newArray2 = filterValues(array2, array1);

  return !isEqual(newArray1, newArray2);
};

const rearrangeChildren = (nodeInfo, embed, flags) => {
  let resNodeInfo = { ...nodeInfo };
  const supportedEmbedTypes = [
    EmbedTypes.BUTTONS,
    EmbedTypes.CAROUSEL,
    EmbedTypes.LIST_PICKER,
  ];
  const { type: embedType } = embed || {};
  const embedData = embed[embedType];

  if (supportedEmbedTypes.includes(embedType) && !isEmpty(embedData)) {
    // Check if the order is changed
    let oldNodeOrders = [];

    if (flags.pe19873) {
      oldNodeOrders = (nodeInfo.embeds[embedType]?.[embedData.type] || [])
        .map((node) => node?.link?.number)
        .filter((order) => !!order);
    } else {
      oldNodeOrders = (nodeInfo.children || []).map((node) => node.number);
    }

    const newNodeOrders = (embedData[embedData.type] || [])
      .map((node) => node?.link?.number)
      .filter((order) => !!order);

    if (!isEmpty(newNodeOrders) && orderChanged(newNodeOrders, oldNodeOrders)) {
      // The order was changed.
      const childrenInfo = (resNodeInfo.children || []).reduce(
        (res, child) => ({ ...res, [child.number]: child }),
        {}
      );
      let orderIndex = 0;
      const newChildren = [];
      (resNodeInfo.children || []).forEach((child) => {
        if (newNodeOrders.includes(child.number)) {
          newChildren.push(childrenInfo[newNodeOrders[orderIndex]]);
          orderIndex += 1;
        } else {
          newChildren.push(child);
        }
      });
      resNodeInfo = {
        ...resNodeInfo,
        children: [...newChildren],
      };
    }
  }

  return resNodeInfo;
};

// ------------------------------------
// Reducers
// ------------------------------------
export default (state = getInitialState(), action) => {
  const { nodeInfo, featureFlags } = state;

  let newNodeInfo = {};

  switch (action.type) {
    case SELECT_SAMPLE_NODE:
      return Object.assign({}, state, {
        nodeInfo: cloneDeep(action.data.node),
      });
    case UPDATE_SAMPLE_NODE:
      newNodeInfo = {};

      switch (action.data.type) {
        case "embeds":
        case "textfield":
          if (nodeInfo[action.data.type]) {
            newNodeInfo = cloneDeep({
              ...nodeInfo,
              [action.data.type]: {
                ...nodeInfo[action.data.type],
                type: action.data.data.type,
                [action.data.data.type]: {
                  ...nodeInfo[action.data.type][action.data.data.type],
                  ...(action.data.data[action.data.data.type] || {}),
                },
              },
              isWorkflow: action.data.isWorkflow || false,
            });
            if (action.data.type === "embeds") {
              // rearrange children in case the order is changed.
              newNodeInfo = rearrangeChildren(
                newNodeInfo,
                action.data.data,
                featureFlags
              );
            }
          } else {
            newNodeInfo = cloneDeep({
              ...nodeInfo,
              [action.data.type]: { ...(action.data.data || {}) },
              isWorkflow: action.data.isWorkflow || false,
            });
          }
          newNodeInfo = populateEmbedsInfo(
            newNodeInfo,
            action.data.type === "embeds",
            action.data.data.type
          );
          break;
        case "messages":
          newNodeInfo = cloneDeep({
            ...nodeInfo,
            messages: action.data.data,
            isWorkflow: action.data.isWorkflow || false,
          });
          break;
        default:
          newNodeInfo = {
            ...nodeInfo,
            [action.data.type]: cloneDeep(action.data.data),
            isWorkflow: action.data.isWorkflow || false,
          };
          break;
      }

      return Object.assign({}, state, {
        nodeInfo: newNodeInfo,
      });
    case CLEAR_SAMPLE_NODE:
      return Object.assign({}, state, {
        nodeInfo: {},
      });
    case ADD_CHILD_SAMPLE_NODE:
      if (nodeInfo.children) {
        nodeInfo.children = [
          ...nodeInfo.children.slice(0, action.data.pos),
          { ...action.data.node },
          ...nodeInfo.children.slice(action.data.pos),
        ];
      } else {
        nodeInfo.children = [action.data.node];
      }

      return Object.assign({}, state, {
        nodeInfo,
      });
    case FORMAT_SAMPLE_NODE:
      if (action.data.type === NodeType.DECISION) {
        nodeInfo.embeds = {};
        nodeInfo.textfield = {};
        nodeInfo.messages = [];

        return Object.assign({}, state, {
          nodeInfo,
        });
      } else if (action.data.type === NodeType.ACTION) {
        if (action.data.isWorkflow) {
          nodeInfo.action = {
            script: "ExecuteWorkflow.py",
            conditions: [
              {
                condition: {
                  id: `condition-1`,
                  variable: "SUCCESS",
                  value: "true",
                  operator: "equal",
                  options: [],
                  link: {},
                },
                type: "if",
              },
              {
                condition: {
                  id: `condition-2`,
                  variable: "SUCCESS",
                  value: "false",
                  operator: "equal",
                  options: [],
                  link: {},
                },
                type: "else",
              },
            ],
            outputs_variables: nodeInfo.outputs_variables || [
              { value: "SUCCESS", label: "SUCCESS" },
              { value: "WORKFLOW_OUTPUT", label: "WORKFLOW_OUTPUT" },
            ],
            inputs: {
              parameters: JSON.stringify({
                jsonFileName: `${action.data.customerId}.${action.data.botId}_${nodeInfo.id}`,
              }),
            },
          };
        } else {
          nodeInfo.action = null;
        }

        return Object.assign({}, state, {
          nodeInfo,
        });
      }

      return state;
    case SET_SAMPLE_NODE_FLAGS:
      return Object.assign({}, state, {
        featureFlags: action.data.flags,
      });
    default:
      return state;
  }
};
