import React from "react";
import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";

const SkeletonLoader = ({ columns = 3, width = 200, height = 100 }) => {
  const loaderStyles = {
    display: "flex",
    justifyContent: "space-between",
    margin: "30px",
  };
  const renderArr = [...Array(columns)];
  return (
    <div style={loaderStyles}>
      {renderArr.map((arr, i) => (
        <div key={i}>
          <Skeleton variant="text" width={width} />
          <br />
          <Skeleton variant="rect" width={width} height={height} />
        </div>
      ))}
    </div>
  );
};

SkeletonLoader.propTypes = {
  columns: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SkeletonLoader;
