import React from "react";
import PropTypes from "prop-types";
import config from "../../../../config";
import botManagerAPI from "../../../../bot-manager-api";
import Loader from "../../Loader";
import { PreviewPanel } from "ui-elements";
import { Helmet } from "react-helmet";
import "./Preview.scss";
import { DotMenu } from "ui-elements/build/common";
import { isEmpty } from "lodash";
class PreviewModal extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      previewData: {},
      Pypestream: {},
      fetchingWidget: false,
      passthroughData: {},
      pype_id: "",
      stream_id: "",
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.launchWidget = this.launchWidget.bind(this);
    this.reloadWidget = this.reloadWidget.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.setPreviewData = this.setPreviewData.bind(this);
    this.handleSubmitPassthrough = this.handleSubmitPassthrough.bind(this);
  }

  async componentDidUpdate(prevProps) {
    const { show } = this.props;

    if (show && show != prevProps.show) {
      this.reloadWidget();
    }
  }

  setPreviewData(response) {
    return new Promise((resolve, reject) => {
      const { botData } = this.props;
      const { customerName, botName } = botData.latestVersion;

      const pypeId = response?.data[0]?.pype_id;
      const streamId = response?.data[0]?.stream_ids[0];
      this.setState({
        pype_id: pypeId,
        stream_id: streamId,
      });

      botManagerAPI
        .getSolutionMetadata(
          "sandbox",
          this.props.botUserId,
          response?.data[0]?.pype_id,
          response?.data[0]?.stream_ids[0]
        )
        .then((res) => {
          let metadataMap = {};
          const metadata = res?.data?.preview_metadata ?? [];
          if (metadata && metadata != "undefined" && metadata != undefined) {
            metadata.forEach((item) => {
              const key = Object.keys(item);
              const value = Object.values(item);
              metadataMap[key] = value[0];
            });
            this.setState({
              passthroughData: JSON.stringify({
                preview: `${customerName}.${botName}`,
                ...(metadataMap ?? {}),
              }),
            });
            this.props.updateMetadata(metadata);

            resolve();
          } else {
            this.setState({
              passthroughData: JSON.stringify({
                preview: `${customerName}.${botName}`,
              }),
            });
            resolve();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async reloadWidget() {
    const { customer, botData } = this.props;
    const customerGesId = customer.gesId;
    const { latestVersion } = botData;
    const { customerName, botName } = latestVersion;
    const latestVersionId = latestVersion.id;
    const body = { customerName, botName };
    const currentEnv = "sandbox";

    this.setState({ fetchingWidget: true });

    const urls = {
      live: `${
        config.webserviceEnvConfig.live.wcwLauncherUrl
      }/api-launcher.js?ts=${Date.now()}`.replace("webchat", "web"),
      sandbox: `${
        config.webserviceEnvConfig.sandbox.wcwLauncherUrl
      }/api-launcher.js?ts=${Date.now()}`.replace("webchat", "web"),
    };
    this.setState({ urls });
    botManagerAPI.setPreviewWidget(latestVersionId).then(() => {
      botManagerAPI
        .getPreviewWidget(customerGesId, currentEnv, body)
        .then((widgetData) => {
          this.setPreviewData(widgetData).then(() => {
            this.setState(
              {
                previewData: widgetData.data,
              },
              () => {
                this.launchWidget();
                setTimeout(() => {
                  this.setState({ fetchingWidget: false });
                  // this.state.Pypestream("show");
                }, 3000);
              }
            );
          });
        })
        .catch((e) => {
          this.setState({
            error: "Failed to fetch preview microapp",
          });
          console.error(e);
        });
    });
  }

  handleRefresh() {
    const { Pypestream } = this.state;
    const urls = {
      live: `${
        config.webserviceEnvConfig.live.wcwLauncherUrl
      }/api-launcher.js?ts=${Date.now()}`.replace("webchat", "web"),
      sandbox: `${
        config.webserviceEnvConfig.sandbox.wcwLauncherUrl
      }/api-launcher.js?ts=${Date.now()}`.replace("webchat", "web"),
    };

    if (typeof Pypestream === "function") {
      Pypestream("shutdown");
      this.setState(
        {
          urls,
        },
        () => {
          this.launchWidget();
          this.setState({ fetchingWidget: false });
        }
      );
    } else console.error("Need to Launch Conversation before refreshing!");
  }

  onMenuClick(menu) {
    switch (menu.value) {
      case "edit":
        this.props.handleEdit();
        break;
      case "restart":
        this.handleRefresh();
        break;
      default:
        break;
    }
  }

  async launchWidget() {
    //eslint-disable-next-line
    const { customer, botData } = this.props;
    const customerGesId = customer.gesId;
    const { latestVersion } = botData;
    const { customerName, botName } = latestVersion;
    const latestVersionId = latestVersion.id;
    const body = { customerName, botName };
    const currentEnv = "sandbox";
    const { previewData } = this.state;

    const windowExists = typeof window?.parent?.Pypestream !== "undefined";

    if (!previewData.error_reason && windowExists) {
      const Pypestream = window.parent.Pypestream;
      const config = {
        passthrough: this.state.passthroughData || "",
        APP_ID: previewData[0].widget_ids[0],
        gtm_id: "GTM-P6DV8B4",
      };
      if (!isEmpty(previewData)) {
        try {
          await Pypestream(
            "boot",
            config,
            document.getElementById("previewContainer")
          );
          console.log("Boot pypestream");
          botManagerAPI.setPreviewWidget(latestVersionId).then(() => {
            console.log("Set Preview");
            botManagerAPI
              .getPreviewWidget(customerGesId, currentEnv, body)
              .then((widgetData) => {
                console.log("Got Preview");
                this.setPreviewData(widgetData).then(() => {
                  console.log("Set Data");
                  this.setState(
                    {
                      previewData: widgetData.data,
                    },
                    () => {
                      setTimeout(() => {
                        this.setState({ fetchingWidget: false });
                        Pypestream("show");
                      }, 3000);
                    }
                  );
                });
              })
              .catch((e) => {
                this.setState({
                  error: "Failed to fetch preview microapp",
                });
                console.error(e);
              });
          });
        } catch (e) {
          console.error(e);
          // this.launchWidget();
          return;
        }
      }

      this.setState({ Pypestream: window.parent.Pypestream });
    }
  }

  handleSubmitPassthrough() {
    const { botData } = this.props;
    const { latestVersion } = botData;
    const { customerName, botName } = latestVersion;

    return new Promise((resolve, reject) => {
      try {
        JSON.parse(this.props.sandboxPreviewMetadata);
        botManagerAPI
          .updateSolutionMetadata(
            "sandbox",
            this.props.botUserId,
            this.state.pype_id,
            this.state.stream_id,
            this.props.sandboxPreviewMetadata
          )
          .then((res) => {
            const updatedMetadata = {};
            const parsedMetadata = JSON.parse(
              this.props.sandboxPreviewMetadata
            ).preview_metadata;
            parsedMetadata.forEach((item) => {
              const key = Object.keys(item);
              const value = Object.values(item);
              updatedMetadata[key] = value[0];
            });

            if (res.status === 201) {
              this.setState({
                passthroughData: JSON.stringify({
                  preview: `${customerName}.${botName}`,
                  ...updatedMetadata,
                }),
              });
              this.handleRefresh();
              resolve();
            }
            if (res.status === 400) {
              reject();
            }
          })
          .catch((e) => {
            reject();
            console.error(e);
          });
      } catch (e) {
        return;
      }
    });
  }

  render() {
    const { show, handleClose } = this.props;
    const { fetchingWidget, Pypestream, previewData } = this.state;
    const currentEnv = "sandbox";
    const scriptSrc = this.state.urls && this.state.urls[currentEnv];
    let menus = [
      { menu: "Edit Initializing Data", value: "edit" },
      { menu: "Restart Microapp", value: "restart" },
    ];

    return (
      <div>
        <Helmet>
          {scriptSrc && (
            <script id="pypesScriptPreview" src={scriptSrc} async={true} />
          )}
        </Helmet>
        <PreviewPanel
          variant={this.props.variant}
          open={show}
          extra={
            <DotMenu
              closeOnMouseLeave={false}
              iconName="more"
              menus={menus}
              onClick={(menu) => this.onMenuClick(menu)}
              placement="bottom"
            />
          }
          title="Test"
          onClose={() => handleClose(Pypestream)}
          className="previewPanel"
        >
          {/* <Modal.Body className="preview_modal_body"> */}
          {show && (
            <div style={{ overflowX: "hidden" }}>
              <div style={{ margin: "0 -10px" }}>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  id="previewContainer"
                >
                  {fetchingWidget && !previewData.error_reason && (
                    <Loader center={false} />
                  )}
                  {previewData.error_reason && (
                    <p style={{ margin: "1rem" }} className="error">
                      There was a problem while fetching your preview microapp.
                      Please try again later.
                    </p>
                  )}
                </div>
                {/* </Modal.Body> */}
              </div>
            </div>
          )}
        </PreviewPanel>
      </div>
    );
  }
}

PreviewModal.propTypes = {
  customer: PropTypes.object,
  botData: PropTypes.object,
  show: PropTypes.bool,
  title: PropTypes.string,
  handleClose: PropTypes.func,
  handleEdit: PropTypes.func,
  variant: PropTypes.string,
  flags: PropTypes.object,
  botUserId: PropTypes.string,
  updateMetadata: PropTypes.func,
  sandboxPreviewMetadata: PropTypes.string,
  customerId: PropTypes.string,
};

PreviewModal.defaultProps = {
  customer: {},
  botData: {},
  flags: {},
  show: false,
  title: "",
  variant: "",
  botUserId: "",
  customerId: "",
  sandboxPreviewMetadata: "",
  handleEdit: () => {},
  updateMetadata: () => {},
  handleClose: () => {},
};

export default PreviewModal;
