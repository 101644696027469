import React from "react";

const ChannelPlaceholder = () => {
  return (
    <div className="channel-placeholder">
      <div className="channel-placeholder-name skeleton-box" />
      <div className="channel-placeholder-content">
        <div className="channel-placeholder-line skeleton-box" />
        <div className="channel-placeholder-line half skeleton-box" />
      </div>
    </div>
  );
};

export default ChannelPlaceholder;
