import isEmpty from "lodash/isEmpty";
import { NODE_SETTING } from "../enum";

import {
  getFileType,
  getFileCategory,
} from "../components/NodeDetails/MessageTypeSection/MediaBubble/helpers";

const NodeType = NODE_SETTING.TYPE;
const MessageType = NODE_SETTING.BUBBLE_TYPES;
const EmbedType = NODE_SETTING.EMBED_TYPES;

export const convertGraphToJSON = (graphData) => {
  let res = [];
  res = (graphData.children || []).reduce(
    (sum, node) => [...sum, ...loopNode(node)],
    []
  );

  return res;
};

const loopNode = (node) => {
  let res = [convertRow(node)];

  (node.children || [])
    .filter((node) =>
      [NodeType.ACTION, NodeType.DECISION, NodeType.UNKNOWN].includes(node.type)
    )
    .forEach((child) => {
      res = [...res, ...loopNode(child)];
    });

  return res;
};

const getFileNameWithoutExtension = (fileName) => {
  let res = "";

  if (fileName) {
    const arrs = fileName.split(".");

    if (arrs.length > 1) {
      res = arrs.slice(0, arrs.length - 1).join(".");
    } else {
      res = fileName;
    }
  }

  return res;
};

const getContentPropertiesOfDecisionNode = (node) => {
  let nextNode = null;
  let answerRequired = false;
  let globalVariable = null;
  let nluDisabled = false;

  if (node.embeds) {
    const embedType = node.embeds?.type;

    if (
      embedType === EmbedType.BUTTONS ||
      embedType === EmbedType.LIST_PICKER
    ) {
      const embedContentType = node.embeds[node.embeds.type]?.type;
      if (["text", "images"].includes(embedContentType)) {
        const embedSettings =
          (node.embeds[node.embeds.type]?.settings || {})[embedContentType] ??
          {};

        if (
          embedSettings?.saveUserSelection &&
          !isEmpty(embedSettings?.userVariables)
        ) {
          globalVariable = embedSettings?.userVariables[0].value;
        }
      }
    } else if (embedType === EmbedType.NONE) {
      // In case of None Embed Type
      const { enabled, data: textfieldContent } =
        (node.textfield || {})[EmbedType.NONE] || {};

      nextNode = textfieldContent?.link?.number;
      if (enabled && textfieldContent?.isCollect === "collect") {
        globalVariable = (textfieldContent?.userVariables || []).at(0);
        if (globalVariable) {
          globalVariable = globalVariable.label;
          nluDisabled = true;
        }
      }
    } else if (embedType === EmbedType.OTHER) {
      const embedContent = (node?.embeds || {})[EmbedType.OTHER];

      if (embedContent?.embedType === EmbedType.DATE_PICKER) {
        nextNode = embedContent?.link?.number;
        nluDisabled = true;
      }
    }
  }

  switch (node?.embeds?.type) {
    case EmbedType.BUTTONS:
    case EmbedType.CAROUSEL:
    case EmbedType.LIST_PICKER:
    case EmbedType.OTHER:
      answerRequired = true;
      break;
    case EmbedType.NONE:
      answerRequired = !!((node.textfield || {})[EmbedType.NONE] || {}).enabled;
      break;
    default:
      answerRequired = false;
      break;
  }

  return {
    nextNode,
    answerRequired: node?.answerRequired || answerRequired ? true : false,
    globalVariable,
    nluDisabled,
  };
};

const convertRow = (node) => {
  let res = {};

  const nodeType = getNodeType(node.type);

  if (node.type === NodeType.DECISION) {
    const embedType = getEmbedType(node);

    res = {
      number: node.number,
      type: nodeType,
      name: node.title,
      nodeContent: {
        ...getContentPropertiesOfDecisionNode(node),
        messages: (node.messages || [])
          .filter((msg) => {
            const bubbleTypes = [
              MessageType.TEXT,
              MessageType.MEDIA,
              MessageType.PAUSE,
              MessageType.WEBSITE,
              MessageType.FILE,
            ];

            return !isEmpty(msg) && bubbleTypes.includes(msg?.type);
          })
          .map((msg) => getMessageRow(msg)),
        richAssetType: embedType,
        richAssetContent: getEmbedContent(node.embeds, embedType) || {},
        behaviors: getBehaviors(node),
      },
      nluRouting: {
        intent: node.NLU?.intent ?? "",
        entity: node.NLU?.entity ?? "",
        type: node.NLU?.entityType ?? "",
      },
      nodeTag: "",
      skillTag: "",
      platformFlag: "",
      flows: [],
      metadata: {
        parent:
          !node.parent || node.parent === "n0"
            ? null
            : Number(node.parent.slice(1)),
        children: (node.children || []).map((child) => child.number),
        usecase: "main",
        collapsed: false,
        isWorkflow: node.isWorkflow || false,
      },
    };
    if (isEmpty(res.nodeContent.messages)) {
      res.nodeContent.messages = [
        {
          messageType: "text",
          message: "",
        },
      ];
    }
  } else if (node.type === NodeType.ACTION) {
    const decisionVar = node.action?.conditions?.[0]?.condition?.variable;

    res = {
      number: node.number,
      type: nodeType,
      name: getFileNameWithoutExtension(node.action?.script),
      nodeContent: {
        scriptName: getFileNameWithoutExtension(node.action?.script),
        description: node.action?.description,
        input: {
          globalVariables: (node.action?.inputs?.global_variable || []).map(
            (vr) => vr.value
          ),
          localVariables: [],
          staticParameters: node.action?.inputs?.parameters
            ? JSON.parse(node.action?.inputs?.parameters)
            : {},
        },
        output: (node.action?.outputs_variables || []).map((v) => v.value),
        decisionVar,
        outputMapping: (node.action?.conditions || []).map((cond) => ({
          value: cond?.condition?.value,
          dest: cond?.condition?.link?.number,
        })),
      },
      nluRouting: {
        intent: node.NLU?.intent ?? "",
        entity: node.NLU?.entity ?? "",
        type: node.NLU?.entityType ?? "",
      },
      nodeTag: "",
      skillTag: "",
      platformFlag: "",
      flows: [],
      metadata: {
        parent:
          !node.parent || node.parent === "n0"
            ? null
            : Number(node.parent.slice(1)),
        children: (node.children || []).map((child) => child.number),
        usecase: "main",
        collapsed: false,
        isWorkflow: node.isWorkflow || false,
      },
    };
  } else {
    res = {
      number: node.number,
      type: "",
      name: node.title,
      nodeContent: {},
      nluRouting: {
        intent: node.NLU?.intent ?? "",
        entity: node.NLU?.entity ?? "",
        type: node.NLU?.entityType ?? "",
      },
      nodeTag: "",
      skillTag: "",
      platformFlag: "",
      flows: [],
      metadata: {
        parent:
          !node.parent || node.parent === "n0"
            ? null
            : Number(node.parent.slice(1)),
        children: (node.children || []).map((child) => child.number),
        usecase: "main",
        collapsed: false,
        isWorkflow: node.isWorkflow || false,
      },
    };
  }

  return res;
};

const getNodeType = (nodeType) => {
  switch (nodeType) {
    case NodeType.ACTION:
      return "A";
    case NodeType.DECISION:
      return "D";
    default:
      return "";
  }
};

const getMessageRow = (message) => {
  let res = {};
  let isVideo = false;

  switch (message.type) {
    case MessageType.TEXT:
      res = {
        messageType: message.type,
        message: message.data?.text,
      };
      break;
    case MessageType.MEDIA:
      isVideo = getFileCategory(getFileType(message.data?.image)) === "video";
      res = {
        messageType: isVideo ? "video" : message.type,
        type: "static",
        url: message.data?.image,
        alt_text: message.data?.text,
      };
      break;
    case MessageType.FILE:
      res = {
        messageType: message.type,
        type: "static",
        url: message.data?.text,
      };
      break;
    case MessageType.PAUSE:
      res = {
        type: message.type,
      };
      break;
    case MessageType.WEBSITE:
      res = {
        messageType: message.type,
        type: "static",
        url: message.data?.website,
        label: message.data?.text ?? "",
        inline: !message.data?.expanded || !message.data?.text,
      };
      break;
    case MessageType.DELETED:
      res = {
        type: message.type,
      };
      break;
    default:
  }

  return res;
};

const getEmbedType = (node) => {
  let type = "";
  let innerType = "";
  const { embeds, textfield } = node || {};

  switch (embeds?.type) {
    case EmbedType.BUTTONS:
      innerType = embeds[EmbedType.BUTTONS]?.type ?? "";
      if (innerType === "images") {
        type = "imagebutton";
      } else if (innerType === "text") {
        type = "quick_reply";
      }
      break;
    case EmbedType.LIST_PICKER:
      type = "listpicker";
      break;
    case EmbedType.CAROUSEL:
      type = "carousel";
      break;
    case EmbedType.OTHER:
      if (embeds[EmbedType.OTHER]?.embedType === EmbedType.DATE_PICKER) {
        type = "datepicker";
      }
      break;
    case EmbedType.NONE:
      if (textfield) {
        const { data, enabled } = textfield[textfield.type] || {};

        if (enabled && data?.checkedInfo) {
          type = "secure";
        } else {
          type = "";
        }
      } else {
        type = "";
      }
      break;
    default:
      break;
  }

  return type;
};

const getBehaviors = (node) => {
  let behaviors = [];
  let settings = null;

  if (node?.embeds?.type) {
    const { enabled: textFieldEnabled } =
      (node.textfield || {})[node.textfield?.type] || {};

    switch (node?.embeds?.type) {
      case EmbedType.BUTTONS:
      case EmbedType.CAROUSEL:
      case EmbedType.OTHER:
      case EmbedType.NONE:
        behaviors = textFieldEnabled ? [] : ["disable_input"];
        break;
      case EmbedType.LIST_PICKER:
        settings = (node?.embeds[EmbedType.LIST_PICKER].settings || {})[
          node?.embeds[EmbedType.LIST_PICKER].type
        ];

        if (settings?.allowMultiSelect) {
          behaviors.push("multiple_choice");
        }

        if (!textFieldEnabled) {
          behaviors.push("disable_input");
        }
        break;
      default:
        break;
    }
  }

  return behaviors;
};

const getEmbedContent = (embeds, embedType) => {
  let res = null;

  if (embeds && embeds[embeds.type]) {
    switch (embeds?.type) {
      case EmbedType.BUTTONS:
        res = {
          type:
            embeds[EmbedType.BUTTONS].type === "dynamic" ? "dynamic" : "static",
          ...(embeds[EmbedType.BUTTONS].type === "dynamic"
            ? {
                source_node:
                  embeds[EmbedType.BUTTONS]?.dynamic?.sourceNode?.number ?? 0,
                source_var: embeds[EmbedType.BUTTONS]?.dynamic?.sourceVar ?? "",
              }
            : {
                options: (
                  embeds[EmbedType.BUTTONS][embeds[EmbedType.BUTTONS].type] ||
                  []
                )
                  .filter((em) => em.label && (em?.link?.number ?? 0))
                  .map((em) => ({
                    label: em?.label,
                    dest: em?.link?.number ?? 0,
                    image: em?.image ?? "",
                  })),
              }),
        };
        break;
      case EmbedType.LIST_PICKER:
        res = {
          type:
            embeds[EmbedType.LIST_PICKER].type === "dynamic"
              ? "dynamic"
              : "static",
          ...(embeds[EmbedType.LIST_PICKER].type === "dynamic"
            ? {
                source_node:
                  embeds[EmbedType.LIST_PICKER]?.dynamic?.sourceNode?.number ??
                  0,
                source_var:
                  embeds[EmbedType.LIST_PICKER]?.dynamic?.sourceVar ?? "",
              }
            : {
                options: (
                  embeds[EmbedType.LIST_PICKER][
                    embeds[EmbedType.LIST_PICKER].type
                  ] || []
                )
                  .filter((em) => {
                    const settings = (embeds[EmbedType.LIST_PICKER].settings ||
                      {})[embeds[EmbedType.LIST_PICKER].type];

                    if (settings?.allowMultiSelect) {
                      return true;
                    }
                    return em.label && em.link && em.link.number;
                  })
                  .map((em) => {
                    let linkTo = "";

                    const settings = (embeds[EmbedType.LIST_PICKER].settings ||
                      {})[embeds[EmbedType.LIST_PICKER].type];

                    if (settings?.allowMultiSelect) {
                      linkTo = (settings?.linkTo?.number ?? "").toString();
                    } else {
                      linkTo = (em?.link?.number ?? "").toString();
                    }

                    return {
                      label: em?.label ?? "",
                      dest: linkTo,
                      image: em?.image ?? "",
                      description: em?.description ?? "",
                    };
                  }),
              }),
        };
        break;
      case EmbedType.CAROUSEL:
        res =
          embeds[EmbedType.CAROUSEL].type === "dynamic"
            ? {
                type: "dynamic",
                source_node:
                  embeds[EmbedType.CAROUSEL]?.dynamic?.sourceNode?.number ??
                  null,
                source_var:
                  embeds[EmbedType.CAROUSEL]?.dynamic?.sourceVar ?? null,
              }
            : (embeds[EmbedType.CAROUSEL]?.standard ?? []).map((car) => {
                return {
                  title: car?.label ?? "",
                  image: car?.image ?? "",
                  subtitle: car?.subTitleEnabled ? car?.subTitle : "",
                  opt_text: car?.descriptionEnabled ? car?.description : "",
                  label: car?.buttonLabelEnabled ? car?.buttonLabel : "Select",
                  dest: (car?.link?.number ?? "").toString() ?? "",
                  url: "",
                  // additional_cta: [], // Disabling since it's not supported yet.
                };
              });
        if (isEmpty(res)) {
          res = {};
        }
        break;
      case EmbedType.OTHER:
        if (embeds[EmbedType.OTHER].embedType === EmbedType.DATE_PICKER) {
          res = {
            type: "static",
            message: embeds[EmbedType.OTHER]?.buttonLabel ?? "",
          };
        }
        break;
      case EmbedType.NONE:
        if (embedType === "secure") {
          res = {
            type: "static",
            message: "",
          };
        }
        break;
      default:
        break;
    }
  }

  return res;
};
