import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { deleteFromCustomer, setNotification } from "../../Store/actions";
import botManagerAPI from "../../../bot-manager-api";
import { DataModal, TextField } from "ui-elements";

const DeleteSolutionModal = ({
  open,
  bot,
  onClose,
  deleteFromCustomer,
  setNotification,
  ...otherProps
}) => {
  const [deleteDisabled, setDeleteDisabled] = useState(true);
  const [solutionName, setSolutionName] = useState("");

  const onDeleteSolution = async () => {
    try {
      const { history } = otherProps;
      setDeleteDisabled(true);
      await botManagerAPI.deleteSolution(bot.id);
      deleteFromCustomer(bot.customerName, bot.id);
      setNotification({
        openNotification: true,
        notificationDuration: 3000,
        notificationTitle: `Microapp ${bot.botName} has been successfully deleted.`,
        notificationType: "success",
      });
      history.push(`/customer/${bot.customerName}`);
    } catch (error) {
      console.log(error);
      setDeleteDisabled(true);
    }
  };

  const onChange = (value) => {
    setSolutionName(value);
    setDeleteDisabled(value !== bot.botName);
  };

  return (
    <DataModal
      open={open}
      className="delete-solution-modal"
      okLabel="Delete"
      title="Are you sure?"
      disableToAdd={deleteDisabled}
      onAdd={onDeleteSolution}
      onCancel={onClose}
      {...otherProps}
    >
      <div className="delete-solution-modal-container">
        <p>
          Deleting a microapp will remove it for all users. It is permanent and
          will disable any interfaces in use by this microapp.
        </p>
        <p>
          {`If you are sure you'd like to delete it, please type ${bot.botName}
          below.`}
        </p>
        <TextField
          variant="outlined"
          placeholder={bot.botName}
          value={solutionName}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    </DataModal>
  );
};

DeleteSolutionModal.propTypes = {
  open: PropTypes.bool,
  bot: PropTypes.object,
  onClose: PropTypes.func,
  deleteFromCustomer: PropTypes.func,
  setNotification: PropTypes.func,
};

DeleteSolutionModal.defaultProps = {
  open: false,
  bot: {},
  onClose: () => {},
  deleteFromCustomer: () => {},
};

const mapDispatchToProps = (dispatch) => ({
  deleteFromCustomer: (customerId, botId) =>
    dispatch(deleteFromCustomer(customerId, botId)),
  setNotification: (data) => dispatch(setNotification(data)),
});

export default withRouter(
  connect(() => ({}), mapDispatchToProps)(DeleteSolutionModal)
);
