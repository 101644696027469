import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { IconNames, Icon, OutlinedButton, Button } from "ui-elements";
import { NLU_MODEL_STATUS } from "../defs";

const NLUTrainingState = ({
  state,
  className,
  training,
  onTrainSolution,
  onTestNLU,
}) => {
  const configs = {
    [NLU_MODEL_STATUS.NO_DATA]: {
      icon: IconNames.StatusOk,
      fill: "#32b777",
      title: "Your microapp doesn't need to be trained",
      desc: "You don't have any Intents or Entities so your microapp cannot be tested or trained.",
      hideTestNLU: true,
      disableTrain: true,
    },
    [NLU_MODEL_STATUS.TRAINED]: {
      icon: IconNames.StatusOk,
      fill: "#32b777",
      title: "Your NLU model is up-to-date",
      desc: "Your most recent training data has been incorporated into your NLU model.",
      hideTestNLU: false,
      disableTrain: true,
    },
    [NLU_MODEL_STATUS.NOT_TRAINED]: {
      icon: IconNames.InputError,
      fill: "#e45f4f",
      title: "Your microapp needs to be trained",
      desc: "You've made changes to your training data which means that your microapp must be trained before these changes can be tested or published.",
      hideTestNLU: false,
      disableTrain: false,
    },
  };

  return (
    <div className={clsx(className, "nlu-training-state")}>
      <div className="nlu-training-state-top">
        <div
          className={clsx("nlu-training-state-icon", {
            warning: configs[state].icon === IconNames.InputError,
          })}
        >
          <Icon fill={configs[state].fill} name={configs[state].icon} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <h5>{configs[state].title}</h5>
          <h6>{configs[state].desc}</h6>
        </div>
      </div>
      <div
        className={clsx("nlu-training-state-actions", {
          loading: training,
        })}
      >
        {!configs[state].hideTestNLU && !training && (
          <OutlinedButton onClick={onTestNLU}>Test NLU</OutlinedButton>
        )}
        <Button
          className="train-solution-button"
          disabled={training}
          isLoading={training}
          onClick={onTrainSolution}
        >
          {training ? "" : "Train Microapp"}
        </Button>
      </div>
    </div>
  );
};

NLUTrainingState.propTypes = {
  className: PropTypes.string,
  state: PropTypes.string,
  training: PropTypes.bool,
  onTrainSolution: PropTypes.func,
  onTestNLU: PropTypes.func,
};

NLUTrainingState.defaultProps = {
  className: "",
  state: NLU_MODEL_STATUS.NO_DATA,
  training: false,
  onTrainSolution: () => {},
  onTestNLU: () => {},
};

export default NLUTrainingState;
