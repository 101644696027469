import React from "react";
import PropTypes from "prop-types";
import { Dropdown, IconButton, IconNames, Nav, Tooltip } from "ui-elements";
import { userLogout } from "bot-user-session";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { BOT_DIALOG, BOT_MONITOR } from "../Manager/Components/Bot/defs";
import PreviewPublishNav from "./PreviewPublishNav";

function Header({ showProfile, bots, customer, botId, userLogout, flags }) {
  const signOutButton = (
    <Dropdown.Item key="sign-out" onClick={userLogout}>
      Sign Out
    </Dropdown.Item>
  );
  const menu = showProfile
    ? [
        <Dropdown.Item
          key="profile"
          component="a"
          href="/console/profile"
          target="_blank"
        >
          Profile
        </Dropdown.Item>,
        signOutButton,
      ]
    : [signOutButton];
  const botsMenu = bots.map((bot) => (
    <Dropdown.Item
      to={`/bot/${customer.id}/${bot.name}`}
      key={bot.name}
      component={Link}
    >
      {bot.name}
    </Dropdown.Item>
  ));
  const isMonitorTab = window.location.href.includes(`tab=${BOT_MONITOR}`);
  return (
    <Nav>
      <Nav.Logo />
      <Nav.Product>Builder</Nav.Product>
      <Nav.SolutionDropdown>
        {customer ? (
          <Dropdown
            menu={[
              ...botsMenu,
              <Dropdown.Divider key="menu-solutions-divider" />,
              <Dropdown.Item
                key="AllCustomers"
                component={Link}
                to="/customers"
              >
                All Customers
              </Dropdown.Item>,
            ]}
          >
            {(open) => (
              <Nav.SolutionDropdownTrigger open={open}>
                {customer.customerName}
              </Nav.SolutionDropdownTrigger>
            )}
          </Dropdown>
        ) : null}
      </Nav.SolutionDropdown>
      <Nav.Links>
        {customer && botId ? (
          <>
            <Nav.LinksItem>
              <Link to={`/bot/${customer.id}/${botId}?tab=${BOT_DIALOG}`}>
                <Nav.LinksItemInner active={!isMonitorTab}>
                  Manage
                </Nav.LinksItemInner>
              </Link>
            </Nav.LinksItem>
            <Nav.LinksItem>
              <a href={`/solution/${customer.id}/${botId}`}>
                <Nav.LinksItemInner>Build</Nav.LinksItemInner>
              </a>
            </Nav.LinksItem>
            {flags.pe18092 && (
              <Nav.LinksItem>
                <Link to={`/bot/${customer.id}/${botId}?tab=${BOT_MONITOR}`}>
                  <Nav.LinksItemInner active={isMonitorTab}>
                    Monitor
                  </Nav.LinksItemInner>
                </Link>
              </Nav.LinksItem>
            )}
          </>
        ) : null}
      </Nav.Links>
      <Nav.Extra>
        {customer && customer.id && botId && (
          <PreviewPublishNav customerId={customer.id} botId={botId} />
        )}
      </Nav.Extra>
      <Nav.Profile>
        <Dropdown menu={menu} anchorHorizontalOrigin="right">
          {(open) => (
            <Tooltip label="Account">
              <IconButton dark iconName={IconNames.Profile} active={open} />
            </Tooltip>
          )}
        </Dropdown>
      </Nav.Profile>
    </Nav>
  );
}

Header.propTypes = {
  userLogout: PropTypes.func,
  bots: PropTypes.array,
  customer: PropTypes.object,
  flags: PropTypes.object,
  showProfile: PropTypes.bool,
  botId: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  userLogout() {
    dispatch(userLogout());
  },
});

const mapStateToProps = (state, ownProps) => {
  let bots = [];
  const { customerId, botId } = ownProps.computedMatch.params;
  const customer = state.manager.customers[customerId];
  if (customer && customer.bots) {
    bots = customer.bots
      .sort()
      .map((bot) => ({ name: bot.substr(customer.id.length + 1) }));
  }

  return {
    customer,
    botId,
    bots,
    customerId,
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
