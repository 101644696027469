import React from "react";
import PropTypes from "prop-types";

const RadioComponent = ({ checked, index, channel, name, onChange }) => (
  <div className="radio">
    <label>
      <input
        type="radio"
        checked={checked}
        onChange={() => {
          onChange(channel, index);
        }}
      />
      <span className="radiomark">{name}</span>
    </label>
  </div>
);

RadioComponent.propTypes = {
  checked: PropTypes.bool,
  index: PropTypes.number,
  channel: PropTypes.any,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

RadioComponent.defaultProps = {
  checked: false,
  index: 0,
  channel: null,
  name: "",
  onChange: () => {},
};

export { RadioComponent };
