import { Modal, ModalFooter } from "react-bootstrap";
import { Button } from "ui-elements";
import React from "react";
import Loader from "../Loader";
import PropTypes from "prop-types";

const StopModal = ({
  show,
  stopWordString,
  onCancel,
  onConfirm,
  handleStopWordChange,
  isSaving,
  isLoading,
}) => (
  <Modal show={show} className="intent_modal">
    <Modal.Header className="intent_modal_header">
      <Modal.Title>Stopwords</Modal.Title>
    </Modal.Header>
    <Modal.Body className="intent_modal_body">
      {
        "Stopwords are common words that are removed from the NLU training data as a part of NLU processing. Below is a list which you can edit, delete or add new stopwords to."
      }
      <br />
      <br />
      {isLoading ? (
        <Loader center={false} />
      ) : (
        <>
          <span className="stopword_header">Stopwords</span>
          <textarea
            className="stopword_area"
            value={stopWordString}
            onChange={(e) => handleStopWordChange(e)}
          ></textarea>
        </>
      )}
    </Modal.Body>

    <ModalFooter className="intent_modal_footer">
      <Button className="stopword_cancel_button" hollow onClick={onCancel}>
        Cancel
      </Button>
      <Button onClick={onConfirm} isLoading={isSaving} disabled={isLoading}>
        Save
      </Button>
    </ModalFooter>
  </Modal>
);

StopModal.propTypes = {
  show: PropTypes.bool,
  stopWordString: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  handleStopWordChange: PropTypes.func,
  isSaving: PropTypes.bool,
  isLoading: PropTypes.bool,
};

StopModal.defaultProps = {
  show: false,
  stopWordString: "",
  onCancel: () => {},
  onConfirm: () => {},
  handleStopWordChange: () => {},
  isSaving: false,
  isLoading: true,
};

export { StopModal };
