import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  ProgressBar,
  Glyphicon,
  Modal,
  FormControl,
} from "react-bootstrap";
import PropTypes from "prop-types";
import {
  isPermitted,
  PermissionAction,
  PermissionResource,
} from "bot-user-session";
import { DataTable } from "ui-elements";
import botManagerAPI from "../../../bot-manager-api";
import config from "../../../config";
import { getMaxTableHeight } from "../../utils/utils";
import { ACTION_NODE_URL } from "./defs";
import activityTracker from "activityTracker";
import { doesUserHasRole, SUPER_ADMIN_ROLE } from "bot-user-session";
import { setNotification } from "Manager/Store/actions";

class Files extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUploadModal: false,
      uploadProgress: 0,
      assetCopyIndicators: [],
      filesTableMaxHeight: "323px",
      filesFilter: "",
      fileName: "",
      showDeleteModal: false,
      fileToDelete: {},
      isCurrentlyDeleting: false,
      isFilesLoading: false,
    };
    this.openUploadModal = this.openUploadModal.bind(this);
    this.closeUploadModal = this.closeUploadModal.bind(this);
    this.startFileUpload = this.startFileUpload.bind(this);
    this.updateFilesFilter = this.updateFilesFilter.bind(this);
    this.deleteFormatter = this.deleteFormatter.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.copyAndDeleteFormatter = this.copyAndDeleteFormatter.bind(this);
    this.fileNameFormatter = this.fileNameFormatter.bind(this);
    this.handleFileDelete = this.handleFileDelete.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.openMedia = this.openMedia.bind(this);
    this.openFile = this.openFile.bind(this);
  }

  componentDidMount() {
    const { assets } = this.props;

    this.setState({
      filesTableMaxHeight: getMaxTableHeight(this.filesTable),
      isFilesLoading: true,
    });

    const assetsData = [];

    if (assets && Array.isArray(assets)) {
      const filter = this.state.filesFilter.toLowerCase();
      assets.forEach((a, index) => {
        let name = a;
        if (name.toLowerCase().indexOf(filter) > -1) {
          let type = "";
          const lastDot = a.lastIndexOf(".");
          if (lastDot) {
            name = a.substr(0, lastDot);
            type = a.substr(lastDot + 1);
          }
          assetsData.push({
            name,
            type,
            asset: a,
            index,
          });
        }
      });
    }

    this.setState({ assetsData, isFilesLoading: false });
  }

  openUploadModal() {
    this.setState({
      uploadProgress: 0,
      showUploadModal: true,
    });
  }

  closeUploadModal() {
    this.setState({
      showUploadModal: false,
      hasUploadError: false,
      errorInfo: "",
    });
  }

  updateFilesFilter(event) {
    this.setState({ filesFilter: event.target.value });
  }

  async startFileUpload(event) {
    const onUploadProgress = (e) => {
      this.setState({ uploadProgress: e });
    };
    let files = [];
    let file = {};
    const formData = new FormData();
    if (event.target.files.length === 1) {
      const file = event.target.files[0];
      this.setState({ fileName: file.name });
      formData.append("assetData", file);
    } else {
      for (let i = 0; i < event.target.files.length; i++) {
        files.push(event.target.files[i]);
        if (i + 1 === event.target.files.length) {
          for (let j = 0; j < files.length; j++) {
            formData.append("assetData", files[j]);
            this.setState({ fileName: "" });
          }
        }
      }
    }

    this.openUploadModal();

    try {
      const { status, data } = await botManagerAPI.uploadBotAsset(
        `${this.props.customerId}.${this.props.botId}`,
        formData,
        onUploadProgress
      );
      if (status === 200) {
        this.setState({
          templateUploadSuccessfully: true,
        });

        this.state.fileName !== ""
          ? this.props.setNotification({
              openNotification: true,
              notificationDuration: 3000,
              notificationTitle: `${this.state.fileName} was uploaded!`,
              notificationType: "success",
            })
          : this.props.setNotification({
              openNotification: true,
              notificationDuration: 3000,
              notificationTitle: `All files were uploaded!`,
              notificationType: "success",
            });

        const assets = data.data.assets;
        const mappedAssets = assets.map((file) => {
          const extension = file.split(".")[file.split(".").length - 1];
          const indexOfExtension = file.indexOf(`.${extension}`);
          const slicedName = file.slice(0, indexOfExtension);
          return { name: slicedName, type: extension, asset: file };
        });
        this.setState({ assetsData: mappedAssets });

        activityTracker.logEvent(
          activityTracker.eventTypeNames.UPLOAD_MEDIA_FILE,
          {
            fileSize: file.size,
            fileType: file.type,
            solutionName: `${this.props.customerId}.${this.props.botId}`,
          }
        );
      } else {
        this.setState({
          templateUploadSuccessfully: false,
          hasUploadError: true,
          errorInfo: data.errors,
        });
      }
      this.closeUploadModal();
    } catch (err) {
      console.log(err);
      this.props.setNotification({
        openNotification: true,
        notificationDuration: 3000,
        notificationTitle: err ? err : "Server Error",
        notificationType: "error",
      });
      this.closeUploadModal();
    }
  }

  copyLinkPress(row) {
    return () => {
      const link = `${config.s3bucketUrl}/${this.props.customerId}/${this.props.botId}/assets/${row.asset}`;
      const textArea = document.createElement("textarea");

      // Place in top-left corner of screen regardless of scroll position.
      textArea.style.position = "fixed";
      textArea.style.top = 0;
      textArea.style.left = 0;

      // Ensure it has a small width and height. Setting to 1px / 1em
      // doesn't work as this gives a negative w/h on some browsers.
      textArea.style.width = "2em";
      textArea.style.height = "2em";

      // We don't need padding, reducing the size if it does flash render.
      textArea.style.padding = 0;

      // Clean up any borders.
      textArea.style.border = "none";
      textArea.style.outline = "none";
      textArea.style.boxShadow = "none";

      // Avoid flash of white box if rendered for any reason.
      textArea.style.background = "transparent";

      textArea.value = link;

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      let res = false;
      try {
        res = document.execCommand("copy");
      } catch (err) {
        res = false;
      }

      document.body.removeChild(textArea);
      if (!res) {
        this.props.setNotification({
          openNotification: true,
          notificationDuration: 3000,
          notificationTitle: `Something went wrong. Can't copy the following URL to your clipboard: ${link}`,
          notificationType: "error",
        });
      }

      const assetCopyIndicators = this.state.assetCopyIndicators.splice(0);
      assetCopyIndicators[row.index] = true;
      this.setState({ assetCopyIndicators });
      setTimeout(() => {
        const assetCopyIndicators = this.state.assetCopyIndicators.splice(0);
        assetCopyIndicators[row.index] = false;
        this.setState({ assetCopyIndicators });
      }, 3000);
    };
  }

  deleteFormatter(cell, row) {
    return (
      <div onClick={() => this.deleteFile(row)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="delete-trashcan"
        >
          <g fillRule="evenodd">
            <circle cx="12" cy="12" r="12" fill="#FFF" />
            <path
              fill="#0A3AB4"
              d="M15.628 20c.757 0 1.426-.612 1.494-1.366L18.159 7h.591c.138 0 .25-.112.25-.25v-.5c0-.138-.112-.25-.25-.25H15.5l-1.05-1.4c-.248-.331-.786-.6-1.2-.6h-2.5c-.414 0-.952.269-1.2.6L8.5 6H5.25c-.138 0-.25.112-.25.25v.5c0 .138.112.25.25.25h.59l1.038 11.634c.068.754.737 1.366 1.494 1.366h7.256zM14.25 6h-4.5l.6-.8c.083-.11.262-.2.4-.2h2.5c.138 0 .317.09.4.2l.6.8zm1.38 13H8.37c-.252 0-.474-.204-.495-.456L6.844 7h10.312l-1.031 11.544c-.02.252-.243.456-.496.456z"
            />
          </g>
        </svg>
      </div>
    );
  }

  deleteFile(file) {
    this.setState({ fileToDelete: file });
    try {
      this.openDeleteModal();
    } catch (e) {
      console.error(e);
    }
  }

  openDeleteModal() {
    this.setState({ showDeleteModal: true });
  }

  closeDeleteModal() {
    this.setState({ showDeleteModal: false });
  }

  openFile(row) {
    const link = `${config.s3bucketUrl}/${this.props.customerId}/${this.props.botId}/assets/${row.asset}`;

    window.open(link, "_blank");
  }

  fileNameFormatter(cell, row) {
    return (
      <div onClick={() => this.openFile(row)}>
        {row.name.length > 38 ? `${row.name.slice(0, 37)}...` : row.name}
      </div>
    );
  }

  copyAndDeleteFormatter(cell, row) {
    // const copied = this.state.assetCopyIndicators[row.index];
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <svg
          className="copy-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          onClick={this.copyLinkPress(row)}
        >
          <g fill="none" fillRule="evenodd">
            <g>
              <g transform="translate(-452 -252) translate(452 252)">
                <circle cx="12" cy="12" r="12" fill="#FFF" />
                <path
                  fill="#0A3AB4"
                  d="M15.5 20c.828 0 1.5-.672 1.5-1.5V17h1.5c.828 0 1.5-.672 1.5-1.5v-10c0-.828-.672-1.5-1.5-1.5h-10C7.672 4 7 4.672 7 5.5V7H5.5C4.672 7 4 7.672 4 8.5v10c0 .828.672 1.5 1.5 1.5h10zm3-4h-10c-.276 0-.5-.224-.5-.5v-10c0-.276.224-.5.5-.5h10c.276 0 .5.224.5.5v10c0 .276-.224.5-.5.5zm-3 3h-10c-.276 0-.5-.224-.5-.5v-10c0-.276.224-.5.5-.5H7v7.5c0 .828.672 1.5 1.5 1.5H16v1.5c0 .276-.224.5-.5.5z"
                />
              </g>
            </g>
          </g>
        </svg>
        {doesUserHasRole(SUPER_ADMIN_ROLE) && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="delete-trashcan"
            onClick={() => this.deleteFile(row)}
          >
            <g fillRule="evenodd">
              <circle cx="12" cy="12" r="12" fill="#FFF" />
              <path
                fill="#0A3AB4"
                d="M15.628 20c.757 0 1.426-.612 1.494-1.366L18.159 7h.591c.138 0 .25-.112.25-.25v-.5c0-.138-.112-.25-.25-.25H15.5l-1.05-1.4c-.248-.331-.786-.6-1.2-.6h-2.5c-.414 0-.952.269-1.2.6L8.5 6H5.25c-.138 0-.25.112-.25.25v.5c0 .138.112.25.25.25h.59l1.038 11.634c.068.754.737 1.366 1.494 1.366h7.256zM14.25 6h-4.5l.6-.8c.083-.11.262-.2.4-.2h2.5c.138 0 .317.09.4.2l.6.8zm1.38 13H8.37c-.252 0-.474-.204-.495-.456L6.844 7h10.312l-1.031 11.544c-.02.252-.243.456-.496.456z"
              />
            </g>
          </svg>
        )}
      </div>
    );
  }

  async handleFileDelete() {
    const { assetsData, fileToDelete } = this.state;
    const { customerId, botId } = this.props;
    this.setState({
      isCurrentlyDeleting: true,
    });
    try {
      botManagerAPI
        .deleteMediaFile(customerId, botId, fileToDelete.asset)
        .then(async () => {
          this.closeDeleteModal();
          this.setState({
            fileToDelete: {},
            isCurrentlyDeleting: false,
            assetsData: assetsData.filter(
              (file) => file.asset !== fileToDelete.asset
            ),
          });
        })
        .catch(async (e) => {
          console.error(e);
          this.setState({
            errorInfo: e,
          });
          this.closeDeleteModal();
        });
    } catch (err) {
      this.setState({
        errorInfo: err.message,
      });
      this.closeUploadModal();
    }
  }

  uploadFile() {
    this.upload.click();
  }

  openMedia(asset) {
    const link = `${config.s3bucketUrl}/${this.props.customerId}/${this.props.botId}/assets/${asset}`;
    window.open(link);
  }

  render() {
    const {
      fileName,
      uploadProgress,
      filesFilter,
      hasUploadError,
      errorInfo,
      showDeleteModal,
      assetsData,
      isFilesLoading,
    } = this.state;
    const { show, customerGesId } = this.props;
    const { copyAndDeleteFormatter } = this;
    const uploadProgressActive = uploadProgress > 0 && uploadProgress < 100;
    const uploadProgressDone = uploadProgress === 100;
    const isWritePermitted = isPermitted(
      PermissionResource.BOT,
      PermissionAction.WRITE,
      customerGesId
    );

    if (!show) {
      return null;
    }

    const Columns = [
      {
        title: "File Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        hover: true,
        render: (data) => this.fileNameFormatter({}, data),
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        sorter: (a, b) => a.type.localeCompare(b.type),
        render: (data) => <div style={{ fontWeight: 600 }}>{data.type}</div>,
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
        render: (data) => copyAndDeleteFormatter({}, data),
      },
    ];

    return (
      <div>
        <div className="section-title">
          <h3>Media and Files</h3>
        </div>
        <p>
          In order for your microapp to display images, documents, or videos to
          the user, these assets must first be uploaded here. The asset’s URL
          can then be included within the microapp csv file. For more
          information regarding acceptable file formats and restrictions on file
          size, please refer to{" "}
          <a href={ACTION_NODE_URL} target="_blank" rel="noopener noreferrer">
            {" "}
            Designing Microapps
          </a>
          .
        </p>

        {!this.state.showUploadModal && (
          <input
            id="myInput"
            type="file"
            ref={(ref) => (this.upload = ref)}
            style={{ display: "none" }}
            onChange={this.startFileUpload}
            multiple
          />
        )}
        <div
          className="container-table-header"
          style={{ flexDirection: "column" }}
        >
          <div className="inner-addon right-addon" style={{ display: "none" }}>
            <Glyphicon
              glyph="glyphicon glyphicon-search"
              style={{ color: "#043ac4" }}
            />
            <FormControl
              type="text"
              value={filesFilter}
              placeholder="Search for a file"
              onChange={(e) => this.updateFilesFilter(e)}
              style={{ width: 312 }}
            />
          </div>
          {isWritePermitted && (
            <Button
              bsStyle="primary"
              className="main-button"
              onClick={this.uploadFile}
              style={{ alignSelf: "flex-end" }}
            >
              <Glyphicon glyph="glyphicon glyphicon-arrow-up" />
              Upload File
            </Button>
          )}
        </div>

        {!isFilesLoading && (!assetsData || !assetsData.length) ? (
          <>
            <br />
            {!filesFilter ? (
              <i>
                {" "}
                You have not uploaded any media files yet.
                {isWritePermitted && (
                  <span>
                    {" "}
                    Would you like to{" "}
                    <span className="as-a-link" onClick={this.uploadFile}>
                      upload a file
                    </span>
                    ?
                  </span>
                )}
              </i>
            ) : (
              <i> There are no results matching that term, please try again.</i>
            )}
          </>
        ) : (
          <DataTable
            columns={Columns}
            dataSource={assetsData || []}
            isLoading={isFilesLoading}
            rowKey="index"
            bordered={false}
          />
        )}

        <Modal
          show={this.state.showUploadModal}
          bsClass="manager modal"
          className={hasUploadError ? "error-modal" : ""}
          onHide={this.closeUploadModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Upload File</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProgressBar
              now={this.state.uploadProgress}
              active
              label={`${this.state.uploadProgress}%`}
            />
            <div
              className={`bot-upload-modal-line ${
                uploadProgressActive > 0 ? "active" : ""
              }`}
            >
              <div>Uploading {fileName}...</div>
              {hasUploadError ? (
                <div>
                  Failed!&nbsp;&nbsp;
                  <Glyphicon glyph="glyphicon glyphicon-exclamation-sign error" />
                </div>
              ) : (
                <div>
                  {uploadProgress < 100 ? `${uploadProgress}%` : "Complete!"}
                  {uploadProgressDone && (
                    <Glyphicon glyph="glyphicon glyphicon-ok-sign" />
                  )}
                </div>
              )}
            </div>
            {hasUploadError && (
              <div className="error-box">
                {errorInfo && errorInfo.assetData}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeUploadModal} bsStyle="primary">
              Hide
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Delete Modal */}
        <Modal
          show={showDeleteModal}
          bsClass="manager modal"
          onHide={this.closeDeleteModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton={!this.state.isCurrentlyDeleting}>
            <Modal.Title
              style={{
                fontFamily: "Open Sans",
                fontSize: "24px",
                fontWeight: 600,
              }}
            >
              Delete {this.state.fileToDelete.asset}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ fontFamily: "Open Sans", fontSize: "16px" }}>
              Are you sure you want to delete {this.state.fileToDelete.asset}?
              This could potentially remove this asset from existing published
              microapps.
            </p>
          </Modal.Body>
          <Modal.Footer>
            {!this.state.isCurrentlyDeleting ? (
              <Button onClick={this.closeDeleteModal} type="button">
                Cancel
              </Button>
            ) : null}
            <Button
              onClick={this.handleFileDelete}
              bsStyle="primary"
              isLoading={this.state.isCurrentlyDeleting}
              disabled={this.state.isCurrentlyDeleting}
            >
              {this.state.isCurrentlyDeleting ? "Deleting..." : "Delete"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setNotification: (data) => dispatch(setNotification(data)),
  };
};

Files.propTypes = {
  show: PropTypes.bool,
  assets: PropTypes.array,
  customerId: PropTypes.string,
  botId: PropTypes.string,
  customerGesId: PropTypes.string,
  setNotification: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(Files);
