import Papa from "papaparse";

export function isASCIILetter(name) {
  return !name.match(/[^a-zA-Z]/g);
}

export function isASCIIAlphanumeric(name) {
  return !name.match(/[^a-zA-Z0-9]/g);
}

export function isASCIIAlphanumericWithSpace(name) {
  return !name.match(/[^a-zA-Z0-9 ]/g);
}

export function isValidPhoneFormat(phone) {
  if (phone && phone.indexOf("+") === 0) {
    //check is valid phone number format
    // return phone.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/); future change to implement using temp fix
    // }
    const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    return re.test(phone);
  }

  return false;
}

export function firstLetterCaps(name) {
  if (!name) return "";

  const itemName = name.split("");
  itemName[0] = itemName[0].toUpperCase();
  return itemName.join("");
}

export const botValidationMessages = {
  None: "",
  CREATED_SUCCESSFULLY: "Microapp created successfully!",
  ID_ALREADY_EXIST: "A microapp already exists with this name.",
  FIRST_LETTER_ISSUE: "Microapp name must start with a capital letter.",
};

export const validateFileHeaders = (file, headers) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const scvFileAsText = event.target.result;
      // get the first line
      const endOfLine = scvFileAsText.indexOf("\n");
      if (endOfLine) {
        const firstLine = scvFileAsText.substr(0, endOfLine);
        if (headers.every((header) => firstLine.indexOf(header) >= 0)) {
          resolve(true);
          return;
        }
      }
      resolve(false);
    };
    reader.onerror = function () {
      resolve(false);
    };
    reader.readAsText(file);
  });
};

export const validateFileContent = (file, requiredColums) => {
  return new Promise((resolve, reject) => {
    const config = {
      header: true,
      dynamicTyping: true,
      delimiter: ",",
      complete(results) {
        const isValid = results.data.every((item) =>
          requiredColums.every((col) => !!item[col])
        );

        resolve(isValid);
      },
      skipEmptyLines: true,
      error: function () {
        console.error(arguments);
        reject(arguments);
      },
    };

    Papa.parse(file, config);
  });
};
