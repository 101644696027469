import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  ProgressBar,
  Glyphicon,
  Modal,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { Button, DataTable } from "ui-elements";
import {
  isPermitted,
  PermissionAction,
  PermissionResource,
} from "bot-user-session";
import { withRouter } from "react-router-dom";
import pLimit from "p-limit";

import botManagerAPI from "../../../bot-manager-api";
import { DESIGNING_NLU_BOTS_URL } from "./defs";
import { saveFile } from "../../utils/utils";
import activityTracker from "../../../activityTracker";
import { doesUserHasRole, BOT_MANAGER_ROLES } from "bot-user-session";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { setNotification, validateSolution } from "Manager/Store/actions";

const SCRIPTS_UNIT = 10;

const limit = pLimit(1);
class Actions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUploadModal: false,
      uploadProgress: 0,
      uploadingFileName: "",
      scriptsFiles: [],
      configFiles: [],
      showDeleteModal: false,
      fileToDelete: {},
      isCurrentlyDeleting: false,
      isLoadingActionNodes: false,
    };
    this.openUploadModal = this.openUploadModal.bind(this);
    this.closeUploadModal = this.closeUploadModal.bind(this);
    this.startFileUpload = this.startFileUpload.bind(this);
    this.uploadConfig = this.uploadConfig.bind(this);
    this.clickOnConfigUploadInput = this.clickOnConfigUploadInput.bind(this);
    this.clickOnScriptsUploadInput = this.clickOnScriptsUploadInput.bind(this);
    this.uploadScript = this.uploadScript.bind(this);
    this.uploadSupportedScript = this.uploadSupportedScript.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.openActionNodeLibrary = this.openActionNodeLibrary.bind(this);
    this.populateFiles = this.populateFiles.bind(this);
    this.handleScriptDelete = this.handleScriptDelete.bind(this);
    this.onScriptRowClick = this.onScriptRowClick.bind(this);
  }

  componentDidMount() {
    this.populateFiles();
  }

  openUploadModal() {
    this.setState({
      uploadProgress: 1,
      showUploadModal: true,
    });
  }

  openDeleteModal() {
    this.setState({ showDeleteModal: true });
  }

  closeUploadModal() {
    this.setState({ showUploadModal: false, errorInfo: null });
  }

  closeDeleteModal() {
    this.setState({ showDeleteModal: false });
  }

  async startFileUpload(file, propName, apiFunction) {
    const onUploadProgress = (e, step, allStepNum) => {
      this.setState({
        uploadProgress: Math.floor(e / allStepNum + (100 / allStepNum) * step),
      });
    };

    this.openUploadModal();
    let requests = [];
    if (file.length === undefined) {
      const formData = new FormData();
      formData.append(propName, file);
      requests.push(formData);
      this.setState({ uploadingFileName: file.name });
    } else {
      for (let i = 0; i < file.length; i = i + SCRIPTS_UNIT) {
        const formData = new FormData();
        for (let k = i; k < i + SCRIPTS_UNIT; k++) {
          if (k < file.length) {
            formData.append(propName, file[k]);
          }
        }
        requests.push(formData);
      }
      this.setState({ uploadingFileName: " " });
    }

    const draftVersion = this.props.botData.latestVersion.id;
    try {
      let requestNum = requests.length;
      const results = await Promise.all(
        requests.map((req, index) =>
          limit(() =>
            apiFunction(draftVersion, req, (progress) =>
              onUploadProgress(progress, index, requestNum)
            )
          )
        )
      );
      const success = results.every((res) => res.status === 200);
      if (success) {
        const { scriptsFiles, configFiles } = this.state;
        if (propName === "scriptFile") {
          if (Array.isArray(file)) {
            const newScripts = file
              .filter(
                (singleFile) =>
                  !scriptsFiles.find(
                    (scriptFile) => scriptFile.name === singleFile.name
                  )
              )
              .map((singleFile) => {
                return {
                  name: singleFile.name,
                  path: `services/${singleFile.name}`,
                };
              });
            this.setState({
              uploadProgress: 100,
              scriptsFiles: [...newScripts, ...scriptsFiles],
            });
          } else {
            const filePresent = scriptsFiles.find(
              (scriptFile) => scriptFile.name === file.name
            );
            const newScripts = filePresent
              ? [...scriptsFiles]
              : [
                  { name: file.name, path: `services/${file.name}` },
                  ...scriptsFiles,
                ];
            this.setState({
              uploadProgress: 100,
              scriptsFiles: newScripts,
            });
          }
        }
        if (propName === "configFile") {
          if (Array.isArray(file)) {
            const newConfigs = file
              .filter(
                (singleFile) =>
                  !configFiles.find(
                    (configFile) => configFile.name === singleFile.name
                  )
              )
              .map((singleFile) => {
                return {
                  name: singleFile.name,
                  path: `${singleFile.name}`,
                };
              });
            this.setState({
              configFiles: [...newConfigs, ...configFiles],
            });
          } else {
            const filePresent = configFiles.find(
              (configFile) => configFile.name === file.name
            );
            const newConfigs = filePresent
              ? [...configFiles]
              : [{ name: file.name, path: `${file.name}` }, ...configFiles];
            this.setState({
              configFiles: newConfigs,
            });
          }
        }

        file.length === undefined
          ? this.props.setNotification({
              openNotification: true,
              notificationDuration: 3000,
              notificationTitle: `You have successfully uploaded ${file.name}!`,
              notificationType: "success",
            })
          : this.props.setNotification({
              openNotification: true,
              notificationDuration: 3000,
              notificationTitle: `You have successfully uploaded all files!`,
              notificationType: "success",
            });

        setTimeout(() => this.closeUploadModal(), 1500);
        this.props.initBot();
        activityTracker.logEvent(
          propName === "configFile"
            ? activityTracker.eventTypeNames.UPLOAD_CONFIG
            : activityTracker.eventTypeNames.UPLOAD_SCRIPT,
          {
            fileSize: file.size,
            fileType: file.type,
            solutionName: `${this.props.customerId}.${this.props.botId}`,
          }
        );
        const {
          latestVersion: { id, version },
        } = this.props.botData;
        this.props.validateSolution(id, version, false);
      } else {
        let errorInfo = [];
        results.forEach((res) => {
          if (res.data.errors.scriptFile) {
            errorInfo = [...errorInfo, res.data.errors];
          } else {
            errorInfo = res.data;
          }
        });
        this.setState({ errorInfo });
      }
    } catch (err) {
      this.setState({
        errorInfo: err.message,
      });
      this.closeUploadModal();
    }
  }

  clickOnConfigUploadInput() {
    this.configUploadInput.click();
  }

  clickOnScriptsUploadInput() {
    this.scriptsUploadInput.click();
  }

  async uploadConfig() {
    const file = this.configUploadInput.files[0];
    await this.startFileUpload(file, "configFile", botManagerAPI.uploadConfig);
  }

  async uploadScript() {
    let files = [];
    let file = {};
    if (this.scriptsUploadInput.files.length === 1) {
      file = this.scriptsUploadInput.files[0];
    } else {
      for (let i = 0; i < this.scriptsUploadInput.files.length; i++) {
        files.push(this.scriptsUploadInput.files[i]);
      }
    }

    await this.startFileUpload(
      this.scriptsUploadInput.files.length > 1 ? files : file,
      "scriptFile",
      botManagerAPI.uploadScript
    );
  }

  async uploadSupportedScript() {
    let files = [];
    let file = {};
    let filteredFiles = [];

    for (let i = 0; i < this.scriptsUploadInput.files.length; i++) {
      const temp = this.scriptsUploadInput.files[i];

      if (temp.name && temp.name.endsWith(".py")) {
        filteredFiles.push(temp);
      }
    }

    if (filteredFiles.length !== this.scriptsUploadInput.files.length) {
      this.props.setNotification({
        openNotification: true,
        notificationDuration: 3000,
        notificationTitle: "Only .py files can be uploaded to Actions.",
        notificationType: "error",
      });
    } else if (filteredFiles.length) {
      if (filteredFiles.length === 1) {
        file = filteredFiles[0];
      } else {
        files = [...filteredFiles];
      }

      await this.startFileUpload(
        this.scriptsUploadInput.files.length > 1 ? files : file,
        "scriptFile",
        botManagerAPI.uploadScript
      );
    }
  }

  async downloadFile(file, e) {
    e.stopPropagation();
    try {
      const response = await botManagerAPI.downloadTemplateFile(
        `${this.props.latestVersion.id}`,
        file.path
      );
      saveFile(
        new Blob([response.file_data], { type: "text/pain" }),
        file.name
      );
    } catch (e) {
      console.error(e);
    }
  }

  async deleteFile(file, e) {
    e.stopPropagation();
    this.setState({ fileToDelete: file });
    try {
      this.openDeleteModal();
    } catch (e) {
      console.error(e);
    }
  }

  openActionNodeLibrary() {
    activityTracker.logEvent(
      activityTracker.eventTypeNames.VIEW_ACTION_NODE_LIBRARY
    );
    const url =
      "https://drive.google.com/drive/folders/1spRqDdhnehRU1IL5QAhpmk2Vniv08ung";
    window.open(url);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.latestVersion) {
      if (prevProps.latestVersion.files !== this.props.latestVersion.files) {
        this.populateFiles();
      }
    }
  }

  populateFiles() {
    const { latestVersion } = this.props;
    this.setState({ isLoadingActionNodes: true });
    if (latestVersion && latestVersion.files) {
      const serverFiles = Object.values(latestVersion.files);
      if (serverFiles.indexOf("app.js") > -1) {
        this.setState({
          configFiles: [{ name: "app.js", path: "app.js" }],
        });
      } else if (serverFiles.indexOf("app.py") > -1) {
        this.setState({
          configFiles: [{ name: "app.py", path: "app.py" }],
        });
      }

      let scriptsFiles = [];

      serverFiles.forEach((filePath) => {
        if (filePath.startsWith("services/")) {
          const constFileName = filePath.substr(9);
          if (constFileName) {
            scriptsFiles.push({ name: constFileName, path: filePath });
          }
        }
      });

      this.setState({
        scriptsFiles,
        isLoadingActionNodes: false,
      });
    }
  }

  async handleScriptDelete() {
    const { scriptsFiles, fileToDelete } = this.state;
    this.setState({
      isCurrentlyDeleting: true,
    });
    // eslint-disable-next-line no-unused-vars

    try {
      botManagerAPI
        .deleteActionNodeScript(
          `${this.props.latestVersion.id}`,
          fileToDelete.name
        )
        .then(async (res) => {
          if (res.status === 412) {
            this.setState({
              fileToDelete: {},
              isCurrentlyDeleting: false,
              errorInfo: res.data.errors,
            });
            this.closeDeleteModal();
            this.props.setNotification({
              openNotification: true,
              notificationDuration: 3000,
              notificationTitle: `There was an error deleting ${fileToDelete.name}!`,
              notificationType: "error",
            });
          } else {
            this.closeDeleteModal();
            this.setState({
              fileToDelete: {},
              isCurrentlyDeleting: false,
              scriptsFiles: scriptsFiles.filter(
                (script) => script.name !== fileToDelete.name
              ),
            });
            this.props.initBot();
            this.props.setNotification({
              openNotification: true,
              notificationDuration: 3000,
              notificationTitle: `You have successfully deleted ${fileToDelete.name}!`,
              notificationType: "success",
            });
            const {
              latestVersion: { id, version },
            } = this.props.botData;
            this.props.validateSolution(id, version, false);
          }
        })
        .catch((e) => console.error(e));
    } catch (err) {
      this.setState({
        errorInfo: err.message,
      });
      this.closeUploadModal();
    }
  }

  onScriptRowClick(row, typeOfFile) {
    this.props.history.push({
      pathname: `/bot/${this.props.customerId}/${this.props.botId}/editAction/${row.name}`,
      search: `?isConfig=${typeOfFile === "config"}`,
      state: { isOpen: true },
    });
  }

  render() {
    const { show, customerGesId } = this.props;
    const {
      showUploadModal,
      uploadProgress,
      uploadingFileName,
      errorInfo,
      scriptsFiles,
      configFiles,
      showDeleteModal,
      isLoadingActionNodes,
    } = this.state;
    if (!show) {
      return null;
    }
    const canEdit = isPermitted(
      PermissionResource.BOT,
      PermissionAction.WRITE,
      customerGesId
    );

    const scriptTollTip = (
      <OverlayTrigger
        overlay={
          <Tooltip id="scrip-tip" className="manager">
            Action node scripts allow you to include custom logic in your
            solution.
          </Tooltip>
        }
      >
        <span className="info">i</span>
      </OverlayTrigger>
    );

    const ConfigTollTip = (
      <OverlayTrigger
        overlay={
          <Tooltip id="config-tip" className="manager">
            Config is required for third-party services and manages storage and
            access for API endpoints, keys, and tokens.
          </Tooltip>
        }
      >
        <span className="info">i</span>
      </OverlayTrigger>
    );

    const hasUploadError = !!errorInfo;

    const deleteAndButtonFormatter = (cell, row, typeOfFile) => {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {doesUserHasRole(BOT_MANAGER_ROLES) && typeOfFile === "script" && (
            <div className="mr-3" onClick={(e) => this.deleteFile(row, e)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="delete-trashcan"
              >
                <g fillRule="evenodd">
                  <circle cx="12" cy="12" r="12" fill="#FFF" />
                  <path
                    fill="#0A3AB4"
                    d="M15.628 20c.757 0 1.426-.612 1.494-1.366L18.159 7h.591c.138 0 .25-.112.25-.25v-.5c0-.138-.112-.25-.25-.25H15.5l-1.05-1.4c-.248-.331-.786-.6-1.2-.6h-2.5c-.414 0-.952.269-1.2.6L8.5 6H5.25c-.138 0-.25.112-.25.25v.5c0 .138.112.25.25.25h.59l1.038 11.634c.068.754.737 1.366 1.494 1.366h7.256zM14.25 6h-4.5l.6-.8c.083-.11.262-.2.4-.2h2.5c.138 0 .317.09.4.2l.6.8zm1.38 13H8.37c-.252 0-.474-.204-.495-.456L6.844 7h10.312l-1.031 11.544c-.02.252-.243.456-.496.456z"
                  />
                </g>
              </svg>
            </div>
          )}
          <Button onClick={(e) => this.downloadFile(row, e)}>Download</Button>
        </div>
      );
    };

    const ConfigFilesColumns = [
      {
        title: "File Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        align: "right",
        showOnHover: true,
        width: "20%",
        render: (data) => deleteAndButtonFormatter(data.name, data, "config"),
      },
    ];

    const ScriptFilesColumns = [
      {
        title: "File Name",
        dataIndex: "name",
        key: "",
        width: "80%",
        render: (data) => (
          <p className="flex items-center m-0 h-full font-semibold hover:text-blue-800">
            {data.name}
          </p>
        ),
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        align: "right",
        showOnHover: true,
        width: "20%",
        render: (data) => deleteAndButtonFormatter(data.name, data, "script"),
      },
    ];

    return (
      <div>
        <div className="Intent_content">
          <div className="description-line">
            <div className="section-title">
              <h3>Action Nodes</h3>
            </div>
            <p>
              Action Nodes allow the designer to perform custom integrations and
              special logic. Action Nodes must be written into the Conversation
              file, with corresponding script(s), utils, and config files to be
              uploaded below. Action Node scripts must have a .py extension. For
              more information regarding Action Nodes, please read the{" "}
              <a
                href={DESIGNING_NLU_BOTS_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                documentation
              </a>
              .
            </p>
          </div>
          <div className="description-line">
            <p className="body-header">Not sure where to begin?</p>
            <p>
              Start building your microapp from one of our templates to give
              yourself a head start.
            </p>
            <Button onClick={this.openActionNodeLibrary}>
              Action Script Library
            </Button>
          </div>
        </div>

        {/* hidden inputs */}
        {!showUploadModal && (
          <Fragment>
            <input
              id="myInput"
              type="file"
              ref={(ref) => {
                this.configUploadInput = ref;
              }}
              style={{ display: "none" }}
              onChange={this.uploadConfig}
            />
            <input
              id="myInput"
              type="file"
              accept={""}
              ref={(ref) => {
                this.scriptsUploadInput = ref;
              }}
              style={{ display: "none" }}
              multiple
              onChange={this.uploadScript}
            />
          </Fragment>
        )}

        <div className="container-table-header">
          <h4>Configuration {ConfigTollTip} </h4>
          {canEdit && (
            <Button bsStyle="primary" onClick={this.clickOnConfigUploadInput}>
              <Glyphicon glyph="glyphicon glyphicon-arrow-up" /> Upload Config
            </Button>
          )}
        </div>
        <DataTable
          className="config-file-list"
          columns={ConfigFilesColumns}
          dataSource={configFiles}
          isLoading={isLoadingActionNodes}
          rowKey="name"
          bordered={false}
          onRowClick={(row) => this.onScriptRowClick(row, "config")}
        />
        {configFiles.length < 1 && (
          <p className="body-notation" style={{ marginTop: "1rem" }}>
            No configs have been uploaded.
          </p>
        )}
        <div className="container-table-header">
          <h4>Functions {scriptTollTip}</h4>
          {canEdit && (
            <Button bsStyle="primary" onClick={this.clickOnScriptsUploadInput}>
              <Glyphicon glyph="glyphicon glyphicon-arrow-up" /> Upload Scripts
            </Button>
          )}
        </div>
        <DataTable
          className="script-file-list"
          columns={ScriptFilesColumns}
          dataSource={scriptsFiles}
          rowKey="name"
          bordered={false}
          onRowClick={(row) => this.onScriptRowClick(row, "script")}
        />
        {scriptsFiles.length < 1 && (
          <p className="body-notation" style={{ marginTop: "1rem" }}>
            No scripts have been uploaded.
          </p>
        )}
        <Modal
          show={showUploadModal}
          bsClass="manager modal"
          className={hasUploadError ? "error-modal" : ""}
          onHide={this.closeUploadModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Upload Action Node Files</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProgressBar
              now={this.state.uploadProgress}
              active
              label={`${this.state.uploadProgress}%`}
            />
            <div
              className={`bot-upload-modal-line ${
                uploadProgress > 0 ? "active" : ""
              }`}
            >
              <div>Uploading {uploadingFileName}...</div>
              {hasUploadError ? (
                <div>
                  Failed!&nbsp;&nbsp;
                  <Glyphicon glyph="glyphicon glyphicon-exclamation-sign error" />
                </div>
              ) : (
                <div>
                  {uploadProgress < 100 ? `${uploadProgress}%` : "Complete!"}
                  {uploadProgress === 100 && (
                    <Glyphicon glyph="glyphicon glyphicon-ok-sign" />
                  )}
                </div>
              )}
            </div>
            {hasUploadError && (
              <div className="error-box">
                {errorInfo ? errorInfo.errors : errorInfo.scriptFile}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeUploadModal} bsStyle="primary">
              Hide
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Delete Modal */}
        <Modal
          show={showDeleteModal}
          bsClass="manager modal"
          onHide={this.closeDeleteModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton={!this.state.isCurrentlyDeleting}>
            <Modal.Title
              style={{
                fontFamily: "Open Sans",
                fontSize: "24px",
                fontWeight: 600,
              }}
            >
              Delete {this.state.fileToDelete.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ fontFamily: "Open Sans", fontSize: "16px" }}>
              Are you sure you want to delete {this.state.fileToDelete.name}?
              This could potentially remove this asset from existing published
              microapps.
            </p>
          </Modal.Body>
          <Modal.Footer>
            {!this.state.isCurrentlyDeleting ? (
              <Button onClick={this.closeDeleteModal} type="button" hollow>
                Cancel
              </Button>
            ) : null}
            <Button
              onClick={this.handleScriptDelete}
              bsStyle="primary"
              isLoading={this.state.isCurrentlyDeleting}
              disabled={this.state.isCurrentlyDeleting}
            >
              {this.state.isCurrentlyDeleting ? "Deleting" : "Delete"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

Actions.propTypes = {
  show: PropTypes.bool.isRequired,
  latestVersion: PropTypes.objectOf(PropTypes.any),
  customerGesId: PropTypes.string,
  customerId: PropTypes.string,
  botId: PropTypes.string,
  flags: PropTypes.object,
  history: PropTypes.object,
  setNotification: PropTypes.func,
  initBot: PropTypes.func,
  botData: PropTypes.object,
  updateSolutionInfo: PropTypes.func,
  validateSolution: PropTypes.func,
};

Actions.defaultProps = {
  latestVersion: null,
  customerGesId: "",
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNotification: (data) => dispatch(setNotification(data)),
    validateSolution: (botId, version, autoTrain) =>
      dispatch(validateSolution(botId, version, autoTrain)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withLDConsumer()(withRouter(Actions)));
