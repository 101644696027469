/* eslint-disable no-async-promise-executor */
/* eslint-disable no-unused-vars */
import config from "./config";
import { doesUserHasRole, SUPER_ADMIN_ROLE } from "bot-user-session";
import Axios from "axios";
import { isJSONString } from "./utils/functions";

const baseURL = config.botManagerAPIurl;
const temp = baseURL.split("/");
const baseAPIURL = temp[0] + "//" + temp[2];
const getUserToken = () =>
  window.sessionStorage.getItem("global_accessToken") || "not set";
const liveWebserviceUrl = config.webserviceEnvConfig["live"].rest;
const sandboxWebserviceUrl = config.webserviceEnvConfig["sandbox"].rest;
const channelEndpoints = {
  getAllChannels: (channelURL, customerId) =>
    `${channelURL}configuration/customers/${customerId}/channels`,
  getAllChannelsBySolution: (channelURL, customerId, solutionId) =>
    `${channelURL}configuration/customers/${customerId}/channels/${solutionId}`,
  getParticularChannel: (channelURL, customerId, channelId) =>
    `${channelURL}configuration/customers/${customerId}/channel/${channelId}`,
  patchChannel: (channelURL, customerId, channelId) =>
    `${channelURL}configuration/customers/${customerId}/channel/${channelId}`,
  postNewChannel: (channelURL, customerId) =>
    `${channelURL}configuration/customers/${customerId}/channel/`,
  deleteChannel: (channelURL, customerId, channelId) =>
    `${channelURL}configuration/customers/${customerId}/channel/${channelId}`,
};

const dashboardEndpoints = {
  getDashboardData: (env, customerId, solutionId, timePeriod) =>
    `${
      env === "sandbox" ? sandboxWebserviceUrl : liveWebserviceUrl
    }customers/${customerId}/solutions/${solutionId}/insights${timePeriod}`,
};

export const apiEndpoints = {
  createCustomerEndpoint: `${baseURL}/customers`,
  getEnvsEndpoint: `${baseURL}/envs`,
  getCustomersEndpoint: (userId) =>
    `${baseAPIURL}/ges/v5/${
      doesUserHasRole(SUPER_ADMIN_ROLE) ? "" : `users/${userId}`
    }/customers`,
  getCustomerEndpoint: (customerId) => `${baseURL}/customers/${customerId}`,
  getCreateBotEndpoint: (customerId) =>
    `${baseURL}/customers/${customerId}/bots`,
  getBotEndpoint: (botId) => `${baseURL}/bots/${botId}`,
  getBotHistoryEndpoint: (customerName) => `${baseURL}/history/${customerName}`,
  updateBotEndpoint: (botId) => `${baseURL}/bots/${botId}`,
  getBotVersionEndpoint: (versionId) => `${baseURL}/versions/${versionId}`,
  getFileDownloadEndpoint: (versionId, filePath) =>
    `${baseURL}/versions/${versionId}/files?filePath=${filePath}`,
  getVersionFilesDownloadEndpoint: (versionId) =>
    `${baseURL}/versions/${versionId}/scripts`,
  getNewBotVersionEndpoint: (botId) => `${baseURL}/bots/${botId}/versions`,
  getUploadScriptToBotVersionEndpoint: (versionId) =>
    `${baseURL}/versions/${versionId}/scripts`,
  getUploadConfigToBotVersionEndpoint: (versionId) =>
    `${baseURL}/versions/${versionId}/config`,
  getUploadTemplateToBotVersionEndpoint: (versionId, botVersion) =>
    `${baseURL}/versions/${versionId}/templates?botVersion=${botVersion}`,
  getUploadBotAssetEndpoint: (botId) => `${baseURL}/bots/${botId}/assets`,
  getVersionValidateEndpoint: (versionId) =>
    `${baseURL}/versions/${versionId}/validate`,
  getVersionDeployEndpoint: (versionId) =>
    `${baseURL}/versions/${versionId}/deploy`,
  getEnvironmentBotVersionEndpoint: (botId) => `${baseURL}/envs/${botId}`,
  getTrainVersionEndpoint: (versionId) =>
    `${baseURL}/versions/${versionId}/train?splitTrainingData=false`,
  getTestVersionEndpoint: (versionId, utterance) =>
    `${baseURL}/versions/${versionId}/test_nlu?utterance=${utterance}`,
  getSiteMetadata: (url) => `${baseURL}/studio/metadata?queryURL=${url}`,
  getPdfPreview: (url) => `${baseURL}/studio/pdf?queryURL=${url}`,
  getPypes: (customerId, versionId) =>
    `${baseAPIURL}/${versionId}/v5/customers/${customerId}/pypes`,
  getPypeStream: (customerId, versionId, pypeId, streamId) =>
    `${baseAPIURL}/${versionId}/v5/customers/${customerId}/pypes/${pypeId}/streams`,
  getUpdateStream: (customerId, versionId, pypeId, streamId) =>
    `${baseAPIURL}/${versionId}/v5/customers/${customerId}/pypes/${pypeId}/streams/${streamId}/bot`,
  getUsername: (uuid) => `${baseAPIURL}/ges/v5/users/${uuid}`,
  getUtterances: (botId, startNum, numOfEntries) =>
    `${baseURL}/supervisedlearning/${botId}?startNum=${startNum}&numEntries=${numOfEntries}`,
  getStopWords: (versionId) => `${baseURL}/versions/${versionId}/stopwords`,
  postStopWords: (versionId, stopwords) =>
    `${baseURL}/versions/${versionId}/stopwords`,
  deleteActionNodeScript: (versionId, scriptId) =>
    `${baseURL}/versions/${versionId}/scripts/${scriptId}`,
  deleteMediaFile: (customerId, botId, fileName) =>
    `${baseURL}/bots/${customerId}.${botId}/assets/${fileName}`,
  getCompileEndpoint: (botVersion) =>
    `${baseURL}/compile?botVersion=${botVersion}`,
  getBotVersionPreview: (customerName, botName, versionId) =>
    `${baseURL}/versions/${customerName}.${botName}.${versionId}/preview`,
  getPreviewWidget: (customerGesId, env) =>
    env === "live"
      ? `${baseAPIURL}/live/v5/customers/${customerGesId}/pypes/preview`
      : `${baseAPIURL}/sandbox/v5/customers/${customerGesId}/pypes/preview`,
  setPreviewWidget: (latestVersionId) =>
    `${baseURL}/versions/${latestVersionId}/preview`, // Mariano.TestNewFeatures.v27
  addIntentToUtterance: (customerId, botId) =>
    `${baseURL}/intent/${customerId}.${botId}/one_utterance`,
  // intent API endpoints
  populateTrainingIntentDB: (botId) => `${baseURL}/intent/${botId}`,
  getAllIntents: (botId) => `${baseURL}/intent/${botId}`,
  createOneIntent: (botId) => `${baseURL}/intent/${botId}/one_intent`,
  updateOneIntent: (botId) => `${baseURL}/intent/${botId}/one_intent`,
  deleteOneIntent: (botId, intent) =>
    `${baseURL}/intent/${botId}/one_intent?intent=${intent}`,
  // utterance API endpoints
  addOneUtteranceToIntent: (botId) =>
    `${baseURL}/intent/${botId}/one_utterance`,
  updateOneUtteranceToIntent: (botId) =>
    `${baseURL}/intent/${botId}/one_utterance`,
  deleteOneUtteranceFromIntent: (botId, intent, utterance) =>
    `${baseURL}/intent/${botId}/one_utterance?intent=${intent}&utterance=${utterance}`,
  // Entity API endpoints
  populateTrainingEntityDB: (botId) => `${baseURL}/entity/${botId}`,
  getAllEntities: (botId) => `${baseURL}/entity/${botId}`,
  createOneEntityType: (botId) => `${baseURL}/entity/${botId}/one_type_entity`,
  updateOneEntityType: (botId) => `${baseURL}/entity/${botId}/one_type_entity`,
  deleteOneEntityType: (botId, type, entity) =>
    `${baseURL}/entity/${botId}/one_type_entity?type=${type}&entity=${entity}`,
  addSynonymToEntityTypePair: (botId) =>
    `${baseURL}/entity/${botId}/one_synonym`,
  deleteSynonymFromEntityTypePair: (botId, type, entity, synonym) =>
    `${baseURL}/entity/${botId}/one_synonym?type=${type}&entity=${entity}&synonym=${synonym}`,
  deleteSolution: (botId) => `${baseURL}/bots/${botId}`,
  getSolutionGraph: (versionId) =>
    `${baseURL}/versions/${versionId}/graph?compiled=false`,
  updateSolutionGraph: (versionId) => `${baseURL}/versions/${versionId}/graph`,
  createStream: (customerId, pypeId, env) =>
    `${baseAPIURL}/${env}/v5/customers/${customerId}/pypes/${pypeId}/streams`,
  getMetadataForSolution: (env, customerId, pypeId, streamId) =>
    `${baseAPIURL}/${env}/v5/customers/${customerId}/pypes/${pypeId}/streams/${streamId}/metadata`,
  ...channelEndpoints,
  ...dashboardEndpoints,
};

let handleExpiredAuthentication = null;

export function setExpiredAuthenticationCallback(callback) {
  handleExpiredAuthentication = callback;
}

class CallApiConstructor {
  callApi(request) {
    return new Promise((resolve) => {
      fetch(request)
        .then((response) => {
          if (response.status === 401) {
            if (handleExpiredAuthentication) {
              handleExpiredAuthentication();
              resolve();
            }
          }
          resolve(response);
        })
        .catch((err) => {
          resolve();
        });
    });
  }
}

export const callApiInstance = new CallApiConstructor();
const { callApi } = callApiInstance;

const httpGetAsync = (url, isJson = true) =>
  new Promise(async (resolve, reject) => {
    const request = new Request(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    });

    try {
      const response = await callApi(request);
      if (isJson) {
        const json = await response.json();
        resolve(json.data ? json.data : json);
      } else {
        const blob = await response.blob();
        resolve(blob);
      }
    } catch (error) {
      reject(error);
    }
  });

const httpAsyncWithProgress = (
  method,
  url,
  data,
  contentType,
  onProgress,
  auth
) =>
  new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.open(method, url);
    req.setRequestHeader("Accept", "application/json");
    if (contentType) req.setRequestHeader("Content-Type", contentType);
    if (!url.includes("heroku-pypes-solution-mock.herokuapp.com")) {
      if (auth) {
        req.setRequestHeader("Authorization", auth);
      } else {
        req.setRequestHeader("Authorization", `Bearer ${getUserToken()}`);
      }
    }
    req.addEventListener("load", () => {
      if (req.status === 401) {
        if (req.responseURL.includes("/ges/v5/users/")) {
          return resolve({ status: req.status, data: {} });
        }
        if (handleExpiredAuthentication) {
          handleExpiredAuthentication();
          return;
        }
      }

      let resData = null;
      if (req.response) {
        resData = isJSONString(req.response)
          ? JSON.parse(req.response)
          : req.response;
      }
      resolve({ status: req.status, data: resData });
    });

    req.addEventListener("error", (err) => {
      reject(err.message);
    });

    if (onProgress) {
      req.upload.addEventListener("progress", (e) => {
        let progress = 0;
        if (e.total !== 0) {
          progress = parseInt((e.loaded / e.total) * 100, 10);
        }
        onProgress(progress);
      });
    }

    req.addEventListener(
      "abort",
      (err) => {
        reject(err.message);
      },
      false
    );

    req.send(data);
  });

const botManagerAPI = {
  // required permission: "bot:read:{customerId}"
  validateVersion: (versionId) => {
    return new Promise((resolve, reject) => {
      Axios.get(apiEndpoints.getVersionValidateEndpoint(versionId), {
        headers: {
          Authorization: `Bearer ${getUserToken()}`,
        },
      })
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          resolve(e.response);
        });
    });
  },

  getWords: (versionId) =>
    new Promise((resolve, reject) => {
      const request = new Request(apiEndpoints.getStopWords(versionId), {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getUserToken()}`,
        },
      });
      let statusCode = null;
      callApi(request)
        .then((response) => {
          statusCode = response.status;
          return response.json();
        })
        .then((json) => {
          resolve({ statusCode, data: json });
        })
        .catch((error) => {
          reject({ data: { errorMessage: error.message }, statusCode });
        });
    }),

  postStopWords: (versionId, stopwords) =>
    new Promise((resolve, reject) => {
      const payload = JSON.stringify({
        stopwords,
      });
      const request = new Request(
        apiEndpoints.postStopWords(versionId, stopwords),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserToken()}`,
          },
          method: "POST",
          body: payload,
        }
      );
      let statusCode = null;
      callApi(request)
        .then((response) => {
          statusCode = response.status;
          return response.json();
        })
        .then((json) => {
          resolve({ statusCode, data: json });
        })
        .catch((error) => {
          reject({ data: { errorMessage: error.message }, statusCode });
        });
    }),

  // /required permission: "bot:read:{customerId}"
  testVersion: (versionId, utterance) =>
    new Promise((resolve, reject) => {
      const request = new Request(
        apiEndpoints.getTestVersionEndpoint(versionId, utterance),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserToken()}`,
          },
          method: "POST",
        }
      );

      let statusCode = null;

      callApi(request)
        .then((response) => {
          statusCode = response.status;
          return response.json();
        })
        .then((json) => {
          if (statusCode !== 200) {
            throw Error(json.errors || json.message);
          }
          resolve({ responseMessage: JSON.stringify(json), statusCode });
        })
        .catch((error) => {
          reject({ errorMessage: error.message, statusCode });
        });
    }),

  // required permission: "bot:write:{customerId}"
  trainVersion: (versionId) => {
    return new Promise((resolve, reject) => {
      Axios.put(
        apiEndpoints.getTrainVersionEndpoint(versionId),
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserToken()}`,
          },
        }
      )
        .then((data) => {
          resolve(data.data);
        })
        .catch((e) => {
          console.log(e);
          resolve(e.response);
        });
    });
  },

  // /required permission: "bot:deploy:{customerId}"
  deployVersion: (versionId, environment) =>
    new Promise((resolve, reject) => {
      const payload = environment;
      const request = new Request(
        apiEndpoints.getVersionDeployEndpoint(versionId),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserToken()}`,
          },
          method: "PUT",
          body: payload,
        }
      );

      let statusCode = null;

      callApi(request)
        .then((response) => {
          statusCode = response.status;
          return response.json();
        })
        .then((json) => {
          if (statusCode !== 200) {
            resolve(json);
          }
          resolve(statusCode);
        })
        .catch((e) => {
          reject();
        });
    }),

  // /required permission: "bot:write:{customerId}"
  uploadBotAsset: (botId, asset, onProgress) => {
    const url = apiEndpoints.getUploadBotAssetEndpoint(botId);
    return httpAsyncWithProgress("PUT", url, asset, null, onProgress);
  },

  // /required permission: "bot:write:{customerId}"
  uploadTemplate: (versionId, template, botVersion, onProgress) => {
    const url = apiEndpoints.getUploadTemplateToBotVersionEndpoint(
      versionId,
      botVersion
    );
    return httpAsyncWithProgress("POST", url, template, null, onProgress);
  },

  compileTemplate: (template, botVersion, onProgress) => {
    const url = apiEndpoints.getCompileEndpoint(botVersion);
    return httpAsyncWithProgress("PUT", url, template, null, onProgress);
  },

  // /required permission: "bot:write:{customerId}"
  uploadScript: (versionId, script, onProgress) => {
    const url = apiEndpoints.getUploadScriptToBotVersionEndpoint(versionId);
    return httpAsyncWithProgress("POST", url, script, null, onProgress);
  },

  // /required permission: "bot:write:{customerId}"
  uploadConfig: (versionId, configFile, onProgress) => {
    const url = apiEndpoints.getUploadConfigToBotVersionEndpoint(versionId);
    return httpAsyncWithProgress("POST", url, configFile, null, onProgress);
  },

  // /required permission: "bot:write:{customerId}"
  createNewBotVersion: (botId, basedOn = null) =>
    new Promise((resolve, reject) => {
      const payload = JSON.stringify({
        botId,
        basedOn,
      });

      const request = new Request(
        apiEndpoints.getNewBotVersionEndpoint(botId),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserToken()}`,
          },
          method: "POST",
          body: payload,
        }
      );

      let statusCode = null;

      callApi(request)
        .then((response) => {
          statusCode = response.status;
          return response.json();
        })
        .then((json) => {
          if (statusCode >= 400) {
            throw Error(json.errors);
          }
          resolve(json.data);
        })
        .catch((error) => {
          reject({ errorMessage: error.message, statusCode });
        });
    }),

  // /required permission: "bot:write:{customerId}"
  createBot: (customerId, botName, botLanguage) =>
    new Promise((resolve, reject) => {
      const payload = JSON.stringify({
        botName,
        botLanguage,
      });

      const request = new Request(
        apiEndpoints.getCreateBotEndpoint(customerId),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserToken()}`,
          },
          method: "POST",
          body: payload,
        }
      );

      callApi(request)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    }),

  updateStream: (
    customerId,
    versionId,
    pypeId,
    streamId,
    botId,
    value,
    startChatId,
    endChatId,
    solution
  ) =>
    new Promise((resolve, reject) => {
      const payload = JSON.stringify({
        auto_start_with_bot: value,
        start_chat_bot_id:
          (startChatId !== botId || startChatId === botId) &&
          "start_chat_bot_id" === solution
            ? botId
            : startChatId &&
              (startChatId !== endChatId || startChatId === endChatId)
            ? startChatId
            : "",
        end_chat_bot_id:
          (endChatId !== botId || endChatId === botId) &&
          "end_chat_bot_id" === solution
            ? botId
            : endChatId &&
              (endChatId !== startChatId || endChatId === startChatId)
            ? endChatId
            : "",
        bot_enabled: value,
        start_chat_bot_enabled:
          (startChatId !== botId || startChatId === botId) &&
          "start_chat_bot_id" === solution
            ? true
            : startChatId &&
              (startChatId !== endChatId || startChatId === endChatId)
            ? true
            : false,
        end_chat_bot_enabled:
          (endChatId !== botId || endChatId === botId) &&
          "end_chat_bot_id" === solution
            ? true
            : endChatId &&
              (endChatId !== startChatId || endChatId === startChatId)
            ? true
            : false,
      });
      const request = new Request(
        apiEndpoints.getUpdateStream(customerId, versionId, pypeId, streamId),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserToken()}`,
          },
          method: "PUT",
          body: payload,
        }
      );

      let statusCode = null;

      callApi(request)
        .then(async (response) => {
          statusCode = response.status;
          const json = await response.json();
          return json;
        })
        .then((json) => {
          if (statusCode !== 200) {
            throw Error(json.errors);
          }
          resolve(json);
        })
        .catch((error) => {
          reject({ errorMessage: error.message, statusCode });
        });
    }),

  // /required permission: "bot:*:*"
  getEnvsFromBack: () =>
    new Promise((resolve, reject) => {
      httpGetAsync(apiEndpoints.getEnvsEndpoint)
        .then((envsFromBack) => {
          resolve(envsFromBack);
        })
        .catch((error) => {
          reject(error);
        });
    }),

  getCustomers: (userId) =>
    new Promise((resolve, reject) => {
      httpGetAsync(apiEndpoints.getCustomersEndpoint(userId))
        .then((customer) => {
          resolve(customer);
        })
        .catch((error) => {
          reject(error);
        });
    }),

  // /required permission: "bot:read:{customerId}"
  getCustomer: (customerId) =>
    new Promise((resolve, reject) => {
      httpGetAsync(apiEndpoints.getCustomerEndpoint(customerId))
        .then((customer) => {
          resolve(customer);
        })
        .catch((error) => {
          reject(error);
        });
    }),

  // /required permission: "bot:read:{customerId}"
  getPypes: (customerId, versionId) =>
    new Promise((resolve, reject) => {
      httpGetAsync(apiEndpoints.getPypes(customerId, versionId))
        .then((pypes) => {
          resolve(pypes);
        })
        .catch((error) => {
          reject(error);
        });
    }),

  getPypeStream: (customerId, versionId, pypeId, streamId) =>
    new Promise((resolve, reject) => {
      httpGetAsync(
        apiEndpoints.getPypeStream(customerId, versionId, pypeId, streamId)
      )
        .then((stream) => {
          resolve(stream);
        })
        .catch((error) => {
          reject(error);
        });
    }),

  // /required permission: "bot:read:{customerId}"
  getBot: (botId) =>
    new Promise((resolve, reject) => {
      httpGetAsync(apiEndpoints.getBotEndpoint(botId))
        .then((bot) => {
          resolve(bot);
        })
        .catch((error) => {
          reject(error);
        });
    }),

  getBotHistory: (customerId, botId) =>
    new Promise((resolve, reject) => {
      const data = {
        bot: botId,
      };
      Axios.post(apiEndpoints.getBotHistoryEndpoint(customerId), data, {
        headers: {
          Authorization: `Bearer ${getUserToken()}`,
        },
        responseType: "application/json",
      })
        .then((bot) => {
          resolve(bot);
        })
        .catch((e) => reject(e));
    }),

  // /required permission: "bot:write:{customerId}"
  updateBot: (botId, data) =>
    httpAsyncWithProgress(
      "PATCH",
      apiEndpoints.updateBotEndpoint(botId),
      JSON.stringify(data),
      "application/json",
      null
    ),

  // /required permission: "bot:read:{customerId}"
  getBotVersion: (versionId) =>
    new Promise((resolve, reject) => {
      httpGetAsync(apiEndpoints.getBotVersionEndpoint(versionId))
        .then((version) => {
          resolve(version);
        })
        .catch((error) => {
          reject(error);
        });
    }),

  // /required permission: "bot:read:{customerId}"
  getEnvironmentBotVersion: (botId) =>
    new Promise((resolve, reject) => {
      httpGetAsync(apiEndpoints.getEnvironmentBotVersionEndpoint(botId))
        .then((version) => {
          resolve(version);
        })
        .catch((error) => {
          reject(error);
        });
    }),

  downloadVersionScripts: (versionId) =>
    new Promise((resolve, reject) => {
      httpGetAsync(apiEndpoints.getVersionFilesDownloadEndpoint(versionId))
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    }),

  // /required permission: "bot:read:{customerId}"
  downloadTemplateFile: (versionId, filePath) =>
    new Promise((resolve, reject) => {
      httpGetAsync(apiEndpoints.getFileDownloadEndpoint(versionId, filePath))
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    }),

  getSiteMetadata: (url) => httpGetAsync(apiEndpoints.getSiteMetadata(url)),

  getPdfPreview: (url) => httpGetAsync(apiEndpoints.getPdfPreview(url), false),

  getUsername: (uuid) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "GET",
        apiEndpoints.getUsername(uuid),
        {},
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),

  getUtterances: (botId, startNum, numOfEntries) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "GET",
        apiEndpoints.getUtterances(botId, startNum, numOfEntries),
        {},
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),

  getInterfaces: (pypeId, env, interfacesAreEnabled) =>
    new Promise(async (resolve, reject) => {
      const url = env === "sandbox" ? sandboxWebserviceUrl : liveWebserviceUrl;
      const request = new Request(`${url}business/widget?pype_id=${pypeId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getUserToken()}`,
        },
      });

      if (interfacesAreEnabled) {
        try {
          callApi(request).then((response) => {
            resolve(response.json());
          });
        } catch (error) {
          reject(error);
        }
      } else {
        resolve("interfaces are disabled");
      }
    }),

  editInterface: (data, env, interfacesAreEnabled) =>
    new Promise((resolve, reject) => {
      const url = env === "sandbox" ? sandboxWebserviceUrl : liveWebserviceUrl;
      const myRequest = new Request(`${url}business/widget`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });

      if (interfacesAreEnabled) {
        try {
          callApi(myRequest).then((response) => {
            resolve(response.json());
          });
        } catch (error) {
          reject(error);
        }
      } else {
        resolve("interfaces are disabled");
      }
    }),

  createInterface: (data, env, interfacesAreEnabled) =>
    new Promise((resolve, reject) => {
      const url = env === "sandbox" ? sandboxWebserviceUrl : liveWebserviceUrl;
      const request = new Request(`${url}business/widget`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });

      if (interfacesAreEnabled) {
        try {
          callApi(request).then((response) => {
            resolve(response.json());
          });
        } catch (error) {
          reject(error);
        }
      } else {
        resolve("interfaces are disabled");
      }
    }),

  deleteInterface: (data, env, interfacesAreEnabled) =>
    new Promise((resolve, reject) => {
      const url = env === "sandbox" ? sandboxWebserviceUrl : liveWebserviceUrl;
      const request = new Request(`${url}business/widget`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });

      if (interfacesAreEnabled) {
        try {
          callApi(request).then((response) => {
            resolve(response);
          });
        } catch (error) {
          reject(error);
        }
      } else {
        resolve("interfaces are disabled");
      }
    }),

  deleteActionNodeScript: (versionId, scriptId) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "DELETE",
        apiEndpoints.deleteActionNodeScript(versionId, scriptId),
        {},
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),

  addIntentToUtterance: (customerId, botId, body) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "POST",
        apiEndpoints.addIntentToUtterance(customerId, botId),
        JSON.stringify(body),
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  deleteMediaFile: (customerId, botId, fileName) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "DELETE",
        apiEndpoints.deleteMediaFile(customerId, botId, fileName),
        {},
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  getPreviewWidget: (customerGesId, env, body) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "POST",
        apiEndpoints.getPreviewWidget(customerGesId, env),
        JSON.stringify(body),
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  setPreviewWidget: (latestVersionId) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "PUT",
        apiEndpoints.setPreviewWidget(latestVersionId),
        {},
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => {
          reject(e);
        });
    }),
  populateTrainingIntentDB: (botId, body) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "POST",
        apiEndpoints.populateTrainingIntentDB(botId),
        body,
        null,
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  getAllIntents: (botId) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "GET",
        apiEndpoints.getAllIntents(botId),
        {},
        "application/json",
        null
      )
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    }),
  createOneIntent: (botId, body) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "POST",
        apiEndpoints.createOneIntent(botId),
        JSON.stringify(body),
        "application/json",
        null
      )
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    }),
  updateOneIntent: (botId, body) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "PATCH",
        apiEndpoints.updateOneIntent(botId),
        JSON.stringify(body),
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  deleteOneIntent: (botId, intent) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "DELETE",
        apiEndpoints.deleteOneIntent(botId, intent),
        {},
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  addOneUtteranceToIntent: (botId, body) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "POST",
        apiEndpoints.addOneUtteranceToIntent(botId),
        JSON.stringify(body),
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  updateOneUtteranceToIntent: (botId, body) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "PATCH",
        apiEndpoints.updateOneUtteranceToIntent(botId),
        JSON.stringify(body),
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  deleteOneUtteranceFromIntent: (botId, intent, utterance) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "DELETE",
        apiEndpoints.deleteOneUtteranceFromIntent(botId, intent, utterance),
        {},
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  populateTrainingEntityDB: (botId, body) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "POST",
        apiEndpoints.populateTrainingEntityDB(botId),
        body,
        null,
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  getAllEntities: (botId) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "GET",
        apiEndpoints.getAllEntities(botId),
        {},
        "application/json",
        null
      )
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    }),
  createOneEntityType: (botId, body) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "POST",
        apiEndpoints.createOneEntityType(botId),
        JSON.stringify(body),
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  updateOneEntityType: (botId, body) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "PATCH",
        apiEndpoints.updateOneEntityType(botId),
        JSON.stringify(body),
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  deleteOneEntityType: (botId, type, entity) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "DELETE",
        apiEndpoints.deleteOneEntityType(botId, type, entity),
        {},
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  addSynonymToEntityTypePair: (botId, body) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "POST",
        apiEndpoints.addSynonymToEntityTypePair(botId),
        JSON.stringify(body),
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  deleteSynonymFromEntityTypePair: (botId, type, entity, synonym) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "DELETE",
        apiEndpoints.deleteSynonymFromEntityTypePair(
          botId,
          type,
          entity,
          synonym
        ),
        {},
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  deleteSolution: (botId) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "DELETE",
        apiEndpoints.deleteSolution(botId),
        {},
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  getSolutionGraph: (versionId) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "GET",
        apiEndpoints.getSolutionGraph(versionId),
        {},
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  updateSolutionGraph: (versionId, body) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "PUT",
        apiEndpoints.updateSolutionGraph(versionId),
        JSON.stringify(body),
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  postNewChannel: (customerId, env, body) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "POST",
        apiEndpoints.postNewChannel(
          env === "sandbox" ? sandboxWebserviceUrl : liveWebserviceUrl,
          customerId
        ),
        JSON.stringify(body),
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  getAllChannels: (customerId, env) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "GET",
        apiEndpoints.getAllChannels(
          env === "sandbox" ? sandboxWebserviceUrl : liveWebserviceUrl,
          customerId
        ),
        {},
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  getAllChannelsInSolution: (customerId, botId, env) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "GET",
        apiEndpoints.getAllChannelsBySolution(
          env === "sandbox" ? sandboxWebserviceUrl : liveWebserviceUrl,
          customerId,
          botId
        ),
        {},
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  getChannel: (customerId, channelId, env) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "GET",
        apiEndpoints.getParticularChannel(
          env === "sandbox" ? sandboxWebserviceUrl : liveWebserviceUrl,
          customerId,
          channelId
        ),
        {},
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  deleteChannel: (customerId, env, channelId) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "DELETE",
        apiEndpoints.deleteChannel(
          env === "sandbox" ? sandboxWebserviceUrl : liveWebserviceUrl,
          customerId,
          channelId
        ),
        {},
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  updateChannel: (customerId, env, channelId, data) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "PATCH",
        apiEndpoints.patchChannel(
          env === "sandbox" ? sandboxWebserviceUrl : liveWebserviceUrl,
          customerId,
          channelId
        ),
        JSON.stringify(data),
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  getSolutionMetadata: (env, customerId, pypeId, streamId) => {
    console.log(env, customerId, pypeId, streamId);
    return new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "GET",
        apiEndpoints.getMetadataForSolution(env, customerId, pypeId, streamId),
        { headers: { Authorization: `Bearer ${getUserToken()}` } },
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },
  createStream: (customerId, pypeId, env, body) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "POST",
        apiEndpoints.createStream(customerId, pypeId, env),
        JSON.stringify(body),
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
  updateSolutionMetadata: (env, customerId, pypeId, streamId, data) => {
    return new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "PUT",
        apiEndpoints.getMetadataForSolution(
          env === "sandbox" ? "sandbox" : "live",
          customerId,
          pypeId,
          streamId
        ),
        data,
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },
  getDashboardAnalytics: (env, customerId, botId, timePeriod) =>
    new Promise((resolve, reject) => {
      httpAsyncWithProgress(
        "GET",
        apiEndpoints.getDashboardData(env, customerId, botId, timePeriod),
        {},
        "application/json",
        null
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    }),
};

export default botManagerAPI;
