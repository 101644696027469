/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import connect from "react-redux/es/connect/connect";
import Papa from "papaparse";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import isEqual from "lodash/isEqual";
import { ProgressBar, Glyphicon, Modal } from "react-bootstrap";
import JSZip from "jszip";

import {
  Button,
  IconButton,
  IconNames,
  OutlinedButton,
  DataTable,
  DotMenu,
  Icon,
  Tooltip,
} from "ui-elements";
import {
  isPermitted,
  PermissionAction,
  PermissionResource,
} from "bot-user-session";
import activityTracker from "../../../activityTracker";
import { getIntentFormat } from "./utils";
import { saveFile } from "../../utils/utils";

import {
  updateIntentData,
  updateEntityData,
  resetIntentData,
  resetEntityData,
  setEditedStopWords,
  setNotification,
  updateSolutionInfo,
  validateSolution,
} from "../../Store/actions";
import {
  BOT_UPLOAD_STATE,
  BOT_VERSION_FLAGS,
  CSV_HEADERS_H,
  CSV_HEADERS_H_V5,
  CSV_HEADERS_H_V6,
  NLU_ENTITY_HEADERS_H,
  NLU_INTENT_HEADERS_H,
  NLU_INTENT_HEADERS_SIMPLIFIED_H,
  TEMPLATE_FILE_STATE,
  BLACK_LIST_FILE_NAMES,
} from "./defs";
import botManagerAPI from "../../../bot-manager-api";

import VersionCard from "./VersionCard";
import SolutionHeader from "./SolutionHeader";

import config from "../../../config";

const INTENT_DEFAULT_FILE_URL = `${config.homepage}/intent.default.csv`;
const ENTITY_DEFAULT_FILE_URL = `${config.homepage}/entity.default.csv`;
const VERSION_LIST_MAX_LENGTH = 5;

class Dialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showUploadModal: false,
      prepProgress: 0,
      uploadProgress: 0,
      compileProgress: 0,
      stopApiAction: false,
      filesData: [],
      csvFilesData: null,
      nluFilesData: [],
      compilerVersion: BOT_VERSION_FLAGS.UNKNOWN,
      currentVersionInfo: {},
      showDownloadModal: false,
      prepDownloadProgress: 0,
      downloadProgress: 0,
      zipProgress: 0,
      showMore: true,
      errorShowMore: false,
      isSettingFiles: false,
    };

    this.setupFiles = this.setupFiles.bind(this);
    this.getFileFromServer = this.getFileFromServer.bind(this);
    this.fileVerson = this.fileVerson.bind(this);
    this.sendBotHasNewTemplates = this.sendBotHasNewTemplates.bind(this);
    this.getCurrentVersions = this.getCurrentVersions.bind(this);
    this.onCreateOrEditDraft = this.onCreateOrEditDraft.bind(this);
    this.clickOnCsvInput = this.clickOnCsvInput.bind(this);
    this.getCompilerVersion = this.getCompilerVersion.bind(this);
    this.setCsvFile = this.setCsvFile.bind(this);
    this.getBotName = this.getBotName.bind(this);
    this.openUploadModal = this.openUploadModal.bind(this);
    this.closeUploadModal = this.closeUploadModal.bind(this);
    this.startFileUpload = this.startFileUpload.bind(this);
    this.onPresVersionMenuClick = this.onPresVersionMenuClick.bind(this);
    this.envsFormatter = this.envsFormatter.bind(this);
    this.lastPublishedFormatter = this.lastPublishedFormatter.bind(this);
    this.downloadAllFiles = this.downloadAllFiles.bind(this);
    this.setShowMore = this.setShowMore.bind(this);
    this.handleCsvFile = this.handleCsvFile.bind(this);
    this.getSolutionHeaderProps = this.getSolutionHeaderProps.bind(this);
    this.handleDraftClick = this.handleDraftClick.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
  }

  componentDidMount() {
    if (this.props.latestVersion && this.props.latestVersion.compilerVersion) {
      this.setState(
        { compilerVersion: this.props.latestVersion.compilerVersion },
        this.setupFiles
      );
    } else {
      this.setupFiles();
    }
    this.setState({
      currentVersionInfo: this.getCurrentVersions(this.props.currentVersions),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // update version cards
    if (!isEqual(prevProps.currentVersions, this.props.currentVersions)) {
      this.setState({
        currentVersionInfo: this.getCurrentVersions(this.props.currentVersions),
      });
    }
    if (prevProps.editedIntent !== this.props.editedIntent) {
      const nluFilesData = this.state.nluFilesData.slice(0);
      nluFilesData[1].fileState = TEMPLATE_FILE_STATE.FROM_DATA;
      this.setState({ nluFilesData });
    }
    if (prevProps.editedEntity !== this.props.editedEntity) {
      const nluFilesData = this.state.nluFilesData.slice(0);
      nluFilesData[0].fileState = TEMPLATE_FILE_STATE.FROM_DATA;
      this.setState({ nluFilesData });
    }
    let prevLen = 0;
    let currentLen = 0;

    const prevCompilerVersion = prevProps.latestVersion
      ? prevProps.latestVersion.compilerVersion
      : -1;
    const currentLenCompilerVersion = this.props.latestVersion
      ? this.props.latestVersion.compilerVersion
      : -1;

    if (prevCompilerVersion !== currentLenCompilerVersion) {
      this.setState(
        { compilerVersion: this.props.latestVersion.compilerVersion },
        () => {
          this.setupFiles();
        }
      );
      return;
    }

    if (
      this.props.latestVersion &&
      this.props.latestVersion.files &&
      Array.isArray(this.props.latestVersion.files)
    )
      currentLen = this.props.latestVersion.files.length;
    if (
      prevProps.latestVersion &&
      prevProps.latestVersion.files &&
      Array.isArray(prevProps.latestVersion.files)
    )
      prevLen = prevProps.latestVersion.files.length;

    if (prevLen !== currentLen) {
      this.setupFiles();
    }

    if (!prevProps.solutionInfo.error && this.props.solutionInfo.error) {
      this.setState({ errorShowMore: false });
    }
  }

  async setupFiles() {
    const { latestVersion } = this.props;
    const filesData = [];
    const nluFilesData = [];
    this.setState({ isSettingFiles: true });

    if (
      latestVersion &&
      latestVersion.files &&
      Array.isArray(latestVersion.files)
    ) {
      let files;
      // let fileVersion
      if (latestVersion.files.includes("templates/bot.csv")) {
        files = await this.getFileFromServer("templates/bot.csv");
        let fileVersion = await this.fileVerson(files);
        this.setState({ compilerVersion: fileVersion }, () => {});
      }

      const serverFiles = Object.values(latestVersion.files);
      if (
        this.state.compilerVersion === BOT_VERSION_FLAGS.NLU_ENABLED ||
        this.state.compilerVersion === BOT_VERSION_FLAGS.NLU_ROUTING
      ) {
        if (serverFiles.indexOf("templates/bot.csv") > -1) {
          filesData.push({
            type: "CSV",
            name: "bot.csv",
            fileState: TEMPLATE_FILE_STATE.ON_SERVER,
          });
        } else {
          filesData.push({
            type: "CSV",
            name: "",
            fileState: TEMPLATE_FILE_STATE.NO_FILE,
          });
        }

        if (serverFiles.indexOf("templates/entity.csv") > -1) {
          nluFilesData.push({
            type: "Entity",
            name: "entity.csv",
            fileState: TEMPLATE_FILE_STATE.ON_SERVER,
          });
        } else {
          nluFilesData.push({
            type: "Entity",
            name: "",
            fileState: TEMPLATE_FILE_STATE.NO_FILE,
          });
        }

        if (serverFiles.indexOf("templates/intent.csv") > -1) {
          nluFilesData.push({
            type: "Intent",
            name: "intent.csv",
            fileState: TEMPLATE_FILE_STATE.ON_SERVER,
          });
        } else {
          nluFilesData.push({
            type: "Intent",
            name: "",
            fileState: TEMPLATE_FILE_STATE.NO_FILE,
          });
        }

        this.setState({ filesData, nluFilesData });
        if (serverFiles.indexOf("templates/intent.csv") === -1) {
          await this.props.updateIntentData();
        }
        if (serverFiles.indexOf("templates/entity.csv") === -1) {
          await this.props.updateEntityData();
        }

        this.sendBotHasNewTemplates();
      } else if (
        this.state.compilerVersion === BOT_VERSION_FLAGS.API_CONTAINERIZED
      ) {
        if (serverFiles.indexOf("templates/bot.csv") > -1) {
          filesData.push({
            type: "CSV",
            name: "bot.csv",
            fileState: TEMPLATE_FILE_STATE.ON_SERVER,
          });
        } else {
          filesData.push({
            type: "CSV",
            name: "",
            fileState: TEMPLATE_FILE_STATE.NO_FILE,
          });
        }
        this.setState({ filesData, nluFilesData });
        this.sendBotHasNewTemplates();
      }
    } else {
      filesData.push({
        type: "CSV",
        name: "",
        fileState: TEMPLATE_FILE_STATE.NO_FILE,
      });
      nluFilesData.push({
        type: "Entity",
        name: "",
        fileState: TEMPLATE_FILE_STATE.NO_FILE,
      });
      nluFilesData.push({
        type: "Intent",
        name: "",
        fileState: TEMPLATE_FILE_STATE.NO_FILE,
      });
      this.setState({ filesData, nluFilesData });
    }

    this.setState({ isSettingFiles: false });
  }

  async getFileFromServer(filename) {
    return new Promise((resolve, reject) => {
      botManagerAPI
        .downloadTemplateFile(this.props.latestVersion.id, filename)
        .then((response) => {
          resolve(response.file_data);
        });
    });
  }

  fileVerson(filesData) {
    const scvFileAsText = filesData;
    // get the first line
    const endOfLine = scvFileAsText.indexOf("\n");
    if (endOfLine) {
      const firstLine = scvFileAsText.substr(0, endOfLine);
      if (CSV_HEADERS_H.every((header) => firstLine.indexOf(header) >= 0)) {
        return BOT_VERSION_FLAGS.API_CONTAINERIZED;
      }
      if (CSV_HEADERS_H_V5.every((header) => firstLine.indexOf(header) >= 0)) {
        return BOT_VERSION_FLAGS.NLU_ENABLED;
      }
      if (CSV_HEADERS_H_V6.every((header) => firstLine.indexOf(header) >= 0)) {
        return BOT_VERSION_FLAGS.NLU_ROUTING;
      }
      return BOT_VERSION_FLAGS.UNKNOWN;
    }
  }

  sendBotHasNewTemplates() {
    const hasNewFile = this.state.filesData.some(
      (f) => f.fileState === TEMPLATE_FILE_STATE.NEW
    );
    const hasFilesThatWereNotUploaded = this.state.filesData.some(
      (f) => f.fileState === TEMPLATE_FILE_STATE.NO_FILE
    );
    let val = BOT_UPLOAD_STATE.NO_CHANGE; // no new file
    if (hasNewFile) {
      if (hasFilesThatWereNotUploaded) {
        val = BOT_UPLOAD_STATE.NOT_READY;
      } else {
        val = BOT_UPLOAD_STATE.READY;
      }
    }
    this.props.botHasNewTemplates(val);
  }

  getCurrentVersions(versionList) {
    let res = {
      draft: {
        version: "v-",
        description: "A draft hasn't been created yet.",
        published: false,
      },
      sandbox: {
        version: "v-",
        description: "Nothing has been published on Sandbox.",
        published: false,
      },
      live: {
        version: "v-",
        description: "Nothing has been published on the Live Environment.",
        published: false,
      },
    };
    const ENVS = ["draft", "sandbox", "live"];

    versionList.forEach((version) => {
      ENVS.forEach((env) => {
        if (version.envs && version.envs.includes(env)) {
          res[env] = {
            ...res[env],
            version: version.version,
            description: version.lastPublished,
            published: true,
          };
        }
      });
    });

    return res;
  }

  async onCreateOrEditDraft(edit) {
    if (edit) {
      const { flags } = this.props;
      let url = "";

      if (flags.designProDesignTool20) {
        url = `/solution/${this.props.customerId}/${this.props.botId}`;
      } else {
        url = `/designer/${this.props.customerId}/${this.props.botId}`;
      }

      this.props.history.push(url);
    } else {
      const { customerId, botId } = this.props;
      await botManagerAPI.createNewBotVersion(`${customerId}.${botId}`, null);
      await this.props.initBot(
        customerId,
        botId,
        BOT_VERSION_FLAGS.NLU_ENABLED
      );
    }
  }

  clickOnCsvInput() {
    this.csvUpload.click();
  }

  async getCompilerVersion(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        const scvFileAsText = event.target.result;
        // get the first line
        const endOfLine = scvFileAsText.indexOf("\n");
        if (endOfLine) {
          const firstLine = scvFileAsText.substr(0, endOfLine);
          if (CSV_HEADERS_H.every((header) => firstLine.indexOf(header) >= 0)) {
            resolve(BOT_VERSION_FLAGS.API_CONTAINERIZED);
            return;
          }
          if (
            CSV_HEADERS_H_V5.every((header) => firstLine.indexOf(header) >= 0)
          ) {
            resolve(BOT_VERSION_FLAGS.NLU_ENABLED);
            return;
          }
          if (
            CSV_HEADERS_H_V6.every((header) => firstLine.indexOf(header) >= 0)
          ) {
            resolve(BOT_VERSION_FLAGS.NLU_ROUTING);
            return;
          }
        }
        resolve(BOT_VERSION_FLAGS.UNKNOWN);
      };
      reader.onerror = function (event) {
        resolve(BOT_VERSION_FLAGS.UNKNOWN);
      };
      reader.readAsText(file);
    });
  }

  async setCsvFile() {
    if (this.csvUpload.files && this.csvUpload.files[0]) {
      const filesData = this.state.filesData.slice(0);
      filesData[0].name = this.csvUpload.files[0].name;
      filesData[0].fileState = TEMPLATE_FILE_STATE.NEW;
      filesData[0].error = "";
      let fileCompilerVersion = await this.getCompilerVersion(
        this.csvUpload.files[0]
      );
      let hasError = false;

      const nluFilesData = this.state.nluFilesData;

      if (fileCompilerVersion === BOT_VERSION_FLAGS.API_CONTAINERIZED) {
        // if the old bot is DEFAULT_NONE_CONTAINERIZED, leave it as is and dont upgrade it to API_CONTAINERIZED
        if (
          this.props.latestVersion &&
          this.props.latestVersion.compilerVersion ===
            BOT_VERSION_FLAGS.DEFAULT_NONE_CONTAINERIZED
        ) {
          fileCompilerVersion = BOT_VERSION_FLAGS.DEFAULT_NONE_CONTAINERIZED;
        }
      } else if (
        (fileCompilerVersion === BOT_VERSION_FLAGS.NLU_ENABLED ||
          fileCompilerVersion === BOT_VERSION_FLAGS.NLU_ROUTING) &&
        (this.state.compilerVersion !== BOT_VERSION_FLAGS.NLU_ENABLED ||
          this.state.compilerVersion !== BOT_VERSION_FLAGS.NLU_ROUTING)
      ) {
        if (this.props.latestVersion && this.props.latestVersion.files) {
          const serverFiles = Object.values(this.props.latestVersion.files);
          if (nluFilesData[0].fileState !== 2) {
            if (serverFiles.indexOf("templates/entity.csv") > -1) {
              nluFilesData[0] = {
                type: "Entity",
                name: "entity.csv",
                fileState: TEMPLATE_FILE_STATE.ON_SERVER,
              };
            } else {
              nluFilesData[0] = {
                type: "Entity",
                name: "",
                fileState: TEMPLATE_FILE_STATE.NO_FILE,
              };
            }
          }

          if (nluFilesData[0].fileState !== 2) {
            if (serverFiles.indexOf("templates/intent.csv") > -1) {
              nluFilesData[1] = {
                type: "Intent",
                name: "intent.csv",
                fileState: TEMPLATE_FILE_STATE.ON_SERVER,
              };
            } else {
              nluFilesData[1] = {
                type: "Intent",
                name: "",
                fileState: TEMPLATE_FILE_STATE.NO_FILE,
              };
            }
          }
        }
      } else if (fileCompilerVersion === BOT_VERSION_FLAGS.UNKNOWN) {
        hasError = true;
        filesData[0].error = "CSV file header does not match template";
      }
      if (!hasError) {
        // Amplitude event on BOT csv upload
        const fileObj = this.csvUpload.files[0];
        activityTracker.logEvent(
          activityTracker.eventTypeNames.UPLOAD_TEMPLATE,
          {
            fileSize: fileObj.size,
            fileType: fileObj.type,
            fileName: fileObj.name,
            solutionName: this.getBotName(),
          }
        );
      }

      this.setState(
        {
          filesData,
          csvFilesData: this.csvUpload.files,
          compilerVersion: fileCompilerVersion,
        },
        () => {
          this.sendBotHasNewTemplates();
          this.startFileUpload();
        }
      );
    } else {
      this.csvUpload.files = this.state.csvFilesData;
    }
  }

  getBotName() {
    const parts = this.props.botId.split(".");
    return parts.length >= 2 ? parts[1] : parts[0];
  }

  openUploadModal() {
    this.setState({
      prepProgress: 1,
      compileProgress: 0,
      uploadProgress: 0,
      showUploadModal: true,
      stopApiAction: false,
    });
  }

  closeUploadModal() {
    this.setState({ showUploadModal: false });
  }

  async startFileUpload() {
    let res = false;

    const onUploadProgress = (e) => {
      this.setState({ uploadProgress: e });
    };
    if (this.state.compilerVersion === BOT_VERSION_FLAGS.UNKNOWN) {
      this.props.updateSolutionInfo({
        loading: false,
        error: true,
        title: `Your latest draft (${this.props.latestVersion.version}) isn't ready to be published.`,
        description: "CSV file header does not match template",
      });
      this.setState({
        errorShowMore: false,
      });
      this.closeUploadModal();
      return false;
    }
    this.openUploadModal("");

    const formData = new FormData();
    let simplifiedTrainingData;

    if (this.state.filesData[0].fileState === TEMPLATE_FILE_STATE.ON_SERVER) {
      const csvFileFromServer = await this.getFileFromServer(
        "templates/bot.csv"
      );
      const blob = new Blob([csvFileFromServer], { type: "text/xml" });
      formData.append("templateFile", blob, "bot.csv");
    } else {
      formData.append("templateFile", this.csvUpload.files[0]);
    }

    this.setState({
      prepProgress:
        this.state.compilerVersion === BOT_VERSION_FLAGS.NLU_ENABLED ||
        this.state.compilerVersion === BOT_VERSION_FLAGS.NLU_ROUTING
          ? 33
          : 100,
    });
    if (
      this.state.compilerVersion === BOT_VERSION_FLAGS.NLU_ENABLED ||
      this.state.compilerVersion === BOT_VERSION_FLAGS.NLU_ROUTING
    ) {
      if (
        this.state.nluFilesData[1].fileState ===
          TEMPLATE_FILE_STATE.ON_SERVER ||
        this.state.nluFilesData[1].fileState === TEMPLATE_FILE_STATE.FROM_DATA
      ) {
        if (this.props.editedIntent) {
          const updatedCsv = this.props.intentData;
          const blob = new Blob([updatedCsv], { type: "text/xml" });
          formData.append("trainingIntentFile", blob, "intent.csv");
          simplifiedTrainingData = getIntentFormat(
            updatedCsv,
            NLU_INTENT_HEADERS_H,
            NLU_INTENT_HEADERS_SIMPLIFIED_H
          );
        } else {
          const csvFileFromServer = await this.getFileFromServer(
            "templates/intent.csv"
          );

          const blob = new Blob([csvFileFromServer], { type: "text/xml" });
          formData.append("trainingIntentFile", blob, "intent.csv");
          simplifiedTrainingData = getIntentFormat(
            csvFileFromServer,
            NLU_INTENT_HEADERS_H,
            NLU_INTENT_HEADERS_SIMPLIFIED_H
          );
        }
      } else if (
        this.state.nluFilesData[1].fileState === TEMPLATE_FILE_STATE.NO_FILE
      ) {
        const intentFileBlob = await (
          await fetch(new Request(INTENT_DEFAULT_FILE_URL))
        ).blob();
        formData.append("trainingIntentFile", intentFileBlob, "intent.csv");
        simplifiedTrainingData = "simplified";
      }

      this.setState({ prepProgress: 66 });

      if (
        this.state.nluFilesData[0].fileState ===
          TEMPLATE_FILE_STATE.ON_SERVER ||
        this.state.nluFilesData[0].fileState === TEMPLATE_FILE_STATE.FROM_DATA
      ) {
        if (this.props.editedEntity) {
          const updatedEntity = this.props.entityData;
          console.log("updatedEntity", updatedEntity);
          const blob = new Blob([updatedEntity], { type: "text/xml" });
          formData.append("trainingEntityFile", blob, "entity.csv");
        } else {
          const csvFileFromServer = await this.getFileFromServer(
            "templates/entity.csv"
          );
          const blob = new Blob([csvFileFromServer], { type: "text/xml" });
          formData.append("trainingEntityFile", blob, "entity.csv");
        }
      } else if (
        this.state.nluFilesData[0].fileState === TEMPLATE_FILE_STATE.NO_FILE
      ) {
        const entityFileBlob = await (
          await fetch(new Request(ENTITY_DEFAULT_FILE_URL))
        ).blob();
        formData.append("trainingEntityFile", entityFileBlob, "entity.csv");
      }
      this.setState({ prepProgress: 100 });
    }

    formData.append("simplifiedTrainingData", simplifiedTrainingData);
    await this.props.onUpdateDraft(simplifiedTrainingData);

    try {
      if (!this.state.stopApiAction) {
        const draftVersion = await this.props.botData.latestVersion.id;
        const { status, data } = await botManagerAPI.uploadTemplate(
          draftVersion,
          formData,
          this.state.compilerVersion,
          onUploadProgress
        );
        if (status === 200) {
          this.props.resetIntentData();
          this.props.resetEntityData();
          this.props.setEditedStopWords(false);

          this.csvUpload.value = null;
          await this.setupFiles();
          this.props.updateSolutionInfo({
            loading: false,
            error: false,
            title: `Your latest draft (${this.props.latestVersion.version}) is ready to be published.`,
            description: "",
          });
          this.props.setNotification({
            openNotification: true,
            notificationDuration: 3000,
            notificationTitle:
              "You have successfully uploaded your new template.",
            notificationType: "success",
          });
          this.closeUploadModal();
          this.sendBotHasNewTemplates();
          const {
            latestVersion: { id, version },
          } = this.props.botData;
          await this.props.validateSolution(id, version, true);
          res = true;
        } else {
          let filesData = [];
          filesData.push({
            type: "CSV",
            name: "",
            fileState: TEMPLATE_FILE_STATE.NO_FILE,
          });
          this.setState({ filesData });
          let msg = "Error";
          if (typeof data === "object") {
            const errors = JSON.stringify(data.errors);
            msg += " " + errors;
          } else if (typeof data === "string") {
            msg = data;
          }
          this.props.updateSolutionInfo({
            loading: false,
            error: true,
            title: `Your latest draft (${this.props.latestVersion.version}) isn't ready to be published.`,
            description: msg,
          });
          this.setState({ errorShowMore: false });
          this.closeUploadModal();
        }
      } else {
        this.closeUploadModal();
        console.log("Uploaded was canceled");
      }
    } catch (err) {
      this.props.updateSolutionInfo({
        loading: false,
        error: true,
        title: `Your latest draft (${this.props.latestVersion.version}) isn't ready to be published.`,
        description: err
          ? err
          : "An issue occurred while creating a new draft. This may be due to a slow or unstable internet connection. Try again in a few minutes or contact us if the issue persists",
      });
      this.setState({ errorShowMore: false });
      this.closeUploadModal();
    }

    return res;
  }

  async onPresVersionMenuClick(menu, data) {
    switch (menu.value) {
      case "download":
        await this.downloadAllFiles(data.version);
        break;
      case "publish":
        await this.props.restore(data.version);
        break;
      case "republish":
        await this.props.restore(data.version, true);
        break;
      default:
        break;
    }
  }

  actionFormatter(cell, row) {
    return (
      <div className="version-action-menu">
        <Tooltip label="Download">
          <IconButton
            iconName={IconNames.DownloadArrow}
            inactive={false}
            loaded={false}
            onClick={() =>
              this.onPresVersionMenuClick({ value: "download" }, row)
            }
          />
        </Tooltip>
        <Button
          onClick={() =>
            this.onPresVersionMenuClick({ value: "republish" }, row)
          }
        >
          Republish
        </Button>
      </div>
    );
  }

  versionFormatter(cell) {
    return <div className="download-version">{cell}</div>;
    // return (
    //   <Button onClick={this.downloadAllFiles(cell)} style={{ border: "none" }}>
    //     <img alt="" src={IconDownloadArrow} className="download-arrow" />
    //     {cell}
    //   </Button>
    // );
  }

  envsFormatter(cell, row) {
    return <div className="env-version">{(cell || []).join(", ")}</div>;
  }

  lastPublishedFormatter(cell) {
    return <div className="env-version">{cell}</div>;
  }

  revertSortFunc(a, b) {
    return a.version.localeCompare(b.version, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  }

  async handleDraftClick(menu) {
    const { currentVersions } = this.props;
    if (menu.value === "download") {
      const data = (currentVersions || []).find(
        (ver) => ver.envs[0] === "draft"
      );

      if (data && data.version) {
        await this.downloadAllFiles(data.version);
      }
    }
  }

  async downloadAllFiles(verssion) {
    this.setState({
      showDownloadModal: true,
      prepDownloadProgress: 0,
      downloadProgress: 0,
      zipProgress: 0,
    });
    const version = await botManagerAPI.getBotVersion(
      `${this.props.bot.id}.${verssion}`
    );
    this.setState({ prepDownloadProgress: 100 });
    if (version) {
      const promises = [];
      version.files.forEach((file) => {
        if (
          !file.endsWith("/") &&
          !BLACK_LIST_FILE_NAMES.some((f) => f === file)
        ) {
          promises.push(
            new Promise((resolve, reject) => {
              botManagerAPI
                .downloadTemplateFile(
                  `${this.props.bot.id}.${verssion}`,
                  encodeURIComponent(file)
                )
                .then((response) => {
                  resolve({ file, data: response.file_data });
                });
            })
          );
        }
      });
      const [...filesData] = await Promise.all(promises);
      this.setState({ downloadProgress: 100 });
      const zip = new JSZip();
      filesData.forEach(({ file, data }) => {
        zip.file(file, data);
      });

      const zipContent = await new Promise((resolve, reject) => {
        zip.generateAsync({ type: "blob" }).then((content) => {
          // see FileSaver.js
          resolve(content);
        });
      });
      this.setState({ zipProgress: 100 });
      saveFile(zipContent, `${this.props.bot.id}.${verssion}.zip`);
    }
    this.setState({ showDownloadModal: false });
  }

  setShowMore(flag) {
    this.setState({ showMore: flag });
  }

  handleCsvFile(event) {
    const { csvFilesData } = this.state;
    if (csvFilesData && csvFilesData.length) {
      const filesData = [];
      filesData.push({
        type: "CSV",
        name: "",
        fileState: TEMPLATE_FILE_STATE.NO_FILE,
      });
      setTimeout(() => {
        this.setState({ filesData });
      }, 1000);
    }
    event.target.value = null;
  }

  getSolutionHeaderProps() {
    const { currentVersionInfo, errorShowMore, isSettingFiles } = this.state;
    const { solutionInfo } = this.props;
    let params = {
      loading: solutionInfo.loading,
      error: solutionInfo.error,
      text: solutionInfo.title,
      description: solutionInfo.description,
    };

    if (isSettingFiles) {
      params = {
        loading: true,
        error: false,
        text: "Loading your latest published versions...",
        description: "",
        header: null,
      };
    } else if (
      (currentVersionInfo.draft && currentVersionInfo.draft.published) ||
      (currentVersionInfo.sandbox && currentVersionInfo.sandbox.published)
    ) {
      if (solutionInfo.error) {
        params.header = errorShowMore ? (
          <OutlinedButton
            onClick={() => {
              this.setState({ errorShowMore: false });
            }}
          >
            Read More
          </OutlinedButton>
        ) : null;
        params.content = errorShowMore ? null : (
          <div className="solution-header-error-content">
            <p>{solutionInfo.description}</p>
            <div className="solution-header-error-content-footer">
              <OutlinedButton
                onClick={() => {
                  this.setState({ errorShowMore: true });
                }}
              >
                Read Less
              </OutlinedButton>
            </div>
          </div>
        );
      }
    }

    return params;
  }

  render() {
    const {
      show,
      presVersions,
      currentVersions,
      botData,
      customer,
      envs,
      flags,
    } = this.props;
    const {
      currentVersionInfo,
      prepProgress,
      uploadProgress,
      compileProgress,
      stopApiAction,
      prepDownloadProgress,
      downloadProgress,
      zipProgress,
      showDownloadModal,
      showMore,
      isSettingFiles,
    } = this.state;

    const prepProgressActive = prepProgress > 0 && prepProgress < 100;
    const prepProgressDone = prepProgress === 100;
    const uploadProgressActive = uploadProgress > 0 && uploadProgress < 100;
    const uploadProgressDone = uploadProgress === 100;
    const compileProgressActive = compileProgress > 0 && compileProgress < 100;
    const compileProgressDone = compileProgress === 100;

    const prepDownloadProgressActive =
      prepDownloadProgress > 0 && prepDownloadProgress < 100;
    const prepDownloadProgressDone = prepDownloadProgress === 100;
    const downloadProgressActive =
      downloadProgress > 0 && downloadProgress < 100;
    const downloadProgressDone = downloadProgress === 100;
    const zipProgressActive = zipProgress > 0 && zipProgress < 100;
    const zipProgressDone = zipProgress === 100;

    if (!show) {
      return null;
    }

    const Columns = [
      {
        title: "Version",
        dataIndex: "version",
        key: "version",
        width: "11%",
        sorter: this.revertSortFunc,
        render: (data) => this.versionFormatter(data.version),
      },
      {
        title: "Environment",
        dataIndex: "envs",
        key: "envs",
        width: "23%",
        sorter: (a, b) => {
          const env1 = (a.envs || []).join(", ");
          const env2 = (b.envs || []).join(", ");

          return env1.localeCompare(env2);
        },
        render: (data) => this.envsFormatter(data.envs),
      },
      {
        title: "Last Published",
        dataIndex: "lastPublished",
        key: "lastPublished",
        width: "45%",
        render: (data) => this.lastPublishedFormatter(data.lastPublished),
        sorter: this.revertSortFunc,
      },
      {
        title: "",
        dataIndex: "publish",
        key: "publish",
        width: "21%",
        showOnHover: true,
        render: (data) => this.actionFormatter({}, data),
      },
    ];

    const versionList = [...currentVersions, ...presVersions]
      .filter((ver) => !(ver.envs || []).includes("draft"))
      .sort((a, b) => {
        const version1 = parseInt(a.version.slice(1), 10);
        const version2 = parseInt(b.version.slice(1), 10);
        return version1 > version2 ? -1 : 1;
      });

    return (
      <div className="solution-dialog">
        <input
          id="myInput"
          type="file"
          ref={(ref) => (this.csvUpload = ref)}
          style={{ opacity: 0, height: 0 }}
          accept=".csv"
          onChange={this.setCsvFile}
          onClick={this.handleCsvFile}
        />
        <div className="section-title">
          <h3>Microapp Design</h3>
        </div>
        <p>
          The design is the flow of what your microapp does, and what it expects
          to receive back from your users. Combined with Actions this creates
          the “guided” portion of your microapp.
        </p>
        <div className="solution-dialog-draft-cards">
          {Object.keys(currentVersionInfo).map((env, index) => (
            <VersionCard
              key={index}
              title={env}
              type={
                env === "live"
                  ? "secondary"
                  : currentVersionInfo[env].published
                  ? "primary"
                  : "secondary"
              }
              version={currentVersionInfo[env].version}
              description={currentVersionInfo[env].description}
              hideMenu={env !== "draft"}
              onMenuClick={this.handleDraftClick}
            >
              {env === "draft" && (
                <div className="draft-card-actions">
                  <Button
                    disabled={isSettingFiles}
                    onClick={() =>
                      this.onCreateOrEditDraft(
                        currentVersionInfo[env].published
                      )
                    }
                  >
                    {currentVersionInfo[env].published
                      ? "Edit Microapp"
                      : "Create Draft"}
                  </Button>
                  <Tooltip label="Upload CSV">
                    <IconButton
                      iconName={IconNames.Upload2}
                      disabled={isSettingFiles}
                      onClick={this.clickOnCsvInput}
                      style={{
                        display: "flex",
                        marginRight: "auto",
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            </VersionCard>
          ))}
        </div>
        <SolutionHeader {...this.getSolutionHeaderProps()} />
        {versionList && versionList.length > 0 && (
          <>
            <div className="container-table-header">
              <h4>Previous Versions</h4>
            </div>
            <DataTable
              className="current-version-list"
              columns={Columns}
              dataSource={(versionList || [])
                .slice(
                  0,
                  showMore ? VERSION_LIST_MAX_LENGTH : versionList.length
                )
                .map((item, index) => ({
                  ...item,
                  id: index,
                }))}
              rowKey="id"
              defaultSortName="version"
              defaultSortOrder="desc"
              bordered={false}
            />
            {versionList.length > VERSION_LIST_MAX_LENGTH && (
              <div
                className="version-show-more"
                onClick={() => this.setShowMore(!showMore)}
              >
                <span>{showMore ? "Show More" : "Show Less"}</span>
                <Icon
                  name={IconNames.ThinChevronRight}
                  style={{
                    transform: showMore ? "rotate(90deg)" : "rotate(-90deg)",
                  }}
                />
              </div>
            )}
          </>
        )}
        <Modal
          show={this.state.showUploadModal}
          bsClass="manager modal"
          onHide={this.stopApiAction}
        >
          <Modal.Header closeButton={uploadProgress <= 0 && !stopApiAction}>
            <Modal.Title>Upload Microapp CSV</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProgressBar
              now={prepProgress / 3 + uploadProgress / 3 + compileProgress / 3}
              active
              label={`${Math.round(
                prepProgress / 3 + uploadProgress / 3 + compileProgress / 3
              )}%`}
            />
            <div
              className={`bot-upload-modal-line ${
                prepProgressActive ? "active" : ""
              }`}
            >
              <div>Preparing files...</div>
              <div>
                {prepProgress < 100 ? `${prepProgress}%` : "Complete!"}{" "}
                {prepProgressDone && (
                  <Glyphicon glyph="glyphicon glyphicon-ok-sign" />
                )}
              </div>
            </div>
            <div
              className={`bot-upload-modal-line ${
                compileProgressActive ? "active" : ""
              }`}
            >
              <div>Validating files...</div>
              <div>
                {compileProgress < 100 ? `${compileProgress}%` : "Complete!"}{" "}
                {compileProgressDone && (
                  <Glyphicon glyph="glyphicon glyphicon-ok-sign" />
                )}
              </div>
            </div>
            <div
              className={`bot-upload-modal-line ${
                uploadProgressActive > 0 ? "active" : ""
              }`}
            >
              <div>Uploading files...</div>
              <div>
                {uploadProgress < 100 ? `${uploadProgress}%` : "Complete!"}{" "}
                {uploadProgressDone && (
                  <Glyphicon glyph="glyphicon glyphicon-ok-sign" />
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.stopApiAction}
              disabled={uploadProgress > 0 || stopApiAction}
              bsStyle="danger"
            >
              {stopApiAction ? "Canceling..." : "Cancel"}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showDownloadModal} bsClass="manager modal">
          <Modal.Header>
            <Modal.Title>Download Microapp Version</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProgressBar
              now={
                prepDownloadProgress / 3 +
                downloadProgress / 3 +
                zipProgress / 3
              }
              active
              label={`${Math.round(
                prepDownloadProgress / 3 +
                  downloadProgress / 3 +
                  zipProgress / 3
              )}%`}
            />
            <div
              className={`bot-upload-modal-line ${
                prepDownloadProgressActive ? "active" : ""
              }`}
            >
              <div>Preparing files...</div>
              <div>
                {prepDownloadProgress < 100
                  ? `${prepDownloadProgress}%`
                  : "Complete!"}{" "}
                {prepDownloadProgressDone && (
                  <Glyphicon glyph="glyphicon glyphicon-ok-sign" />
                )}
              </div>
            </div>
            <div
              className={`bot-upload-modal-line ${
                downloadProgressActive ? "active" : ""
              }`}
            >
              <div>Downloading files...</div>
              <div>
                {downloadProgress < 100 ? `${downloadProgress}%` : "Complete!"}{" "}
                {downloadProgressDone && (
                  <Glyphicon glyph="glyphicon glyphicon-ok-sign" />
                )}
              </div>
            </div>
            <div
              className={`bot-upload-modal-line ${
                zipProgressActive > 0 ? "active" : ""
              }`}
            >
              <div>Compressing files...</div>
              <div>
                {zipProgress < 100 ? `${zipProgress}%` : "Complete!"}{" "}
                {zipProgressDone && (
                  <Glyphicon glyph="glyphicon glyphicon-ok-sign" />
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

Dialog.propTypes = {
  show: PropTypes.bool,
  editedIntent: PropTypes.bool,
  editedEntity: PropTypes.bool,
  editedStopWords: PropTypes.bool,
  intentData: PropTypes.string,
  entityData: PropTypes.string,
  customerGesId: PropTypes.string,
  latestVersion: PropTypes.object,
  customer: PropTypes.object,
  envs: PropTypes.array,
  botData: PropTypes.object,
  bot: PropTypes.object,
  currentVersions: PropTypes.array,
  presVersions: PropTypes.array,
  hasDraft: PropTypes.bool,
  customerId: PropTypes.string,
  botId: PropTypes.string,
  flags: PropTypes.object,
  botHasNewTemplates: PropTypes.func,
  getOrCreateDraftVersionId: PropTypes.func,
  onUpdateDraft: PropTypes.func,
  onDrawerSelectionChanged: PropTypes.func,
  restore: PropTypes.func,
  updateIntentData: PropTypes.func,
  updateEntityData: PropTypes.func,
  resetIntentData: PropTypes.func,
  resetEntityData: PropTypes.func,
  setEditedStopWords: PropTypes.func,
  setNotification: PropTypes.func,
};

Dialog.defaultProps = {
  show: false,
  customerGesId: "",
  latestVersion: {},
  customer: {},
  envs: [],
  botData: {},
  bot: {},
  currentVersions: [],
  presVersions: [],
  hasDraft: false,
  customerId: "",
  botId: "",
  flags: {},
  botHasNewTemplates: () => {},
  getOrCreateDraftVersionId: () => {},
  onUpdateDraft: () => {},
  onDrawerSelectionChanged: () => {},
  restore: () => {},
};

const mapStateToProps = (state, ownProps) => {
  const canEdit = isPermitted(
    PermissionResource.BOT,
    PermissionAction.WRITE,
    ownProps.customerGesId
  );
  const intentData = state.manager.intentData;
  const editedIntent = state.manager.editedIntent;
  const entityData = state.manager.entityData;
  const editedEntity = state.manager.editedEntity;
  const editedStopWords = state.manager.editedStopWords;
  const solutionInfo = state.manager.solutionInfo;

  return {
    canEdit,
    intentData,
    editedIntent,
    entityData,
    editedEntity,
    editedStopWords,
    solutionInfo,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateIntentData() {
    return dispatch(
      updateIntentData({
        data: Papa.unparse({
          fields: NLU_INTENT_HEADERS_SIMPLIFIED_H,
          data: [],
        }),
        isEdited: false,
      })
    );
  },
  updateEntityData() {
    return dispatch(
      updateEntityData({
        data: Papa.unparse({
          fields: NLU_ENTITY_HEADERS_H,
          data: [],
        }),
        isEdited: false,
      })
    );
  },
  resetIntentData() {
    return dispatch(resetIntentData());
  },
  resetEntityData() {
    return dispatch(resetEntityData());
  },

  setEditedStopWords(isEdited = false) {
    return dispatch(setEditedStopWords(isEdited));
  },
  setNotification(data) {
    return dispatch(setNotification(data));
  },
  updateSolutionInfo({ loading, title, error, description }) {
    return dispatch(updateSolutionInfo({ loading, title, error, description }));
  },
  validateSolution: (botId, version, autoTrain) =>
    dispatch(validateSolution(botId, version, autoTrain)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dialog));
