import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Icon, IconNames } from "ui-elements";

const SolutionHeader = ({ error, text, loading, header, content }) => {
  return (
    <div className="solution-header">
      <div className="solution-header-top">
        <div
          className={clsx("solution-header-top-left", {
            error,
            loading,
          })}
        >
          {loading ? (
            <Icon
              rotateIcon
              width={24}
              height={24}
              fill="#0a3ab4"
              name={IconNames.Loading}
            />
          ) : (
            <Icon
              width={24}
              height={24}
              fill={error ? "#e45f4f" : "#32b777"}
              name={error ? IconNames.InputError : IconNames.StatusOk}
            />
          )}
          <span className="solution-header-title">{text}</span>
        </div>
        <div className="solution-header-top-right">{header}</div>
      </div>
      <div className="solution-header-content">{content}</div>
    </div>
  );
};

SolutionHeader.propTypes = {
  error: PropTypes.bool,
  loading: PropTypes.bool,
  text: PropTypes.string,
  header: PropTypes.any,
  content: PropTypes.any,
};

SolutionHeader.defaultProps = {
  error: false,
  loading: false,
  text: "",
  header: null,
  content: null,
};

export default SolutionHeader;
