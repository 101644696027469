import { Button, Modal } from "react-bootstrap";
import React from "react";
import PropTypes from "prop-types";

const WarningModal = ({ show, messageBody, onCancel, onConfirm }) => (
  <Modal show={show} bsClass="manager modal" onHide={onCancel}>
    <Modal.Header closeButton>
      <Modal.Title>Warning</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="description-line">{messageBody}</div>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onConfirm}>Confirm</Button>
    </Modal.Footer>
  </Modal>
);

WarningModal.propTypes = {
  show: PropTypes.bool,
  messageBody: PropTypes.any,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

WarningModal.defaultProps = {
  show: false,
  messageBody: null,
  onCancel: () => {},
  onConfirm: () => {},
};

export { WarningModal };
