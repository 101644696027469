/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Modal, Col } from "react-bootstrap";
import { NLU_INTENT_HEADERS_H, NLU_INTENT_HEADERS_SIMPLIFIED_H } from "./defs";
import { getIntentFormat } from "./utils";
import botManagerAPI from "../../../bot-manager-api";
import {
  DataTable,
  Tabs,
  TextField,
  Button,
  Icon,
  IconNames,
  IconButton,
} from "ui-elements";
import { InputAdornment, LinearProgress } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Loader from "../Loader";
import { connect } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import { backgroundGrey300 } from "../../../utils/styleVariables";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { setNotification } from "Manager/Store/actions";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    minHeight: "40px",
    height: "40px",
    borderRadius: "4px",
  },
  bar1Determinate: {
    backgroundColor: "#6491e8",
  },
  colorPrimary: {
    backgroundColor: backgroundGrey300,
  },
}))(LinearProgress);

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testPhrase: "",
      testResults: null,
      callingServer: false,
      intentData: "",
      currentTab: 0,
      selectedUtterance: {},
      selectedIntent: "",
      editedUtterance: "",
      allIntents: [],
      intentsWithPercentages: [],
      newUtterance: "",
      utteranceExistsInData: false,
      showModal: false,
    };

    this.test = this.test.bind(this);
    this.updateTestPhrase = this.updateTestPhrase.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.capitalize = this.capitalize.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.setOption = this.setOption.bind(this);
    this.handlePressEnter = this.handlePressEnter.bind(this);
    this.clearInput = this.clearInput.bind(this);
    this.handlePressEnter = this.handlePressEnter.bind(this);
    this.handleIntentPageChange = this.handleIntentPageChange.bind(this);
    this.handleEntityPageChange = this.handleEntityPageChange.bind(this);
  }

  async componentDidMount() {
    const { flags } = this.props;

    if (this.props.latestVersion) {
      const csvFileFromServerIntent = await this.getFileFromServer(
        "templates/intent.csv"
      );
      const intentData = getIntentFormat(
        csvFileFromServerIntent,
        NLU_INTENT_HEADERS_H,
        NLU_INTENT_HEADERS_SIMPLIFIED_H
      );
      this.setState({
        intentData: intentData,
      });
    }
  }

  async getFileFromServer(filename) {
    return new Promise((resolve, reject) => {
      botManagerAPI
        .downloadTemplateFile(this.props.latestVersion.id, filename)
        .then((response) => {
          resolve(response.file_data);
        });
    });
  }
  async test() {
    try {
      this.setState({
        testResults: null,
        callingServer: true,
      });
      const res = await botManagerAPI.testVersion(
        this.props.latestVersion.id,
        this.state.testPhrase
      );
      if (res.statusCode === 200) {
        const testResults = JSON.parse(res.responseMessage);
        if (typeof testResults.data === "string") {
          this.setState({
            testResults: JSON.parse(testResults.data).output,
            callingServer: false,
          });
        } else if (typeof testResults.data === "object") {
          this.setState({
            testResults: testResults.data.output,
            callingServer: false,
          });
        }
      } else {
        this.props.setNotification({
          openNotification: true,
          notificationDuration: 3000,
          notificationTitle: res.statusCode,
          notificationType: "error",
        });
        this.setState({ callingServer: false });
      }
    } catch ({ errorMessage, statusCode }) {
      this.props.setNotification({
        openNotification: true,
        notificationDuration: 3000,
        notificationTitle: errorMessage,
        notificationType: "error",
      });
      this.setState({ callingServer: false });
    }
  }

  updateTestPhrase(event) {
    this.setState({
      testPhrase: event.target.value,
    });
  }

  changeTab(tab) {
    this.setState({
      currentTab: tab,
    });
  }

  capitalize(string) {
    if (typeof string !== "string") return "";
    return string
      .split(" ")
      .reduce((acc, el) => {
        acc.push(el.charAt(0).toUpperCase() + el.slice(1));
        return acc;
      }, [])
      .join(" ");
  }

  toggleModal(newState, intent = {}) {
    const { intents } = this.props;
    const { testResults } = this.state;
    const utterance = testResults;
    let intentsWithPercentages = [];
    const utteranceIntents = utterance.intents;

    Object.keys(intents).forEach((intentKey) => {
      const matchedIntent = utteranceIntents.find(
        (intentFound) => intentFound.intent_name === intentKey
      );
      if (matchedIntent !== undefined) {
        let newLabel = matchedIntent.intent_name.replace(/_/g, " ");
        newLabel = this.capitalize(newLabel);

        intentsWithPercentages.push({
          label: `${newLabel} (${(matchedIntent.score * 100).toFixed(1)}%)`,
          value: matchedIntent.intent_name,
        });
      } else if (matchedIntent === undefined) {
        let newLabel = intentKey;
        newLabel = this.capitalize(newLabel);

        intentsWithPercentages.push({
          label: `${newLabel}`,
          value: intentKey,
        });
      }
    });

    utteranceIntents.forEach((intent) => {
      const intentAlreadyExists = Object.keys(intents).find(
        (trainedIntent) => trainedIntent === intent.intent_name
      );
      if (!intentAlreadyExists) {
        let newLabel = intent.intent_name.replace(/_/g, " ");
        newLabel = this.capitalize(newLabel);

        intentsWithPercentages.push({
          label: `${newLabel} (${(intent.score * 100).toFixed(1)}%)`,
          value: intent.intent_name,
        });
      }
    });

    intentsWithPercentages = intentsWithPercentages
      .filter((intent) => intent.value !== "ps_temp_intent_hidden")
      .filter((intent) => intent.value !== "columns")
      .filter((intent) => intent.value !== "intents");

    if (newState === true) {
      this.setState({
        showModal: newState,
        intentsWithPercentages,
        selectedIntent: intent,
      });
    } else {
      this.setState({
        showModal: newState,
        selectedUtterance: "",
        option: "",
        selectedIntent: "",
        intentsWithPercentages: [],
      });
    }
  }

  setOption(newInputLabel) {
    this.setState({ option: newInputLabel });
  }

  handlePressEnter(e) {
    if (e.keyCode === 13) {
      this.test();
    }
  }

  clearInput() {
    this.setState({
      testPhrase: "",
    });
  }

  handleIntentPageChange(e) {
    return;
  }

  handleEntityPageChange(e) {
    return;
  }

  render() {
    const { show, latestVersion, botData } = this.props;
    const {
      testResults,
      callingServer,
      currentTab,
      testPhrase,
      showModal,
      utteranceExistsInData,
      intentsWithPercentages,
      selectedIntent,
    } = this.state;
    const { changeTab, handlePressEnter } = this;
    if (!show || !latestVersion) {
      return null;
    }

    const editUtteranceModal = (
      <Modal
        className="edit-utterance-modal"
        dialogClassName="modal-width"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={showModal}
        onHide={() => this.toggleModal(false)}
        backdrop="static"
        enforceFocus={false}
      >
        <Modal.Header className="edit-utterance-modal--header">
          <Modal.Title>Add to Training Data</Modal.Title>
        </Modal.Header>
        <Modal.Body className="edit-utterance-modal--body">
          <p>
            Modify the utterance or intent label below before adding this to
            your intent training data.
          </p>
          <form>
            <div className="display-utterance">
              <Col lg={8} style={{ paddingLeft: 0 }}>
                <TextField
                  error={utteranceExistsInData}
                  label="utterance"
                  value={testResults && testResults.user_input}
                  onChange={this.handleUtteranceChange}
                  variant="outlined"
                  fullWidth
                  helperText={
                    utteranceExistsInData
                      ? "Utterance already exists in training data"
                      : null
                  }
                  InputProps={
                    utteranceExistsInData
                      ? {
                          endAdornment: (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <g fill="none" fillRule="evenodd">
                                <g>
                                  <g transform="translate(-754 -204) translate(754 204)">
                                    <circle
                                      cx="12"
                                      cy="12"
                                      r="12"
                                      fill="#FFF"
                                    />
                                    <path
                                      fill="#E45F4F"
                                      d="M10.7 3.75c.577-1 2.024-1 2.6 0l7.496 13c.578 1-.147 2.25-1.297 2.25H4.502c-1.156 0-1.875-1.253-1.3-2.25zM12 15.125c-.485 0-.876.39-.876.875 0 .484.39.875.875.875s.875-.39.875-.875c0-.484-.39-.875-.875-.875zM12.551 8h-1.11c-.105 0-.19.087-.187.194l.235 6.125c.003.103.087.181.187.181h.64c.1 0 .185-.081.188-.181l.235-6.125c.003-.107-.081-.194-.188-.194z"
                                    />
                                  </g>
                                </g>
                              </g>
                            </svg>
                          ),
                        }
                      : {}
                  }
                  InputLabelProps={{
                    style: {
                      textTransform: "capitalize",
                    },
                  }}
                  FormHelperTextProps={{
                    style: {
                      margin: 0,
                      marginLeft: "16px",
                      fontSize: "12px",
                    },
                  }}
                />
              </Col>
              <Col lg={4} style={{ paddingRight: 0 }}>
                <Autocomplete
                  options={intentsWithPercentages ? intentsWithPercentages : []}
                  getOptionLabel={(option) => option.label || option}
                  defaultValue={
                    intentsWithPercentages.length > 0
                      ? intentsWithPercentages.find(
                          (intent) => intent.value === selectedIntent
                        )
                        ? intentsWithPercentages.find(
                            (intent) => intent.value === selectedIntent
                          ).label
                        : null
                      : null
                  }
                  autoHighlight
                  className="autocomplete"
                  popupIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none" fillRule="evenodd">
                        <g>
                          <g
                            style={{ transition: "none" }}
                            transform="translate(-80 -300) translate(80 300)"
                          >
                            <circle cx="12" cy="12" r="12" fill="#FFF" />
                            <path
                              fill="#0A3AB4"
                              d="M12.265 16.46l6.594-6.597c.15-.147.15-.384.003-.531l-.222-.222c-.147-.147-.384-.147-.531 0l-6.106 6.11-6.11-6.11c-.147-.147-.384-.147-.531 0l-.222.222c-.147.147-.147.384 0 .531l6.594 6.597c.147.147.384.147.531 0z"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  }
                  inputValue={
                    this.state.option !== undefined
                      ? this.state.option
                      : selectedIntent
                  }
                  onInputChange={(event, newInputValue) => {
                    this.setOption(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="intent"
                      InputLabelProps={{
                        style: {
                          textTransform: "capitalize",
                          fill: "#bbbbbb",
                        },
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Col>
            </div>
          </form>
          <div className="text-right">
            <Button
              type="button"
              onClick={() => this.toggleModal(false)}
              hollow
              style={{ marginRight: ".57rem" }}
            >
              Cancel
            </Button>
            <Button
              style={{ marginRight: 0 }}
              disabled={utteranceExistsInData}
              onClick={() => this.toggleModal(false)}
            >
              Add
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );

    const intentsAndUtterances =
      this.props.intents && this.props.intents.intents;
    const resultIntents =
      testResults && intentsAndUtterances && testResults.intents;
    const matchedIntents =
      resultIntents &&
      resultIntents.reduce((acc, el) => {
        const matchedIntent = intentsAndUtterances[el.intent_name];
        if (matchedIntent) {
          let firstFiveUtterances = intentsAndUtterances[el.intent_name].slice(
            0,
            5
          );
          const utterancesToDisplay = 5;

          // Stringify first 5 utterances and join them with •
          firstFiveUtterances = firstFiveUtterances.reduce((acc, el) => {
            acc += `${el.utterance} • `;
            return acc;
          }, "");

          if (
            intentsAndUtterances[el.intent_name].length > utterancesToDisplay
          ) {
            const lengthDifference =
              intentsAndUtterances[el.intent_name].length - utterancesToDisplay;
            firstFiveUtterances += `${lengthDifference} more`;
          } else {
            firstFiveUtterances = firstFiveUtterances
              .split(" • ")
              .slice(0, firstFiveUtterances.length - 1)
              .join(" • ");
          }

          acc.push({
            intent_name: el.intent_name,
            utterances: firstFiveUtterances,
            score: el.score,
          });
        }
        return acc;
      }, []);

    let intentColumns = [
      {
        title: "Conf",
        dataIndex: "score",
        key: "score",
        hover: true,
        sorter: (a, b) => (a.score > b.score ? -1 : 1),
        render: (value) => (
          <p className="table-big-numbers">{(value.score * 100).toFixed(1)}%</p>
        ),
        width: "10%",
      },
      {
        title: "Intent",
        dataIndex: "intent_name",
        key: "intent_name",
        hover: true,
        sorter: (a, b) => (a.intent_name > b.intent_name ? -1 : 1),
        render: (value) => (
          <p className="table-bold">
            {this.capitalize(value.intent_name.replace(/_/g, " "))}
          </p>
        ),
        width: "20%",
      },
      {
        title: "Training Utterances",
        dataIndex: "utterances",
        key: "utterances",
        hover: true,
        render: (value) => (
          <p style={{ padding: "14px 0" }}>{value.utterances}</p>
        ),
        width: "40%",
      },
      {
        title: "",
        dataIndex: Math.random(),
        key: Math.random(),
        align: "right",
        width: "30%",
        showOnHover: true,
        render: (value) => (
          <Button onClick={() => this.toggleModal(true, value.intent_name)}>
            NLU Training
          </Button>
        ),
      },
    ];

    const entityColumns = [
      {
        title: "Type",
        dataIndex: "entity_type",
        key: "entity_type",
        hover: true,
        sorter: (a, b) => (a.entity_type > b.entity_type ? -1 : 1),
        render: (value) => (
          <div style={{ padding: "14px 0" }}>
            <p className="table-bold">{this.capitalize(value.entity_type)}</p>
            <p className="text-gray">
              {value.entity_type.includes("sys-")
                ? "System Entity"
                : "List Entity"}
            </p>
          </div>
        ),
      },
      {
        title: "Entity",
        dataIndex: "entity_name",
        key: "entity_name",
        hover: true,
        sorter: (a, b) => (a.entity_name > b.entity_name ? 1 : -1),
        render: (value) => <p>{this.capitalize(value.entity_name)}</p>,
      },
      {
        title: "Matching Text",
        dataIndex: "match",
        key: "match",
        hover: true,
        sorter: (a, b) => (a.match > b.match ? 1 : -1),
        render: (value) => <p>{`"${value.match}"`}</p>,
      },
    ];

    let emotionObject = testResults && testResults.emotion;
    let emotionsArray = [];
    if (emotionObject) {
      emotionsArray = Object.keys(emotionObject)
        .reduce((acc, emotionKey) => {
          const value = emotionObject[emotionKey];
          const capitalizedName =
            emotionKey.charAt(0).toUpperCase() + emotionKey.slice(1);
          acc.push({ name: capitalizedName, value });
          return acc;
        }, [])
        .sort((a, b) => (a.value * 100 > b.value * 100 ? -1 : 1));
    }

    const sentimentAndEmotionColumns = [
      {
        title: "Conf",
        dataIndex: "value" + Math.random(),
        key: "value" + Math.random(),
        hover: true,
        render: (value) => (
          <span className="table-big-numbers">
            {(value.value * 100).toFixed(1)}%
          </span>
        ),
        sorter: (a, b) => (a.value > b.value ? -1 : 1),
        width: "10%",
      },
      {
        title: "Emotion",
        dataIndex: "name",
        key: "name",
        hover: true,
        width: "90%",
      },
    ];

    const sentimentScore =
      testResults &&
      testResults.sentiment &&
      testResults.sentiment.score &&
      (testResults.sentiment.score * 100).toFixed(1);
    const minScore = -100;
    const maxScore = 100;
    const normalise = (value) =>
      ((value - minScore) * 100) / (maxScore - minScore);

    let TabData = [
      {
        label: "Intents",
        children:
          testResults &&
          (testResults.intents ? (
            <div style={{ marginTop: "2rem" }}>
              <DataTable
                columns={intentColumns}
                dataSource={matchedIntents}
                rowKey="intent"
                bordered={false}
                pagination={{
                  current: 1,
                  pageSize: 5,
                }}
                onPageChange={this.handleIntentPageChange}
              />
            </div>
          ) : (
            <p style={{ marginTop: "1rem" }} className="body-notation">
              No Intents matched this utterance
            </p>
          )),
      },
      {
        label: "Entities",
        children:
          testResults &&
          (testResults.entities[0].entity_name ? (
            <div style={{ marginTop: "2rem" }}>
              <DataTable
                columns={entityColumns}
                dataSource={testResults.entities}
                rowKey="entity"
                bordered={false}
                pagination={{
                  current: 1,
                  pageSize: 5,
                }}
                onPageChange={this.handleEntityPageChange}
              />
            </div>
          ) : (
            <p style={{ marginTop: "1rem" }} className="body-notation">
              No Entities matched this utterance
            </p>
          )),
      },
    ];
    TabData.push({
      label: "Sentiment/Emotion",
      children:
        testResults &&
        testResults.sentiment &&
        (testResults.sentiment.score ? (
          <Row style={{ marginTop: "2rem" }}>
            <Col md={6}>
              <p className="body-header" style={{ margin: "1.3rem 0" }}>
                Sentiment
              </p>
              <BorderLinearProgress
                variant="determinate"
                value={normalise(sentimentScore)}
              />
              <Row style={{ marginTop: ".5rem" }}>
                <Col md={4}>
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g fill="none" fillRule="evenodd">
                        <g transform="translate(-432 -662)">
                          <path fill="none" d="M0 0H1440V1200H0z" />
                          <rect
                            width="358"
                            height="40"
                            x="420"
                            y="650"
                            fill="none"
                            rx="4"
                          />
                          <path
                            fill="none"
                            d="M424 650h192v40H424c-2.21 0-4-1.79-4-4v-32c0-2.21 1.79-4 4-4z"
                          />
                          <g transform="translate(428 658)">
                            <circle cx="12" cy="12" r="12" />
                            <path d="M12 19.75c4.28 0 7.75-3.47 7.75-7.75 0-4.28-3.47-7.75-7.75-7.75-4.28 0-7.75 3.47-7.75 7.75 0 4.28 3.47 7.75 7.75 7.75z" />
                            <path
                              fill="#29302E"
                              d="M12 4.25c4.281 0 7.75 3.469 7.75 7.75 0 4.281-3.469 7.75-7.75 7.75-4.281 0-7.75-3.469-7.75-7.75 0-4.281 3.469-7.75 7.75-7.75zm0 1c-3.722 0-6.75 3.028-6.75 6.75s3.028 6.75 6.75 6.75 6.75-3.028 6.75-6.75S15.722 5.25 12 5.25zM12 14c1.387 0 2.694.612 3.581 1.681.178.21.15.525-.062.703-.222.188-.538.138-.703-.062-.7-.838-1.729-1.319-2.82-1.319-1.09 0-2.118.481-2.818 1.319-.175.212-.49.24-.703.062-.212-.175-.24-.49-.063-.703C9.306 14.612 10.612 14 12 14zM9.5 9.5c.553 0 1 .447 1 1 0 .553-.447 1-1 1-.553 0-1-.447-1-1 0-.553.447-1 1-1zm5 0c.553 0 1 .447 1 1 0 .553-.447 1-1 1-.553 0-1-.447-1-1 0-.553.447-1 1-1z"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                </Col>
                <Col md={4}>
                  <p style={{ textAlign: "center" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g fill="none" fillRule="evenodd">
                        <g transform="translate(-591 -662)">
                          <path fill="none" d="M0 0H1440V1200H0z" />
                          <rect
                            width="358"
                            height="40"
                            x="420"
                            y="650"
                            fill="none"
                            rx="4"
                          />
                          <path
                            fill="none"
                            d="M424 650h192v40H424c-2.21 0-4-1.79-4-4v-32c0-2.21 1.79-4 4-4z"
                          />
                          <g transform="translate(587 658)">
                            <circle cx="12" cy="12" r="12" />
                            <path d="M12 19.75c4.28 0 7.75-3.47 7.75-7.75 0-4.28-3.47-7.75-7.75-7.75-4.28 0-7.75 3.47-7.75 7.75 0 4.28 3.47 7.75 7.75 7.75z" />
                            <path
                              fill="#29302E"
                              d="M12 4.25c4.281 0 7.75 3.469 7.75 7.75 0 4.281-3.469 7.75-7.75 7.75-4.281 0-7.75-3.469-7.75-7.75 0-4.281 3.469-7.75 7.75-7.75zm0 1c-3.722 0-6.75 3.028-6.75 6.75s3.028 6.75 6.75 6.75 6.75-3.028 6.75-6.75S15.722 5.25 12 5.25zm3 9.25c.275 0 .5.225.5.5s-.225.5-.5.5H9c-.275 0-.5-.225-.5-.5s.225-.5.5-.5zm-5.5-5c.553 0 1 .447 1 1 0 .553-.447 1-1 1-.553 0-1-.447-1-1 0-.553.447-1 1-1zm5 0c.553 0 1 .447 1 1 0 .553-.447 1-1 1-.553 0-1-.447-1-1 0-.553.447-1 1-1z"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                </Col>
                <Col md={4}>
                  <p style={{ textAlign: "right" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g fill="none" fillRule="evenodd">
                        <g transform="translate(-750 -662)">
                          <path fill="none" d="M0 0H1440V1200H0z" />
                          <rect
                            width="358"
                            height="40"
                            x="420"
                            y="650"
                            fill="none"
                            rx="4"
                          />
                          <g transform="translate(746 658)">
                            <circle cx="12" cy="12" r="12" />
                            <path d="M12 19.75c4.28 0 7.75-3.47 7.75-7.75 0-4.28-3.47-7.75-7.75-7.75-4.28 0-7.75 3.47-7.75 7.75 0 4.28 3.47 7.75 7.75 7.75z" />
                            <path
                              fill="#29302E"
                              d="M12 4.25c4.281 0 7.75 3.469 7.75 7.75 0 4.281-3.469 7.75-7.75 7.75-4.281 0-7.75-3.469-7.75-7.75 0-4.281 3.469-7.75 7.75-7.75zm0 1c-3.722 0-6.75 3.028-6.75 6.75s3.028 6.75 6.75 6.75 6.75-3.028 6.75-6.75S15.722 5.25 12 5.25zm2.819 8.931c.178-.212.493-.24.706-.065.212.175.24.49.062.703-.89 1.069-2.2 1.681-3.587 1.681-1.388 0-2.697-.612-3.584-1.678-.179-.213-.15-.525.062-.703.21-.178.525-.15.703.062.7.838 1.728 1.319 2.819 1.319 1.09 0 2.119-.481 2.819-1.319zM9.5 9.5c.553 0 1 .447 1 1 0 .553-.447 1-1 1-.553 0-1-.447-1-1 0-.553.447-1 1-1zm5 0c.553 0 1 .447 1 1 0 .553-.447 1-1 1-.553 0-1-.447-1-1 0-.553.447-1 1-1z"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </p>
                </Col>
              </Row>
              <p
                className="table-big-numbers"
                style={{ textAlign: "center", marginTop: "1rem" }}
              >
                {sentimentScore >= 0 && "+"}
                {sentimentScore}%
              </p>
            </Col>
            <Col md={6}>
              <DataTable
                columns={sentimentAndEmotionColumns}
                dataSource={emotionsArray}
                rowKey="value"
                bordered={false}
              />
            </Col>
          </Row>
        ) : (
          <p style={{ marginTop: "1rem" }} className="body-notation">
            {" "}
            No Sentiments matched this utterance
          </p>
        )),
    });

    return (
      <div style={{ marginBottom: "2rem" }}>
        <div className="section-title">
          <h3>NLU Testing</h3>
        </div>

        <p>
          Natural Language Understanding (NLU) is used to help your microapp
          understand any written text (aka utterances) sent to it by users.
          Utterances are either classified as intents and entities (uploaded as
          CSV files from the Templates tab) or as Q&A nodes included in the
          conversation flow.
        </p>

        <br />
        <br />
        <h4>Test Single Utterance</h4>
        <TextField
          label="Utterance"
          variant="outlined"
          value={this.state.testPhrase}
          onChange={this.updateTestPhrase}
          onKeyDown={handlePressEnter}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  iconName={IconNames.Clear}
                  onClick={this.clearInput}
                />
                <Button
                  style={{ marginLeft: "8px" }}
                  disabled={!botData.isTrained || testPhrase === ""}
                  onClick={() => this.test()}
                >
                  Test
                </Button>
              </InputAdornment>
            ),
          }}
        />
        {!botData.isTrained && (
          <small>Microapp must first be trained before testing your NLU!</small>
        )}

        {callingServer && (
          <div style={{ marginTop: "10%" }}>
            <Loader center={false} />
          </div>
        )}

        {testResults && (
          <div style={{ marginTop: "2rem" }}>
            <Tabs
              tabData={TabData}
              value={currentTab}
              onChange={changeTab}
              size="small"
              hasBottomLine
              hasBorder={false}
            />
          </div>
        )}
        {editUtteranceModal}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setNotification: (data) => dispatch(setNotification(data)),
  };
};

Test.propTypes = {
  flags: PropTypes.object,
  latestVersion: PropTypes.object,
  intents: PropTypes.object,
  show: PropTypes.bool,
  botData: PropTypes.object,
  setNotification: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(withLDConsumer()(Test));
